<script setup lang="ts">
import { UrlUtils } from '@swimm/shared';
import { Action, TextField } from '@swimm/ui';
import { computed, ref } from 'vue';

withDefaults(
  defineProps<{
    loading?: boolean;
  }>(),
  {
    loading: false,
  }
);

const emit = defineEmits<{
  (e: 'invite', emails: string[]): void;
}>();

const error = ref('');

const emails = ref('');
const emailsArray = computed(() =>
  emails.value
    .split(',')
    .map((email) => email.trim().toLowerCase())
    .filter(Boolean)
);
const validEmailField = computed(() => emailsArray.value.every(UrlUtils.isValidEmail));
const shouldDisableSend = computed(() => !validEmailField.value || !emails.value.length);

function onBlur() {
  error.value = validEmailField.value ? '' : 'Some emails are not valid';
}

function invite() {
  if (!shouldDisableSend.value) {
    error.value = '';
    emit('invite', emailsArray.value);
    emails.value = '';
  }
}
</script>

<template>
  <form class="invite-form" @submit.prevent="invite">
    <TextField
      class="field"
      v-model="emails"
      :error="error"
      placeholder="Work emails, comma separated"
      data-testid="add-emails-input"
      @blur="onBlur"
    />
    <Action class="action" :disabled="shouldDisableSend || loading" :loading="loading" data-testid="invite-users-button"
      >Add users</Action
    >
  </form>
</template>

<style scoped lang="postcss">
.invite-form {
  display: flex;
  gap: var(--space-base);

  .field {
    flex: 1;
  }

  .action {
    height: 32px;
    align-self: flex-start;
  }
}
</style>

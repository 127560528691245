/* Return default notifications settings */
import { NOTIFICATION_TARGETS, notificationsSettingsData } from '@swimm/shared';
import * as firestore from '@/adapters-common/firestore-wrapper';
import { config } from '@swimm/shared';

async function getUserFromDb(userId: string) {
  const result = await firestore.getDocFromCollection(firestore.collectionNames.USERS, userId);
  if (result.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(result.errorMessage);
  }
  return result.data;
}

// Return the user notifications settings combined with the default settings

export async function getUserNotificationsSettingsData(userId) {
  const user = await getUserFromDb(userId);
  const userSettings = user['notifications_settings'] || {};
  const notificationSettings = {};
  for (const data of notificationsSettingsData.filter((d) => !d.noUserSettings)) {
    notificationSettings[data.type] = {};
    for (const target of Object.values(NOTIFICATION_TARGETS)) {
      const defaultValue = data.initials.includes(target);
      const userValue = (userSettings[data.type] || {})[target];
      const hasUserValue = userValue === true || userValue === false;
      notificationSettings[data.type][target] = hasUserValue ? userValue : defaultValue;
    }
  }
  const isInSlack = !!(user['slack_user_id'] && user['slack_team_id']);
  return { notificationSettings, isInSlack };
}

export async function updateUserNotificationsSettings(userId, notificationsSettings) {
  const result = await firestore.setValuesInDoc(
    firestore.collectionNames.USERS,
    userId,
    { notifications_settings: notificationsSettings },
    { merge: true }
  );
  if (result.code !== config.SUCCESS_RETURN_CODE) {
    throw new Error(result.errorMessage);
  }
}

<template>
  <div v-if="!loading" :class="{ saving: saving }">
    <div class="headline3"><b>Docs</b></div>
    <div class="notifications-row">
      <div class="col-title bold">Notify me when:</div>
      <div class="col-checkbox">Email</div>
      <div class="col-checkbox">In-app</div>
      <div class="col-checkbox">Slack</div>
    </div>
    <Divider />
    <div v-for="notificationData of notificationsData" :key="notificationData.type" class="notifications-row">
      <div class="col-title">{{ notificationData.title }}</div>
      <div v-for="target of targets" :key="target" class="col-checkbox">
        <VMenu popper-class="tooltip-on-modal">
          <Checkbox
            v-if="showCheckbox(notificationData, target)"
            v-model="notificationsSettings[notificationData.type][target]"
            size="small"
            :disabled="isDisabled(notificationData.type, target)"
          />
          <template #popper>
            <div v-if="showSlackPopUp(target)" class="slack-popup">
              <div class="subtitle-L bold">Connect to Swimm’s Slack App</div>
              <div class="body-XS">
                You need to connect your Swimm account to Slack in order to configure this setting.
              </div>
              <a v-if="slackUrl" :href="slackUrl" target="_blank">
                <Action size="small" class="slack-popup-action">Connect to Slack</Action>
              </a>
            </div>
          </template>
        </VMenu>
      </div>
    </div>
  </div>
  <Loader v-if="saving || loading" />
</template>

<script>
import { SLACK_APP_ID } from '@/config';
import { NOTIFICATION_TARGETS, NOTIFICATION_TYPES, notificationsSettingsData } from '@swimm/shared';
import { mapGetters } from 'vuex';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import {
  getUserNotificationsSettingsData,
  updateUserNotificationsSettings,
} from '@/modules/notifications-center/services/user-settings';
import { Checkbox, Divider } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { toRaw } from 'vue';

export default {
  setup() {
    const { user } = storeToRefs(useAuthStore());

    return { user, NOTIFICATION_TARGETS };
  },
  components: { Checkbox, Divider },
  data() {
    const targets = [NOTIFICATION_TARGETS.EMAIL, NOTIFICATION_TARGETS.IN_APP, NOTIFICATION_TARGETS.SLACK];
    return {
      notificationsData: notificationsSettingsData.filter((d) => !d.noUserSettings),
      targets,
      notificationsSettings: null,
      saving: false,
      loading: true,
      isInSlack: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getSlackUrl']),
    slackUrl() {
      return this.db_getSlackUrl(this.$route.params.workspaceId, SLACK_APP_ID);
    },
  },
  async created() {
    await this.loadData();
    this.$watch('notificationsSettings', this.saveData, { deep: true });
  },
  methods: {
    async notifyError() {
      await swal({ title: 'Unexpected error, please reopen the modal', content: SWAL_CONTACT_US_CONTENT() });
    },
    showSlackPopUp(target) {
      return target === NOTIFICATION_TARGETS.SLACK && !this.isInSlack;
    },
    showCheckbox(notificationData, target) {
      return !(notificationData.excludeTargets && notificationData.excludeTargets.includes(target));
    },
    isDisabled(notificationType, target) {
      if (target === NOTIFICATION_TARGETS.SLACK) {
        return !this.isInSlack;
      }

      return false;
    },
    async saveData() {
      try {
        this.saving = true;
        await updateUserNotificationsSettings(this.user.uid, this.notificationsSettings);
      } catch (err) {
        this.$logger.error(`Failed to update user notification settings for user ${this.user.uid} ${err}`);
        await this.notifyError();
      } finally {
        this.saving = false;
      }
    },
    filterAssigneeData() {
      // dead feature
      this.notificationsData = toRaw(this.notificationsData).filter((d) => d.type !== NOTIFICATION_TYPES.ASSIGNMENT);
    },
    async loadData() {
      try {
        const { notificationSettings, isInSlack } = await getUserNotificationsSettingsData(this.user.uid);
        this.notificationsSettings = notificationSettings;
        this.isInSlack = isInSlack;
        this.filterAssigneeData();
      } catch (err) {
        this.$logger.error(`Failed to read user notification settings for user ${this.user.uid} ${err}`);
        await this.notifyError();
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.saving {
  pointer-events: none;
}

.notifications-row {
  display: flex;
  margin: 24px 0px 10px 0px;
}

.col-title {
  flex: 50%;
}

.col-checkbox {
  flex: 16.6%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slack-popup {
  display: flex;
  align-items: center;
  padding: 8px;
  width: 210px;
  flex-direction: column;
}

.slack-popup-action {
  margin-top: 8px;
}
</style>

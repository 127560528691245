import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { config, firestoreCollectionNames } from '@swimm/shared';

export const FirestoreFieldValue = firebase.firestore.FieldValue;

export const collectionNames = firestoreCollectionNames;

type FirestreOperationResult<T> = { code: 0; data: T } | { code: 1; errorMessage: string };
type DocDataResult = FirestreOperationResult<firebase.firestore.DocumentData>;
type DocRefResult = FirestreOperationResult<firebase.firestore.DocumentReference<firebase.firestore.DocumentData>>;
type DocSnapshotResult = FirestreOperationResult<firebase.firestore.DocumentSnapshot<firebase.firestore.DocumentData>>;

type QueryDocsResult = FirestreOperationResult<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>;
type CollectionRefResult = FirestreOperationResult<
  firebase.firestore.CollectionReference<firebase.firestore.DocumentData>
>;
type EmptyResult = { code: 0 } | { code: 1; errorMessage: string };

type ClauseArray = [string, firebase.firestore.WhereFilterOp, string | number | boolean];

type CollectionName = string;

export const SUPPORTED_WHERE_IN_VALUES = 30; // 'IN' supports up to 30 comparison values
/**
 * Get firestore server timestamp
 */
export function firestoreTimestamp(): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.serverTimestamp();
}

export function firestoreDeleteField(): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.delete();
}

/**
 * Get firestore timestamp for the current timestamp (similar to Date.now())
 */
export function firestoreTimestampNow(): firebase.firestore.Timestamp {
  return firebase.firestore.Timestamp.now();
}

export function firestoreTimestampInSeconds(seconds: number): firebase.firestore.Timestamp {
  const newTimestamp = Date.now() + seconds * 1000;
  return firebase.firestore.Timestamp.fromDate(new Date(newTimestamp));
}

export function firestoreTimestampFromDate(date: Date): firebase.firestore.Timestamp {
  return firebase.firestore.Timestamp.fromDate(date);
}

export function firestoreIncrement(n = 1): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.increment(n);
}

/**
 * Remove fields from array using firestore arrayRemove
 */
export function firestoreArrayRemove(toRemove: string | number): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.arrayRemove(toRemove);
}

/**
 * Add fields to array using firestore arrayUnion
 */
export function firestoreArrayUnion(toAdd: string | number): firebase.firestore.FieldValue {
  return firebase.firestore.FieldValue.arrayUnion(toAdd);
}

/**
 * Gets a doc by its ID from a certain collection
 */
export async function getDocFromCollection(collectionName: CollectionName, docId: string): Promise<DocDataResult> {
  try {
    const docRef = await firebase.firestore().collection(collectionName).doc(docId).get();
    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Doc ${docId} doesn't exist in collection ${collectionName}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collection
 * clause array is array of clauses (array of arrays), for example  [['email', '==', 'a@b.com'], ['value', '==', 12]]
 * order by is array of [field, direction]
 */
export async function getDocsRefWithWhereClauses(
  collectionName: CollectionName,
  clauseArrays: ClauseArray[],
  orderBy?: [string, firebase.firestore.OrderByDirection],
  limit?: number
) {
  try {
    let baseRef: firebase.firestore.Query = firebase.firestore().collection(collectionName);
    for (const clauseArray of clauseArrays) {
      baseRef = baseRef.where(...clauseArray);
    }
    if (orderBy) {
      baseRef = baseRef.orderBy(...orderBy);
    }

    if (limit) {
      baseRef = baseRef.limit(limit);
    }
    const docsRef = await baseRef.get();
    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collection ${collectionName} with clauses ${clauseArrays.join(',')}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * get multiple documents from a collection using an array of document IDs
 */
export async function getDocumentsByIds(collectionName: CollectionName, ids: string[]): Promise<QueryDocsResult> {
  try {
    if (ids.length > SUPPORTED_WHERE_IN_VALUES) {
      return {
        code: config.ERROR_RETURN_CODE,
        errorMessage: 'getDocumentsByIds: ids array length is greater than where in supported values',
      };
    }
    const docsRef = await firebase
      .firestore()
      .collection(collectionName)
      .where(firebase.firestore.FieldPath.documentId(), 'in', ids)
      .get();

    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }
    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collection ${collectionName} with ids ${ids}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collection
 * similar to getDocsRefWithWhereClauses, but for sub collection
 */
export async function getDocsRefFromSubCollectionWithWhereClauses(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  clauseArrays: ClauseArray[],
  getOptions?: firebase.firestore.GetOptions
): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
  let baseRef: firebase.firestore.Query = firebase
    .firestore()
    .collection(parentCollectionName)
    .doc(parentId)
    .collection(collectionName);

  for (const clauseArray of clauseArrays) {
    baseRef = baseRef.where(...clauseArray);
  }
  return await baseRef.get(getOptions);
}

/**
 * Gets a reference for docs that answer a certain where clause in a certain collectionGroup
 */
export async function getCollectionGroupRefWithWhereClause(
  collectionGroupName: string,
  clauseArray: ClauseArray
): Promise<QueryDocsResult> {
  try {
    const docsRef = await firebase
      .firestore()
      .collectionGroup(collectionGroupName)
      .where(...clauseArray)
      .get();
    if (docsRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: docsRef };
    }
    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Couldn't find docs in collectionGroupName ${collectionGroupName} with clause ${clauseArray}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets all docs in a certain sub collection
 */
export async function getSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  getOptions?: firebase.firestore.GetOptions
): Promise<QueryDocsResult> {
  try {
    const collectionRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .get(getOptions);

    if (collectionRef) {
      return { code: config.SUCCESS_RETURN_CODE, data: collectionRef };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Collection ${collectionName} doesn't exist under ${parentCollectionName}/${parentId}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Builds a reference to a certain sub collection, no actual access to firestore
 */
export function getSubCollectionRef(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName
): CollectionRefResult {
  try {
    const collectionRef = firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName);
    return { code: config.SUCCESS_RETURN_CODE, data: collectionRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a specific doc in a certain sub collection
 */
export async function getDocFromSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string
): Promise<DocDataResult> {
  try {
    const docRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .get();

    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }

    return {
      code: config.ERROR_RETURN_CODE,
      errorMessage: `Doc ${docId} doesn't exist under ${parentCollectionName}/${parentId}/${collectionName}`,
    };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a reference of a doc in a certain sub collection
 */
export async function getDocRefFromSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string
): Promise<DocSnapshotResult> {
  try {
    const docRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .get();

    return { code: config.SUCCESS_RETURN_CODE, data: docRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Build a doc ref in a certain hierarch of sub collections
 * no access to firestore
 */

export function getDocRefRecursive(
  collectionNames: CollectionName[],
  ids: string[]
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  if (collectionNames.length !== ids.length || collectionNames.length === 0) {
    throw Error('getDocRefRecursive: length of collection names should be equal to the length of ids and non mepty');
  }
  let ref = firebase.firestore().collection(collectionNames[0]).doc(ids[0]);
  for (let i = 1; i < ids.length; i++) {
    ref = ref.collection(collectionNames[i]).doc(ids[i]);
  }
  return ref;
}

/**
 * Gets the collection ref in a certain hierarch of sub collections
 * no access to firestore
 */

export function getSubCollectionRefRecursive(
  collectionNames: CollectionName[],
  ids: string[]
): firebase.firestore.CollectionReference<firebase.firestore.DocumentData> {
  if (collectionNames.length !== ids.length + 1) {
    throw Error('getSubCollectionRefRecursive: length of collection names should be 1 + length of ids');
  }
  let ref = firebase.firestore().collection(collectionNames[0]);
  for (let i = 0; i < ids.length; i++) {
    ref = ref.doc(ids[i]).collection(collectionNames[i + 1]);
  }
  return ref;
}

/**
 * Gets the docs in a certain hierarch of sub collections
 */

export async function getSubCollectionRecursive(
  parentCollectionNames: string[],
  parentIds: string[]
): Promise<QueryDocsResult> {
  try {
    const collection = await getSubCollectionRefRecursive(parentCollectionNames, parentIds).get();
    return { code: config.SUCCESS_RETURN_CODE, data: collection };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Gets a doc from a collection reference
 */
export async function getDocFromRef(collectionRef: firebase.firestore.CollectionReference, docId: string) {
  try {
    const docRef = await collectionRef.doc(docId).get();
    if (docRef.exists) {
      return { code: config.SUCCESS_RETURN_CODE, data: docRef.data() };
    }
    return { code: config.ERROR_RETURN_CODE, errorMessage: `Doc ${docId} doesn't exist in ref` };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Updates fields of a specific doc
 */
export async function updateDocInCollection(
  collectionName: CollectionName,
  docId: string,
  updateValue: firebase.firestore.DocumentData
): Promise<EmptyResult> {
  try {
    await firebase
      .firestore()
      .collection(collectionName)
      .doc(docId)
      .update({ ...updateValue });

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Delete a specific doc
 */
export async function deleteDocFromCollection(collectionName: CollectionName, docId: string): Promise<EmptyResult> {
  try {
    await firebase.firestore().collection(collectionName).doc(docId).delete();
    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Updates fields of a specific doc in a sub collection
 */
export async function updateDocInSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string,
  updateValue: firebase.firestore.DocumentData
): Promise<EmptyResult> {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .update({ ...updateValue });

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc
 */
export async function setValuesInDoc(
  collectionName: CollectionName,
  docId: string,
  setValues: firebase.firestore.DocumentData,
  options: firebase.firestore.SetOptions = {}
): Promise<EmptyResult> {
  try {
    await firebase.firestore().collection(collectionName).doc(docId).set(setValues, options);
    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc in a sub collection
 */
export async function setValuesInDocInSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string,
  setValues: firebase.firestore.DocumentData,
  options: firebase.firestore.SetOptions = {}
): Promise<EmptyResult> {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .set(setValues, options);
    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Set fields in an existing doc in a sub collection
 */
export async function setValuesInDocSubCollectionRecursive(
  parentCollectionNames: CollectionName[],
  parentIds: string[],
  setValues: firebase.firestore.DocumentData,
  options: firebase.firestore.SetOptions = {}
) {
  try {
    if (parentCollectionNames.length !== parentIds.length || parentCollectionNames.length === 0) {
      throw new Error('collection and ids must be non empty arrays of the same length');
    }
    let docRef = firebase.firestore().collection(parentCollectionNames[0]).doc(parentIds[0]);
    for (let i = 1; i < parentIds.length; i++) {
      docRef = docRef.collection(parentCollectionNames[i]).doc(parentIds[i]);
    }
    await docRef.set(setValues, options);
    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Add a new doc to a collection
 * @param collectionName - the collection to query
 * @param addValues - to add to the collection
 */
export async function addDocToCollection(
  collectionName: CollectionName,
  addValues: firebase.firestore.DocumentData
): Promise<DocRefResult> {
  try {
    const addedRef = await firebase.firestore().collection(collectionName).add(addValues);
    return { code: config.SUCCESS_RETURN_CODE, data: addedRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Add a new doc to a sub collection
 */
export async function addDocToSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  addValues: firebase.firestore.DocumentData
): Promise<DocRefResult> {
  try {
    const addedRef = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .add(addValues);

    return { code: config.SUCCESS_RETURN_CODE, data: addedRef };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Create a new doc to a sub collection with a specific id
 */
export async function createDocInSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string,
  addValues: firebase.firestore.DocumentData
): Promise<EmptyResult> {
  try {
    const existingId = await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .get();

    if (existingId.exists) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: `ID ${docId} already exists` };
    }

    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .set(addValues);

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

/**
 * Delete a doc from a sub collection by its id
 */
export async function deleteDocFromSubCollection(
  parentCollectionName: CollectionName,
  parentId: string,
  collectionName: CollectionName,
  docId: string
): Promise<EmptyResult> {
  try {
    await firebase
      .firestore()
      .collection(parentCollectionName)
      .doc(parentId)
      .collection(collectionName)
      .doc(docId)
      .delete();

    return { code: config.SUCCESS_RETURN_CODE };
  } catch (error) {
    return { code: config.ERROR_RETURN_CODE, errorMessage: error };
  }
}

type GetDocRecursiveOptions = { addId: boolean };

/**
  get doc data from path - path is combination of [collectionId, docId, collectionId, docId, ...] 
  if addId is true, will add the doc id to the data  
*/

export async function getDocRecursive(
  pathParts: string[],
  options?: GetDocRecursiveOptions
): Promise<firebase.firestore.DocumentData> {
  const pathNice = pathParts.join('/');
  if (pathParts.length % 2 !== 0 || pathParts.length === 0) {
    throw Error('length of pathParts must be non empty and even');
  }
  let ref = firebase.firestore().collection(pathParts[0]).doc(pathParts[1]);
  for (let i = 2; i < pathParts.length; i += 2) {
    ref = ref.collection(pathParts[i]).doc(pathParts[i + 1]);
  }
  const doc = await ref.get();
  if (!doc.exists) {
    throw new Error(`Cannot find doc: ${pathNice}`);
  }
  const extra = options?.addId ? { id: doc.id } : {};
  return { ...extra, ...doc.data() };
}

/**
 * update doc by path - path is combination of [collectionId, docId, collectionId, docId, ...]
 */
export async function updateDocRecursive(pathParts: string[], values: firebase.firestore.DocumentData) {
  const pathNice = pathParts.join('/');

  if (pathParts.length % 2 !== 0 && pathParts.length === 0) {
    throw Error('length of pathParts must be non empty and even');
  }
  let ref = firebase.firestore().collection(pathParts[0]).doc(pathParts[1]);
  for (let i = 2; i < pathParts.length; i += 2) {
    ref = ref.collection(pathParts[i]).doc(pathParts[i + 1]);
  }
  try {
    await ref.update({ ...values });
  } catch (error) {
    throw Error(`Failed to updated ${pathNice} with ${JSON.stringify(values)}`);
  }
}

type GetCollectionDocsRecursiveOptions = { addId: boolean; filters: ClauseArray[] };

/**
 * return all collection docs from a path - path is combination of [collectionId, docId, collectionId, docId, ...]
 * options.filters is optional list of filters (array of arrays)
 * options.addId if true, then id will be added to all docs
 */

export async function getCollectionDocsRecursive(
  pathParts: string[],
  options?: GetCollectionDocsRecursiveOptions
): Promise<firebase.firestore.DocumentData[]> {
  if (pathParts.length % 2 !== 1) {
    throw Error('length of pathParts must be odd');
  }
  let ref = firebase.firestore().collection(pathParts[0]);
  for (let i = 1; i < pathParts.length; i += 2) {
    ref = ref.doc(pathParts[i]).collection(pathParts[i + 1]);
  }
  const result = [];
  let query: firebase.firestore.Query = ref;
  for (const f of options?.filters ?? []) {
    query = query.where(...f);
  }
  const snap = await query.get();
  snap.docs.forEach((doc) => {
    const extra = options?.addId ? { id: doc.id } : {};
    result.push({ ...extra, ...doc.data() });
  });
  return result;
}

<script setup>
import GitHubAppSettings from '@/common/components/CISettings/CISettings.vue';
import EllipsisTooltip from '@/common/components/organisms/EllipsisTooltip.vue';
import { useAnalytics } from '@/common/composables/useAnalytics';
import DummyRepoBanner from '@/modules/demo/components/DummyRepoBanner.vue';
import SlackAppSettings from '@/modules/slack-app/components/SlackAppSettings.vue';
import StorageSettings from '@/modules/storage/components/StorageSettings.vue';
import GenerativeAISettings from '@/modules/generative-ai/components/GenerativeAISettings.vue';
import AskSwimmSettings from '@/modules/ask-swimm/components/AskSwimmSettings.vue';
import ImageStorageSettings from './ImageStorageSettings.vue';
import {
  GitProviderName,
  getGitProviderIconName,
  gitProviderUtils,
  integrationSettingsTabs,
  isRepoIdDummyRepo,
  productEvents,
  workspaceSettingsTabs,
} from '@swimm/shared';
import { Icon, SwText } from '@swimm/ui';
import SettingsModalBase from '@/modules/core/components/settings-modal/SettingsModalBase.vue';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';

const MODAL_TITLE = 'Integrations & settings';
const REPO_TABS = [
  {
    code: integrationSettingsTabs.GITHUB_APP,
    title: 'GitHub App',
    event: productEvents.VIEW_GITHUB_APP_TAB,
  },
  {
    code: integrationSettingsTabs.SLACK,
    title: 'Slack',
    event: productEvents.VIEW_SLACK_TAB,
  },
  {
    code: integrationSettingsTabs.STORAGE,
    title: 'Sharing',
    event: productEvents.VIEW_STORAGE_TAB,
  },
  {
    code: integrationSettingsTabs.GENERATIVE_AI,
    title: 'Generative AI',
    event: productEvents.VIEW_GENERATIVE_AI_TAB,
  },
  {
    code: integrationSettingsTabs.ASK_SWIMM,
    title: 'Ask Swimm',
    event: productEvents.VIEW_ASK_SWIMM_SETTINGS_TAB,
  },
  {
    code: integrationSettingsTabs.IMAGE_STORAGE,
    title: 'Image Storage',
    event: productEvents.VIEW_IMAGE_STORAGE_SETTINGS_TAB,
  },
];

const props = defineProps({
  show: { type: Boolean, required: true },
  repoId: { type: String, default: '' },
  origin: { type: String, default: '' },
  initialTabCode: { type: String, default: '' },
});
const emit = defineEmits(['close']);

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { openWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
const { workspaceFeatureFlags } = storeToRefs(useWorkspaceStore());

const changeToTabCode = ref('');
const isDummyRepo = computed(() => isRepoIdDummyRepo(props.repoId));

const title = computed(() => (props.repoId ? MODAL_TITLE + ' for' : MODAL_TITLE));
const repoMetadata = computed(() => store.getters['database/db_getRepoMetadata'](props.repoId));
const repoName = computed(() => repoMetadata.value?.name);
const repoIcon = computed(() => getGitProviderIconName(repoMetadata.value?.provider));

const isSlackInstalled = computed(() => repoMetadata.value?.slack_config?.is_slack_installed ?? false);
const isGitHubAppInstalled = computed(() => repoMetadata.value?.isGithubAppInstalled ?? false);

const repoTabs = computed(() => {
  if (isDummyRepo.value) {
    // prevent user from seeing the UI of Slack and Storage tab on dummy repo
    return [REPO_TABS.find((tab) => tab.code === integrationSettingsTabs.GITHUB_APP)];
  }

  const repoProvider = repoMetadata.value?.provider;
  const isSharingInternallySupported = gitProviderUtils.doesProviderSupportSharingInternally(repoProvider);
  const hasCI = gitProviderUtils.doesProviderHaveCI(repoProvider);

  const filteredRepoTabs = REPO_TABS.filter((tab) => {
    if (tab.code === integrationSettingsTabs.STORAGE) {
      return isSharingInternallySupported;
    }

    if (tab.code === integrationSettingsTabs.GITHUB_APP) {
      return hasCI;
    }

    if (tab.code === integrationSettingsTabs.ASK_SWIMM) {
      return !workspaceFeatureFlags.value.hide_ask_swimm;
    }

    return true;
  });

  if (repoProvider !== GitProviderName.GitHub || workspaceFeatureFlags.value.force_use_cli) {
    // rename Github App to CI Integration
    return filteredRepoTabs.map((tab) => {
      if (tab.code !== integrationSettingsTabs.GITHUB_APP) {
        return tab;
      }
      return { ...tab, title: 'CI Integration' };
    });
  }

  return filteredRepoTabs;
});

function tabChanged(tabCode) {
  changeToTabCode.value = '';
  reportTabSelected(tabCode);
}

function reportTabSelected(tabSelectedCode) {
  const tab = repoTabs.value.find((tab) => tab.code === tabSelectedCode);
  if (tab) {
    let context = '';
    if (tabSelectedCode === integrationSettingsTabs.SLACK) {
      context = isSlackInstalled.value ? 'Configuration' : 'Installation';
    } else if (tabSelectedCode === integrationSettingsTabs.GITHUB_APP) {
      context = isGitHubAppInstalled.value ? 'Configuration' : 'Installation';
    }
    analytics.track(tab.event, {
      Origin: props.origin,
      Type: 'Popup',
      ...(context && { Context: context }),
    });
  }
}

function changeTab(tab) {
  changeToTabCode.value = tab;
}

function openWorkspaceSettings() {
  analytics.track(productEvents.CLICKED_AI_WORKSPACE_SETTINGS_SHORTCUT_BANNER);
  emit('close');
  openWorkspaceSettingsModal({
    initialTabCode: workspaceSettingsTabs.AI_AND_FEATURE_ACCESS,
  });
}
</script>

<template>
  <SettingsModalBase
    :show="show"
    :tabs="repoTabs"
    :initial-tab-code="initialTabCode"
    :change-to-tab-code="changeToTabCode"
    @tab-selected="tabChanged"
    @close="$emit('close')"
  >
    <template #header>
      <div class="header">
        <SwText class="text-ellipsis" variant="headline3">{{ title }}</SwText>
        <span class="repo-data">
          <Icon :name="repoIcon" no-padding />
          <EllipsisTooltip class="text-ellipsis" :content="repoName" :length="30">
            {{ repoName }}
          </EllipsisTooltip>
        </span>
      </div>
    </template>
    <template #banner>
      <DummyRepoBanner v-if="isDummyRepo">Any changes you make will not be saved</DummyRepoBanner>
    </template>
    <template #content="{ selectedTabCode }">
      <GitHubAppSettings
        v-if="selectedTabCode === integrationSettingsTabs.GITHUB_APP"
        :repo-id="repoId"
        :workspace-id="route.params.workspaceId"
        :origin="origin"
        @close="$emit('close')"
      />
      <SlackAppSettings
        v-if="selectedTabCode === integrationSettingsTabs.SLACK"
        :repo-id="repoId"
        :workspace-id="route.params.workspaceId"
        :is-slack-installed="isSlackInstalled"
        @close="$emit('close')"
      />
      <Suspense v-if="selectedTabCode === integrationSettingsTabs.STORAGE">
        <StorageSettings
          :repo-id="repoId"
          :workspace-id="route.params.workspaceId"
          @close="$emit('close')"
          @change-tab="changeTab"
        />
      </Suspense>
      <Suspense v-if="selectedTabCode === integrationSettingsTabs.GENERATIVE_AI">
        <GenerativeAISettings
          :repo-id="repoId"
          :workspace-id="route.params.workspaceId"
          @close="$emit('close')"
          @change-tab="changeTab"
          @open-workspace-settings="openWorkspaceSettings"
        />
      </Suspense>
      <Suspense v-if="selectedTabCode === integrationSettingsTabs.ASK_SWIMM">
        <AskSwimmSettings
          :repo-id="repoId"
          :workspace-id="route.params.workspaceId"
          @close="$emit('close')"
          @change-tab="changeTab"
          @open-workspace-settings="openWorkspaceSettings"
        />
      </Suspense>
      <Suspense v-if="selectedTabCode === integrationSettingsTabs.IMAGE_STORAGE">
        <ImageStorageSettings :repo-id="repoId" @close="$emit('close')" @change-tab="changeTab" />
      </Suspense>
    </template>
  </SettingsModalBase>
</template>

<style scoped lang="postcss">
.header {
  display: flex;
  align-items: center;
  gap: var(--space-base);
  padding: var(--space-xs) 0;

  .text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .repo-data {
    display: flex;
    align-items: center;
    gap: var(--space-xs);
    padding: var(--space-xs) var(--space-base);
    max-width: 265px;
    border-radius: 4px;
    color: var(--text-color-primary);
    background-color: var(--color-surface);
  }
}
</style>

<script setup lang="ts">
import BaseLayoutIcons from '../../components/BaseLayoutIcons/BaseLayoutIcons.vue';
import BaseLayoutGap from '../../components/BaseLayoutGap/BaseLayoutGap.vue';
import BaseProse from '../../components/BaseProse/BaseProse.vue';
import { computed, ref, useSlots } from 'vue';

const props = withDefaults(
  defineProps<{
    draft?: boolean;
    selected?: boolean;
    focused?: boolean;
    wrapper?: string;
    alignment?: 'center' | 'left';
  }>(),
  {
    wrapper: 'li',
    alignment: 'center',
  }
);

const slots = useSlots();

const root = ref<HTMLElement | null>(null);
const text = ref<HTMLElement | null>(null);

const computedClasses = computed(() => ({
  'menu-item--selected': props.selected,
  'menu-item--focused': props.focused,
}));

const hasLeftIcon = computed(() => !!slots.leftIcon);

defineExpose({
  root,
  text,
});
</script>

<template>
  <component :is="wrapper" ref="root" class="menu-item" :class="computedClasses" role="menuitem" tabindex="0">
    <BaseLayoutGap wrapper="div" direction="row" size="small" alignment="stretch">
      <BaseLayoutIcons wrapper="div" inner-wrappers="div" class="menu-item__wrapper">
        <template v-if="hasLeftIcon" #leftIcon><slot name="leftIcon" /></template>
        <template #default>
          <BaseLayoutGap wrapper="div" :alignment="alignment" size="xxsmall">
            <div ref="text" class="menu-item__text"><slot /></div>
            <BaseProse v-if="draft" wrapper="div" variant="secondary" size="xsmall">(Draft)</BaseProse>
          </BaseLayoutGap>
        </template>
      </BaseLayoutIcons>
      <slot name="additional" />
    </BaseLayoutGap>
  </component>
</template>

<style scoped lang="scss">
@use '../../assets/styles/utils' as *;

.menu-item {
  @include defaults;

  color: var(--color-text-default);
  border-radius: var(--space-xxsmall);
  font-size: var(--font-size-small);
  list-style: none;
  padding: var(--space-xxsmall) var(--space-xsmall);

  &:hover,
  &:focus,
  &--focused {
    cursor: pointer;
    background-color: var(--color-bg-surface-hover);
    outline: none;
  }

  &__wrapper {
    align-items: center;
  }

  &__text {
    white-space: nowrap;
    max-width: 100%;
  }

  &--selected {
    background-color: var(--color-bg-selected);
  }
}
</style>

import { CALENDLY_SALES_LINK, SWIMM_ONPREM_AGENT_CLOUD_RUN_URL } from '@/config';
import { createPRToDoc, incrementGenerativeAICap } from '@/modules/generative-ai/genAIClient';
import {
  BIG_SNIPPET_SIZE_IN_LINES,
  GenerativeAIStreamingCloseReason,
  GenerativeAiRequestType,
  type PrData,
  type ProviderTerminology,
  type SnippetInfo,
  StiggFeatures,
  type SwimmDocument,
  config,
  eventLogger,
  getLoggerNew,
  getPrChangesDetails,
  gitProviderUtils,
  gitwrapper,
  objectUtils,
  productEvents,
} from '@swimm/shared';
import { getMergedPrs, getOpenPrs } from '@/remote-adapters/pulls';
import { type Ref, computed, nextTick, onUnmounted, ref, watch } from 'vue';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { useRoute, useRouter } from 'vue-router';
import swal from 'sweetalert';
import LocalStorage from '@/local-storage';
import { localStorageKeys } from '@/common/consts';
import { v4 as uuidv4 } from 'uuid';
import { useStigg } from '@/common/composables/useStigg';
import {
  SwimmEditorServices,
  buildSwimmDocumentFromSnippets,
  containsSnippetComment,
  isDocEmpty,
  parseSwmdContent,
  schema,
  splitSnippets,
  swmdSnippetsToText,
} from '@swimm/swmd';
import type { JSONContent } from '@tiptap/core';
import { getUserFromDB } from '../utils/user-utils';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { Transform } from '@tiptap/pm/transform';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useNotificationsStore } from '@swimm/editor';

export function usePr2Doc({
  swimmEditorServices,
  setDocument,
  swimmifyDoc,
  getDocumentContent,
  getDocumentTitle,
  toggleSnippetStudio,
  addWholeFile,
  reportDraftFeature,
  setDocumentContent,
}: {
  swimmEditorServices: Ref<SwimmEditorServices>;
  setDocument: (swimmDocument: SwimmDocument) => void;
  swimmifyDoc: () => void;
  getDocumentContent: () => JSONContent;
  getDocumentTitle: () => string | null;
  toggleSnippetStudio: () => void;
  addWholeFile: () => void;
  reportDraftFeature: (feature: string) => void;
  setDocumentContent: (content: JSONContent) => void;
}) {
  const logger = getLoggerNew(__modulename);
  const route = useRoute();
  const router = useRouter();
  const { meteredFeatureAllowed } = useStigg();
  const { id: workspaceId, workspaceSettings } = storeToRefs(useWorkspaceStore());
  const { repoId } = storeToRefs(useReposStore());
  const analytics = useAnalytics();
  const authStore = useAuthStore();

  const isGeneratingDocFromPr = ref(false);
  const generationTime = ref(0);
  const generatedSwmdFile = ref<SwimmDocument>(null);
  const currentPrId = ref(null);
  const pr2DocError = ref<'unknown' | 'cap' | null>(null);
  const shouldShowPR2DocFeedback = ref(false);
  const showPr2DocTipModal = ref(false);
  const showPr2DocOutdatedModal = ref(false);
  const showPaywallModal = ref(false);
  const shouldCancelGeneration = ref(false);
  let timerId = null;
  let startTime = null;
  const customPrompt = ref<string>('');
  const genAiCapAllowed = ref(true);
  const swimmDocForRevert = ref<SwimmDocument>(null);
  const showRevertConfirmation = ref(false);
  const showGenerateFromPRModal = ref(false);
  const mergedPRs = ref<PrData[]>([]);
  const openPrsOnBranch = ref<PrData[]>([]);
  const repoProviderTerminology = ref<ProviderTerminology | null>(null);
  let repoDefaultBranch = '';
  const prDropdownLoadingState = ref(false);
  let prFilesChanged = 0;

  const drafts3Store = useDrafts3Store();
  const { addNotification } = useNotificationsStore();

  const streamingEndpoint = computed(() =>
    workspaceSettings.value?.onprem_agent_endpoint
      ? `${workspaceSettings.value?.onprem_agent_endpoint}/agent-api`
      : workspaceSettings.value?.onprem_openai_endpoint
      ? `${workspaceSettings.value?.onprem_openai_endpoint}/ask-swimm-backend`
      : SWIMM_ONPREM_AGENT_CLOUD_RUN_URL
  );

  const loadPRsTimeout = 30000;
  async function loadMergedPRs(): Promise<void> {
    const response = await getMergedPrs(repoId.value, loadPRsTimeout);

    if (response.code === config.SUCCESS_RETURN_CODE) {
      const { prs } = response;
      mergedPRs.value = prs
        .map((pr) => {
          pr.filesWithAdditions = getPrFileAmount(pr);
          return pr;
        })
        .filter((pr) => pr.filesWithAdditions);

      const userGitName = (await getUserFromDB(authStore.user.uid)).github_login;

      mergedPRs.value.sort((a, _b) => {
        if (a.author === userGitName) {
          return -1;
        }
        return 1;
      });
    }
  }

  async function getOpenPrsForSourceBranch(branch: string): Promise<void> {
    if (branch === repoDefaultBranch) {
      return;
    }
    const response = await getOpenPrs(repoId.value, loadPRsTimeout);
    if (response.code === config.SUCCESS_RETURN_CODE) {
      const { prs } = response;
      openPrsOnBranch.value = prs
        .filter((pr) => pr.sourceBranchName === branch)
        .map((pr) => {
          pr.filesWithAdditions = getPrFileAmount(pr);
          return pr;
        });
    }
  }

  function getPrFileAmount(pr) {
    let filesWithAdditions = 0;
    for (const prFile of pr.files) {
      if (prFile.additionsInFile) {
        filesWithAdditions++;
      }
    }
    return filesWithAdditions;
  }

  watch(pr2DocError, async (error) => {
    await handlePR2DocError(error);
  });

  watch(generatedSwmdFile, (swimmDocument) => {
    if (swimmDocument && isGeneratingDocFromPr.value) {
      if (setDocument != null) {
        setDocument(swimmDocument);
      }
    }
  });

  onUnmounted(() => {
    unmount();
  });

  function shouldShowPr2DocTipModal() {
    if (LocalStorage.get(localStorageKeys.PR_CREATION_TIP_SHOWN)) {
      return false;
    }
    LocalStorage.set(localStorageKeys.PR_CREATION_TIP_SHOWN, true);
    return true;
  }

  const shouldShowPr2DocOutdatedTipModal = () => !LocalStorage.get(localStorageKeys.PR_OUTDATED_TIP_SHOWN);

  function unmount() {
    if (isGeneratingDocFromPr.value) {
      stopGenerating(false);
    } else {
      resetData();
      hidePR2DocFeedback();
    }
  }

  function resetData() {
    isGeneratingDocFromPr.value = false;
    shouldShowPR2DocFeedback.value = !pr2DocError.value && !showPaywallModal.value;
    generationTime.value = 0;
    generatedSwmdFile.value = null;
    currentPrId.value = null;
    startTime = null;
    if (timerId) {
      clearInterval(timerId);
    }
    timerId = null;
  }

  const hasLongSnippets = computed(
    () => (swimmEditorServices.value?.maxSnippetLineCount.value ?? 0) > BIG_SNIPPET_SIZE_IN_LINES
  );
  async function generateSnippetsToDoc(swimmDocument: SwimmDocument): Promise<void> {
    analytics.track(productEvents.CLICKED_GENEARATE_WITH_AI, {
      ...getGenAIAnlyticsProps(),
      'Has Custom Prompt': customPrompt.value.length > 0,
      'Allow Split Large Snippets': hasLongSnippets.value ? splitLongSnippets.value : null,
    });
    showGenerateDrawer.value = false;
    return streamDocFromAI({
      swimmDocument,
      type: 'snippets',
      method: 'Manual',
      origin: 'webapp',
      customPrompt: customPrompt.value,
      splitLongSnippets: splitLongSnippets.value,
    });
  }

  async function generateAutomaticPR2Doc(useAI) {
    if (useAI) {
      generatePrDocWithAI(true);
    } else {
      analytics.track(productEvents.PR_ADDED_TO_DOC, {
        'Workspace ID': workspaceId.value,
        'Repo ID': repoId.value,
        'PR ID': selectedPrData.value.prId.toString(),
        Origin: 'GitHub App',
      });
      addSnippetsToDoc(true, true);
    }
  }

  async function streamDocFromAI({
    swimmDocument,
    type,
    method,
    prId,
    customPrompt,
    splitLongSnippets,
    origin = 'New Document',
  }: {
    swimmDocument: SwimmDocument;
    type: string;
    method: string;
    prId?: string;
    customPrompt?: string;
    splitLongSnippets?: boolean;
    origin?: string;
  }) {
    fetchGenAICapAllowed();
    if (!genAiCapAllowed.value) {
      showPaywallModal.value = true;
      resetData();
      return;
    }
    try {
      isGeneratingDocFromPr.value = true;
      shouldShowPR2DocFeedback.value = false;
      generatedSwmdFile.value = null;
      showGenerateDrawer.value = false; // hide the drawer when starting the generation
      currentPrId.value = prId;
      const requestId = `pr-to-doc-${prId ?? 'snippets2doc'}-${uuidv4()}`;

      swimmDocForRevert.value = objectUtils.deepClone(swimmDocument);
      startGenerationTimer();
      shouldCancelGeneration.value = false;
      const docToGenerate = objectUtils.deepClone(swimmDocument);
      if (splitLongSnippets) {
        docToGenerate.content = splitSnippets(docToGenerate.content, repoId.value);
      }
      const snippetsText = swmdSnippetsToText(docToGenerate.content);
      const prToDocStream = createPRToDoc(
        {
          type: GenerativeAiRequestType.GENERATE_PR_TO_DOC,
          repoId: repoId.value,
          workspaceId: workspaceId.value,
          swimmDocument: docToGenerate,
          requestId,
          snippetsText,
          customPrompt,
          shouldCancel: () => shouldCancelGeneration.value,
        },
        streamingEndpoint.value,
        async (filePath, startLine, endLine) => {
          const services = swimmEditorServices.value;
          if (!services) {
            throw new Error('Swimm Editor Services not initialized');
          }
          return (
            await services.external.getFileContent({
              repoId: repoId.value,
              filePath,
              revision: services.branch.value,
            })
          )
            .split('\n')
            .slice(startLine - 1, endLine);
        }
      );
      for await (const streamMessage of prToDocStream) {
        switch (streamMessage.type) {
          case 'stop': {
            if (streamMessage?.code === GenerativeAIStreamingCloseReason.STOPPED_FROM_CLIENT) {
              return;
            }

            if (streamMessage.code === GenerativeAIStreamingCloseReason.FINISHED_STREAMING) {
              finishedStreaming(type, method, prId, origin, requestId, !!customPrompt);
              return;
            }

            if (streamMessage.code === GenerativeAIStreamingCloseReason.ENTITLEMENT_LIMIT_REACHED) {
              showPaywallModal.value = true;
              resetData();
              return;
            }
            finishedStreaming(type, method, prId, origin, requestId, !!customPrompt);
            return;
          }
          case 'error': {
            logger.warn(
              `Pr2doc stream was closed unexpectedly. Code: ${streamMessage.code}, Reason: ${streamMessage.reason}`
            );
            errorWhileStreaming(origin, streamMessage.reason?.toLowerCase().includes('cap'));
            resetData();
            return;
          }
          case 'chunk': {
            generatedSwmdFile.value = objectUtils.deepClone(streamMessage.swimmDocument);
          }
        }
      }
    } catch (err) {
      logger.error(`Pr2doc stream couldn't open. Error: ${err}`);
      errorWhileStreaming(origin, false);
    }
  }

  function finishedStreaming(
    type,
    method: string,
    prId: string,
    origin: string,
    requestId: string,
    usedCustomPrompt: boolean
  ) {
    const numOfSnippets = generatedSwmdFile.value?.content?.content?.filter(
      (node) => node?.type === 'swmSnippet'
    )?.length;
    if (type === 'snippets') {
      analytics.track(productEvents.AI_SNIPPETS_TO_DOC, {
        'Total Snippets': `${numOfSnippets}`,
        Origin: origin,
        Context: 'AI Snippets2Doc',
        'Used Custom Prompt': usedCustomPrompt,
        backofficeCode: eventLogger.SWIMM_EVENTS.AI_SNIPPETS_TO_DOC.code,
      });
    } else {
      showPr2DocOutdatedModal.value = shouldShowPr2DocOutdatedTipModal();
      analytics.track(productEvents.PR_ADDED_TO_DOC, {
        'Total Snippets': `${numOfSnippets}`,
        'PR Type': type,
        'PR Method': method,
        'PR ID': prId,
        Origin: origin,
        Context: 'AI PR2Doc',
        'Used Custom Prompt': usedCustomPrompt,
        backofficeCode: eventLogger.SWIMM_EVENTS.PR_ADDED_TO_DOC.code,
      });
    }
    swimmifyDoc();
    removeActionFromURL();
    reportDraftFeature(type === 'snippets' ? 'AI Snippets2Doc' : 'AI PR2Doc');
    resetData();
    void incrementGenerativeAICap(streamingEndpoint.value, workspaceId.value, requestId);
  }

  const setShownPr2DocOutdatedTipModal = () => {
    LocalStorage.set(localStorageKeys.PR_OUTDATED_TIP_SHOWN, true);
  };

  function removeActionFromURL() {
    // Remove the action so that refreshing the page won't open PR2Doc modal again.
    const query = { ...route.query, action: undefined };
    router.replace({ query });
  }

  function errorWhileStreaming(origin: string, capReached: boolean) {
    pr2DocError.value = capReached ? 'cap' : 'unknown';
    analytics.track(productEvents.FAILED_PR2DOC_WITH_AI, {
      'PR ID': currentPrId.value,
      Origin: origin,
    });
    removeActionFromURL();
    resetData();
  }

  function continuouslyUpdateAIGenerationTime() {
    try {
      generationTime.value = Date.now() - startTime + 1; // to make sure we get a non 0 result
    } catch (_err) {
      // avoid throwing without context
    }
  }

  function startGenerationTimer() {
    startTime = Date.now();
    timerId = setInterval(continuouslyUpdateAIGenerationTime, 2500);
  }

  function stopGenerating(userRequest = true) {
    if (userRequest) {
      shouldCancelGeneration.value = true;
      analytics.track(productEvents.CLICKED_STOP_GENERATING_AI_PR2DOC, {
        'PR ID': currentPrId.value,
        'Total Runtime MS': generationTime.value,
        Origin: 'New Document',
      });
    }
    swimmifyDoc();
    removeActionFromURL();
    resetData();
  }

  function errorShown() {
    pr2DocError.value = null;
  }

  function hidePR2DocFeedback() {
    shouldShowPR2DocFeedback.value = false;
  }

  async function createFromPr({ prId = '', useAI = false } = {}) {
    if (useAI && !swimmEditorServices.value?.external.isAIGenerationEnabledForRepo()) {
      // if creating from AI and AI disabled on the repo - open the PR2Doc modal,
      // set the selected PR Id and pop the 'AI disabled' message.
      startGenerateDocAction('pr');
      return;
    }
    try {
      await getPRDetailsFromPRNumAndSetAsSelected(prId);
      await generateAutomaticPR2Doc(useAI);
    } catch (err) {
      logger.error({ err });
      swal({
        title: `Failed to handle ${repoProviderTerminology.value?.pullRequest}`,
        text: 'Something went wrong while trying to generate a document from the selected PR. Please try again later or contact support.',
      });
    }
  }

  async function handleEditorOnOpenAction() {
    const action = route.query?.action;
    const repoData = await gitProviderUtils.getRepoStateData(repoId.value);
    repoProviderTerminology.value = gitwrapper.getTerminology(repoData.provider);
    repoDefaultBranch = repoData.defaultBranch;

    fetchGenAICapAllowed();
    await startGenerateDocAction(action as 'snippets' | 'pr');
    const prId = route.query?.prId as string;
    if (prId) {
      await createFromPr({ prId, useAI: 'ai' in route.query });
    }
  }

  async function startGenerateDocAction(action: 'snippets' | 'pr' | 'file') {
    switch (action) {
      case 'file':
        addWholeFile();
        break;
      case 'snippets':
        // toggle the snippet studio to open and expand the drawer
        // once the snippet studio is close the state of the drawer should be expand when starting with the action
        toggleSnippetStudio();
        nextTick(() => {
          generateDrawerInProgress.value = true;
          expandGenerateDrawer();
        });
        break;
      case 'pr': {
        showGenerateFromPRModal.value = true;
        prDropdownLoadingState.value = true;
        const loadPromises = [loadMergedPRs(), getOpenPrsForSourceBranch(swimmEditorServices.value?.branch.value)];
        Promise.allSettled(loadPromises).then(() => {
          prDropdownLoadingState.value = false;
        });
        break;
      }
    }
  }

  async function handlePR2DocError(error) {
    let shouldContinue: boolean;
    switch (error) {
      case 'unknown':
        shouldContinue = await swal({
          title: 'Could not complete your document',
          text: "We couldn't generate your entire document because of an error. This might happen with very large Pull Requests. You can continue with what we have so far, or try starting over.",
          buttons: {
            confirm: { text: 'Continue with existing content', visible: true },
            cancel: { text: 'Try again', visible: true },
          },
        });
        break;
      case 'cap': {
        const result = await swal({
          title: 'AI quota exceeded',
          text: 'Your workspace has reached its AI usage quota. Contact sales to increase your quota, or try again when the quota resets at the next billing cycle.',
          buttons: {
            confirm: { text: 'Contact sales', visible: true, value: 'contact-sales' },
            cancel: { text: 'Try again', visible: true, value: 'cancel' },
          },
        });
        if (result === 'contact-sales') {
          window.open(CALENDLY_SALES_LINK, '_blank');
        }
        shouldContinue = false;
        break;
      }
      case null:
        return;
    }

    if (!shouldContinue) {
      revertAIResult();
    }

    errorShown();
  }

  function fetchGenAICapAllowed() {
    genAiCapAllowed.value = meteredFeatureAllowed(StiggFeatures.GENERATIVE_AI_CAP);
  }
  const splitLongSnippets = ref(true);
  const showGenerateDrawer = ref(true);
  // this is to keep track if the user interacted with the drawer at least once in which case we want to show a message and keep the drawer visible
  const generateDrawerInProgress = ref(false);
  const shouldExpandGenerateDrawer = ref(false);

  const expandGenerateDrawer = (track = false) => {
    if (track) {
      analytics.track(productEvents.EXPANDED_GENERATE_DRAFT_MODAL, {
        ...getGenAIAnlyticsProps(),
        'Is AI Enabled': swimmEditorServices.value?.external.isAIGenerationEnabledForRepo(),
        'Is In Progress': generateDrawerInProgress.value,
      });
    }
    fetchGenAICapAllowed();
    if (swimmEditorServices.value?.snippetsInDocumentCount.value > 0) {
      generateDrawerInProgress.value = true;
    }
    shouldExpandGenerateDrawer.value = true;
  };

  const dismissGenerateDrawer = () => {
    showGenerateDrawer.value = false;
    analytics.track(productEvents.DISMISSED_GENERATE_DRAFT_MODAL, {
      ...getGenAIAnlyticsProps(),
      'Is In Progress': generateDrawerInProgress.value,
    });
  };

  function collapseGenerateDrawer() {
    analytics.track(productEvents.COLLAPSED_GENERATE_DRAFT_MODAL, getGenAIAnlyticsProps());
    shouldExpandGenerateDrawer.value = false;
  }

  function getGenAIAnlyticsProps() {
    const docContent = getDocumentContent();
    const docTitle = getDocumentTitle();
    return {
      Tab: 'Generate Doc',
      'Is Doc Empty': isDocEmpty(docContent),
      'Has Text Inside Snippets': containsSnippetComment(docContent),
      'Snippet Count': swimmEditorServices.value?.snippetsInDocumentCount.value,
      'Has Title': !!docTitle,
      Title: docTitle,
      'Max Snippet Line Count': swimmEditorServices.value?.maxSnippetLineCount.value,
    };
  }

  const sharedGenerateProps = computed(() => ({
    genAiEnabledForRepo: swimmEditorServices.value?.external.isAIGenerationEnabledForRepo(),
    workspaceId: workspaceId.value,
    repoId: repoId.value,
    snippetsLength: swimmEditorServices.value?.snippetsInDocumentCount.value,
  }));

  const generateFromPRProps = computed(() => ({
    customPrompt: customPrompt.value,
    genAiCapAvailable: genAiCapAllowed.value,
    mergedPrs: mergedPRs.value,
    openPrs: openPrsOnBranch.value,
    show: showGenerateFromPRModal.value,
    selectedPr: selectedPrData.value,
    snippets: selectedSnippets.value,
    prsLoading: prDropdownLoadingState.value,
    providerTerminology: repoProviderTerminology.value,
    groupSnippetsByFilePath: groupSnippetsByFilePath.value,
  }));

  const generateDocProps = computed(() => ({
    customPrompt: customPrompt.value,
    genAiCapAvailable: genAiCapAllowed.value,
    showSplitSnippetCheckbox: hasLongSnippets.value,
    splitLongSnippets: splitLongSnippets.value,
  }));

  function clickedRevert() {
    analytics.track(productEvents.CLICKED_REVERT_GENERATED_DRAFT, {
      ...getGenAIAnlyticsProps(),
      'Used Custom Instructions': customPrompt.value.length > 0,
    });
    showRevertConfirmation.value = true;
  }

  function revertAIResult() {
    showRevertConfirmation.value = false;
    shouldShowPR2DocFeedback.value = false;
    showGenerateDrawer.value = true;
    if (swimmDocForRevert.value) {
      setDocumentContent(swimmDocForRevert.value.content);
    }
    analytics.track(productEvents.REVERT_GENERATED_DRAFT, {
      'Used Custom Instructions': customPrompt.value.length > 0,
    });
    generateDrawerInProgress.value = true;
    showGenerateDrawer.value = true;
    expandGenerateDrawer();
  }

  function clickedRepoSettings(prModal = false) {
    if (prModal) {
      showGenerateFromPRModal.value = false;
    } else {
      collapseGenerateDrawer();
    }
    swimmEditorServices.value?.setShowAiGenerationDisabledModal(true, 'Generate Doc');
  }

  function startWithOption(option: 'pr' | 'snippets'): void {
    void startGenerateDocAction(option);
    const analyticsProps = {
      'Workspace ID': workspaceId.value,
      'Repo ID': repoId.value,
      'Is AI Enabled': swimmEditorServices.value?.external.isAIGenerationEnabledForRepo(),
    };
    if (option === 'snippets') {
      analytics.track(productEvents.START_FROM_CODE, analyticsProps);
    } else {
      analytics.track(productEvents.START_WITH_PR, analyticsProps);
    }
  }

  function clickActionFromDrawer(option: 'pr' | 'snippets' | 'file', tab: 'Empty state' | 'Generate Doc'): void {
    void startGenerateDocAction(option);
    const analyticsProps = {
      Tab: tab,
      'Workspace ID': workspaceId.value,
      'Repo ID': repoId.value,
      'Is AI Enabled': swimmEditorServices.value?.external.isAIGenerationEnabledForRepo(),
    };
    switch (option) {
      case 'snippets':
        analytics.track(productEvents.ADD_SNIPPET_FROM_GENERATE_DRAFT_MODAL, analyticsProps);
        break;
      case 'pr':
        analytics.track(productEvents.ADD_CHANGES_FROM_GENERATE_DRAFT_MODAL, analyticsProps);
        break;
      case 'file':
        analytics.track(productEvents.ADD_FILE_FROM_GENERATE_DRAFT_MODAL, analyticsProps);
        break;
    }
  }

  const selectedSnippets = ref<SnippetInfo[]>([]);
  const selectedPrData = ref<PrData | null>(null);
  async function setPrDetails(pr: PrData) {
    // get snippets list of the pr
    selectedPrData.value = pr;
    selectedSnippets.value = (
      await getPrChangesDetails({ repoId: repoId.value, prHead: pr.prHeadSha, prBase: pr.prBaseSha })
    ).diffSnippets;
    prFilesChanged = Object.keys(groupSnippetsByFilePath.value).length;
  }

  async function getPRDetailsFromPRNumAndSetAsSelected(prId: string) {
    let pr = mergedPRs.value.concat(openPrsOnBranch.value).find((pr) => pr.prId.toString() === prId);
    analytics.track(productEvents.CLICKED_LOOK_FOR_PR, {
      'PR Was In List': !!pr,
      'PR ID': prId,
    });
    if (!pr) {
      prDropdownLoadingState.value = true;
      try {
        pr = await gitwrapper.getPr({ repoId: repoId.value, prId });
      } catch (err) {
        logger.error({ err }, `Could not get PR data from PR #${prId}. Details: ${err.message}`);
        // PR not found error in GraphQL
        if (err.message.includes('Could not resolve to a PullRequest with the number of')) {
          await swal({
            // the error message for not found in pulls.ts has is invalid in it's message
            title: `PR not found`,
            text: `${repoProviderTerminology.value.pullRequest} ${prId} is invalid. \n Make sure you use the ${repoProviderTerminology.value.pullRequestShort} number from the ${repoProviderTerminology.value.pullRequest} page on ${repoProviderTerminology.value.displayName}.`,
          });
        } else {
          await swal({
            title: `Failed to handle ${repoProviderTerminology.value.pullRequest}`,
            text: `There was an issue fetching ${repoProviderTerminology.value.pullRequestShort} #${prId} \n Double check your ${repoProviderTerminology.value.pullRequestShort} number and try again later.`,
          });
        }
        prDropdownLoadingState.value = false;
        return;
      }
      pr.filesWithAdditions = getPrFileAmount(pr);

      mergedPRs.value.push(pr);
    }
    prDropdownLoadingState.value = false;
    await setPrDetails(pr);
  }

  function removeSnippetsForFilePath(filePath: string) {
    const snippetCount = groupSnippetsByFilePath.value[filePath].length;
    selectedSnippets.value = selectedSnippets.value.filter((snippet) => snippet.filePath !== filePath);
    analytics.track(productEvents.DELETE_PR_FILE_CHANGES, {
      'File Change Count': snippetCount,
      'Total Changed Files Count': prFilesChanged,
    });
  }

  async function buildSwimmDocumentFromSelectedPR(): Promise<SwimmDocument> {
    const documentNode = schema.topNodeType.create();
    const tr = new Transform(documentNode);
    if (selectedPrData.value?.description) {
      tr.insert(
        tr.doc.content.size,
        ProseMirrorNode.fromJSON(schema, parseSwmdContent(selectedPrData.value?.description, {}))
      );
    }

    const snippetContent = buildSwimmDocumentFromSnippets(selectedSnippets.value, repoId.value);
    tr.insert(tr.doc.content.size, ProseMirrorNode.fromJSON(schema, snippetContent));

    // prepare the swimm document
    return {
      frontmatter: {},
      title: selectedPrData.value.title,
      content: tr.doc.toJSON(),
    };
  }

  async function moveDraftToSourceBranchIfRequired() {
    if (
      selectedPrData.value.state === repoProviderTerminology.value.prState.open &&
      selectedPrData.value.sourceBranchName !== swimmEditorServices.value?.branch.value
    ) {
      // Move the draft to the source branch
      const docId: string = swimmEditorServices.value?.unitId.value;
      await drafts3Store.moveDraftsToBranch([docId], selectedPrData.value.sourceBranchName);
      // We need to replace and not push here
      // otherwise, the discard draft button does not work well
      await router.replace({ params: { branch: selectedPrData.value.sourceBranchName } });
      addNotification(`Switched to branch "${selectedPrData.value.sourceBranchName}"`, {
        autoClose: false,
        closeButtonText: 'CLOSE',
      });
    }
  }

  async function addSnippetsToDoc(expandDrawer = false, automatic = false) {
    await moveDraftToSourceBranchIfRequired();
    const doc = await buildSwimmDocumentFromSelectedPR();
    setDocument(doc);
    swimmifyDoc();
    // Show a tip on the first time used
    showPr2DocTipModal.value = shouldShowPr2DocTipModal();
    showGenerateFromPRModal.value = false;
    const analyticsProps = {
      'Total Changed Files Count': selectedPrData.value.filesWithAdditions,
      'Snippet Count': selectedSnippets.value.length,
      'PR Type': selectedPrData.value.state,
      'Workspace ID': workspaceId.value,
      'PR Method': automatic ? 'Automatic' : 'Manual',
      'PR ID': selectedPrData.value.prId.toString(),
      'Repo ID': repoId.value,
      Origin: 'New Document',
      Context: 'PR2Doc',
    };
    if (expandDrawer) {
      // Edit snippets
      generateDrawerInProgress.value = true;
      expandGenerateDrawer();
      analytics.track(productEvents.CLICKED_EDIT_SNIPPET_PR2DOC, analyticsProps);
    } else {
      // Add to doc
      analytics.track(productEvents.CLICKED_ADD_TO_DOC_FROM_PR, analyticsProps);
    }
  }

  const groupSnippetsByFilePath = computed(() => {
    const groupedSnippets: Record<string, SnippetInfo[]> = {};
    selectedSnippets.value.forEach((snippet) => {
      if (!groupedSnippets[snippet.filePath]) {
        groupedSnippets[snippet.filePath] = [];
      }
      groupedSnippets[snippet.filePath].push(snippet);
    });
    return groupedSnippets;
  });

  async function generatePrDocWithAI(automatic = false) {
    moveDraftToSourceBranchIfRequired();
    const doc = await buildSwimmDocumentFromSelectedPR();
    showGenerateFromPRModal.value = false;
    if (!automatic) {
      analytics.track(productEvents.CLICKED_GENERATE_WITH_AI_PR2DOC, {
        'PR ID': selectedPrData.value.prId.toString(),
        'Workspace ID': workspaceId.value,
        'Repo ID': repoId.value,
        Origin: 'New Document',
        'Did Remove Files': prFilesChanged !== Object.keys(groupSnippetsByFilePath.value).length,
      });
    }
    return streamDocFromAI({
      swimmDocument: doc,
      type: selectedPrData.value.state,
      method: automatic ? 'Automatic' : 'Manual',
      prId: selectedPrData.value.prId.toString(),
      origin: automatic ? 'GitHub App' : 'webapp',
    });
  }

  return {
    // generation
    generationTime,
    isGeneratingDocFromPr,
    customPrompt,
    splitLongSnippets,
    generateSnippetsToDoc,
    stopGenerating,
    revertAIResult,
    startGenerateDocAction,
    startWithOption,
    clickActionFromDrawer,
    addSnippetsToDoc,
    generatePrDocWithAI,
    setPrDetails,
    removeSnippetsForFilePath,
    getPRDetailsFromPRNumAndSetAsSelected,
    // computed
    sharedGenerateProps,
    generateFromPRProps,
    generateDocProps,
    // UI
    showPr2DocTipModal,
    showPr2DocOutdatedModal,
    setShownPr2DocOutdatedTipModal,
    shouldShowPR2DocFeedback,
    showGenerateDrawer,
    shouldExpandGenerateDrawer,
    collapseGenerateDrawer,
    expandGenerateDrawer,
    hidePR2DocFeedback,
    showRevertConfirmation,
    showGenerateFromPRModal,
    generateDrawerInProgress,
    // Actions
    handleEditorOnOpenAction,
    dismissGenerateDrawer,
    clickedRevert,
    showPaywallModal,
    clickedRepoSettings,
    // State
    genAiCapAllowed,
    prDropdownLoadingState,
    repoProviderTerminology,
  };
}

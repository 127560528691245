<template>
  <NarrowSidebarLayout :no-sidebar="embedded">
    <template #sidebar>
      <WorkspaceSidebar />
    </template>
    <Loader v-if="loading" class="loader" />
    <AuthorizeModal v-else-if="shouldShowAuthorizeModal" />
    <router-view v-else />
    <RepoSettingsModal
      :show="showRepoSettingsModal"
      :repo-id="repoId"
      origin="User Settings"
      :initial-tab-code="modalTabOnMount"
      @close="showRepoSettingsModal = false"
    />
    <UserSettingsModal
      :show="showUserSettingsModal"
      origin="User Settings"
      :initial-tab-code="modalTabOnMount ?? userSettingsTabs.NOTIFICATIONS"
      @close="showUserSettingsModal = false"
    />
  </NarrowSidebarLayout>
</template>

<script>
import { every } from 'lodash-es';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { PageRoutesNames, localStorageKeys } from '@/common/consts';
import LocalStorage from '@/local-storage';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import RepoSettingsModal from '@/modules/repo/settings/RepoSettingsModal.vue';
import UserSettingsModal from '@/modules/user-settings/modals/UserSettingsModal.vue';
import { mapActions, mapGetters } from 'vuex';
import NarrowSidebarLayout from '@/common/layouts/NarrowSidebarLayout.vue';
import WorkspaceSidebar from '@/common/components/organisms/WorkspaceSidebar.vue';
import {
  GitProviderName,
  GitProviderRateLimitError,
  getLoggerNew,
  settingsTypes,
  state,
  userSettingsTabs,
} from '@swimm/shared';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { measurePerformance } from '@/common/utils/sentry-measurements';
import { getSystemTheme } from '@/common/utils/theme-utils';
import { storeToRefs } from 'pinia';
import { useNotificationsStore } from '@swimm/editor';
import { useInitData } from '../composables/initData';
import { useRouting } from '../composables/routing';
import { useNavigate } from '../composables/navigate';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import AuthorizeModal from '@/modules/core/components/AuthorizeModal.vue';
import { useDemoStore } from '@/modules/demo/demo';
import { getSharedDocByOriginalDocId } from '@/modules/cloud-docs/cloud-doc-utils';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useRepoDocsStore } from '@/modules/core/stores/repo-docs';
import { useDocsContentStore } from '@/modules/core/stores/docs-content';
import { computed, watch } from 'vue';
import { useStore } from 'vuex';
import { useGitAuthorizationStore } from '@/modules/core/stores/git-authorization-store';
import { useDocsStore } from '@/modules/drafts3/stores/docs';
import { useRepoStoreUtils } from '@/common/composables/repos-store-utils';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useLocalStateStatusStore } from '@/common/store/localStateStatus';
import { Loader } from '@swimm/ui';

const logger = getLoggerNew(__modulename);

export default {
  name: 'RepoWorkspacePage',
  components: {
    UserSettingsModal,
    RepoSettingsModal,
    NarrowSidebarLayout,
    WorkspaceSidebar,
    AuthorizeModal,
    Loader,
  },
  props: {
    workspaceId: {
      type: String,
      default: null,
    },
    repoId: {
      type: String,
      default: null,
    },
    branch: {
      type: String,
      default: null,
    },
    modalToOpenOnMount: {
      type: String,
      default: null,
    },
    modalTabOnMount: {
      type: String,
      default: null,
    },
    showSlackConnectErrorOnMount: {
      type: Boolean,
      default: false,
    },
    redirectLinkOnMount: {
      type: String,
      default: null,
    },
    embedded: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { user } = storeToRefs(useAuthStore());
    const analytics = useAnalytics();
    const { logEvent } = useSwimmEventLogs();
    const { addNotification } = useNotificationsStore();
    const { setHomeData, setRepoData } = useInitData();
    const { assertRouting, saveLatestWorkspace, getCurrentOrDefaultBranch, getDefaultBranch } = useRouting();
    const { navigateToPageAndTerminateWorker, getRepoPath } = useNavigate();
    const { isOnDummyRepoPage } = storeToRefs(useDemoStore());
    const gitAuthorizationStore = useGitAuthorizationStore();
    const { isCurrentRepoAuthorized } = storeToRefs(gitAuthorizationStore);
    const { validateGitAuthorizationForRepo } = gitAuthorizationStore;
    const workspaceStore = useWorkspaceStore();
    const { addRepoToRecents, fetchWorkspace } = workspaceStore;
    const { provider } = storeToRefs(workspaceStore);
    const repoDocsStore = useRepoDocsStore();
    const { fetchDocsContent } = useDocsContentStore();
    const { fetchDocs } = useDocsStore();
    const store = useStore();
    const getRepoSwmsLists = (args) => store.dispatch('filesystem/getRepoSwmsLists', args);
    const { assertRepoState } = useRepoStoreUtils();
    const drafts3Store = useDrafts3Store();
    const { localStateDataReady } = storeToRefs(useLocalStateStatusStore());

    watch(
      () => [props.workspaceId, props.repoId, props.branch],
      async () => {
        const { workspaceId, repoId, branch } = props;
        if (workspaceId && repoId && branch) {
          const { migrationSucceded } = await drafts3Store.fetchDrafts(workspaceId, repoId, branch);
          if (!migrationSucceded) {
            addNotification(
              'We encountered an issue reading some of your drafts. Please reach out to Swimm support for assistance.',
              {
                icon: 'warning',
              }
            );
          }
        }
      },
      { immediate: true }
    );

    watch(provider, async (newValue) => {
      if (accessingBitbucketFromFirefox(newValue)) {
        await swal({
          title: 'Bitbucket is not support on Firefox, please use Chrome or Edge.',
        });
      }
    });

    function accessingBitbucketFromFirefox(provider) {
      const isBitbucket = provider === GitProviderName.Bitbucket || provider === GitProviderName.BitbucketDc;
      const isFirefox = /Firefox/i.test(navigator.userAgent);
      return isBitbucket && isFirefox;
    }

    const readyToFetchDocs = computed(
      () => (isCurrentRepoAuthorized.value || isOnDummyRepoPage.value) && localStateDataReady.value.has(props.repoId)
    );

    watch(
      () => [props.workspaceId, props.repoId, props.branch, readyToFetchDocs.value, drafts3Store.committedToBranchName],
      async (newValue, oldValue) => {
        const [workspaceId, repoId, branch, readyToFetch, committedToBranch] = newValue;

        if (!every([workspaceId, repoId, branch, readyToFetch])) {
          return;
        }

        const somethingChangedExcludingRefresh = !newValue
          .slice(0, -1)
          ?.every((val, index) => oldValue?.[index] === val);
        const oldCommittedBranch = oldValue[4];
        if (
          somethingChangedExcludingRefresh ||
          // Reload the data if the branch we committed to is the same as the one we ar on
          (committedToBranch && oldCommittedBranch !== committedToBranch && branch === committedToBranch)
        ) {
          await fetchDocs(props.workspaceId, props.repoId, props.branch);
          await fetchDocsContent(props.workspaceId, props.repoId, props.branch);
          drafts3Store.committedToBranchName = null;
        }
      },
      { deep: true, immediate: true }
    );

    watch(
      () => workspaceStore.isSidebarExpanded,
      (val) => {
        // We use this value for the dynamic snippet studio width
        document.body.dataset.sidebarExpanded = val.toString();
      },
      { immediate: true }
    );

    async function loadRepoData() {
      const fetchRepoChildren = (args) => store.dispatch('database/fetchRepoChildren', args);
      const fetchSwimms = fetchRepoChildren({ repoId: props.repoId, children: ['swimms', 'playlists'] });
      const db_getSwimms = computed(() => store.getters['database/db_getSwimms'](props.repoId));
      const db_getPlaylists = computed(() => store.getters['database/db_getSwimms'](props.repoId));
      if (!db_getSwimms.value || !db_getPlaylists.value) {
        await fetchSwimms;
      }
      const currentBranch = await getCurrentOrDefaultBranch(props.repoId);
      const getRepoSwmsLists = (args) => store.dispatch('filesystem/getRepoSwmsLists', args);
      await getRepoSwmsLists({ repoId: props.repoId, branch: currentBranch });
      if (isCurrentRepoAuthorized.value || isOnDummyRepoPage.value) {
        repoDocsStore.docsLoaded = true;
      }
    }

    return {
      user,
      analytics,
      logEvent,
      settingsTypes,
      userSettingsTabs,
      addNotification,
      setHomeData,
      assertRouting,
      saveLatestWorkspace,
      getCurrentOrDefaultBranch,
      getDefaultBranch,
      loadRepoData,
      navigateToPageAndTerminateWorker,
      getRepoPath,
      validateGitAuthorizationForRepo,
      isCurrentRepoAuthorized,
      isOnDummyRepoPage,
      fetchWorkspace,
      getRepoSwmsLists,
      setRepoData,
      repoDocsStore,
      addRepoToRecents,
      assertRepoState,
    };
  },
  data() {
    return {
      loading: true,
      selectedWorkspaceToJoin: null,
      selectedWorkspaceId: '',
      isBeingDestroyed: false,
      showRepoSettingsModal: false,
      showUserSettingsModal: false,
      repoInaccessible: false,
      repoName: '',
      initStartedForRepo: false,
    };
  },
  computed: {
    ...mapGetters('database', ['db_isWorkspaceAdmin', 'db_getUserWorkspaces', 'db_hasWorkspaces', 'db_getRepository']),
    ...mapGetters('filesystem', ['fs_getRepoBranchesError']),
    workspacesList() {
      return this.db_getUserWorkspaces(this.user.uid);
    },
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    getRepoBranchesError() {
      return this.fs_getRepoBranchesError(this.$route.params.repoId);
    },
    shouldOpenRepoSettingsModalOnMount() {
      return this.modalToOpenOnMount === settingsTypes.INTEGRATION;
    },
    shouldOpenUserSettingsModalOnMount() {
      return this.modalToOpenOnMount === settingsTypes.USER;
    },
    shouldShowAuthorizeModal() {
      if (this.isOnDummyRepoPage) {
        return false;
      }

      return this.$route.params.repoId && !this.isCurrentRepoAuthorized;
    },
  },
  watch: {
    repoId: {
      handler(value, oldValue) {
        if (value && value !== oldValue) {
          this.initStartedForRepo = false;
          this.initRepoData();
        }
      },
      immediate: true,
    },
    async isCurrentRepoAuthorized() {
      if (this.$route.params.repoId) {
        await this.assertRepoRouteAndInitRepo();
      }
    },
    async $route(to, from) {
      if (
        to.params.repoId === from.params.repoId &&
        to.params.workspaceId === from.params.workspaceId &&
        to.params.branch === from.params.branch
      ) {
        return;
      }
      this.$logger.debug(`Route changed: ${this.$route.fullPath}`, { module: 'RepoWorkspacePage' });
      try {
        await measurePerformance({ name: 'repo-page-init' }, async () => {
          if (from.params.branch && from.params.repoId === to.params.repoId) {
            // The filesystem store is not familiar with the branch so we need to clean it when moving between repos/branches.
            this.cleanLoadedRepoData(from.params.repoId);
          }
          if (to.params.repoId) {
            await this.assertRepoRouteAndInitRepo();
          }
          if (from.params.workspaceId !== to.params.workspaceId) {
            await this.setHomeData();
            await this.initHomeData();
            void this.reportInitialUserData();
          }
        });
      } catch (err) {
        logger.error({ err }, `Failed to load workspace data, workspaceId: ${this.workspaceId}, error: ${err.message}`);
        await swal({ title: `Failed to load workspace data`, content: SWAL_CONTACT_US_CONTENT() });
      } finally {
        this.loading = false;
      }
    },
    getRepoBranchesError(error) {
      if (error instanceof GitProviderRateLimitError) {
        this.addNotification(`${error} - Swimm might not function properly. Please wait a few minutes and try again.`, {
          icon: 'warning',
        });
      }
    },
  },
  async created() {
    await this.fetchWorkspace();
    const promises = [
      (async () => {
        if (this.repoId) {
          await this.setRepoData(this.repoId);
          void this.loadRepoData();
        }
      })(),
      (async () => {
        await this.assertRepoRouteAndInitRepo();
        await this.setHomeData();
        await this.initHomeData();
      })(),
    ];
    await Promise.all(promises);
    await this.redirectToSharedDocIfExists();
    this.loading = false;

    void this.reportInitialUserData();

    if (this.modalToOpenOnMount) {
      if (this.showSlackConnectErrorOnMount) {
        const title = 'There was an issue connecting to Slack. Please try again later.';
        await swal({ title, content: SWAL_CONTACT_US_CONTENT() });
      } else {
        this.showModalOnMount();
        if (LocalStorage.get(localStorageKeys.SLACK_TOAST_UNSEEN)) {
          this.addNotification(
            'You have successfully connected to Slack! You can now configure personalized notifications.'
          );
          LocalStorage.remove(localStorageKeys.SLACK_TOAST_UNSEEN);
        }
      }
    }
  },
  async beforeUnmount() {
    this.isBeingDestroyed = true;
  },
  methods: {
    ...mapActions('filesystem', ['cleanLoadedRepoData', 'fetchRepoBranches']),
    async assertReplaceRouteBySelectedWorkspace() {
      if (this.workspaceId) {
        this.selectedWorkspaceId = this.workspaceId;
        this.saveLatestWorkspace(this.selectedWorkspaceId).then();
      } else if (this.$route.path.startsWith(`/workspaces/initRepo`)) {
        // New repo
        return;
      } else {
        // If there is no workspace in the route, try to open the last workspace the user opened or the first workspace from their list
        const latestUserWorkspace = await state.get({ key: 'latest_user_workspace_id', defaultValue: null });
        this.selectedWorkspaceId = Object.keys(this.workspacesList).includes(latestUserWorkspace)
          ? latestUserWorkspace
          : Object.keys(this.workspacesList)[0];
        await this.workspaceSelectionChanged({ id: this.selectedWorkspaceId });
      }
    },

    async reportInitialUserData() {
      this.analytics.userIdentify(this.user.uid, {
        'Full Name': this.user.nickname,
        'Browser System Theme': getSystemTheme(),
        'Last Login Date': new Date().toISOString(),
        email: this.user.email,
        groups: { Workspace: this.selectedWorkspaceId },
      });
    },
    async workspaceSelectionChanged(selectedWorkspace) {
      this.loading = true;
      this.selectedWorkspaceId = selectedWorkspace.id;
      await this.saveLatestWorkspace(selectedWorkspace.id);
      this.loading = false;
      this.$logger.debug(
        `Changing route, from: ${this.$route.fullPath}, to workspaceId: ${this.selectedWorkspaceId}, isBeingDestroyed: ${this.isBeingDestroyed}`,
        { module: 'RepoWorkspacePage' }
      );
      if (this.isBeingDestroyed) {
        this.$logger.debug(`Aborted route change, component is destroyed`, { module: 'RepoWorkspacePage' });
        return;
      }
      await this.$router.replace({ path: `/workspaces/${this.selectedWorkspaceId}`, query: this.$route.query });
    },
    async initRepoData() {
      if (!this.isCurrentRepoAuthorized && !this.isOnDummyRepoPage) {
        return;
      }
      if (this.initStartedForRepo) {
        return;
      }
      this.repoDocsStore.docsLoaded = false;
      this.initStartedForRepo = true;
      await this.getCurrentOrDefaultBranch(this.repoId);
      this.fetchRepoBranches(this.repoId);
      void this.loadRepoData();
      await this.assertRepoState(this.repoId);
      void this.addRepoToRecents(this.repoId);
    },
    async initHomeData() {
      await measurePerformance({ name: 'home-init' }, async () => {
        if (this.db_hasWorkspaces(this.user.uid) || this.workspaceId) {
          await this.assertReplaceRouteBySelectedWorkspace();
        } else if (this.$route.name === PageRoutesNames.NO_ACCESS) {
          logger.warn(`User can't access firebase because client is offline`);
        } else {
          // When the user has no workspaces at all - force to join or create
          this.$router.push('/joinWorkspace');
        }
      });
    },
    async assertRepoRouteAndInitRepo() {
      await this.validateGitAuthorizationForRepo(this.repoId);
      const assertResult = await this.assertRouting();
      if (!assertResult) {
        return;
      }
      if (this.repoId) {
        void this.initRepoData();
      }
    },
    goToPageOrPromptSignup(path) {
      this.goToPage(path);
    },
    goToPage(path) {
      this.navigateToPageAndTerminateWorker({ newRoute: path });
    },
    showModalOnMount() {
      if (this.shouldOpenRepoSettingsModalOnMount) {
        this.showRepoSettingsModal = true;
        this.removeQueriesFromRoute();
      }
      if (this.shouldOpenUserSettingsModalOnMount) {
        this.showUserSettingsModal = true;
        this.removeQueriesFromRoute();
      }
    },
    removeQueriesFromRoute() {
      // To remove the queries from the rout after we use them
      const query = { ...this.$route.query, modal: undefined, source: undefined, tab: undefined, redirect: undefined };
      this.$router.replace({ query });
    },

    async redirectToSharedDocIfExists() {
      if (!this.isCurrentRepoAuthorized && this.$route.params.unitId) {
        const sharedDocId = await getSharedDocByOriginalDocId({
          workspaceId: this.workspaceId,
          repoId: this.repoId,
          docId: this.$route.params.unitId,
        });

        if (sharedDocId) {
          this.$router.push(`/workspaces/${this.workspaceId}/shared-docs/${sharedDocId}`);
        }
      }
    },
  },
};
</script>

<style scoped>
.loader {
  height: 100%;
}
</style>

<template>
  <InstanceNotification :action="action" :add-notifier-to-title="true" :title="title" :notification="notification" />
</template>

<script lang="ts">
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';
import { trimedWorkspaceName } from '@/common/utils/workspace-utils';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    action() {
      return ` ${trimedWorkspaceName(this.notification.topic_name)}`;
    },
    title() {
      return 'joined workspace';
    },
  },
};
</script>

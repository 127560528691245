import {
  type GenerateMermaidRequest,
  GenerativeAiRequestType,
  LLMResponseFormat,
  type LlmCallGenericRequest,
  type Prompt,
} from '../types';
import { StatusCodes } from 'http-status-codes';
import { v4 as uuidv4 } from 'uuid';

const MERMAID_JSON_RESPONSE_START_MARKER = '<JSON-RESPONSE-START>';

function getPrompt(request: Omit<GenerateMermaidRequest, 'type'>): Prompt {
  const specificType = request.selectedSample != null ? `of type ${request.selectedSample.label} ` : '';
  const systemPrompt = `You are a developer who hates long texts, and thinks code should speak for itself, and documentation should only point out the 'why'.
You believe that diagrams shouldn't be too detailed, but should give a high-level overview of the system (unless the user asks for a detailed diagram specifically).
You will get a markdown document that the user wrote and some more instructions.
Your goal is to generate a mermaid diagram ${specificType}based on the instructions and the document.

Begin by writing a short introduction text about the diagram you are going to generate, including a numbered list of the main topics to discuss.
In this short introduction, make sure to summarize only the most important parts of the information, ignoring logging calls and or trivial parts of the flow.
In the diagram, use names of variables/functions/classes/files instead of generic text whenever possible. 

After writing the introduction, write ${MERMAID_JSON_RESPONSE_START_MARKER}, followed by a JSON in the following format:
{error?: string; generatedText?: string}
Put the generated mermaid diagram in the "generatedText" field. The generated mermaid must not be wrapped with triple quotes. Verify it is a legal mermaid diagram.
Do not set any node fill color unless explicitly mentioned in the instructions.
If there is an error, put it in the error field`;

  const userPrompt = JSON.stringify({
    instructions: request.userPrompt,
    document: request.before + '\n' + request.after,
  });
  return {
    messages: [
      {
        role: 'system',
        content: systemPrompt,
      },
      {
        role: 'user',
        content: userPrompt,
      },
      ...(request.prevInvalidMermaidData
        ? [
            {
              role: 'system',
              content: `${JSON.stringify({ generatedText: request.prevInvalidMermaidData.generated })}`,
            } as const,
            {
              role: 'user',
              content: `The generated mermaid is invalid: ${request.prevInvalidMermaidData.error}.
Please respond again in the requested format and a valid diagram.`,
            } as const,
          ]
        : []),
    ],
    options: {
      max_tokens: 1000,
      temperature: 0.7,
      top_p: 1,
      n: 1,
    },
    workspaceId: request.workspaceId,
  };
}

export function getGenerateMermaidRequestParams(request: GenerateMermaidRequest): LlmCallGenericRequest {
  return {
    prompt: getPrompt(request),
    type: GenerativeAiRequestType.GENERATE_MERMAID,
    workspaceId: request.workspaceId,
    requestId: `${GenerativeAiRequestType.GENERATE_MERMAID}-${uuidv4()}`,
  };
}

export function parseGenerateMermaidResponse(responseString: string): LLMResponseFormat {
  const jsonStartMarkerIndex = responseString.indexOf(MERMAID_JSON_RESPONSE_START_MARKER);
  if (jsonStartMarkerIndex === -1) {
    throw new Error('Failed to parse response from OpenAI (no marker)');
  }
  const jsonStartIndex = responseString.indexOf('{', jsonStartMarkerIndex);
  const jsonEndIndex = responseString.lastIndexOf('}');
  if (jsonStartIndex === -1 || jsonEndIndex === -1) {
    throw new Error('Failed to parse response from OpenAI (no JSON)');
  }
  const responseJSONString = responseString.substring(jsonStartIndex, jsonEndIndex + 1);
  const responseJSON = JSON.parse(responseJSONString) as { error?: string; generatedText?: string };
  return { ...responseJSON, code: StatusCodes.OK };
}

import { type Extensions, getSchema } from '@tiptap/core';
import type { Schema } from '@tiptap/pm/model';

import KeyboardShortcuts from '@/tiptap/extensions/KeyboardShortcuts';
import StarterKit from '@tiptap/starter-kit';
import Document from '@tiptap/extension-document';
import BulletList from '@tiptap/extension-bullet-list';
import OrderedList from '@tiptap/extension-ordered-list';
import TaskItem from '@tiptap/extension-task-item';
import ListItem from '@tiptap/extension-list-item';
import Heading from '@/tiptap/extensions/Heading';
import Image from '@/tiptap/extensions/Image';
import Link from '@/tiptap/extensions/Link';
import Table from '@/tiptap/extensions/Table';
import TableRow from '@/tiptap/extensions/TableRow';
import TableCell from '@/tiptap/extensions/TableCell';
import TableHeader from '@/tiptap/extensions/TableHeader';
import Code from '@tiptap/extension-code';
import ListKeymap from '@tiptap/extension-list-keymap';
import Placeholder from '@tiptap/extension-placeholder';
import CodeBlock from '@/tiptap/extensions/CodeBlock';
import Codemark from '@/tiptap/extensions/codemark';
import GlobalDragHandle from '@/tiptap/extensions/GlobalDragHandle';
import SlashCommands from '@/tiptap/extensions/SlashCommands';
import slashCommands from '@/tiptap/slashCommands';
import InsertionPlaceholder from '@/tiptap/extensions/InsertionPlaceholder';
import SpecialPaste from '@/tiptap/extensions/SpecialPaste';
import SwmPath from '@/tiptap/extensions/SwmPath';
import SwmLink from '@/tiptap/extensions/SwmLink';
import SwmToken from '@/tiptap/extensions/SwmToken';
import SwmSnippet from '@/tiptap/extensions/SwmSnippet';
import SwmMention from '@/tiptap/extensions/SwmMention';
import Mermaid from '@/tiptap/extensions/Mermaid';
import Youtube from '@/tiptap/extensions/Youtube';
import BlockImage from '@/tiptap/extensions/BlockImage';
import SwmSnippetPlaceholder from '@/tiptap/extensions/SwmSnippetPlaceholder';
import SwmTablePlaceholder from '@/tiptap/extensions/SwmTablePlaceholder';
import SwmMermaidPlaceholder from '@/tiptap/extensions/SwmMermaidPlaceholder';
import SwmTemplateInline from '@/tiptap/extensions/SwmTemplateInline';
// import SwmTextPlaceholder from '@/tiptap/extensions/SwmTextPlaceholder';
import AiContentSuggestions from '@/tiptap/extensions/AiContentSuggestions';
import AiTextImprovements from '@/tiptap/extensions/AiTextImprovements';
import AiSnippetCommentGeneration from '@/tiptap/extensions/AiSnippetCommentGeneration';
import TextCompletion from '@/tiptap/extensions/TextCompletion';
import { sinkListItem } from '@/tiptap/sinkListItem';
import { liftListItem } from '@/tiptap/liftListItem';

// NOTE: swimmport is not part of this list, as it has to be configured from the top
export const extensions: Extensions = [
  KeyboardShortcuts,
  StarterKit.configure({
    document: false,
    codeBlock: false,
    bulletList: false,
    orderedList: false,
    listItem: false,
    heading: false,
    code: false,
  }),
  Document.extend({
    content: '(block|topBlock)+',
  }),
  CodeBlock,
  BulletList.extend({
    content: '(listItem|taskItem)+',
    addAttributes() {
      return {
        ...this.parent?.(),
        tight: {
          default: true,
          parseHTML: (element) => element.hasAttribute('data-tight'),
          renderHTML: (attributes) => {
            return {
              'data-tight': attributes.tight ? '' : undefined,
            };
          },
        },
      };
    },
  }),
  OrderedList.extend({
    addAttributes() {
      return {
        ...this.parent?.(),
        tight: {
          default: true,
          parseHTML: (element) => element.hasAttribute('data-tight'),
          renderHTML: (attributes) => {
            return {
              'data-tight': attributes.tight ? '' : undefined,
            };
          },
        },
      };
    },
  }),
  ListItem.extend({
    addKeyboardShortcuts() {
      return {
        ...this.parent?.(),
        Tab: ({ editor }) => editor.commands.command(({ state, dispatch }) => sinkListItem(this.type)(state, dispatch)), // The built-in sinkListItem doesn't properly handle lists with both ListItems and TaskItems, we override with the fix
        'Shift-Tab': ({ editor }) =>
          editor.commands.command(({ state, dispatch }) => liftListItem(this.type)(state, dispatch)), // The built-in liftListItem doesn't properly handle lists with both ListItems and TaskItems, we override with the fix
      };
    },
  }),
  TaskItem.extend({
    addKeyboardShortcuts() {
      return {
        ...this.parent?.(),
        Tab: ({ editor }) => editor.commands.command(({ state, dispatch }) => sinkListItem(this.type)(state, dispatch)), // The built-in sinkListItem doesn't properly handle lists with both ListItems and TaskItems, we override with the fix
        'Shift-Tab': ({ editor }) =>
          editor.commands.command(({ state, dispatch }) => liftListItem(this.type)(state, dispatch)), // The built-in liftListItem doesn't properly handle lists with both ListItems and TaskItems, we override with the fix
      };
    },
  }).configure({
    nested: true,
    HTMLAttributes: { 'data-type': 'taskItem' },
  }),
  Heading,
  Image.configure({ inline: true }),
  Link,
  Table,
  TableRow,
  TableHeader,
  TableCell,
  Code.configure({
    HTMLAttributes: {
      class: 'code',
    },
  }),

  ListKeymap.configure({
    listTypes: [
      {
        itemName: 'listItem',
        wrapperNames: ['bulletList', 'orderedList'],
      },
      {
        itemName: 'taskItem',
        wrapperNames: ['bulletList'],
      },
    ],
  }),
  Placeholder.configure({
    placeholder: 'Type / to browse options',
  }),
  Codemark,
  GlobalDragHandle,
  SlashCommands.configure({
    suggestion: slashCommands,
  }),
  InsertionPlaceholder,
  SpecialPaste,

  SwmPath,
  SwmLink,
  SwmToken,
  SwmSnippet,
  SwmMention,
  Mermaid,
  Youtube,

  BlockImage,

  SwmSnippetPlaceholder,
  AiContentSuggestions,
  AiTextImprovements,
  AiSnippetCommentGeneration,
  TextCompletion,
  // NOTE Currently disabled
  // SwmTextPlaceholder,
  SwmTablePlaceholder,
  SwmMermaidPlaceholder,
  SwmTemplateInline,
];

export const schema: Schema = getSchema(extensions);

export const ExtensionNames = [...extensions.map((extension) => extension.name)] as const;
export type ExtensionNamesType = (typeof ExtensionNames)[number];

<template>
  <div class="container">
    <div class="content">
      <TextFieldMultiline
        placeholder="Enter commit message"
        :model-value="modelValue"
        class="commit-message-input"
        @update:model-value="commitMessageChanged"
      />
      <slot name="warning" :new-branch="saveOption === SaveOptions.NEW_BRANCH" />
      <RadioButton
        v-model="saveOption"
        v-tooltip="protectedTooltip"
        :value="SaveOptions.CURRENT_BRANCH"
        class="branch-option"
        :disabled="isProtectedBranch"
        data-testid="push-popup-radio-current-branch"
      >
        <span class="default-branch-option">
          Push to
          <span class="branch" data-testid="push-popup-current-branch-name"
            ><Icon name="branch" />{{ displayBranchName }}</span
          >
        </span>
      </RadioButton>
      <RadioButton
        v-model="saveOption"
        :value="SaveOptions.NEW_BRANCH"
        class="branch-option"
        data-testid="push-popup-radio-new-branch"
      >
        Create <span class="bold">new branch</span>
      </RadioButton>
      <div class="branch-input-container">
        <TextField
          v-if="saveOption === SaveOptions.NEW_BRANCH"
          v-model="newRemoteBranchName"
          placeholder="Enter branch name"
          class="branch-input"
          @update:model-value="onBranchNameChange"
        >
          <template #left><Icon name="branch" /></template>
        </TextField>
        <ErrorBox v-if="branchNameError" class="error">{{ branchNameError }}</ErrorBox>
        <Checkbox
          v-if="saveOption === SaveOptions.NEW_BRANCH"
          v-model="startPR"
          size="small"
          data-testid="push-popup-checkbox-create-pr"
        >
          <span class="start-pr-label" data-testid="start-pr"
            >Start a {{ changeRequestName.toLowerCase() }} using this branch</span
          >
        </Checkbox>
      </div>
      <Action
        class="commit-button"
        :loading="loading"
        size="small"
        :disabled="isButtonDisabled"
        data-testid="push-popup-save-button"
        v-tooltip="buttonTooltip"
        @click="onSave"
        >Save</Action
      >
    </div>
  </div>
</template>

<script lang="ts">
import { UserOnboardingFields, gitwrapper } from '@swimm/shared';
import { Checkbox, ErrorBox, RadioButton, TextField } from '@swimm/ui';
import { mapGetters } from 'vuex';
import { UserOnboardingCustomSteps, setUserFieldInDB } from '@/common/utils/user-utils';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { PushData } from '@/modules/batch-commit/store/batch-commit';

export const SaveOptions = {
  CURRENT_BRANCH: 'current-branch',
  NEW_BRANCH: 'new-branch',
};

export default {
  components: { TextField, Checkbox, ErrorBox, RadioButton },
  props: {
    repoId: { type: String, required: true },
    modelValue: { type: String, required: true }, // Commit message
    repoBranchDetails: { type: Object, default: () => ({ branch: '', isProtectedBranch: false }) },
    loading: { type: Boolean, default: false },
    branchName: { type: String, default: '' },
    selectedSaveOption: {
      type: String,
      default: SaveOptions.CURRENT_BRANCH,
      validator: (selectedOption: string) => Object.values(SaveOptions).includes(selectedOption),
    },
  },
  emits: [
    'close-popup',
    'push-method-changed',
    'branch-name-changed',
    'create-pr',
    'create-branch',
    'push-to-branch',
    'update:model-value',
  ],
  setup() {
    const { user } = storeToRefs(useAuthStore());

    return { user, SaveOptions };
  },
  data() {
    return {
      saveOption: this.selectedSaveOption,
      newRemoteBranchName: this.branchName,
      startPR: true,
      branchNameError: '',
      changeRequestName: 'Pull Request',
    };
  },
  computed: {
    ...mapGetters('database', ['db_getRepoMetadata']),
    ...mapGetters('filesystem', ['fs_getRepoBranches']),
    displayBranchName() {
      return this.repoBranchDetails.branch || 'default';
    },
    protectedTooltip() {
      if (this.isProtectedBranch) {
        return `This option is disabled. ${this.repoBranchDetails.branch} branch is protected`;
      }
      return '';
    },
    isButtonDisabled() {
      return this.loading || this.newBranchEmpty || this.newBranchExists;
    },
    isProtectedBranch() {
      return this.repoBranchDetails.isProtectedBranch;
    },
    newBranchExists() {
      return (
        this.saveOption === this.SaveOptions.NEW_BRANCH &&
        this.fs_getRepoBranches(this.repoId).some((br) => br.name === this.newRemoteBranchName)
      );
    },
    newBranchEmpty() {
      return this.saveOption === this.SaveOptions.NEW_BRANCH && this.newRemoteBranchName.trim().length === 0;
    },
    buttonTooltip() {
      if (this.newBranchExists) {
        return 'Branch with this name already exists';
      }
      if (this.newBranchEmpty) {
        return 'Please enter branch name';
      }
      return undefined;
    },
  },
  async created() {
    this.changeRequestName = await gitwrapper.getChangeRequestName({ repoId: this.repoId });
    this.saveOption =
      this.selectedSaveOption === SaveOptions.NEW_BRANCH || this.isProtectedBranch
        ? SaveOptions.NEW_BRANCH
        : SaveOptions.CURRENT_BRANCH;
  },
  watch: {
    saveOption: {
      immediate: true,
      handler() {
        this.$emit('push-method-changed', this.saveOption);
      },
    },
    newRemoteBranchName() {
      this.$emit('branch-name-changed', this.newRemoteBranchName);
    },
  },
  methods: {
    doEmit(event: 'create-pr' | 'create-branch' | 'push-to-branch', pushData: PushData) {
      this.$emit(event, pushData);
    },
    async createPR() {
      this.doEmit('create-pr', {
        commitMessage: this.modelValue,
        prBranch: this.newRemoteBranchName,
        branch: this.repoBranchDetails.branch,
      });
    },
    createBranch() {
      this.doEmit('create-branch', {
        commitMessage: this.modelValue,
        prBranch: this.newRemoteBranchName,
        branch: this.repoBranchDetails.branch,
      });
    },
    pushToBranch() {
      this.doEmit('push-to-branch', {
        commitMessage: this.modelValue,
        branch: this.repoBranchDetails.branch,
      });
    },
    commitMessageChanged(commitMessage: string) {
      this.$emit('update:model-value', commitMessage);
    },
    onSave() {
      if (this.saveOption === this.SaveOptions.CURRENT_BRANCH) {
        this.pushToBranch();
      } else {
        if (this.startPR) {
          this.createPR();
        } else {
          this.createBranch();
        }
      }
      setUserFieldInDB(this.user.uid, UserOnboardingFields.ONBOARDING_CUSTOM_STEPS, {
        [UserOnboardingCustomSteps.FIRST_COMMIT]: { done: true },
      });
    },
    onBranchNameChange() {
      if (this.branchNameError) {
        this.branchNameError = '';
      }
    },
  },
};
</script>

<style scoped lang="postcss">
.container {
  width: 360px;
  font-size: var(--fontsize-xs);
  display: flex;
  flex-direction: column;
}

.bold {
  font-weight: 800;
}

.branch {
  overflow: hidden;
  padding: 7px;
  max-width: 235px;
  border-radius: 8px;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--text-color-secondary);
  background-color: var(--color-surface);
  flex: 1;
  margin-left: var(--space-base);
}

.commit-message-input {
  margin-bottom: 24px;
  height: 115px;
  font-size: var(--fontsize-xs);
}

.default-branch-option {
  display: inline-flex;
  align-items: center;
  width: 100%;
}

.branch-option {
  margin-bottom: 10px;
}

.commit-button {
  margin-top: 16px;
  width: 100%;
}

.branch-input-container {
  margin-left: 32px;
}

.branch-input-container .branch-input {
  width: auto;
  margin-bottom: 8px;
}

.branch-input-container .error {
  margin-top: 8px;
}

.branch-icon {
  padding-left: 0;
}
</style>

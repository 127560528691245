<template>
  <InstanceNotification
    :action="action"
    :title="title"
    :add-notifier-to-title="true"
    :action-in-a-new-line="true"
    :notification="notification"
  />
</template>

<script lang="ts">
import { trimedWorkspaceName } from '@/common/utils/workspace-utils';
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    action() {
      return `Join ${trimedWorkspaceName(this.notification.topic_name)}`;
    },
    title() {
      return 'invited you to a workspace';
    },
  },
};
</script>

<script setup lang="ts">
import { useAnalytics } from '@/common/composables/useAnalytics';
import { GOOGLE_DOMAIN_NAME, GOOGLE_PROVIDER_NAME, SSO_PROVIDER_SUFFIX } from '@/common/consts';
import { getEmailHostedDomain } from '@/common/utils/authUtils';
import UsersInviteForm from '@/modules/workspace/modals/settings/users/UsersInviteForm.vue';
import { LICENSES_WITHOUT_SEATS, Licenses, StiggFeatures, productEvents } from '@swimm/shared';
import { Checkbox, SwText } from '@swimm/ui';
import { computed, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useBillingStore } from '@/modules/billing/store/billing';
import { useStigg } from '@/common/composables/useStigg';
import { SWIMM_DOC_SITE_USERS_AND_BILLING } from '@/config';

const FREE_WORKSPACE_DESCRIPTION =
  'All users are added as members. Admins can change their role after the users log in for the first time.';

const PAYING_WORKSPACE_DESCRIPTION = 'You will be charged a prorated amount for each added user.';

const PAYING_WORKSPACE_WITH_SEATS_DESCRIPTION =
  'You will be charged a prorated amount for each added user beyond these empty seats.';

withDefaults(
  defineProps<{
    loading?: boolean;
  }>(),
  {
    loading: false,
  }
);

defineEmits<{
  (e: 'send-invite', emails: string[]): void;
}>();

const store = useStore();
const route = useRoute();
const { stiggClient } = useStigg();
const analytics = useAnalytics();

const autoJoinToggleInProgress = ref(false);
const nonAdminConnectReposToggleInProgress = ref(false);

const { user: currentUser } = storeToRefs(useAuthStore());
const workspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));
// not counting deleted users since total users will only show if they are on a paid plan
const totalUsers = computed<number>(() => {
  return (workspaceData.value?.invites?.length || 0) + (workspaceData.value?.counter_workspace_users || 0);
});
const { currentSeats } = storeToRefs(useBillingStore());

const workspaceLicense = computed(() => workspaceData.value.license || Licenses.FREE);

const seatsAvailable = computed(() => (currentSeats.value ? currentSeats.value - totalUsers.value : null));

const showSeatsCount = computed(
  () => !LICENSES_WITHOUT_SEATS.includes(workspaceLicense.value) && seatsAvailable.value && seatsAvailable.value > 0
);

const inviteDescription = computed(() => {
  if (
    [
      Licenses.ENTERPRISE,
      Licenses.ENTERPRISE_STARTER,
      Licenses.PRO,
      Licenses.STANDARD,
      Licenses.TEAM,
      Licenses.TEAM10,
      Licenses.SMALL_TEAM,
    ].includes(workspaceLicense.value)
  ) {
    if (seatsAvailable.value && seatsAvailable.value > 0) {
      return PAYING_WORKSPACE_WITH_SEATS_DESCRIPTION;
    }
    return PAYING_WORKSPACE_DESCRIPTION;
  }
  return FREE_WORKSPACE_DESCRIPTION;
});

const autoJoinEnabled = computed(() => !!workspaceData.value.is_auto_join_enabled);
const nonAdminConnectReposEnabled = computed(() => !!workspaceData.value.settings.allow_non_admin_connect_repos);

const companyDomain = computed(() => getEmailHostedDomain(currentUser.value.email));
const showAutoJoin = computed(
  () =>
    (currentUser.value.provider === GOOGLE_PROVIDER_NAME || currentUser.value.provider.includes(SSO_PROVIDER_SUFFIX)) &&
    companyDomain.value &&
    companyDomain.value !== GOOGLE_DOMAIN_NAME
);
const showNonAdminConnectRepos = computed(() => {
  const entitlement = stiggClient.getMeteredEntitlement({
    featureId: StiggFeatures.REPOS,
  });
  return entitlement.isUnlimited;
});

async function onAutoJoinToggle(newValue) {
  analytics.track(productEvents.CONFIGURED_AUTO_JOIN_WORKSPACE, {
    Configuration: newValue ? 'Enabled' : 'Disabled',
    Context: 'Invites modal',
  });
  autoJoinToggleInProgress.value = true;
  await store.dispatch('database/saveWorkspace', {
    resource: { id: route.params.workspaceId, is_auto_join_enabled: newValue },
    user: currentUser.value,
  });
  autoJoinToggleInProgress.value = false;
}

async function onNonAdminConnectReposToggle(newValue) {
  nonAdminConnectReposToggleInProgress.value = true;
  const newSettings = {
    ...workspaceData.value.settings,
    allow_non_admin_connect_repos: newValue,
  };
  await store.dispatch('database/saveWorkspace', {
    resource: { id: route.params.workspaceId, settings: newSettings },
    user: currentUser.value,
  });
  nonAdminConnectReposToggleInProgress.value = false;
}
</script>

<template>
  <UsersInviteForm :loading="loading" @invite="(emails: string[]) => $emit('send-invite', emails)" />
  <div class="description">
    <SwText v-if="showSeatsCount" variant="body-S" class="seats-available" component="span">
      {{ seatsAvailable }} empty {{ seatsAvailable > 1 ? 'seats' : 'seat' }} available.
    </SwText>
    <SwText variant="body-S" class="description" component="span">
      {{ inviteDescription }}
      <a :href="SWIMM_DOC_SITE_USERS_AND_BILLING" target="_blank"><u>Learn more</u></a>
    </SwText>
  </div>
  <Checkbox
    v-if="showAutoJoin"
    class="checkbox"
    size="small"
    :model-value="autoJoinEnabled"
    :disabled="autoJoinToggleInProgress"
    :loading="autoJoinToggleInProgress"
    @update:model-value="onAutoJoinToggle"
  >
    <SwText variant="body-S">
      Allow anyone with a <strong>@{{ companyDomain }}</strong> email to join this workspace (requires them to login
      with Google / SSO).
    </SwText>
  </Checkbox>
  <Checkbox
    v-if="showNonAdminConnectRepos"
    class="checkbox"
    size="small"
    :model-value="nonAdminConnectReposEnabled"
    :disabled="nonAdminConnectReposToggleInProgress"
    :loading="nonAdminConnectReposToggleInProgress"
    @update:model-value="onNonAdminConnectReposToggle"
  >
    <SwText variant="body-XS"> Allow non-admins to connect repositories to this workspace. </SwText>
  </Checkbox>
</template>

<style scoped lang="postcss">
.description {
  margin: var(--space-base) 0;
}

.seats-available {
  color: var(--text-color-success);
}

.checkbox {
  margin-bottom: var(--space-base);
}
</style>

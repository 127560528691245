<template>
  <SwModal :show-modal="show" :heading="options.heading" @close="close">
    <DeleteItemsModalContent
      v-if="Object.values(repoBranchDetails).length"
      v-model="pushCommitMessage"
      :repo-branch-details="repoBranchDetails"
      :repo-id="options.repoId"
      :loading="loading"
      :branch-name="options.branchName"
      :selected-save-option="options.selectedSaveOption"
      @create-pr="createPR"
      @create-branch="createBranch"
      @push-to-branch="pushToBranch"
      @close-popup="close"
    />
  </SwModal>
</template>

<script lang="ts">
import { SwModal } from '@swimm/ui';
import DeleteItemsModalContent from '@/common/components/organisms/DeleteItemsModalContent.vue';
import { useRouting } from '@/common/composables/routing';
import { PushData } from '@/modules/batch-commit/store/batch-commit';
import { useReposStore } from '@/modules/repo/stores/repos-store';
import { storeToRefs } from 'pinia';

export default {
  components: { SwModal, DeleteItemsModalContent },
  props: {
    show: {
      type: Boolean,
      required: true,
    },
    options: {
      type: Object,
      default: () => ({}),
      repoId: { type: String, required: true },
      commitMessage: { type: String, default: '' },
      createBranch: { type: Function, default: () => ({}) },
      createPR: { type: Function, default: () => ({}) },
      commitToBranch: { type: Function, default: () => ({}) },
      branchName: { type: String, default: '' },
      selectedSaveOption: { type: String, default: '' },
      heading: { type: String, default: '' },
    },
  },
  emits: ['close'],
  setup() {
    const { getCurrentOrDefaultBranch, isBranchProtected } = useRouting();
    const { reposStateData } = storeToRefs(useReposStore());

    return { reposStateData, isBranchProtected, getCurrentOrDefaultBranch };
  },
  data: function () {
    return {
      pushCommitMessage: '',
      repoBranchDetails: {},
      loading: false,
    };
  },
  watch: {
    show: {
      immediate: true,
      handler(val) {
        if (val) {
          this.reset();
        }
      },
    },
  },
  methods: {
    async reset() {
      this.repoBranchDetails = {};
      this.pushCommitMessage = this.options.commitMessage;
      const repoDataFromState = this.reposStateData[this.options.repoId];
      if (repoDataFromState) {
        const branch = await this.getCurrentOrDefaultBranch(this.options.repoId);
        this.repoBranchDetails = {
          branch,
          defaultBranch: repoDataFromState.defaultBranch,
          isProtectedBranch: await this.isBranchProtected({ repoId: this.options.repoId, branch }),
        };
      }
    },
    async createPR(pushData: PushData) {
      this.loading = true;
      await this.options.createPR(pushData, this.stopLoading);
    },
    async createBranch(pushData: PushData) {
      this.loading = true;
      await this.options.createBranch(pushData, this.stopLoading);
    },
    async pushToBranch(pushData: PushData) {
      this.loading = true;
      await this.options.commitToBranch(pushData, this.stopLoading);
    },
    stopLoading() {
      this.loading = false;
    },
    close() {
      this.$emit('close');
    },
  },
};
</script>

import { useDeleteItem } from '@/common/composables/delete-item';
import { useNavigate } from '@/common/composables/navigate';
import { useSwimmResource } from '@/common/composables/swimmResource';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { DocumentationTypes } from '@/common/consts';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useFoldersStore } from '@/modules/folders/store/folders';
import { GitProviderName, getLoggerNew, productEvents } from '@swimm/shared';
import { storeToRefs } from 'pinia';
import swal from 'sweetalert';
import { useRoute } from 'vue-router';

const logger = getLoggerNew(__modulename);

export function useResourceActions() {
  const route = useRoute();
  const analytics = useAnalytics();
  const { isSwimmAvailable } = useSwimmResource();
  const { navigateToPageAndTerminateWorker, getRepoPath, navigateToRepoPage } = useNavigate();
  const { getFolder, getFolderItems, deleteFolder, moveItemsToRootFolder } = useFoldersStore();
  const deleteItemUtils = useDeleteItem();
  const { provider } = storeToRefs(useWorkspaceStore());

  const getResourceContainerRoute = ({
    repoId,
    branch,
    type,
  }: {
    repoId: string;
    branch: string;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }) => {
    const typeAppendixes = {
      [DocumentationTypes.DOC]: 'docs',
      [DocumentationTypes.PLAYLIST]: 'playlists',
      [DocumentationTypes.TEMPLATE]: 'templates',
    };

    return `${getRepoPath(repoId, branch)}/${typeAppendixes[type]}`;
  };

  function navToDuplicateResource({
    resource,
    repoId,
    branch,
    type,
  }: {
    resource;
    repoId: string;
    branch: string;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }) {
    navigateToPageAndTerminateWorker({
      newRoute: `${getResourceContainerRoute({ repoId, branch, type })}/new`,
      query: { unitToDuplicateId: resource.id },
    });
  }

  async function duplicateResource({
    repoId,
    branch,
    resource,
    type,
  }: {
    resource;
    repoId: string;
    branch: string;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }) {
    const resourceName = resource.name || `this document`;
    try {
      if (!isSwimmAvailable(repoId, resource.id)) {
        await swal({
          title: `Cannot duplicate ${resourceName}`,
          text: `Duplicating "${resourceName}" failed since it is not available locally. Please make sure "${resourceName}" exists and try again `,
          content: { element: SWAL_CONTACT_US_CONTENT() },
        });
        return;
      }
      navToDuplicateResource({ repoId, branch, resource, type });
    } catch (err) {
      await swal({
        title: `Cannot duplicate ${resourceName}`,
        text: `Something went wrong while trying to duplicate the resource. `,
        content: { element: SWAL_CONTACT_US_CONTENT() },
      });
      logger.error({ err }, `duplication failed. details: ${err.toString()}`);
    }
  }

  function getResourceLink({
    resource,
    repoId,
    branch,
    editMode = false,
    type,
  }: {
    resource;
    repoId: string;
    branch: string;
    editMode?: boolean;
    type: (typeof DocumentationTypes)[keyof typeof DocumentationTypes];
  }): string {
    const resourceId = resource.id;
    const baseUrl = `${getResourceContainerRoute({ repoId, branch, type })}`;
    return `${baseUrl}/${resourceId}${editMode ? '/edit' : ''}`;
  }

  function getFolderState(folder, folderItems) {
    if (!folder.children.length && !folderItems.length) {
      return 'Completely Empty';
    }
    if (folderItems.length) {
      return 'Docs On Current Branch';
    }
    return 'Only Docs On Other Branches';
  }

  function onDeleteItem({ item, repoId }: { item; repoId: string }) {
    if (item.documentationType === DocumentationTypes.FOLDER) {
      const folder = getFolder(item.id, repoId);
      if (!folder) {
        return;
      }
      const folderItems = getFolderItems({ repoId, folderId: folder.id, includeDrafts: true });
      analytics.track(productEvents.CLICKED_DELETE_ON_FOLDER, {
        'Folder Name': folder.name,
        'Folder ID': folder.id,
        'Total Child Count': folderItems.length,
        'Folder State': getFolderState(folder, folderItems),
      });
      deleteFolder(item.id, repoId);
      return;
    }
    async function moveToRootFolderAndNavigate() {
      await moveItemsToRootFolder(repoId, [item]);
      if (checkIfNeedToNavigate(item.id)) {
        navigateToRepoPage({}, null);
      }
    }
    if (provider.value === GitProviderName.BitbucketDc) {
      deleteItemUtils.cannotDeleteOnBitbucketDCSwal([item?.path ?? item?.name ?? item?.id]);
      return;
    }
    deleteItemUtils.openDeleteModal([item], moveToRootFolderAndNavigate);
  }

  function checkIfNeedToNavigate(itemID) {
    if (!itemID) {
      return false;
    }
    const { unitId, playlistId } = route.params;
    return unitId === itemID || playlistId === itemID;
  }

  return {
    onDeleteItem,
    getResourceContainerRoute,
    duplicateResource,
    getResourceLink,
  };
}

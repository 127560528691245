import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { type Ref, ref } from 'vue';
import { useWorkspaceSettingsModalStore } from '@/modules/workspace/modals/settings/store/workspace-settings';
import { useCreationHubStore } from '../creation-hub/store/creation-hub';
import type { PushData } from '@/modules/batch-commit/store/batch-commit';

export interface AddReposModalOptions {
  multiSelect?: boolean;
}

type DeleteItemsModalOptions = {
  repoId: string;
  heading: string;
  commitMessage: string;
  branchName: string;
  createPR: (pushData: PushData, modalCallback: () => void) => Promise<void>;
  createBranch: (pushData: PushData, modalCallback: () => void) => Promise<void>;
  commitToBranch: (pushData: PushData, modalCallback: () => void) => Promise<void>;
};

export const useAppModalsStore = defineStore(STORES.APP_MODAL_STORE, () => {
  const { closeWorkspaceSettingsModal } = useWorkspaceSettingsModalStore();
  const { closeCreationHubModal } = useCreationHubStore();
  const modalOrigin = ref<string>('');
  const modalCallback = ref<{ [emitKey: string]: () => void }>({});
  const addReposModalOptions = ref<AddReposModalOptions>({});
  const showRepoSettingsModal = ref<boolean>(false);

  const showProcessToDocModal = ref<boolean>(false);
  function openProcessToDocModal() {
    showProcessToDocModal.value = true;
  }

  const showAddReposModal = ref<boolean>(false);
  function openAddReposModal(options?: AddReposModalOptions) {
    addReposModalOptions.value = options ?? {};
    showAddReposModal.value = true;
  }

  function closeProcessToDocModal() {
    showProcessToDocModal.value = false;
  }

  function closeAddReposModal() {
    showAddReposModal.value = false;
    modalOrigin.value = '';
    modalCallback.value = {};
  }

  const showDeleteItemsModal = ref<boolean>(false);
  const deleteItemsModalOptions: Ref<DeleteItemsModalOptions | undefined> = ref(undefined);

  function openDeleteItemsModal(options: DeleteItemsModalOptions) {
    deleteItemsModalOptions.value = options;
    showDeleteItemsModal.value = true;
  }

  function closeDeleteItemsModal() {
    showDeleteItemsModal.value = false;
  }

  function closeModals({ fromRouter }: { fromRouter: boolean }) {
    closeAddReposModal();
    closeCreationHubModal({ fromRouter });
    closeWorkspaceSettingsModal({ fromRouter });
    showRepoSettingsModal.value = false;
    closeDeleteItemsModal();
  }

  return {
    addReposModalOptions,
    showAddReposModal,
    showRepoSettingsModal,
    showProcessToDocModal,
    modalOrigin,
    modalCallback,
    openAddReposModal,
    closeAddReposModal,
    closeModals,
    openProcessToDocModal,
    closeProcessToDocModal,
    showDeleteItemsModal,
    openDeleteItemsModal,
    closeDeleteItemsModal,
    deleteItemsModalOptions,
  };
});

import { defineStore } from 'pinia';
import { STORES } from '@/modules/core/stores/store-constants';
import { useRoute } from 'vue-router';
import { computed, onMounted, ref, watch } from 'vue';
import {
  GitProviderInfo,
  GitProviderName,
  type WorkspaceDocumentTemplate,
  type WorkspaceSettings,
  gitwrapper,
  isRepoIdDummyRepo,
  productEvents,
  slugify,
  state,
} from '@swimm/shared';
import {
  WORKSPACE_ONBOARDING_STEPS_KEY,
  updateWorkspaceOnboardingStep,
} from '@/modules/core/services/workspace-onboarding-steps';
import {
  addRepoToWorkspaceUserFavorites,
  createDocumentTemplate,
  fetchDocumentTemplate,
  fetchDocumentTemplates,
  fetchLastTemplateVersion,
  getWorkspaceFromDB,
  getWorkspaceUserFromDB,
  removeRepoFromWorkspaceUserFavorites,
  updateWorkspaceUserRecentRepos,
} from '@/modules/core/services/workspace';
import { GITHUB_APP_SETTINGS_LINK } from '@/common/consts';
import { useRouting } from '@/common/composables/routing';
import { useWindowWidth } from '@swimm/editor';
import { useAuthStore } from './auth-store';
import { useAnalytics } from '@/common/composables/useAnalytics';

const MIN_WINDOW_WIDTH_TO_ALLOW_EXPAND_SIDEBAR_IN_PLAYLIST = 1680;

export const useWorkspaceStore = defineStore(STORES.WORKSPACE, () => {
  const route = useRoute();
  const analytics = useAnalytics();
  const { assertOnPlaylistPageRoute } = useRouting();
  const auth = useAuthStore();
  const id = computed<string>(() => route.params.workspaceId as string);

  const name = ref<string>('');
  const logo = ref<string>('');
  const repositories = ref<string[]>([]);
  const isSidebarPeek = ref<boolean>(false);
  const isSidebarExpanded = ref<boolean>(false);
  const isSidebarFavoritesExpanded = ref<boolean>(true);
  const onboardingStepsState = ref<{ [stepId: string]: boolean }>();
  const shouldForceOpenSubmenu = ref<boolean>(false);
  const provider = ref<GitProviderName>(null);
  const providerDisplayName = ref<string>(null);
  const providerLongDisplayName = ref<string>(null);
  const enterpriseGitUrl = ref<string>(null);
  const tenantId = ref<string>(null);
  const githubAppUrl = ref<string>(GITHUB_APP_SETTINGS_LINK);
  const isPendingManualSetup = ref<boolean>(false);
  const isWorkspaceLocalModeActive = ref<boolean>(false);
  const favoriteRepoIds = ref<string[]>([]);
  const recentRepoIds = ref<string[]>([]);
  const currentUser = ref<string>('');
  const workspaceSettings = ref<WorkspaceSettings>({});
  const documentTemplates = ref<Map<string, WorkspaceDocumentTemplate>>(new Map());
  const workspaceFeatureFlags = ref<Record<string, boolean>>({});

  const isProcessToDocDisabledForWorkspace = ref<boolean>(false);
  const isGenAIDisabledForWorkspace = computed(() => workspaceSettings.value?.disable_gen_ai ?? false);

  watch(isSidebarExpanded, (isSidebarExpanded) => {
    state.set({ key: 'doc_sidebar_expanded', value: isSidebarExpanded }).then();
  });

  watch(isSidebarFavoritesExpanded, (isSidebarFavoritesExpanded) => {
    state.set({ key: 'doc_sidebar_favorites_expanded', value: isSidebarFavoritesExpanded }).then();
  });

  function fetchSidebarExpanded() {
    state.get({ key: 'doc_sidebar_expanded' }).then((isSidebarExpandedFromState) => {
      // Backwards compatibility - this used to be a string.
      if (typeof isSidebarExpandedFromState === 'string') {
        isSidebarExpandedFromState = isSidebarExpandedFromState === 'true';
      }
      isSidebarExpanded.value = isSidebarExpandedFromState ?? true;
    });
  }

  onMounted(() => {
    state.get({ key: 'doc_sidebar_favorites_expanded' }).then((isSidebarFavoritesExpandedFromState) => {
      isSidebarFavoritesExpanded.value = isSidebarFavoritesExpandedFromState ?? true;
    });
  });

  const hasRepos = computed(() => !!repositories.value?.length);
  const hasDummyRepo = computed<boolean>(() => repositories.value?.some(isRepoIdDummyRepo));
  const windowWidth = useWindowWidth();
  const canExpandSidebarMenu = computed(() => {
    return windowWidth.value >= MIN_WINDOW_WIDTH_TO_ALLOW_EXPAND_SIDEBAR_IN_PLAYLIST || !assertOnPlaylistPageRoute();
  });
  const shouldExpandSidebarMenu = computed(() => {
    return canExpandSidebarMenu.value && isSidebarExpanded.value;
  });

  async function fetchWorkspace(): Promise<void> {
    if (!id.value) {
      return;
    }
    const workspaceData = await getWorkspaceFromDB(id.value);
    onboardingStepsState.value = workspaceData[WORKSPACE_ONBOARDING_STEPS_KEY];
    name.value = workspaceData.name;
    logo.value = workspaceData.logo;
    isPendingManualSetup.value = workspaceData.pending_manual_setup ?? false;
    isWorkspaceLocalModeActive.value = workspaceData?.settings?.is_workspace_local_mode ?? false;
    workspaceSettings.value = workspaceData?.settings ?? {};
    repositories.value = workspaceData.repositories;
    provider.value = workspaceData.provider ?? GitProviderName.GitHub;
    providerDisplayName.value = gitwrapper.getTerminology(provider.value).displayName;
    providerLongDisplayName.value = GitProviderInfo[provider.value].displayName;
    if (!workspaceData.sidebar_collapsed_by_default) {
      fetchSidebarExpanded();
    } else {
      isSidebarExpanded.value = false;
      state.set({ key: 'doc_sidebar_expanded', value: isSidebarExpanded.value }).then();
    }

    const workspaceUser = await getWorkspaceUserFromDB(id.value, auth.user.uid);

    documentTemplates.value = await fetchDocumentTemplates(id.value);

    favoriteRepoIds.value = workspaceUser?.favoriteRepoIds ?? [];
    recentRepoIds.value = workspaceUser?.recentRepoIds ?? [];
    currentUser.value = workspaceUser?.external_uid ?? '';

    if (workspaceData.enterprise_url) {
      enterpriseGitUrl.value = workspaceData.enterprise_url;

      if (workspaceData.tenant_id) {
        tenantId.value = workspaceData.tenant_id;
      }
      githubAppUrl.value = workspaceData.enterprise_github_app_url ?? GITHUB_APP_SETTINGS_LINK;
    } else {
      enterpriseGitUrl.value = null;
      tenantId.value = null;
      githubAppUrl.value = GITHUB_APP_SETTINGS_LINK;
    }

    // initialize workspace feature flags
    workspaceFeatureFlags.value['hide_ask_swimm'] = workspaceData?.hide_ask_swimm ?? false;
    workspaceFeatureFlags.value['force_use_cli'] = workspaceData?.force_use_cli ?? false;
  }

  async function dismissOnboardingStep(stepId: string): Promise<void> {
    await updateWorkspaceOnboardingStep(id.value, stepId, { dismiss: true });
    fetchWorkspace();
  }

  function forceOpenSubmenu() {
    shouldForceOpenSubmenu.value = true;
    isSidebarPeek.value = true;
  }

  async function removeRepoFromFavorites(repoId, context: string) {
    const index = favoriteRepoIds.value.findIndex((id) => id === repoId);
    if (index > -1) {
      favoriteRepoIds.value.splice(index, 1);
      await removeRepoFromWorkspaceUserFavorites(id.value, auth.user.uid, repoId);
      analytics.track(productEvents.REMOVED_REPO_FROM_FAVORITES, {
        'Repo ID': repoId,
        Context: context,
      });
    }
  }

  async function addRepoToFavorites(repoId, context: string) {
    if (favoriteRepoIds.value.includes(repoId)) {
      return;
    }
    favoriteRepoIds.value.push(repoId);
    await removeRepoFromRecents(repoId);
    await addRepoToWorkspaceUserFavorites(id.value, auth.user.uid, repoId);
    analytics.track(productEvents.ADDED_REPO_TO_FAVORITES, {
      'Repo ID': repoId,
      Context: context,
    });
  }

  async function removeRepoFromRecents(repoId) {
    const index = recentRepoIds.value.findIndex((id) => id === repoId);
    if (index > -1) {
      recentRepoIds.value.splice(index, 1);
      await updateWorkspaceUserRecentRepos(id.value, auth.user.uid, recentRepoIds.value);
    }
  }

  async function addRepoToRecents(repoId) {
    if (favoriteRepoIds.value.includes(repoId) || recentRepoIds.value.includes(repoId)) {
      // alreay in recents or favorites.
      return;
    }
    recentRepoIds.value.unshift(repoId);
    if (recentRepoIds.value.length > 3) {
      recentRepoIds.value.pop();
    }
    await updateWorkspaceUserRecentRepos(id.value, auth.user.uid, recentRepoIds.value);
  }

  async function getDocumentTemplate(templateId: string): Promise<WorkspaceDocumentTemplate> {
    if (!documentTemplates.value.has(templateId)) {
      return await fetchDocumentTemplate(id.value, templateId);
    }
    return documentTemplates.value.get(templateId);
  }

  async function refreshDocumentTemplates(): Promise<void> {
    documentTemplates.value = await fetchDocumentTemplates(id.value);
  }

  async function saveDocumentTemplate(template: string, name: string): Promise<string> {
    const currentVersion = await fetchLastTemplateVersion(id.value, name);
    const newVersion = currentVersion + 1;
    const templateId = `${slugify(name)}-${newVersion}`;

    await createDocumentTemplate(id.value, templateId, template, name, newVersion);
    void refreshDocumentTemplates();

    return templateId;
  }

  return {
    forceOpenSubmenu,
    isSidebarPeek,
    isSidebarExpanded,
    isSidebarFavoritesExpanded,
    canExpandSidebarMenu,
    shouldExpandSidebarMenu,
    shouldForceOpenSubmenu,
    id,
    name,
    logo,
    repositories,
    hasRepos,
    hasDummyRepo,
    onboardingStepsState,
    fetchWorkspace,
    dismissOnboardingStep,
    enterpriseGitUrl,
    tenantId,
    githubAppUrl,
    isPendingManualSetup,
    isWorkspaceLocalModeActive,
    favoriteRepoIds,
    recentRepoIds,
    currentUser,
    addRepoToFavorites,
    removeRepoFromFavorites,
    addRepoToRecents,
    provider,
    providerDisplayName,
    providerLongDisplayName,
    workspaceSettings,
    workspaceFeatureFlags,
    isGenAIDisabledForWorkspace,
    isProcessToDocDisabledForWorkspace,
    documentTemplates,
    getDocumentTemplate,
    saveDocumentTemplate,
  };
});

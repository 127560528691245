<script setup lang="ts">
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import {
  DEFAULT_SIDEBAR_SIZES,
  useWorkspaceSidebarStore,
} from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';
import Multipane from '@/common/components/Multipane/Multipane.vue';
import MultipaneResizer from '@/common/components/Multipane/MultipaneResizer.vue';
import { clamp, throttle } from 'lodash-es';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { productEvents } from '@swimm/shared';

const analytics = useAnalytics();
const { shouldExpandSidebarMenu } = storeToRefs(useWorkspaceStore());
const { sidebarExpandedWidthPx } = storeToRefs(useWorkspaceSidebarStore());

defineProps<{
  noSidebar?: boolean;
}>();

const SIDEBAR_WIDTH = 48;
const SIDEBAR_NAME = 'sidebar-wrapper';

const multipane = ref<{ isResizing: boolean } | null>(null);

let lastSidebarWidth = sidebarExpandedWidthPx.value;

/**
 * Return the given size as a number (instead of a string).
 * If the pane isn't relevant, return `null` instead.
 * @param pane the resized pane.
 * @param _resizer the element that caused the resizing.
 * @param size the CSS size the pane was resized to.
 */
function paneResizeEventNormalizer(pane: unknown, _resizer: unknown, size: string): null | number {
  // @ts-ignore - `pane` can be either the actual sidebar or any other resizeable element.
  if (pane.attributes?.name?.textContent !== SIDEBAR_NAME) {
    return null;
  }
  // Parse the given size ('XXXpx') into a n number.
  const sizeNumber = parseInt(size.slice(0, -2));
  return sizeNumber;
}

function paneResize(pane: unknown, resizer: unknown, size: string) {
  const normalizerResult = paneResizeEventNormalizer(pane, resizer, size);
  if (normalizerResult === null) {
    return;
  }
  // Since the given size includes the sidebar, we reduce its size (`sidebar-width`) from the calculation.
  const correctedComponentSize = normalizerResult - SIDEBAR_WIDTH;
  // Clamp the width to the minimum and maximum values.
  const width = clamp(correctedComponentSize, DEFAULT_SIDEBAR_SIZES.MIN, DEFAULT_SIDEBAR_SIZES.MAX);
  sidebarExpandedWidthPx.value = width;
}

function startResize(pane: unknown, resizer: unknown, size: string) {
  const normalizerResult = paneResizeEventNormalizer(pane, resizer, size);
  if (normalizerResult === null) {
    return;
  }
  lastSidebarWidth = sidebarExpandedWidthPx.value;
}

function stopResize(pane: unknown, resizer: unknown, size: string) {
  const normalizerResult = paneResizeEventNormalizer(pane, resizer, size);
  if (normalizerResult === null) {
    return;
  }
  const currentSidebarWidth = sidebarExpandedWidthPx.value;
  analytics.track(productEvents.SIDEBAR_RESIZED, { 'Old Size': lastSidebarWidth, 'New Size': currentSidebarWidth });
}

// Throttle the calls so they won't happen every literal pixel, but still give us a high enough FPS.
const throttledPaneResize = throttle(paneResize, 10);

const sidebarExpandedWidth = computed<string>(() => `calc(var(--sidebar-width) + ${sidebarExpandedWidthPx.value}px)`);
const expandedSidebarClass = computed(() => {
  return {
    width: sidebarExpandedWidth.value,
    'min-width': `calc(var(--sidebar-width)) + ${DEFAULT_SIDEBAR_SIZES.MIN}px`,
    'max-width': `calc(var(--sidebar-width)) + ${DEFAULT_SIDEBAR_SIZES.MAX}px`,
  };
});
const multipaneClampSettings = {
  min: DEFAULT_SIDEBAR_SIZES.MIN + SIDEBAR_WIDTH,
  max: DEFAULT_SIDEBAR_SIZES.MAX + SIDEBAR_WIDTH,
};
</script>

<template>
  <div v-if="noSidebar" class="no-sidebar-layout">
    <div id="main" class="main">
      <slot />
    </div>
  </div>
  <Multipane
    v-else
    class="narrow-sidebar-layout"
    :clamp-size="multipaneClampSettings"
    @pane-resize="throttledPaneResize"
    @pane-resize-start="startResize"
    @pane-resize-stop="stopResize"
    ref="multipane"
  >
    <div
      class="sidebar"
      :class="{ 'is-resizing': multipane?.isResizing }"
      :style="shouldExpandSidebarMenu ? expandedSidebarClass : { width: `${SIDEBAR_WIDTH}px` }"
      :name="SIDEBAR_NAME"
    >
      <slot name="sidebar" />
    </div>
    <MultipaneResizer class="resizer" v-if="shouldExpandSidebarMenu" :is-resizing="multipane?.isResizing ?? false" />
    <div id="main" class="main">
      <slot />
    </div>
  </Multipane>
</template>

<style scoped lang="postcss">
.narrow-sidebar-layout {
  --sidebar-width: 48px;
  --notch-size: 20px;
  --notch-radius: calc(var(--notch-size) * 0.8);
  display: flex;
  overflow: hidden;
  height: 100vh;
  position: relative;
  background-color: var(--color-bg);

  .sidebar {
    scroll-behavior: smooth;

    &:not(.is-resizing) {
      transition: width 0.2s ease;
    }
  }
}

.no-sidebar-layout {
  display: flex;
  height: 100vh;
  position: relative;
  background-color: var(--color-bg);
}

.main {
  overflow: auto;
  flex: 1;
  background-color: var(--color-bg);

  & + :not(.sidebar-expanded) {
    border-top-left-radius: var(--notch-radius);
  }
}

@media print {
  .main {
    width: 100%;
    overflow: visible;
  }
}

.resizer {
  height: initial;
  cursor: col-resize;
  z-index: 1000;
}
</style>

<script setup lang="ts">
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { UserRole } from '@/common/consts';
import { useSwimmEventLogs } from '@/modules/core/compositions/swimm-events';
import UsersTableItem from '@/modules/workspace/modals/settings/users/UsersTableItem.vue';
import UsersTableSkeletonRow from '@/modules/workspace/modals/settings/users/UsersTableSkeletonRow.vue';
import { Licenses, WorkspaceUser, eventLogger, firestoreCollectionNames, productEvents } from '@swimm/shared';
import { computed, onMounted, ref } from 'vue';
import { SwText } from '@swimm/ui';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useBillingStore } from '@/modules/billing/store/billing';

const SKELETON_LINES_AMOUNT = 6;

const emit = defineEmits(['accept-request', 'deny-request']);

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { logEvent } = useSwimmEventLogs();
const { fetchBillingData } = useBillingStore();

const loading = ref(true);
const userInProcess = ref('');

onMounted(async () => {
  await store.dispatch('database/refreshWorkspaceUsersAndInvites', { workspaceId: route.params.workspaceId });
  loading.value = false;
});

const workspaceData = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));
const workspaceUsersData = computed<WorkspaceUser[]>(() =>
  store.getters['database/db_getWorkspaceUsers'](route.params.workspaceId)
);
const deletedUsersData = computed<WorkspaceUser>(() =>
  store.getters['database/db_getWorkspaceDeletedUsers'](route.params.workspaceId)
);
const { user } = storeToRefs(useAuthStore());
const currentUserUid = computed(() => user.value.uid);

function mapUser(user: WorkspaceUser) {
  return {
    name: user.name,
    email: user.email,
    uid: user.uid,
    expired: user.expired,
    profile_url: user.profile_url,
  };
}
const existingUsers = computed(() => {
  return Object.values(workspaceUsersData.value || {})
    .map(mapUser)
    .map((user) => ({
      ...user,
      role: user.uid in workspaceData.value.workspace_admins ? UserRole.ADMIN : UserRole.MEMBER,
    }))
    .sort((u1, u2) => u1.role.localeCompare(u2.role));
});

const removedUsers = computed(() => {
  return Object.values(deletedUsersData.value || {}).map(mapUser);
});

const usersRequestedInvite = computed(() => workspaceData.value.invite_requests);
const usersReceivedInvite = computed(() => workspaceData.value?.invites || []);

const workspaceLicense = computed(() => store.getters['database/db_getWorkspaceLicense'](route.params.workspaceId));
const isFreePlan = computed(() => workspaceLicense.value === Licenses.FREE);

const tableHeaders = computed(() => {
  return ['NAME', 'ROLE', ''];
});

async function handleRoleChange(userUid, userName, userPrevRole) {
  userInProcess.value = userUid;

  const firebaseResourceTarget = {
    resourceName: firestoreCollectionNames.WORKSPACE_ADMINS,
    containerDocId: route.params.workspaceId,
    containerCollectionType: firestoreCollectionNames.WORKSPACES,
  };

  if (userPrevRole === UserRole.ADMIN) {
    await store.dispatch('database/removeResourceInFirebaseDocument', {
      ...firebaseResourceTarget,
      resourceId: userUid,
    });
  } else {
    await store.dispatch('database/saveResourceInFirebaseDocument', {
      ...firebaseResourceTarget,
      resource: { uid: userUid, id: userUid, name: userName },
    });
  }

  const swimmEvent =
    userPrevRole === UserRole.ADMIN
      ? eventLogger.SWIMM_EVENTS.WORKSPACE_ADMIN_DEMOTED_TO_WORKSPACE_USER
      : eventLogger.SWIMM_EVENTS.WORKSPACE_USER_PROMOTED_TO_WORKSPACE_ADMIN;
  logEvent(swimmEvent, { srcId: currentUserUid.value, targetId: userUid, targetName: userName });

  userInProcess.value = '';
}

async function acceptRequest(userEmail) {
  userInProcess.value = userEmail;
  emit('accept-request', userEmail);
}

async function denyRequest(userEmail) {
  userInProcess.value = userEmail;
  emit('deny-request', userEmail);
}

async function removeInvite(userEmail: string) {
  userInProcess.value = userEmail;
  const payload = {
    workspaceId: route.params.workspaceId as string,
    email: userEmail,
  };

  const removeInviteResult = await CloudFunctions.removeInvite(payload);
  if (removeInviteResult.data) {
    await store.dispatch('database/removeInviteFromWorkspaceState', payload);
    await fetchBillingData();
  }

  userInProcess.value = '';
}

async function removeUser(uid: string) {
  userInProcess.value = uid;
  const isWorkspaceAdmin = store.getters['database/db_isWorkspaceAdmin'](route.params.workspaceId, uid);
  const removeUserResult = await CloudFunctions.removeUserFromWorkspace({
    workspaceId: route.params.workspaceId as string,
    userId: uid,
  });
  if (removeUserResult.data) {
    await store.dispatch('database/removeUserFromWorkspaceState', {
      workspaceId: route.params.workspaceId,
      userId: uid,
      isAdmin: isWorkspaceAdmin,
    });
    await fetchBillingData();
    await logAndReportUserRemoval(uid);
  }
  userInProcess.value = '';
}

async function resendInvite(userEmail) {
  userInProcess.value = userEmail;
  await CloudFunctions.resendInvite({ workspaceId: route.params.workspaceId as string, email: userEmail });
  userInProcess.value = '';
}

async function logAndReportUserRemoval(uid) {
  await analytics.cloudWorkspaceGroup({ workspaceId: productEvents.NO_WORKSPACE, userId: uid });
  analytics.track(productEvents.USER_REMOVED, { 'Workspace ID': route.params.workspaceId, 'User ID': uid });
  logEvent(eventLogger.SWIMM_EVENTS.USER_REMOVED_FROM_WORKSPACE, { targetId: uid, srcId: currentUserUid.value });
}
</script>

<template>
  <table>
    <thead>
      <tr>
        <th v-for="header in tableHeaders" :key="header">
          <SwText variant="system-body">{{ header }}</SwText>
        </th>
      </tr>
    </thead>
    <tbody>
      <template v-if="loading">
        <UsersTableSkeletonRow
          v-for="index in [...Array(SKELETON_LINES_AMOUNT).keys()]"
          :key="index"
          :headers-amount="tableHeaders.length"
        />
      </template>
      <template v-else>
        <UsersTableItem
          v-for="userEmail in usersRequestedInvite"
          :key="userEmail"
          :loading="userInProcess === userEmail"
          :email="userEmail"
          request-invite
          @accept="acceptRequest(userEmail)"
          @deny="denyRequest(userEmail)"
        />
        <UsersTableItem
          v-for="userEmail in usersReceivedInvite"
          :key="userEmail"
          :loading="userInProcess === userEmail"
          :email="userEmail"
          invited
          @resend-invite="resendInvite(userEmail)"
          @remove="removeInvite(userEmail)"
        />
        <UsersTableItem
          v-for="existingUser in existingUsers"
          :key="existingUser.uid"
          :loading="userInProcess === existingUser.uid"
          :uid="existingUser.uid"
          :display-name="existingUser.name"
          :creator-profile-url="existingUser.profile_url"
          :email="existingUser.email"
          :role="existingUser.role"
          :expired="existingUser.expired"
          @role-change="handleRoleChange(existingUser.uid, existingUser.name, existingUser.role)"
          @remove="removeUser(existingUser.uid)"
        />
        <template v-if="isFreePlan">
          <UsersTableItem
            v-for="removedUser in removedUsers"
            :key="removedUser.uid"
            :uid="removedUser.uid"
            :display-name="removedUser.name"
            :creator-profile-url="removedUser.profile_url"
            :email="removedUser.email"
            deleted-user
        /></template>
      </template>
    </tbody>
  </table>
</template>

<style scoped lang="postcss">
table {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      text-transform: uppercase;
      color: var(--text-color-secondary);
      border-bottom: 2px solid var(--color-border-default-subtle);
      padding: var(--space-base);

      &:first-of-type {
        text-align: left;
        width: 60%;
      }
    }
  }
}
</style>

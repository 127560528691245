<template>
  <InstanceNotification :action="action" :add-notifier-to-title="true" :title="title" :notification="notification" />
</template>

<script lang="ts">
import InstanceNotification from '@/modules/notifications-center/components/InstanceNotification.vue';

export default {
  components: { InstanceNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
  },
  computed: {
    action() {
      return ` ${this.notification.topic_name}`;
    },
    title() {
      return 'marked your doc request as completed:';
    },
  },
};
</script>

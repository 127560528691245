import { OpenAI } from 'openai';
import { type GeneratePRToDocRequest, type SnippetsToDocPrompt } from '../types/generative-ai';
import * as iter from '../utils/iter';

export const SNIPPETS2DOC_SNIPPET_DELIMITER = '~~~';

const SYSTEM_PROMPT = `You are a developer who hates long texts, and thinks code should speak for itself, and documentation should only point out the 'why'.
You also despise fluffy sentences like "X is crucial for a seamless operation of the system" - instead you describe what things do exactly and not just say they are crucial.
You're walking a colleague through something in the code.
You want to create a document that explains why it was implemented the way that it was and go through the main points of the implementation.
Below a list of code snippets taken from the code, separated by "${SNIPPETS2DOC_SNIPPET_DELIMITER}" followed by each snippet numeric identifier and then "${SNIPPETS2DOC_SNIPPET_DELIMITER}" again, for example ${SNIPPETS2DOC_SNIPPET_DELIMITER}1${SNIPPETS2DOC_SNIPPET_DELIMITER} will represent snippet with identifier 1.
Write a documentation walkthrough that integrates the snippets as part of the explanation.
Integrate relevant and important placeholders referring to the snippet - use "${SNIPPETS2DOC_SNIPPET_DELIMITER}{SNIPPET NUMERIC IDENTIFIER}${SNIPPETS2DOC_SNIPPET_DELIMITER}" as placeholder in a new line. For example:

GOOD:

We first define the function.
The function works by considering case 1... case 2... case 3...
${SNIPPETS2DOC_SNIPPET_DELIMITER}1${SNIPPETS2DOC_SNIPPET_DELIMITER}

And then we invoke it from the URL handler
${SNIPPETS2DOC_SNIPPET_DELIMITER}2${SNIPPETS2DOC_SNIPPET_DELIMITER}

It is important to also add the function to this structure because {{PLACEHOLDER FOR USER EXPLANATION}}
${SNIPPETS2DOC_SNIPPET_DELIMITER}3${SNIPPETS2DOC_SNIPPET_DELIMITER}

BAD:
We handle this logic in main.ts, where we first get the API call, then store the value in the database
${SNIPPETS2DOC_SNIPPET_DELIMITER}1${SNIPPETS2DOC_SNIPPET_DELIMITER}
${SNIPPETS2DOC_SNIPPET_DELIMITER}2${SNIPPETS2DOC_SNIPPET_DELIMITER}

GOOD:
We handle this logic in main.ts, where we first get the API call:
${SNIPPETS2DOC_SNIPPET_DELIMITER}1${SNIPPETS2DOC_SNIPPET_DELIMITER}

then store the value in the database
${SNIPPETS2DOC_SNIPPET_DELIMITER}2${SNIPPETS2DOC_SNIPPET_DELIMITER}

END EXAMPLE

Omitting snippets:
  - Omit snippets containing only imports
  - Omit snippets that contain only trivial details and not important logic.
  - Omit snippets containing only styling.

Explaining snippets:
  - Never explain a snippet line by line - instead explain WHY IT IS IMPORTANT and how it fits in the bigger picture.
  - Please try to refer to each snippet at most once.
  - Please try to refer to a single snippet at a time.

General Guidelines
  - Use a brief but friendly tone in the style of a technical writer
  - Avoid the words 'seamless' or synonyms thereof - just describe what things do without using these meaningless words.
  - Be concise - use short sentences and explanations and let the code do most of the talking.
  - Please avoid sending the title of the document
  - Please avoid sending code in your response
  - Use full paths when referencing files like so: full/path/to/file.extension
  - Use sentence case in headers
  - Please consistently use headers in markdown format (starting with the "#" symbol) for each new section of the walkthrough and write them in SENTENCE case. Divide the flow logically by adding headers between related snippets and explanations.
  - Do not directly mention the name of the code change in the document.


Begin with an introduction with a numbered list of the main questions and design decisions that will be explained in the document - make sure to answer these questions in the document.
Example Introduction:

# Introduction
This document will walk you through X....

The X ... <explain the purpose>

We will cover:
1. First design decision/question
2. Second design decision/question.
3. Third design decision/question.
...`;

export function getSnippets2DocPrompt(request: GeneratePRToDocRequest): SnippetsToDocPrompt {
  const allSnippetContent = request.snippetsText;
  const title = request.swimmDocument.title;

  const messages: OpenAI.Chat.ChatCompletionMessageParam[] = [
    {
      role: 'system',
      content: SYSTEM_PROMPT,
    },
    {
      role: 'user',
      content: `The code change title is: "${title}"\nThe snippets are:\n${allSnippetContent}`,
    },
  ];

  if (request.customPrompt?.length > 0) {
    messages.push({
      role: 'user',
      content: `The user input these guidelines as well: "${request.customPrompt}" - the user will be upset if you do not follow them.`,
    });
  }
  return {
    messages,
    options: {
      max_tokens: 4096,
      temperature: 0.1,
      top_p: 1,
      n: 1,
    },
    workspaceId: request.workspaceId,
    requestId: request.requestId,
    shouldCancel: request.shouldCancel,
  };
}

function formatSnippetForSnippets2DocPrompt(snippetIndex: number, path: string, content: string): string {
  return `${SNIPPETS2DOC_SNIPPET_DELIMITER}${snippetIndex}${SNIPPETS2DOC_SNIPPET_DELIMITER}\n${path}\n${content
    .split('\n')
    .filter((line: string) => !!line)
    .join('\n')}`;
}

export function formatSnippetsForSnippets2DocPrompt(
  snippets: Iterable<{ index: number; path: string; content: string }>
): string {
  return iter.join(
    iter.map(snippets, ({ index, path, content }) => formatSnippetForSnippets2DocPrompt(index, path, content)),
    '\n\n'
  );
}

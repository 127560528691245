<template>
  <div
    ref="modalWrapper"
    :class="['modal-wrapper', { padded, expanded: show, 'in-sequence': isInPlaylist, resizing: isResizing }]"
    :style="modalWrapperWidth"
    @click.self.stop="!isResizing && closeModalFromBackground"
  >
    <div ref="modal" class="modal" tabindex="0">
      <Multipane layout="horizontal" @mouseup="isResizing && endResizing()">
        <header class="modal-header">
          <div class="snippet-line" @click="toggleModal">
            <Icon name="terminal" class="snippet-icon" />
            <span class="title">{{ heading }}</span>
            <span class="snippet-badge body-XS">/snippet</span>
          </div>
          <span class="close-btn-wrapper" @click="toggleModal"><Icon :name="closeIcon" class="close-btn" /></span>
        </header>
        <div
          ref="resizeHandler"
          class="pane-resizer-handler"
          :style="resizeHandlerStyle"
          @click="clickResizerHandler"
        />
        <MultipaneResizer
          class="modal-resizer"
          name="modal-resizer"
          @mousedown="startResizing"
          :is-resizing="isResizing"
        />
        <div class="modal-content" :style="modalComputedHeight">
          <slot />
        </div>
      </Multipane>
    </div>
  </div>
</template>

<script>
import Multipane from '@/common/components/Multipane/Multipane.vue';
import MultipaneResizer from '@/common/components/Multipane/MultipaneResizer.vue';
import { useSnippetStore } from '@swimm/editor';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useWorkspaceSidebarStore } from '@/modules/core/workspace/sidebar/store/workspace-sidebar';
import { getLoggerNew, state } from '@swimm/shared';
import { storeToRefs } from 'pinia';

const MODAL_MIN_HEIGHT = 300;

const logger = getLoggerNew('Snippet Studio');
export default {
  components: {
    Multipane,
    MultipaneResizer,
  },
  props: {
    show: { type: Boolean, default: false },
    heading: { type: String, required: true },
    padded: { type: Boolean, default: true },
    permanentModal: { type: Boolean, default: false },
  },
  emits: ['close'],
  setup() {
    const snippetStore = useSnippetStore();
    const { shouldExpandSidebarMenu } = storeToRefs(useWorkspaceStore());
    const { sidebarExpandedWidthPx } = storeToRefs(useWorkspaceSidebarStore());
    const { snippetDrawerHeight } = storeToRefs(snippetStore);
    const { setSnippetDrawerHeight } = snippetStore;

    return { snippetDrawerHeight, setSnippetDrawerHeight, shouldExpandSidebarMenu, sidebarExpandedWidthPx };
  },
  data() {
    return {
      drawerHeight: '',
      isResizing: false,
      resizeWatcher: null,
    };
  },
  computed: {
    closeIcon() {
      return this.show ? 'arrow-down' : 'arrow-up';
    },
    isInPlaylist() {
      return this.$route.path.includes('/playlists');
    },
    modalComputedHeight() {
      return this.drawerHeight ? `height: ${this.show ? this.drawerHeight : 0}` : '';
    },
    resizeHandlerStyle() {
      if (this.show) {
        const drawerHeightNumber = this.drawerHeight.split('px')[0];
        if (drawerHeightNumber) {
          return `height: calc(100vh - ${drawerHeightNumber}px)`;
        }
      }

      return `height: 35vh`;
    },
    modalWrapperWidth() {
      return {
        width: this.shouldExpandSidebarMenu
          ? `calc(100vw - var(--sidebar-width) - ${this.sidebarExpandedWidthPx}px)`
          : `calc(100vw - var(--sidebar-width))`,
      };
    },
  },
  async mounted() {
    const heightFromState = await state.get({ key: 'snippet_drawer_height' });
    if (heightFromState) {
      await this.setSnippetDrawerHeight({ drawerHeight: heightFromState });
    }
    const drawerHeight = this.snippetDrawerHeight;
    if (drawerHeight) {
      this.drawerHeight = `${drawerHeight}px`;
    } else {
      // Default height
      this.drawerHeight = '65vh';
    }
  },
  updated() {
    if (this.show) {
      this.$refs.modal.focus();
    }
  },
  beforeUnmount() {
    if (this.resizeWatcher) {
      this.resizeWatcher.unobserve(this.$refs.resizeHandler);
    }
  },
  methods: {
    closeModal() {
      this.$emit('close', false);
    },
    toggleModal() {
      this.$emit('close', !this.show);
    },
    closeModalFromBackground() {
      if (!this.permanentModal) {
        this.closeModal();
      }
    },
    startResizing() {
      if (this.show) {
        this.isResizing = true;
        // Watching for resize handler (placeholder) height change
        // ResizeObserver is raising sometimes a `ResizeObserver loop limit exceeded` exception
        // We can igonre this exception and it is in the Sentry `ignoreErrors` list.
        try {
          this.resizeWatcher = new ResizeObserver(this.resizing);
          this.resizeWatcher.observe(this.$refs.resizeHandler);
        } catch (error) {
          logger.warn(`Snippet Studio Resize Observer: ${error}`);
        }
      }
    },
    resizing() {
      if (!this.show || !this.isResizing) {
        return;
      }

      this.setDrawerHeight(false);
    },
    async endResizing() {
      if (!this.show || !(this.$refs.resizeHandler && this.$refs.resizeHandler.clientHeight)) {
        return;
      }

      this.setDrawerHeight(true);
      this.isResizing = false;
    },
    setDrawerHeight(saveToState = false) {
      const modalHeight = this.$refs.modalWrapper.clientHeight;
      const resizeHandlerHeight = this.$refs.resizeHandler.clientHeight;

      // Deduct resizer handler from modal's height
      const drawerHeight = modalHeight - resizeHandlerHeight;

      // Set drawer height live while resizing
      if (drawerHeight > MODAL_MIN_HEIGHT) {
        this.drawerHeight = `${drawerHeight}px`;
        // Save selected drawer height to state
        if (saveToState) {
          // Not awaiting to avoid unneeded freeze
          this.setSnippetDrawerHeight({ drawerHeight });
        }
      }
    },
    clickResizerHandler() {
      !this.isResizing && this.closeModalFromBackground();
    },
  },
};
</script>

<style scoped lang="postcss">
.modal-wrapper {
  position: fixed;
  bottom: 0;
  z-index: 201;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  cursor: pointer;
  height: 100%;
  background: transparent;
  transition: background 0.5s ease-in-out;
  /* Allow user to scroll the editor while the modal is open (we explicitly allow pointer-events on the child modal
     content itself later) */
  pointer-events: none;
}

.modal-wrapper.in-sequence {
  left: calc(var(--sidebar-width) + var(--sidemenu-width));
  width: calc(100% - var(--sidebar-width) - var(--sidemenu-width));
}

.modal-wrapper.expanded {
  top: 0;
  z-index: 100;
  background: #00000040;
  cursor: pointer;
}

.modal {
  z-index: 100;
  margin-top: auto;
  width: 100%;
  border-radius: 4px;
  background: transparent;
}

.modal:focus {
  outline: none;
}

.modal-wrapper.expanded .modal {
  height: 100%;
  background: transparent;
  box-shadow: none;
}

.modal-wrapper .modal-content {
  overflow: auto;
  padding: 0;
  transition: height 0.4s ease-in-out, height 0.4s ease-in-out;
  box-sizing: border-box;
  word-break: break-word;
  pointer-events: all; /* To overcome the wrapper's pointer-events: none. */
  background: var(--color-surface);
  border-top: 1px solid var(--color-border-default-subtle);
}

.modal-wrapper:not(.padded) .modal-content {
  padding: 0;
}

.modal-wrapper.expanded.resizing .modal .modal-content {
  border-top: 2px solid var(--color-border-brand);
  /* This prevents the modal from transitioning its height when the user is resizing - the height changes continuously
     when resizing, and transitioning causes the resizing to behave incorrectly. */
  transition: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 2px solid var(--color-border-default-subtle);
  opacity: 1;
  transition: opacity 0.4s ease;
  background: var(--color-surface);
  box-shadow: var(--box-shadow-medium);
  pointer-events: all; /* To overcome the wrapper's pointer-events: none. */
}

/* Hide the header when the modal is expanded. */
.modal-wrapper.expanded .modal-header {
  opacity: 0;
  height: 0; /* Needed for resizing to work (it doesn't regard the header height as it's invisible. */
}

.snippet-line {
  display: flex;
  align-items: center;
}

.modal-header .snippet-icon {
  font-size: var(--headline2);
}

.modal-header .snippet-badge {
  margin: 0 10px;
  padding: 3px 5px;
  border-radius: 4px;
  color: var(--text-color-primary);
  background-color: var(--color-status-info);
}

.close-btn-wrapper,
.placeholder {
  width: 50px;
}

.title {
  flex-grow: 1;
  text-align: center;
}

.close-btn-wrapper {
  padding: 15px 0;
  text-align: right;
  cursor: pointer;
  flex: 1;
}

.close-btn {
  cursor: pointer;
  border-radius: 4px;
}

.close-btn:hover {
  color: var(--text-color-on-dark);
  background: var(--text-color-secondary);
}

.modal-wrapper .pane-resizer-handler {
  display: none;
  margin-top: auto;
  background: transparent;
}

.modal-wrapper.expanded .pane-resizer-handler {
  display: block;
}

.modal-wrapper.expanded .multipane {
  height: 100%;
}

.modal-wrapper.expanded .modal-resizer {
  /* To overcome the wrapper's pointer-events: none. */
  pointer-events: all;
  cursor: row-resize;
}
</style>

import { ObjectInfo, RawProcessFile } from './source-types';
import { AdditionalInfo, ObjectRef, ValueObject } from './output-types';
import { LineWords, getLoggerNew, splitLineByWords } from '@swimm/shared';
import { getFullSuggestionForQuery } from '@swimm/editor';

const logger = getLoggerNew('ppg/source-json-parser.ts');

export const EMPTY_VALUE_OBJECT: ValueObject = {
  line: 1,
  wordStartIndex: 0,
  wordEndIndex: 10,
  lineText: '',
  value: '',
};
export const DESCRIPTION_REGEX = /@description=(?<!\\")"?([^\n@"]+)(?!\\")/;
export const NEW_LINE_REGEX = /\r?\n/;

export function escapeRegexValue(value: string) {
  return value.replace(/[.*+?^${}()|[\]\\"]/g, '\\$&');
}

/**
 * Find the line index of a key-value pair in a file
 * @param fileTextLines - the original file content split by lines
 * @param key - the json key to search for
 * @param value - the value to search for
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 */
export function getLineIndex(fileTextLines: string[], key: string, value: string, startAt = -1) {
  // Values that have new lines are hard to capture with regex.
  // Splitting here on the first line as a good enough approximation of the value's identity
  // Then escaping special regex characters so the value match will take the actual character
  const escapedValue = value && escapeRegexValue(value.split(NEW_LINE_REGEX)[0]);

  // The regex will match the key and the value to find the file line they came from (vital for creating smart tokens later)
  const regExp = new RegExp(`["']${key}["']\\s?:\\s?["']?${String.raw`${escapedValue}`}`);

  return fileTextLines.findIndex((line, index) => {
    // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the regex
    const match = line.replace(/\\{1}/g, '').match(regExp);
    return match != null && index > startAt;
  });
}

export function computeValueObjectWithEndIndex(
  lineText: string,
  lineIndex: number,
  wordIndex: number,
  value: string
): ValueObject {
  const completeValueData = getFullSuggestionForQuery(
    value,
    {
      token: value.split(/\s/)[0], // The function uses the first word to get the full token
      position: { path: '', line: lineIndex, wordStart: wordIndex, wordEnd: wordIndex },
      lineData: lineText,
      repoId: '',
      static: false,
    },
    { exactMatch: true }
  );

  return {
    line: completeValueData.position.line,
    wordStartIndex: completeValueData.position.wordStart,
    wordEndIndex: completeValueData.position.wordEnd,
    lineText: completeValueData.lineData,
    value: completeValueData.token,
  };
}

/**
 * Find the word index of a value in a line
 * We are matching the beginning of the value with a matching word in the line
 * used for composing smart tokens later
 * @param textLine
 * @param value
 */
export function getWordIndex(textLine: string, value: string) {
  const valueWords = splitLineByWords(value);
  const lineWords = splitLineByWords(textLine);
  return lineWords.reduce((acc, word, index) => {
    if (acc > -1) {
      return acc;
    }
    if (valueWords[0] === word) {
      if (
        valueWords
          .splice(1)
          .map((followingWord, i) => followingWord === lineWords[index + i + 1])
          .every(Boolean)
      ) {
        return index;
      }
    }
    return -1;
  }, -1);
}

/**
 * Compose a value object from a key-value pair in a file
 * Used to identify this value as a smart token
 * @param fileTextLines
 * @param key
 * @param value
 * @param startAt - used to reduce false matches by starting a search passed a certain line (an attempt to avoid falsely identifying to lines with similar value)
 * @param lineIndex - used to skip the search and directly use the line index
 */
export function composeValueObject(
  fileTextLines: string[],
  key: string,
  value: string,
  startAt = -1,
  lineIndex = -1
): ValueObject {
  if (!value) {
    return { ...EMPTY_VALUE_OBJECT, lineText: key, value: `Missing value for ${key}` };
  }

  const line = lineIndex > -1 ? lineIndex : getLineIndex(fileTextLines, key, value, startAt);

  if (line === -1) {
    return { ...EMPTY_VALUE_OBJECT, lineText: key, value: `Missing value for ${key}` };
  }

  // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the search function
  const lineText = fileTextLines[line].replace(/(\\{1})/g, '');

  const wordIndex = getWordIndex(lineText, value.split(NEW_LINE_REGEX)[0]);
  // multi line values are hard to match with the file's stringified version
  // we are taking the first line out of the value to match it with the file's line as a good enough approximation of the token
  const valueObject = computeValueObjectWithEndIndex(lineText, line + 1, wordIndex, value.split(NEW_LINE_REGEX)[0]);

  // Place the original line data into the token info - so autosync properly matches it
  return { ...valueObject, lineText: fileTextLines[line] };
}

export function parseDescriptionField(
  fileTextLines: string[],
  descriptionField: string,
  nameField: string,
  objectRefs: Record<string, ObjectRef>
) {
  if (!descriptionField) {
    logger.warn(`Description field for ${nameField} is empty`);
    return {};
  }

  const lineIndex = getLineIndex(fileTextLines, 'description', descriptionField);

  if (lineIndex === -1) {
    logger.warn(`Description field not found for ${nameField}`);
    return {};
  }

  const CONNECTIONS_REGEX = /@([\w]+)[ =]{1}\[([^\]]+)]/g; // Will match, as example, @sources [\n\t@connection1=SAP_SUP_Table_ANZ\n]
  const ATTRIBUTE_REGEX = /@(?!description)([\w]+)[=:]\s?\\*"?([^[\]\r\n,"\\]+)\\*"?/g; // Will match, as example, @sourceSystem=\"Stripe\"

  // Replacing \" with \ is a hack for files where they decided to surround the description with escaped quotes - which fails the search function
  const lineText = fileTextLines[lineIndex].replace(/(\\{1}")/g, '\\');
  const lineWords: LineWords = new LineWords(lineText);

  const additionalInfo: AdditionalInfo = {};

  const descriptionMatch = descriptionField.match(DESCRIPTION_REGEX);
  if (descriptionMatch?.[1]) {
    const value = descriptionMatch[1].trim();
    const wordIndex = lineWords.characterIndexToWordIndex(fileTextLines[lineIndex].search(value));

    // Translate wordStartIndex to start and end indices
    additionalInfo.description = computeValueObjectWithEndIndex(lineText, lineIndex + 1, wordIndex, value);

    // Revert changes made to line data - so autosync properly matches it
    if (additionalInfo.description) {
      additionalInfo.description.lineText = additionalInfo.description.lineText.replace(/(\\{1})\*(?!n)/g, '\\"');
    }
  }

  const connections = descriptionField.matchAll(CONNECTIONS_REGEX);
  for (const connection of connections) {
    if (connection.index > -1) {
      if (connection[2]) {
        additionalInfo[connection[1]] =
          connection[2].split(NEW_LINE_REGEX).reduce((acc, textLine) => {
            const value = textLine.trim().match(/@[\w]+=(.*)/)?.[1];
            if (value != null) {
              const matchingObjectRef = objectRefs[value];
              if (matchingObjectRef) {
                acc.push(matchingObjectRef);
              } else {
                logger.warn(`No matching object found with name ${value}. Using empty object.`);
                acc.push({
                  id: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                  name: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for ${value}` },
                  type: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for type` },
                  description: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for description` },
                  path: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for path` },
                  repoHandle: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for repoHandle` },
                  operation: { ...EMPTY_VALUE_OBJECT, lineText: value, value: `Missing value for operation` },
                });
              }
            }
            return acc;
          }, Array<ObjectRef>()) || [];
      }
    }
  }

  const attributes = descriptionField.matchAll(ATTRIBUTE_REGEX);
  for (const attribute of attributes) {
    if (attribute.index > -1) {
      if (attribute[2]) {
        const value = attribute[2].trim();
        if (value != null) {
          const wordIndex = lineWords.characterIndexToWordIndex(lineText.search(attribute[2].trim()));
          additionalInfo[attribute[1]] = computeValueObjectWithEndIndex(lineText, lineIndex + 1, wordIndex, value);
        }
      }
    }
  }

  // If the description field is a string description, not matching the expected syntax - use it as the description attribute
  if (Object.keys(additionalInfo).length === 0 && descriptionField) {
    additionalInfo.description = composeValueObject(fileTextLines, 'description', descriptionField, -1, lineIndex);
  }

  return additionalInfo;
}

export function parseOperation(
  object: ObjectInfo & { path?: string },
  fileTextLines: string[],
  lastMatchedLine: number
) {
  const operationContextAttribute = (object.metadata?.contextAttributes ?? [])[1];
  const rawOperation = operationContextAttribute?.value?.split('@')[1] ?? '';

  // The regex will match the key and the value to find the file line they came from (vital for creating smart tokens later)
  const regExp = new RegExp(`["']value["']\\s?:\\s?["']?${String.raw`${operationContextAttribute?.value ?? ''}`}`);

  const lineIndex = fileTextLines.findIndex((line, index) => {
    // Replacing \" with " is a hack for files where they decided to surround the description with escaped quotes - which fails the regex
    const match = line.replace(/\\{1}/g, '').match(regExp);
    return match != null && index > lastMatchedLine;
  });

  return composeValueObject(fileTextLines, 'operation', rawOperation, lastMatchedLine, lineIndex);
}

export function parseObjectRefs(
  fileJson: RawProcessFile,
  fileTextLines: string[],
  by: 'name' | 'type',
  filePath: string
): { [name: string]: ObjectRef } {
  if (!fileJson.objectRefs) {
    return {};
  }

  let lastMatchedLine = 0;
  // First run creates a map of all objectRefs
  const objectRefsMap: { [key: string]: ObjectRef } = fileJson.objectRefs.reduce((acc, object) => {
    const parsedObject = {
      id: composeValueObject(fileTextLines, 'id', object.id, lastMatchedLine),
      name: composeValueObject(fileTextLines, 'name', object.name, lastMatchedLine),
      type: composeValueObject(fileTextLines, 'type', object.type as string, lastMatchedLine),
      path: composeValueObject(fileTextLines, 'path', object.path as string, lastMatchedLine),
    };

    const repoHandle = composeValueObject(fileTextLines, 'repoHandle', object.metadata?.repoInfo?.repoHandle as string);
    const operation = parseOperation(object, fileTextLines, lastMatchedLine);

    lastMatchedLine = Math.max(
      ...Object.values(parsedObject).reduce((acc, item) => {
        if (item.line != null) {
          acc.push(item.line);
        }
        return acc;
      }, []),
      lastMatchedLine
    );

    (parsedObject as ObjectRef).repoHandle = repoHandle;
    (parsedObject as ObjectRef).operation = operation;

    (parsedObject as ObjectRef).filePath = filePath;

    if (acc[object[by]]) {
      logger.warn(`Duplicate object ${by} found: ${object[by]}`);
    } else {
      acc[object[by]] = parsedObject;
    }

    return acc;
  }, {});

  // Second run enriches each object with connections and information from its description field
  // (Some of it referenced other objectRefs, and therefore has to have the complete map before running)
  return fileJson.objectRefs.reduce((acc, object) => {
    let descriptionFieldInfo = {};
    if (object.metadata.additionalInfo.description) {
      descriptionFieldInfo = parseDescriptionField(
        fileTextLines,
        object.metadata?.additionalInfo?.description,
        object.name,
        acc
      );
    }

    objectRefsMap[object[by]] = { ...objectRefsMap[object[by]], ...descriptionFieldInfo };
    return objectRefsMap;
  }, objectRefsMap);
}

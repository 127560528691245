import codemark from 'prosemirror-codemark';
import { Plugin, PluginKey } from '@tiptap/pm/state';
import { Mark, markInputRule, markPasteRule, mergeAttributes } from '@tiptap/core';
import { getSwimmEditorServices } from './Swimm';

export interface SwmTemplateInlineOptions {
  /**
   * The HTML attributes applied to the code element.
   * @default {}
   * @example { class: 'foo' }
   */
  HTMLAttributes: Record<string, unknown>;
}

declare module '@tiptap/core' {
  interface Commands<ReturnType> {
    swmTemplateInline: {
      /**
       * Set a swmTemplateInline mark
       */
      setTemplateInline: () => ReturnType;
      /**
       * Toggle swmTemplateInline mark
       */
      toggleTemplateInline: () => ReturnType;
      /**
       * Unset a swmTemplateInline mark
       */
      unsetTemplateInline: () => ReturnType;
    };
  }
}

/**
 * Matches inline template.
 */
export const inputRegex = /(?:^|\s)({{(\??\s?[^\W{}?]+(?:[\\.-]?[^\W{}?]+)+\s?)}}(?!\s+}}))$/;

/**
 * Matches inline template while pasting.
 */
export const pasteRegex = /(?:^|\s)({{(\??\s?[^\W{}?]+(?:[\\.-]?[^\W{}?]+)+\s?)}}(?!\s+}}))/g;

/**
 * This extension allows you to mark text as inline swmTemplateInline.
 * @see https://tiptap.dev/api/marks/code for reference
 */
const SwmTemplateInline = Mark.create<SwmTemplateInlineOptions>({
  name: 'swmTemplateInline',

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      optional: {
        parseHTML: (element) => {
          return element.hasAttribute('data-optional');
        },
        renderHTML: (attributes) => {
          return attributes.optional ? { 'data-optional': '' } : null;
        },
      },
    };
  },

  excludes: '_',

  exitable: true,

  parseHTML() {
    return [
      {
        priority: 51,
        tag: 'span',
        getAttrs: (node) => (node as HTMLElement).hasAttribute('data-swm-template-inline') && null,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({ 'data-swm-template-inline': '' }, this.options.HTMLAttributes, HTMLAttributes),
      0,
    ];
  },

  addCommands() {
    return {
      setTemplateInline:
        () =>
        ({ commands }) => {
          return commands.setMark(this.name);
        },
      toggleTemplateInline:
        () =>
        ({ commands }) => {
          return commands.toggleMark(this.name);
        },
      unsetTemplateInline:
        () =>
        ({ commands }) => {
          return commands.unsetMark(this.name);
        },
    };
  },

  addInputRules() {
    const swimmEditorServices = getSwimmEditorServices(this.editor);
    if (swimmEditorServices.isTemplate) {
      return [
        markInputRule({
          find: inputRegex,
          type: this.type,
        }),
      ];
    }
    return [];
  },

  addPasteRules() {
    const swimmEditorServices = getSwimmEditorServices(this.editor);
    if (swimmEditorServices.isTemplate) {
      return [
        markPasteRule({
          find: pasteRegex,
          type: this.type,
        }),
      ];
    }
    return [];
  },

  addProseMirrorPlugins() {
    return codemark({ markType: this.type }).map<Plugin>((plugin: Plugin, index: number) => {
      if (index === 0) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        plugin.key = new PluginKey('templateInlineCode');
        return plugin;
      }
      return plugin;
    });
  },
});

export default SwmTemplateInline;

<template>
  <BaseNotification
    :unseen="!notification.seen"
    :dismissed="notification.dismissed"
    :action="action"
    :title="titleWithNotifier"
    :notifier="notifier"
    :timestamp="timestamp"
    :after-action="afterAction"
    :action-in-a-new-line="actionInANewLine"
    :notification-id="notification.id"
    :notification-type="notification.type"
    :link="link"
  />
</template>

<script lang="ts">
import BaseNotification from '@/modules/notifications-center/components/BaseNotification.vue';
import { UNKNOWN_USER_NAME } from '@/common/consts';

export default {
  components: { BaseNotification },
  props: {
    notification: { type: Object, default: () => ({}) },
    action: { type: String, required: false, default: '' },
    title: { type: String, required: true },
    addNotifierToTitle: { type: Boolean, required: false, default: false },
    actionInANewLine: { type: Boolean, required: false, default: false },
    afterAction: { type: String, required: false, default: '' },
    skipLink: { type: Boolean, required: false, default: false },
  },
  computed: {
    notifier() {
      return this.notification.notifier_name || UNKNOWN_USER_NAME;
    },
    timestamp() {
      return this.notification.created_at ? this.notification.created_at.seconds : 0;
    },
    link() {
      return this.skipLink ? null : this.notification.action_url;
    },
    titleWithNotifier() {
      if (this.addNotifierToTitle) {
        return ` ${this.notifier} ${this.title}`;
      }
      return this.title;
    },
  },
};
</script>

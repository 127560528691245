// Candidates are words (no whitespace) of at least 2 characters, excluding trailing punctuation.
// We do accept () at the end so that things like 'getDate()' get picked up in their entirety.
// This risks not giving a result if the user refers to a function foo as 'foo()' even though
// it does accept parameters, or if it isn't called in the code.
const SWIMMPORT_CANDIDATE_PATTERN = /(?<quote>['"]?)[a-zA-Z0-9_$\-/{}.]+[a-zA-Z0-9_$/](?:\(\))?\k<quote>/g;

export function* getSwimmportTextCandidates(text: string): Iterable<{ text: string; from: number }> {
  for (const match of text.matchAll(SWIMMPORT_CANDIDATE_PATTERN)) {
    if (match.index == null) {
      continue;
    }
    yield { text: match[0], from: match.index };
  }
}

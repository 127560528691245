export const sgdEvents = {
  SGD_ACTIVE: 'View SGD Toast Notification',
  TOASTER_DISMISSED: 'Dismissed SGD Toast Notification',
  PROMOTION_BOX_HOVERED_FIRST_TIME: 'Hovered SGD Promotion Box For The First Time',
  PROMOTION_BOX_ITEM_SELECTED: 'Clicked SGD Promotion Box Item',
  SUBMITTED_PROMOTION_BOX_FEEDBACK: 'Submitted Promotion-Box-Feedback',
  VIEW_PROMOTION_BOX_FEEDBACK: 'View Promotion-Box-Feedback',
  CLICKED_REMOVE_TEMPLATE: 'Clicked Remove-Template',
  SUBMITTED_REMOVE_TEMPLATE_FEEDBACK: 'Submitted Remove-Template Feedback',
  SHOW_MORE: 'Clicked Show More SGD',
  ELLIPSIS_GENERATED_DOC: 'Clicked New Generated Doc',
  GENERATED_DOC_FROM_QUICKSTART: 'Clicked New SGD Doc from Quick Start Cards',
  SGD_TEMPLATE_MODAL_OPENED: 'View SGD Templates Viewer',
  SELECT_ITEM_IN_TEMPLATE: 'Clicked Generated Templates Item',
  SCROLLED_GENERATED_ITEM: 'Scrolled Generated Templates Item',
  SGD_SEARCH_STARTED: 'Searched Template By Keyword',
  SGD_DRAFT_DISCARDED: 'Discarded SGD Doc',
  SGD_IS_OFF: 'Disabled Generated Docs Feature',
  TUNNEL_ONBOARDING_SGD_COMPLETE: 'Tunnel Onboarding - SGD completed',
};

// These are constants to be used with reportPageVisit
export const pageEvents = {
  VIEW_REPO_TAB: (tab) => `View ${tab} Tab`,
  VIEW_HOME: 'View Home',
  LOADED_A_PAGE: 'Loaded a Page',
  LOADED_A_PAGE_MARKETING: '_loaded_a_page',
  VIEW_EVERYTHING: 'View Everything',
  VIEW_CLOUD_DOCS: 'View Cloud Docs',
  VIEW_BILLING_AND_PLANS: 'View Billing & Plans',
  VIEW_DOCUMENT: 'View Document',
  NEW_DOCUMENT: 'New Document',
  NEW_DOCUMENT_MARKETING: '_new_document_created',
  EDIT_DRAFT: 'Edit Draft',
  EDIT_DOCUMENT: 'Edit Document',
  NEW_PLAYLIST: 'New Playlist',
  EDIT_PLAYLIST: 'Edit Playlist',
  NEW_WORKSPACE: 'New Workspace',
  EDIT_WORKSPACE: 'Edit Workspace',
  VIEW_FORGOT_PASSWORD: 'View Forgot Password',
  VIEW_JOIN_WORKSPACE: 'View Join Workspace',
  VIEW_YOUR_WORKSPACES: 'View Your Workspaces',
  VIEW_SET_UP_YOUR_WORKSPACE: 'View Set Up Your Workspace',
  VIEW_LOGIN: 'View Login',
  VIEW_PLAYLIST: 'View Playlist',
  OPEN_GITHUB_APP_MODAL: 'View GitHub App Settings',
  REMOVE_INVITE: 'View Remove User Invite Confirmation',
  REMOVE_USER: 'Remove User Confirmation Popup',
  SELECT_IMPORT_POPUP: 'View Select File to Import',
  GITHUB_ACCESS_PERMISSIONS: 'View Select GitHub Access Permissions',
  SELECT_PR_POPUP: 'Select Pull Request Popup',
  VIEW_INSTALL_GITHUB_APP: 'View Install GitHub App',
  VIEW_GIT_PROVIDER_NOT_SUPPORTED: 'View Git Provider Not Supported',
  VIEW_EDITOR_BASICS: 'View Editor Basics',
  VIEW_SELECT_REPOSITORY: 'View Select Repository',
  VIEW_SNIPPET_STUDIO: 'View Snippets Studio',
  VIEW_MOBILE: 'View Mobile Not Supported',
  VIEW_GIT_PROVIDER_NOT_SUPPORTED_ON_MOBILE: 'View Git Hosting Not Supported on Mobile',
  VIEW_TUNNEL_ONBOARDING_CREATE_WORKSPACE: 'Onboarding Step - Create Workspace',
  VIEW_TUNNEL_ONBOARDING_EXPLORE_SWIMM: 'Onboarding Step - Explore Swimm',
  VIEW_TUNNEL_ONBOARDING_CONNECT_REPO: 'Onboarding Step - Connect a Repo',
  VIEW_TUNNEL_ONBOARDING_FINISH: 'Onboarding Step - Finish',
  VIEW_LOCKED_GET_STARTED: 'View locked Get Started list',
  VIEW_GET_STARTED_CONGRATS_STEP: 'View Get Started congrats step',
  VIEW_GET_STARTED_CHECKLIST: 'View Get Started checklist',
  VIEW_CLOUD_DOC: 'View Cloud Doc',
  EDIT_CLOUD_DOC: 'Edit Cloud Doc',
  VIEW_HOME_PAGE_TAB_SELECTED: (tab) => `View Workspace ${tab} Tab`,
  VIEW_IDE_LOGIN_PAGE: 'View IDE Login',
  VIEW_REPO_PAGE: 'View Repo Page',
};
// These are constants to be used with reportCustomProductAlert
export const productEvents = {
  NO_WORKSPACE: 'NoWorkspace',
  USER_REMOVED: 'User Removed from Workspace',
  USER_LIMIT_REACHED_PAYWALL: 'User Limit Reached Paywall',
  WORKSPACE_LIMIT_REACHED_PAYWALL: 'Workspace Limit Reached Paywall',
  GITHUB_AUTHENTICATION_SUCCESS: 'GitHub Login Authentication Success',
  GITHUB_AUTHORIZATION_SUCCESS: 'GitHub Authorized',
  GITHUB_AUTHORIZATION_SUCCESS_MARKETING: '_repo_connected',
  GITLAB_AUTHENTICATION_SUCCESS: 'GitLab Login Authentication Success',
  GITLAB_AUTHORIZATION_SUCCESS: 'GitLab Org Authorization Success',
  WORKSPACE_CREATED: 'Workspace Created',
  WORKSPACE_CREATED_MARKETING: '_workspace_created',
  WORKSPACE_DELETED: 'Workspace Deleted',
  LEFT_WORKSPACE: 'Left Workspace',
  USER_SWITCHED_WORKSPACE: 'User Switched Workspace',
  USER_SIGNED_UP: 'Signed Up',
  USER_SIGNED_UP_MARKETING: '_signed_up',
  USER_LOGGED_IN: 'Logged In',
  USER_LOGGED_OUT: 'User Logged Out',
  CLICKED_SIGN_IN: 'Clicked Sign In',
  DOCUMENT_SAVED: 'Document Saved in GitHub',
  REPO_ADDED_TO_WORKSPACE: 'Repo Added to Workspace',
  REPO_REMOVED_SUCCESS: 'Repo Removed',
  PRIVATE_REPO_LIMIT_REACHED_PAYWALL: 'View Private Repo Limit Reached',
  WORKSPACE_NAME_MODIFIED: 'Workspace Name Modified',
  INTELLIJ_PLUGIN_INSTALL: 'Clicked "Installed"',
  INVITE_SUCCESSFULLY_SENT: 'User Invite Sent',
  JOINED_BY_INVITE: 'Joined Existing Workspace',
  SNIPPET_ADDED_TO_DOC: 'Snippet Added to Document',
  ADDED_SNIPPET_TO_DOC_VIA_RIGHT_CLICK: 'Added snippet to doc via right click',
  ADDED_SNIPPET_TO_DOC_WITH_SNIPPET_SELECTION_MODAL: 'Added snippet to doc with snippet selection modal',
  ADDED_SMART_TOKEN_TO_DOC_WITH_RIGHT_CLICK: 'Added Smart Token to doc with right click',
  SNIPPET_UPDATED: 'Snippet Updated',
  SNIPPET_LINE_RANGE_EDITED: 'Edited Snippet Line Range',
  STARTED_SPLIT_SNIPPET: 'Started Split Snippet',
  CANCELED_SPLIT_SNIPPET: 'Canceled Split Snippet',
  SPLIT_SNIPPET: 'Split Snippet',
  SLASH_COMMANDS_MENU_TRIGGERED: 'Triggered Slash Commands Menu',
  SLASH_COMMAND_TRIGGERED: 'Slash Command Triggered',
  FLOATING_MENU_CLICKED: 'Clicked Floating Menu Item',
  USER_SETTINGS_SAVED: 'User Settings Saved',
  PR_ADDED_TO_DOC: 'Pull Request Content Added to Doc',
  CLICKED_ADD_TO_DOC_FROM_PR: 'Clicked "Add to Doc" from PR',
  DELETE_PR_FILE_CHANGES: 'Deleted File Changes in PR2Doc Modal',
  CLICKED_EDIT_SNIPPET_PR2DOC: 'Clicked "Edit snippets before generating" in PR2Doc Modal',
  CLICKED_BUTTON_OPTIONS_PR2DOC: 'Opened Split Menu Button in PR2Doc Modal',
  CLICKED_LOOK_FOR_PR: 'Clicked "Look for PR number" in PR2Doc Picker',
  OPENED_PR_PICKER: 'Opened PR2Doc Picker',
  FILTERED_PR_PICKER: 'Filtered in PR2Doc Picker',
  PICKED_PR: 'Picked PR in PR2Doc Picker',
  AI_SNIPPETS_TO_DOC: 'AI Snippets to Doc Content Added to Doc',
  CLICKED_GENERATE_WITH_AI_PR2DOC: 'Clicked "Generate with AI" in PR2Doc',
  CLICKED_STOP_GENERATING_AI_PR2DOC: 'Clicked "Stop generating" in PR2Doc',
  FAILED_PR2DOC_WITH_AI: 'Failed PR2Doc With AI',
  SMART_TEXT_TOKEN_ADDED: 'Smart Token Added to Document',
  DOC_NOTIFICATION_INTEGRATION_OFF: 'New Document Created Notification Settings Turned Off',
  DOC_NOTIFICATION_INTEGRATION_ON: 'New Document Created Notification Settings Turned On',
  DOC_NOTIFICATION_INTEGRATION_LIST_ADDED: 'New Document Created Notification Settings Added',
  DOC_NOTIFICATION_INTEGRATION_LIST_REMOVED: 'New Document Created Notification Settings Removed',
  SIGNOUT_CLICKED: 'Click "Signout"',
  DRAFTS_DELETED: 'Delete drafts on signout',
  DELETE_NOT_SUPPORTED_POPUP: 'Shown Delete Not Supported Popup',
  SIGNUP_CLICK: 'Clicked "Sign Up"',
  LOGIN_CLICK: 'Clicked "Log In"',
  GOOGLE_SIGNUP_CLICK: 'Clicked "Sign up with Google"',
  GOOGLE_LOGIN_CLICK: 'Clicked "Log In with Google"',
  SSO_SIGNUP_CLICK: 'Clicked "Sign up with SSO"',
  SSO_LOGIN_CLICK: 'Clicked "Log In with SSO"',
  SSO_BUTTON_CLICK: 'Clicked "Continue with SSO"',
  SIGNUP_FORM_ERROR: 'Form Error',
  PLAYLIST_SAVED: 'Playlist Saved in GitHub',
  IMPORTED_TO_DOC: 'Imported File Added to Document',
  TEMPLATE_ADDED: 'Template Added to Document',
  UPGRADED_WORKSPACE: 'Upgraded Workspace',
  SUCCESSFUL_TRIAL: 'Trial Started',
  EXPIRED_TRIAL: 'Trial Expired',
  CANCELED_SUBSCRIPTION: 'Subscription Canceled',
  FAILED_UPGRADE: 'Billing Plan Failed to Upgrade',
  VIEW_UPDATE_TO_SWMD_BANNER: 'View Update to SWMD Banner',
  WORKSPACE_VISIT: 'User was identified at the following workspace',
  INSTALL_GITHUB_APP: 'Clicked "Install GitHub App"',
  GITHUB_APP_AUTOSYNC_TOGGLE: 'Automatic Auto-sync Approval Configuration',
  GITHUB_APP_COMMENTS_TOGGLE: 'Clicked "Customize PR Comments" Option',
  GITHUB_APP_VERIFY_MODIFIED_TOGGLE: 'Clicked "Configure Swimm Verify Check"',
  GITHUB_APP_DISABLE_DELETE_COMMENTS_TOGGLE: "Toggled 'Configure Swimm Disable Comment Deletion'",
  GITHUB_APP_ENABLE_CUSTOM_PR_COMMENTS: 'Toggled "Custom Auto-sync GitHub App commit messages"',
  TABLE_ADDED: 'Table Added to Document',
  GIPHY_ADDED: 'Giphy Added to Document',
  IMAGE_ADDED: 'Added Image To Doc',
  ONBOARDING_DISMISSED: 'Onboarding Card - Dismissed',
  ONBOARDING_STARTED: 'Onboarding Card - Started',
  ONBOARDING_COMPLETED: 'Onboarding Card - Completed',
  REQUEST_A_MEETING_WITH_SALES_OR_CS: 'Request A Meeting With Sales/CS',
  REJECT_A_MEETING_WITH_SALES_OR_CS: 'Reject A Meeting With Sales/CS',
  CONFIGURE_AUTOMATIC_AUTOSYNC_CLICKED: 'Click "Configure Automatic Auto-sync Approval"',
  SGD_PROMOTION_LEARN_MORE: 'Promotion Box - Learn More',
  ONBOARDING_LEARN_MORE: 'Onboarding Card - Learned More',
  AUTOSYNC_TRIGGERED: 'Auto-sync Triggered',
  SMART_PATH_ADDED: 'Smart Path Added to Document',
  MARKDOWN_ADDED: 'Markdown Added to Document',
  SWIMM_LINK_ADDED: 'Swimm Link Added to Document',
  MENTION_ADDED: 'Mention Added to Document',
  DISCARD_DOC_DRAFT: 'Document Draft Discarded',
  DISCARD_PLAYLIST_DRAFT: 'Playlist Draft Discarded',
  EDITED_PLACEHOLDER: 'Edited Placeholder',
  SHOWN_GEN_AI_DISABLED_POPUP: 'Shown Generative AI Disabled Popup',
  GENERATED_IMPROVE_WITH_AI_RESULT: 'Generated Improve with AI Result',
  CLICKED_IMPROVE_WITH_AI: 'Clicked "Improve with AI"',
  SENT_IMPROVE_WITH_AI_REQUEST: 'Sent improve with AI request',
  ABORTED_GEN_AI_CONTENT_REQUEST: 'Aborted "Generative AI" Content Request',
  ADDED_GEN_AI_CONTENT: 'Added "Generative AI" Content',
  CLICKED_GITHUB_APP_YELLOW_SHIELD: 'Clicked "GitHub App Yellow Shield"',
  CLICKED_UPGRADE: 'Clicked "Upgrade"',
  CLICKED_REPO_MENU_OPTION: 'Clicked Repo Settings Menu Option',
  CLICKED_CONNECT_A_REPO: 'Clicked "Connect a Repo"',
  CLICKED_DOCUMENTATION_HUB_MODAL: 'Clicked Documentation Hub modal',
  CLOSED_DOCUMENTATION_HUB_MODAL: 'Closed Documentation Hub modal',
  SAVED_DOCUMENTATION_HUB_LINK: 'Saved Documentation Hub Link',
  ClICKED_DOCUMENTATION_HUB_LINK: 'Clicked Documentation Hub Link',
  CLICKED_SECURITY_OVERVIEW: 'Clicked "Security Overview"',
  QUICK_TOUR_CLOSED: '"Welcome to Swimm" popup closed',
  CLICKED_EXPAND_EDITOR_BASICS: 'Clicked Expand',
  CLICKED_CLOSE_EDITOR_BASICS: 'Clicked Close',
  REPO_PAGE_SORT_CHANGE: 'Clicked "Sort"',
  HELP_CENTER_OPENED: 'Clicked "Help Center"',
  SUGGESTION_CARD_CLICKED: 'Clicked Suggestion Card',
  HELP_CENTER_OPTION_CLICKED: 'Clicked a "Help Center" Resource',
  CLICKED_SNIPPET_ANCHOR_LINK: 'Clicked Snippet Anchor Link',
  CLICKED_CREATE_DOC: 'Clicked "Create a Doc"',
  CLICKED_CREATE_PLAYLIST: 'Clicked "Create a Playlist"',
  CLICKED_TRY_DIFFERENT_EMAIL: 'Clicked "Try a different email"',
  CLICKED_AUTHORIZE_GITHUB: 'Clicked "Authorize GitHub"',
  CLICKED_REMOVE_ASSIGNMENT: 'Clicked Remove Assignment',
  CLICKED_ASSIGN: 'Clicked "Assign"',
  CLICKED_GENERATEAI: 'Clicked Snippet Generate Icon',
  DOCUMENT_ASSIGNED_TO_TEAMMATE: 'Document Assigned to Teammate',
  CLICKED_MARK_AS_READ: 'Clicked "Mark as Read/Unread"',
  SEND_THANKS_CLICKED: 'Clicked "Send Thanks"',
  THANKS_SENT: 'Thanks Sent',
  CLICKED_WATCH: 'Clicked "Watch"',
  CLICKED_UNWATCH: 'Clicked "Unwatch"',
  CLICKED_EDIT_DOC: 'Clicked "Edit Doc"',
  CLICKED_JOIN_WORKSPACE: 'Clicked "Join"',
  CLICKED_REQUEST_TO_JOIN: 'Clicked "Request to Join"',
  CLICKED_CREATE_NEW: 'Clicked "Create New"',
  CLICKED_CONFIGURED_SETTINGS: 'Clicked "Configure settings"',
  SLACK_INSTALL_EVENT: 'Clicked "Install Slack App"',
  SLACK_APP_CONNECT: 'Clicked "Connect"',
  SLACK_CHANNEL_SELECTED: 'Slack Channel Selected',
  SLACK_NOTIFICATION_OPTION_CLICKED: 'Clicked Slack Notification Option',
  CLICKED_REQUEST_DOC: 'Clicked "Request a Doc"',
  DOC_REQUEST_SAVED: 'Doc Request Saved',
  VIEW_INTEGRATIONS_TAB: 'View Integrations Tab',
  VIEW_PROFILE_TAB: 'View Profile Tab',
  VIEW_NOTIFICATIONS_TAB: 'View Notifications Tab',
  VIEW_GITHUB_APP_TAB: 'View Github App Tab',
  VIEW_SLACK_TAB: 'View Slack Tab',
  VIEW_STORAGE_TAB: 'View Storage Tab',
  VIEW_GENERATIVE_AI_TAB: 'View Generative AI Tab',
  VIEW_ASK_SWIMM_SETTINGS_TAB: 'View Ask Swimm Settings Tab',
  VIEW_IMAGE_STORAGE_SETTINGS_TAB: 'View Image Storage Settings Tab',
  VIEW_PREFERENCES_TAB: 'View Preferences Tab',
  VIEW_OAUTH_SETUP_TAB: 'View OAuth Setup Tab',
  VIEW_GENERAL_SETTINGS_TAB: 'View General Settings Tab',
  VIEW_API_KEYS_TAB: 'View API Keys Tab',
  VIEW_USERS_TAB: 'View Users Tab',
  VIEW_BILLING_TAB: 'View Billing Tab',
  VIEW_WORKSPACE_ADVANCED_TAB: 'View Workspace Advanced Tab',
  VIEW_AI_AND_FEATURE_ACCESS_TAB: 'View AI and feature access Tab',
  VISUAL_STUDIO_CODE_INSTALL: 'Clicked "Installed"',
  CLICKED_IN_APP_NOTIFICATION: 'Clicked In-App Notification',
  TUNNEL_ONBOARDING_CLICKED_SKIP_TO_WORKSPACE: 'Tunnel Onboarding - Skipped to workspace ',
  CLICKED_SAVE_DOC_BUTTON: 'Clicked Save Document',
  TUNNEL_ONBOARDING_STEP_REPO_READ_MORE: 'Tunnel Onboarding - Clicked "Read more"',
  TUNNEL_ONBOARDING_STEP_INVITE_ADD: 'Tunnel Onboarding - Clicked "Add another"',
  TUNNEL_ONBOARDING_STEP_INVITE_TEAMMATES: 'Tunnel Onboarding - Invited teammates',
  TUNNEL_ONBOARDING_STEP_INVITE_UPGRADE: 'Tunnel Onboarding - Clicked "Upgrade to Pro"',
  TUNNEL_ONBOARDING_STEP_USE_DUMMY_REPO: 'Tunnel Onboarding - Clicked "Use dummy repo"',
  TUNNEL_ONBOARDING_STEP_VIDEO_CLICKED: 'Onboarding Step - Video Clicked',
  CLICKED_LIST_VIEW: 'Clicked "List view"',
  CLICKED_CARD_VIEW: 'Clicked "Cards view"',
  CLICKED_ON_REPO_TOGGLE_BUTTON: 'Clicked on repo toggle button',
  CLICKED_DOC_TOC_ITEM: 'Clicked Doc TOC Item',
  EXPENDED_GET_STARTED_CHECKLIST: 'Expended Get Started checklist',
  COLLAPSED_GET_STARTED_CHECKLIST: 'Collapsed Get Started checklist',
  GET_STARTED_STEP_COMPLETED: 'Get Started step completed',
  GET_STARTED_STEP_CLICKED: 'Get Started step clicked',
  DOC_SIDEBAR_TOGGLED: 'Doc Sidebar Toggled',
  DOC_SIDEBAR_TAB_SWITCHED: 'Doc Sidebar Tab Switched',
  INVITE_REQUEST_APPROVED: 'Invite Request Approved',
  INVITE_REQUEST_DENIED: 'Invite Request Denied',
  VIEW_HOTSPOT: 'View hotspot',
  OPENED_TOOLTIP: 'Opened tooltip',
  CLOSED_TOOLTIP: 'Closed tooltip',
  CLICKED_ADD_TAG: 'Clicked "Add Tag"',
  TAG_ADDED_TO_DOC: 'Tag Added To Doc',
  TAG_LIMIT_REACHED_PAYWALL: 'Tags Limit Reached Paywall',
  TAG_REMOVED_FROM_DOC: 'Tag Removed From Doc',
  TAG_DELETED_FROM_WORKSPACE: 'Tag Deleted From Workspace',
  TAG_RENAMED: 'Tag Renamed',
  CLICKED_RESET_ALL_FILTERS: 'Clicked "Clear All"',
  SELECTED_FILTER: 'Filter Selected',
  DISMISSED_ONBOARDING_CHECKLIST: 'Dismissed onboarding checklist',
  CLICKED_NEW: 'Clicked New',
  VIEW_CREATION_HUB: 'View "Creation Hub"',
  CLICK_CREATION_HUB_OPTION: 'Clicked "Creation Hub"s Option',
  SELECTED_CREATION_HUB_OPTION: 'Selected "Creation Hub"s Option',
  CLICK_CREATION_HUB_TEMPLATE: 'Clicked "Creation Hub"s Template',
  CLOSED_CREATION_HUB: 'Closed "Creation Hub"',
  VIEW_SGD_NOT_SUPPORTED_POP_UP: 'View "SGD not supported on side-branches" pop-up',
  CLICKED_APPROVE_INVITE_REQUEST: 'Clicked "Approve Invite Request"',
  CLICKED_DENY_INVITE_REQUEST: 'Clicked "Deny Invite Request"',
  COVERAGE_GOAL_MODAL_OPENED: 'View Edit Coverage Goal',
  COVERAGE_GOAL_SET: 'Set Coverage Goal',
  CLICKED_COPY_LINK: 'Clicked "Copy Link"',
  OPEN_SHARE_MODAL: 'Opened Share Modal',
  HOVERED_SHARE_TOGGLE: 'Hovered Share Toggle',
  CHANGED_DOC_SHARED_STATE: 'Changed Document Shared State',
  SHOWN_SHARE_IN_EDIT_MODE_POPUP: 'Shown Share in Edit Mode Popup',
  SWIMMPORT_INITIAL_SUGGESTIONS: 'Loaded Swimmport Suggestions',
  SWIMMPORT_SUGGESTION_APPLIED: 'Accepted Swimmport Suggestion',
  SWIMMPORT_SUGGESTION_SHOWN: 'Hovered Swimmport Suggestion',
  SWIMMPORT_SUGGESTION_DISMISSED: 'Dismissed Swimmport Suggestion',
  MODIFIED_SHARING_OPTIONS: 'Modified Sharing Settings',
  CLICKED_CREATE_CLOUD_DOC: 'Clicked "Create a Cloud Doc"',
  CLOUD_DOC_SAVED: 'Cloud Doc Saved',
  CLOUD_DOC_CREATED: 'Cloud Doc Created',
  SUBMITTED_SWIMM_FEEDBACK_FORM: 'Submitted Swimm Feedback Form',
  AUTOSYNC_PAYWALL_RIBBON_DOC_VIEW: 'Autosync Quota Paywall Ribbon Displayed',
  AUTOSYNC_WARNING_RIBBON_DOC_VIEW: 'Autosync Quota Warning Ribbon Displayed',
  OPENED_BATCH_COMMIT_POPUP: 'Opened Batch Commit Popup',
  EXECUTED_BATCH_COMMIT: 'Executed Batch Commit',
  USER_CLICKED_GENERATE: 'Clicked "Generate"',
  CREATE_RULE: 'Clicked Create Rule',
  EDIT_RULE: 'Clicked on Rule Card',
  SAVE_RULE_FORM: 'Clicked Save Rule form',
  DELETE_RULE_FORM: 'Clicked Delete Rule form',
  COMMIT_RULE: 'Clicked Save Rule on commit dialog',
  INSTALL_SLACK_APP_CLICKED: 'Clicked install slack app',
  IDE_LOGIN_CLICKED_CONTINUE: 'Clicked "Continue" IDE Login',
  IDE_LOGIN_CLICKED_DO_NOT_AUTHORIZED: 'Clicked "Do not authorize" IDE Login',
  SEARCHED_FOR_KEYWORD: 'Searched for Keyword',
  VIEWED_GLOBAL_SEARCH_POPUP: 'Viewed Global Search Popup',
  CLICKED_RECENTLY_VIEWED_ITEM: 'Clicked Recently Viewed Item',
  CLICKED_NEEDS_REVIEW_ITEM: 'Clicked NeedsReview Item',
  FIXED_NEEDS_REVIEW_ITEM: 'Fixed NeedsReview Item',
  CLICKED_SMART_TOKEN: 'Clicked Smart Token',
  CONFIGURED_AUTO_JOIN_WORKSPACE: 'Configured auto join workspace setting',
  CLICKED_CHANGE_GIT_PLATFORM: 'Clicked "Change Git Hosting Platform"',
  PLAYLIST_STEP_REMOVED: 'Playlist - Step Removed',
  PLAYLIST_VIEW_OPTIONS_DROPDOWN: 'Playlist - View Options Dropdown',
  PLAYLIST_OPTION_SELECTED: 'Playlist - Option Selected',
  PLAYLIST_EXISTING_DOCS_AND_PLAYLISTS_ADDED: 'Playlist - Existing Docs & Playlists Added',
  VISITED_DOCUMENT_NOT_IN_BRANCH: 'Visited Document Not In Branch ',
  CONTENT_SUGGESTIONS_TOPIC_SELECT_SHOWN: 'Shown Document Type Suggestions',
  CONTENT_SUGGESTIONS_TOPIC_SELECTED: 'Accepted Document Type Suggestion',
  CONTENT_SUGGESTIONS_SUBJECT_SELECT_SHOWN: 'Shown Title Suggestions',
  CONTENT_SUGGESTIONS_SUBJECT_PICKED: 'Accepted Title Suggestions',
  CONTENT_SUGGESTIONS_CONTENT_SUGGESTION_HOVERED: 'Hovered Content Item Suggestion',
  CONTENT_SUGGESTIONS_CONTENT_SUGGESTION_ADDED_TO_DOC: 'Added Content Item Suggestion',
  CONTENT_SUGGESTIONS_CONTENT_INTRO_ADDED: 'Added Content Intro',
  CONTENT_SUGGESTIONS_CONTENT_INTRO_DISMISSED: 'Dismissed Content Intro',
  AUTOMATION_CLICKED_ON_HEADER: 'Clicked Automation On Header',
  AUTOMATION_STARTED_NEW_DOC_RULE: 'Started New Doc Rule',
  AUTOMATION_ADDED_DOC_RULE: 'Added Doc Rule',
  OPENED_BRANCH_SELECTOR: 'Opened Branch Selector',
  SWITCHED_BRANCH: 'Switched Branch',
  CANCELED_BRANCH_SWITCH: 'Canceled Branch Switch',
  CLICKED_DISCARD_ALL_DRAFTS: 'Clicked Discard All Drafts',
  CLICKED_BATCH_COMMIT_FIX_ACTION: 'Clicked Batch Commit Fix Action',
  UNDO_DISCARD_DRAFT: 'Undo Discard Draft',
  // Only for Intellij because we want to measure intent until we support doc-hooks in intellij
  AUTOMATION_CLICKED_INTELLIJ_LINK: 'Clicked Intellij link under automation',
  CLICKED_MERMAID_LIVE_EDITOR_LINK: 'Clicked Mermaid.Live Website',
  SELECTED_MERMAID_SAMPLE: 'Selected Mermaid Sample',
  VIEW_SIGNUP: 'View Sign Up',
  VIEW_SSO_SIGNUP: 'View SSO Sign Up',
  VIEW_SSO_LOGIN: 'View SSO Login',
  REPO_NEEDS_REVIEW: 'Repo Needs Review',
  CONTENT_ERROR: 'Content Error',
  CLICKED_REPO_PAGE_ITEM: 'Clicked Repo Page Item',
  CLICKED_REPO_PAGE_ITEM_ELLIPSIS: 'Opened Repo Page Item Ellipsis Menu',
  SELECTED_REPO_PAGE_ITEM_ELLIPSIS: 'Selected Repo Page Item Ellipsis Menu Action',
  CLICKED_VIEW_ALL_DOCS: 'Clicked "View all docs"',
  CLICKED_CONTACT_SALES: 'Clicked "Contact sales"',
  ONBOARDING_SKIPPED: 'Onboarding - Skipped',
  ONBOARDING_STEP_VIEWED: (step: number | string) => `Onboarding - Viewed Step ${step}`,
  ONBOARDING_STEP_INTERACTION: (step: number | string) => `Onboarding - Step ${step} interaction`,
  TOGGLE_REPO_DOCUMENT_SHARING: 'Toggled Repo Document Sharing',
  TOGGLE_REPO_GENERATIVE_AI: 'Toggled Repo Generative AI Enabled',
  TOGGLE_REPO_GENERATIVE_AI_ANALYTICS: 'Toggled Generative AI Analytics',
  TOGGLE_REPO_ASK_SWIMM: 'Toggled Repo Ask Swimm Enabled',
  HOVERED_DISABLED_DOCUMENT_SHARING_TOGGLE: 'Hovered Disabled Document Sharing Toggle',
  HOVERED_DISABLED_GENERATIVE_AI_TOGGLE: 'Hovered Disabled Generative AI Toggle',
  OPENED_PAYWALL_MODAL: 'Opened Paywall Modal',
  CLOSED_PAYWALL_MODAL: 'Closed Paywall Modal',
  CLICKED_START_TRIAL_PAYWALL_MODAL: 'Clicked "Start Trial" in Paywall Modal',
  CLICKED_UPGRADE_NOW_LINK: 'Clicked "Upgrade Now" Link',
  CLICKED_START_FREE_TRIAL_LINK: 'Clicked "Start Free Trial" Link',
  CLICKED_EDIT_DOC_IN_SHARED_DOC: 'Clicked "Edit doc" in Shared doc',
  CLICKED_EDIT_DOC_AUTHORIZE_IN_SHARED_DOC: 'Clicked "Authorize GitHub" in Shared Doc Edit Popup',
  HOVERED_SHARED_DOC_CODE_ELEMENT: 'Hovered Shared Doc Code Element',
  SHOWN_SHARING_PLAYLIST_COMING_SOON: 'Shown Sharing Playlist Coming Soon',
  SHOWN_MARKETPLACE_WORKSPACE_SETUP: 'Shown Marketplace Workspace Setup Step',
  CLICKED_NEW_BRANCH_IN_BATCH_COMMIT_BRANCH_SELECTOR: 'Clicked "New branch" in Batch Commit Branch Selector',
  DETECTED_INVALID_GITHUB_TOKEN: 'Detected Revoked/Invalid GitHub Token ',
  SHOWN_REAUTHORIZE_POPUP: 'Shown Reauthorize Popup',
  CLICKED_BACK_HOME: 'Clicked "Back to home"',
  OPENED_CREATE_ELLIPSIS_MENU: 'Opened "Create" Ellipsis Menu',
  SELECTED_CREATE_ELLIPSIS_MENU_OPTION: 'Selected "Create" Ellipsis Menu Option',
  GIT_HOSTING_NOT_SUPPORTED: 'Git hosting is not supported',
  GIT_HOSTING_SELECTION: 'Selected Provider For Account Setup',
  NPS_SHOW: 'Shown NPS Survey',
  NPS_DISMISS: 'Dismissed NPS Survey',
  NPS_ASK_LATER: 'Clicked "Ask me later" in NPS Survey',
  NPS_SELECT_SCORE_OPTION: 'Selected NPS Score Option',
  NPS_DESELECT_SCORE_OPTION: 'Deselected NPS Score Option',
  NPS_SUBMIT_SCORE: 'Submitted NPS Score',
  NPS_ENTER_EXPLANATION: 'Entered NPS Score Explanation',
  NPS_SUBMIT_EXPLANATION: 'Submitted NPS Explanation',
  CLICKED_NEW_FOLDER: 'Clicked "New Folder"',
  RENAMED_FOLDER: 'Renamed Folder',
  SHOWN_FOLDER_NAME_CONFLICT: 'Shown Folder Name Conflict',
  CANCELED_FOLDER_CREATION: 'Canceled Folder Creation',
  CREATED_NEW_FOLDER: 'Created New Folder',
  IDE_CLICKED_AUTO_SYNC: 'Clicked Auto-sync (IDE)',
  SHOWN_SELECT_FOLDER_MODAL: 'Shown Select Folder Modal',
  SELECTED_FOLDER_IN_SELECT_FOLDER_MODAL: 'Selected Folder in Select Folder Modal',
  CLICKED_CANCEL_IN_SELECT_FOLDER_MODAL: 'Clicked "Cancel" in Select Folder Modal ',
  CLICKED_CREATE_FOLDER_IN_SELECT_FOLDER_MODAL: 'Clicked "Create Folder" in Select Folder Modal',
  MOVED_ITEMS_TO_FOLDER: 'Moved Items to Folder',
  MOVE_FOLDERS_HAS_NAME_CONFLICTS: 'Moved Items to Folder Aborted Due To Name Conflict',
  CLICKED_FOLDER_IN_SELECT_FOLDER_MODAL: 'Created Folder in Select Folder Modal',
  CLICKED_RESOURCE_FOLDER_SELECTION: 'Clicked Doc/Playlist Folder Selection',
  OPENED_FOLDER_ADD_MENU_ITEM: "Opened Folder '+' Button Menu",
  SELECTED_FOLDER_ADD_MENU_ITEM: "Selected Folder '+' Button Menu Item",
  SELECTED_REPO_PAGE_ITEM: 'Selected Repo Page Item',
  DESELECTED_REPO_PAGE_ITEM: 'Deselected Repo Page Item',
  CLICKED_SELECT_ALL_CHECKBOX_REPO_PAGE: 'Clicked "Select All" Checkbox on Repo Page',
  PICKED_BATCH_ACTION: 'Picked Batch Action',
  IDE_SEARCH_TAB_VIEWED: 'Search tab viewed (IDE)',
  SIDEBAR_EXPANDED: 'Expanded Doc Sidebar',
  SIDEBAR_COLLAPSED: 'Collapsed Doc Sidebar',
  SIDEBAR_HOVERED: 'Hovered Doc Sidebar',
  SIDEBAR_RESIZED: 'Resized Doc Sidebar',
  CLICKED_DELETE_ON_FOLDER: "Clicked 'Delete' on Folder",
  DELETED_FOLDER_RECURSIVELY: 'Deleted Folder Recursively',
  DROPPED_ITEM_TO_REORDER: 'Dropped Item to Reorder',
  OPENED_SIDEBAR_ELLIPSIS_MENU: 'Opened Sidebar Ellipsis Menu',
  SELECTED_SIDEBAR_ELLIPSIS_MENU_OPTION: 'Selected Sidebar Ellipsis Menu Option',
  OPENED_SIDEBAR_ADD_MENU: 'Opened Sidebar Add Menu',
  SELECTED_SIDEBAR_ADD_MENU_OPTION: 'Selected Sidebar Add Menu Option',
  STARTED_DRAGGING_REPO_ITEMS: 'Started Dragging Repo Page Items',
  DROPPED_ITEMS_INTO_FOLDER: 'Dropped Items Into Folder ',
  GITHUB_APP_SHOWN_MULTI_REPO_BROKEN_DOCS: 'Shown Multi Repo Broken Docs Comment',
  GITHUB_APP_SEND_OUTDATED_CROSS_REPO_NOTIFICATION: 'Sent Outdated Cross-Repo Docs Notification',
  IDE_CLICKED_TUTORIAL_PANEL: 'Clicked Tutorial Panel (IDE)',
  IDE_CLICKED_TUTORIAL_PANEL_ITEM: 'Clicked Tutorial Panel Item (IDE)',
  SEARCHED_IN_SELECT_FOLDER_MODAl: 'Searched in Select Folder Modal',
  HOVERED_OUTDATED_DOT: 'Hovered Outdated Dot',
  CLICKED_OUTDATED_ICON_ON_REPO: 'Clicked "Outdated" Icon on Repo',
  IDE_RULES_TAB_VIEWED: 'Rules Tab Viewed (IDE)',
  IDE_CLICKED_CREATE_AI_RULE: 'Clicked "Create AI Rule" (IDE)',
  IDE_CLICKED_CREATE_NORMAL_RULE: 'Clicked "Create a Rule" (IDE)',
  IDE_GENERATING_AI_RULES_USECASES: 'Generating AI Rules Use Cases (IDE)',
  IDE_REGENERATING_AI_RULES_USECASES: 'Re-Generating AI Rules Use Cases (IDE)',
  IDE_DISPLAY_AI_RULES_USECASES: 'Display AI Rules Use Cases (IDE)',
  IDE_SELECTED_USECASE: 'Selected Use Case (IDE)',
  IDE_GENERATING_AI_RULES_EXAMPLES: 'Generating AI Rules Examples (IDE)',
  IDE_REGENERATING_AI_RULES_EXAMPLES: 'Re-Generating AI Rules Examples (IDE)',
  IDE_DISPLAY_AI_RULES_EXAMPLES: 'Display AI Rules Examples (IDE)',
  IDE_CLICKED_AI_RULE_EXAMPLE: 'Clicked AI Rule Example (IDE)',
  IDE_CLICKED_CHANGE_USECASE: 'Clicked AI Rule "Change Use Case" (IDE)',
  IDE_CLICKED_EDIT_RULE: 'Clicked "Edit Rule" (IDE)',
  IDE_CLICKED_DELETE_RULE: 'Clicked "Delete Rule" (IDE)',
  IDE_SELECTED_DOC_FOR_RULE: 'Selected Doc For Rule (IDE)',
  IDE_CREATED_RULE: 'Created Rule (IDE)',
  IDE_CLICKED_REGEX_SYNTAX: 'Clicked Regex Syntax (IDE)',
  IDE_CLICKED_CANCEL_RULE: 'Clicked Cancel Rule (IDE)',
  IDE_HOVERED_RULE_IN_CODE: 'Hovered Rule In Code (IDE)',
  IDE_EXCEEDED_NUMBER_OF_RULES_PAYWALL: 'Exceeded Number of Rules Paywall (IDE)',
  EXPAND_COLLAPSE_SIDEBAR_ITEM: 'Expand/Collapse Sidebar Item',
  CLICKED_ITEM_NAME: 'Clicked Item Name',
  CLOUD_SUMMARIZED_DOC_FOR_RULES: 'AI Summarized Doc For Rules (Cloud)',
  CLOUD_GENERATED_RULES_USECASES: 'AI Tried Generating Rules Usecases (Cloud)',
  CLOUD_GENERATED_RULES_EXAMPLES: 'AI Tried Generating Rules Examples (Cloud)',
  CLICKED_IDE_INSTALL_CTA: 'Clicked IDE Install CTA',
  DISMISSED_IDE_INSTALL_CTA: 'Dismissed IDE Install CTA',
  AI_SUGGESTIONS_DROPDOWN_OPENED: 'AI Suggestions Dropdown Opened',
  AI_SUGGESTIONS_DROPDOWN_HOVERED: 'AI Suggestions Dropdown Hovered',
  AI_SUGGESTIONS_DROPDOWN_CLOSED: 'AI Suggestions Dropdown Closed',
  FINISHED_GENERATING_HEADERS: 'Finished Generating Headers',
  EXPANDED_SUGGESTED_SECTION_HEADER: 'Expanded Suggested Section Header',
  ADDED_SUGGESTED_SECTION_TO_DOC: 'Added Suggested Section to Doc',
  DISMISSED_SUGGESTED_SECTION: 'Dismissed Suggested Section',
  ERROR_WHILE_GENERATING_CONTENT_SUGGESTIONS: 'Error While Generating Content Suggestions',
  CLICKED_QUICK_SEARCH_BUTTON: 'Clicked Quick Search Button',
  ENTERED_QUICK_SEARCH_TERM: 'Entered Quick Search Term',
  CLICKED_ADD_REPOS: 'Clicked add repos',
  CLICKED_CHANGE_GIT_PROVIDER_IN_ADD_REPO_MODAL: `Clicked 'Change Git hosting' link in Add Repo Modal`,
  CHANGED_GIT_PROVIDER_IN_ADD_REPO_MODAL: `Changed Git hosting in Add Repo Modal`,
  ADDED_REPO_TO_FAVORITES: 'Added Repo to Favorites',
  REMOVED_REPO_FROM_FAVORITES: 'Removed Repo from Favorites',
  CLICKED_SIDEBAR_ITEM: 'Clicked Sidebar Item',
  STARTED_DRAGGING_SIDEBAR_ITEM: 'Started Dragging Sidebar Item',
  SELECTED_REPO_IN_MULTI_REPO_LIST: 'Selected Repo In Multi Repo List',
  CLICKED_SEARCH_RESULT: 'Clicked Search Result',
  SHOWN_REMOTE_CODE_CHANGES_WARNING: 'Shown Remote Code Changes Warning',
  RERAN_AUTOSYNC_AFTER_REMOTE_CODE_CHANGES_WARNING: 'Reran Auto-sync After Remote Code Changes Warning',
  RATED_AI_RESULT: 'Rated AI Result',
  UNRATED_AI_RESULT: 'Unrated AI Result',
  DISMISSED_AI_RESULT_FEEDBACK_TEXT: 'Dismissed AI Result Feedback Text',
  SUBMITTED_AI_RESULT_FEEDBACK_TEXT: 'Submitted AI Result Feedback Text',
  DISMISSED_AI_FEEDBACK: 'Dismissed AI Feedback',
  HOVERED_AI_FEEDBACK: 'Hovered AI Feedback',
  SHOWN_NO_ACCESS_MESSAGE: 'Shown "Browser Cannot Access Swimm" Message',
  RENAMED_DOC: 'Renamed Doc',
  TOKEN_SELECTION_OPENED_ADVANCED_MODE: 'Opened Tokens Advanced Mode',
  TOKEN_SELECTION_ADVANCED_MODE_SELECTED_FILE: 'Selected File In Tokens Advanced Mode',
  TOKEN_SELECTION_ADVANCED_MODE_UNSELECTED_FILE: 'Unselected File In Tokens Advanced Mode',
  SAVE_USER_PROFILE: 'Save user profile',
  ASK_SWIMM_QA: 'Asked a question',
  ASK_ANSWER_DOC_FOUND: 'Doc Found in Ask Answer',
  ASK_USER_CREATED_DOC: 'User Created a Doc Using Ask',
  ASK_USER_ADDED_TO_DOC: 'User Added to Doc Using Ask',
  ASK_LOCATION_SELECTION_SHOWN: 'Shown Ask Swimm Location Selection',
  ASK_SWIMM_ANALYZE: 'Clicked Analyze',
  ASK_SWIMM_REANALYZE: 'Clicked Reanalyze',
  GENERATE_ANALYZE: 'Clicked Analyze in generate tab',
  ASK_SWIMM_FOLLOWUP_QUESTION_CLICKED: 'Clicked Followup Question',
  ASK_SWIMM_FOLLOWUP_QUESTION_ASKED: 'Asked Followup Question',
  ASK_SWIMM_EDITED_QUERY: 'Submitted Edited Query',
  ASK_SWIMM_EDIT_QUERY_CLICKED: 'Clicked Edit on Query',
  ASK_SWIMM_NAVIGATE_QUERY_VERSION: 'Navigated Query Versions',
  ASK_SWIMM_GENERATION_CANCELED: 'Generation Cancelled',
  ASK_SWIMM_INFERRED_QUERY_TYPE: 'Inferred Query Type',
  ASK_SWIMM_SHARED_QUESTION_WITH_TEAM: 'Shared a question with team',
  ASK_SWIMM_ADD_YOUR_ANSWER_CLICKED: 'Clicked "Add your response as a doc" in a shared question',
  ASK_SWIMM_ANALYZE_FINISHED_SUCCESSFULLY: 'Analyze Finished Successfully',
  GENERATE_ANALYZE_FINISHED_SUCCESSFULLY: 'Analyze for Generate Finished Successfully',
  CLICKED_FEEDBACK_BUTTON: 'Clicked Feedback Button',
  PICKED_REGENERATE_OPTION: 'Picked Regenerate Option',
  REGENERATE_WITH_OPTION: 'Regenerated with Option',
  RANK_REGENERATE_RESPONSE: 'Ranked Regenerated Answer',
  LOW_CONFIDENCE_SHOWN: "Shown 'Low Confidence Answer' Warning",
  LOW_CONFIDENCE_CLICKED_CONTINUE: 'Clicked "Continue" in Low Confidence Warning',
  LOW_CONFIDENCE_CLICKED_EDIT_LOCATION: 'Clicked "Edit Location" in Low Confidence Warning',
  CLICK_PRINT_MODE: 'Clicked "Print mode"',
  START_WITH_BRANCH: 'Clicked Start with Branch changes',
  START_WITH_PR: 'Clicked Start with PR changes',
  START_FROM_CODE: 'Clicked Start with from Code',
  GENERATE_DOC_MODAL_REMOVED_SNIPPPET: 'Removed Snippet in Snippets2Doc Modal',
  CLICKED_GENEARATE_WITH_AI: 'Clicked "Generate with AI" in Snippets2Doc Modal',
  CLICKED_CREATE_WITHOUT_AI: 'Clicked "Create without AI" in Snippets2Doc Modal',
  IDE_DOC_GENERATE_CLICKED_STOP: 'Clicked "Stop generating" in doc generation',
  IDE_DOC_GENERATE_FAILED: 'Failed to generate or stream doc',
  GENERATE_DOC_MODAL_TOO_MANY_SNIPPETS: 'Too many snippets in Snippets2Doc Modal',
  CLICKED_AI_WORKSPACE_SETTINGS_SHORTCUT_BANNER: 'Clicked AI Workspace Settings Shortcut Banner',
  EXPANDED_GENERATE_DRAFT_MODAL: 'Expanded Generate Draft with AI ',
  CLICKED_ADD_SNIPPET_IN_GENERATE_DRAFT_MODAL: 'Clicked Add Snippet Button in Generate Draft with AI',
  COLLAPSED_GENERATE_DRAFT_MODAL: 'Collapsed Generate Draft with AI',
  CLICKED_GENERATE_DRAFT: 'Clicked Generate in Generate Draft with AI',
  GENERATION_WITH_AI_COMPLETED: 'Finished Generating in Generate Draft with AI',
  ADD_SNIPPET_FROM_GENERATE_DRAFT_MODAL: 'Clicked Add Snippet Button in Generate Draft with AI',
  ADD_FILE_FROM_GENERATE_DRAFT_MODAL: 'Clicked Add File Button in Generate Draft with AI',
  ADD_CHANGES_FROM_GENERATE_DRAFT_MODAL: 'Clicked Add from changes Button in Generate Draft with AI',
  DISMISSED_GENERATE_DRAFT_MODAL: 'Dismissed Generate Draft with AI',
  REVERT_GENERATED_DRAFT: 'Reverted Generate Draft with AI',
  CLICKED_REVERT_GENERATED_DRAFT: 'Clicked Revert in Generate Draft with AI',
  CLICKED_SMART_TOKEN_IN_BUBBLE_MENU: 'Clicked "Smart Token" option in Bubble Menu',
  COMMITTED_NEW_DOC_IN_IDE: 'Committed New Document (IDE)',
  DOC_AUTOCOMPLETION_ACCEPTED: 'Accepted Doc Autocompletion',
  DOC_AUTOCOMPLETION_SHOWN: 'Shown Doc Autocompletion',
  DOC_AUTOCOMPLETION_DELETED: 'Deleted Doc Autocompletion',
  CLICKED_TOKEN_WITH_DEFINITION_IN_SNIPPET: 'Clicked token with definition in snippet',
  HOVER_TOKEN_WITH_DEFINITION_IN_SNIPPET: 'Hovered token with definition in snippet',
  ADDED_TOKEN_DEFINITION_TO_DOCUMENT: 'Added token definition to document',
  SHOWN_COPY_PASTE_CODE_WARNING: 'Shown Copy Paste Code Warning',
  CLICKED_CONTINUE_ANYWAY_IN_COPY_PASTE_WARNING: "Clicked 'Continue anyway' in Copy Paste Warning",
  CLICKED_ADD_CODE_SNIPPET_INSTEAD_IN_COPY_PASTE_WARNING: "Clicked 'Add code snippet instead' in Copy Paste Warning",
  DOCS_GENERATED_SUCCESSFULLY: 'Docs Generated Successfully',
  OPENED_MERMAID_AI_DIALOG: 'Opened Mermaid AI Dialog',
  CLICKED_GENERATE_MERMAID_AI: 'Clicked "Generate" in Mermaid AI',
  ERROR_STREAMING_MERMAID: 'Error While Streaming Mermaid',
  FINISED_GENERATING_MERMAID: 'Finished Generating Mermaid Diagram',
  DOCS_WITH_MOVED_TOKENS_ALERTED: 'Docs with Moved Tokens Alerted',
  DOC_WITH_MOVED_TOKENS_ALERTED: 'Doc with Moved Tokens Alerted',
  DOC_WITH_MOVED_TOKENS_EDIT_CLICKED: 'Clicked Edit on Doc with Moved Tokens',
  DOC_WITH_MOVED_TOKENS_APPLY_CLICKED: 'Clicked Apply on Doc with Moved Tokens',
  COPILOT_MARKETPLACE_EVENT: 'Copilot Marketplace Event',
  COPILOT_PLUGIN_PLAN_PURCHASED: 'Copilot Plugin Plan Purchased',
  COPILOT_PLUGIN_PLAN_CANCELLED: 'Copilot Plugin Plan Cancelled',
  COPILOT_PLUGIN_INSTALLED: 'Copilot Plugin Installed',
  COPILOT_PLUGIN_UNINSTALLED: 'Copilot Plugin Uninstalled',
  COPILOT_CREATED_DOCUMENT: 'Copilot Created Document',
  ENABLED_MERMAID_PAN_ZOOM: 'Enabled Mermaid Pan Zoom',
  ADDED_FILE_AS_SNIPPET: 'Added Whole File As Snippet',
  PROCESS_DOC_CREATED: 'Created a Process doc',
};

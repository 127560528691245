import Cache from 'lru-cache';
import { ERROR_RETURN_CODE, SUCCESS_RETURN_CODE, SWM_FOLDER_IN_REPO } from '../../config';
import { GitProviderName, PathType, ResultWithReturnCode, SwmResourceFile, SwmResourceState } from '../../types';
import { removeCRLFCharacters } from '../../utils/string-utils';
import gitProviderUtils from '../git-provider-utils';
import { ChangeRequestData, changeRequestsPendingDocsReducer } from '../git-utils-common';
import { BitbucketDcTransport } from '../transports/bitbucket-dc-transport';
import {
  BitbucketDcPullRequestCommitPath,
  BitbucketDcPullRequestHunk,
  BitbucketDcPullRequestResult,
  BitbucketDcPullRequestTypeState,
  BitbucketDcSegmentType,
} from '../transports/bitbucket-dc-transport-types';
import {
  Branch,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  GitDriverBase,
  PartialRemoteRepository,
  PendingPR,
  PrData,
  ProviderSpecificTerms,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  RepoIdOwnerName,
  githubChangeStatus,
} from './git-provider-base';

interface BitbucketRepoInformation {
  projectKey: string;
  url: string;
}

type GetFileContentFromRevisionCacheKey = `RID:${string}-BNAME:${string}-PATH:${string}-SAFE:${string}-RAW${string}`;
type GetBranchCacheKey = `RID:${string}-BNAME${string}`;
type GetRepoTreeCacheKey = `RID:${string}-BSHA:${string}-PATH:${string}-RECURSIVE:${string}`;

const CACHE_TTL_MS = 4_000;

const bitBucketStatus2GitHubStatus: Record<BitbucketDcPullRequestTypeState, githubChangeStatus> = {
  DELETE: githubChangeStatus.Deleted,
  MOVE: githubChangeStatus.Renamed,
  ADD: githubChangeStatus.Added,
  MODIFY: githubChangeStatus.Modified,
  COPY: githubChangeStatus.Renamed,
  UNKNOWN: githubChangeStatus.Modified,
};

const countChanges = (hunks: BitbucketDcPullRequestHunk[], segmentType: BitbucketDcSegmentType): number =>
  hunks.reduce(
    (acc, hunk) =>
      acc + hunk.segments.reduce((acc, segment) => acc + (segment.type === segmentType ? segment.lines.length : 0), 0),
    0
  );

const changeType2GitHubStatus = (
  source: BitbucketDcPullRequestCommitPath | null,
  destination: BitbucketDcPullRequestCommitPath | null
): string => {
  if (source === null) {
    return 'ADDED';
  } else if (destination === null) {
    return 'DELETED';
  }

  return 'MODIFIED';
};

export class BitbucketDcDriver extends ProviderSpecificTerms implements GitDriverBase {
  transport: BitbucketDcTransport;
  repoInformationMap: Map<string, BitbucketRepoInformation> = new Map();

  public constructor({ baseUrl = '', authToken = '' }: DriverOptions = {}) {
    super();
    this.transport = new BitbucketDcTransport({ baseUrl, authToken });
  }

  private invalidateCaches({
    repoId,
    revision,
    filePath,
    raw = false,
  }: {
    repoId?: string;
    revision?: string;
    filePath?: string;
    raw?: boolean;
  }): void {
    if (!revision || !repoId) {
      this.getBranchCache.clear();
      this.getFileContentFromRevisionCache.clear();
      return;
    }
    const branchCacheKey: GetBranchCacheKey = `RID:${repoId}-BNAME:${revision}`;
    this.getBranchCache.delete(branchCacheKey);
    if (filePath) {
      // We want to delete both the "safe" and "unsafe" access to this key.
      const fetchFileKeySafe: GetFileContentFromRevisionCacheKey = `RID:${repoId}-BNAME:${revision}-PATH:${filePath}-SAFE:${true}-RAW:${raw}`;
      const fetchFileKey: GetFileContentFromRevisionCacheKey = `RID:${repoId}-BNAME:${revision}-PATH:${filePath}-SAFE:${false}-RAW:${raw}`;
      this.getFileContentFromRevisionCache.delete(fetchFileKeySafe);
      this.getFileContentFromRevisionCache.delete(fetchFileKey);
    } else {
      this.getFileContentFromRevisionCache.clear();
    }
  }

  private async getRepoInformation(
    repoId: string
  ): Promise<BitbucketRepoInformation & { branch: string; owner: string; repoName: string }> {
    const { branch, owner, repoName } = await gitProviderUtils.getRepoStateData(repoId);
    if (!this.repoInformationMap.has(repoId)) {
      const data = await this.transport.getRepoInfo(owner, repoName);
      if (!data) {
        throw new Error(`Could not find repo ${repoName}`);
      }
      this.repoInformationMap.set(repoId, {
        projectKey: data.project.key,
        url: data.links.self[0].href,
      });
    }
    const repoInfo = this.repoInformationMap.get(repoId);
    return { ...repoInfo, branch, owner, repoName };
  }

  deleteTokenFromMemory(): void {
    this.transport.deleteTokenFromMemory();
  }

  async getUserData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ login: string; id: number | string }> {
    const userDetails = await this.transport.getUserData();
    return { login: userDetails.slug, id: userDetails.id };
  }

  async getOrganizationData(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }> {
    const project = await this.transport.getProjects();
    let numOfDevelopers = 0;
    if (project?.values?.length !== 1) {
      return { name: '', numOfDevelopers: 0 };
    }
    let start = 0;
    let isLastPage = false;
    do {
      const response = await this.transport.getUsers({ start });
      numOfDevelopers += response.values.length;
      start += response.values.length;
      isLastPage = response.isLastPage;
    } while (!isLastPage);
    return { name: project.values[0].name, numOfDevelopers };
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    const branch = await this.getBranch({ repoId, branchName });
    return !!branch;
  }

  async getChangeRequestName(_props: { repoId: string }): Promise<string> {
    return this.getTerminology(GitProviderName.BitbucketDc).pullRequest;
  }

  private getBranchCache = new Cache<GetBranchCacheKey, ReturnType<typeof this.getBranch>>({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getBranch({ repoId, branchName }: { repoId: string; branchName: string }): Promise<Branch> {
    const cacheKey: GetBranchCacheKey = `RID:${repoId}-BNAME:${branchName}`;
    const cacheHit = this.getBranchCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getBranchLogic = async () => {
      const { projectKey, repoName } = await this.getRepoInformation(repoId);
      const branch = await this.transport.getBranches(projectKey, repoName, { filterText: branchName, details: true });
      const branchData = branch.values.find((b) => b.displayId === branchName);
      return {
        id: branchData.displayId,
        name: branchData.displayId,
        sha: branchData.latestCommit,
        protected: branchData.isDefault,
        lastUpdated: new Date(
          branchData.metadata[
            'com.atlassian.bitbucket.server.bitbucket-branch:latest-commit-metadata'
          ].committerTimestamp
        ).toISOString(),
      };
    };
    const operation = getBranchLogic();
    this.getBranchCache.set(cacheKey, operation);
    return operation;
  }

  private async getBranchesByPullRequestStatuses(
    projectKey: string,
    repoName: string,
    start: number,
    prStates: PullRequestState
  ): Promise<{ branches: Branch[]; isLastPage: boolean }> {
    const result = await this.transport.findPullRequests(projectKey, repoName, {
      start,
      state: prStates,
      withProperties: false,
    });
    const branches = result.values.map((pr) => ({
      id: pr.fromRef.displayId,
      name: pr.fromRef.displayId,
      sha: pr.fromRef.latestCommit,
      protected: false,
      lastUpdated: new Date(pr.updatedDate).toISOString(),
    }));
    return { branches, isLastPage: result.isLastPage };
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let start = 0;
    let isLastPage = false;
    let currentPRStatusIndex = 0;
    let currentPRStatus = prStates?.[currentPRStatusIndex];

    do {
      let branches: Branch[] = [];
      if (!prStates?.length) {
        const request = await this.transport.getBranches(projectKey, repoName, {
          start,
          details: true,
          orderBy: 'ALPHABETICAL',
          limit: 100,
        });
        branches.push(
          ...request.values.map((branch) => ({
            id: branch.displayId,
            name: branch.displayId,
            sha: branch.latestCommit,
            protected: false,
            lastUpdated: new Date(
              branch.metadata[
                'com.atlassian.bitbucket.server.bitbucket-branch:latest-commit-metadata'
              ].committerTimestamp
            ).toISOString(),
          }))
        );
        isLastPage = request.isLastPage;
        start += request.values.length;
      } else {
        if (currentPRStatus) {
          ({ branches, isLastPage } = await this.getBranchesByPullRequestStatuses(
            projectKey,
            repoName,
            start,
            currentPRStatus
          ));
          if (isLastPage) {
            currentPRStatusIndex++;
            currentPRStatus = prStates?.[currentPRStatusIndex];
            start = 0;
            isLastPage = !currentPRStatus;
          } else {
            start += branches.length;
          }
        }
      }
      yield { hasNextPage: !isLastPage, branches };
    } while (!isLastPage);
  }

  private async mapPrData(pr: BitbucketDcPullRequestResult): Promise<PrData> {
    let start = 0;
    let isLastPage = false;
    const files: PrData['files'] = [];
    let filesWithAdditions = 0;
    do {
      const response = await this.transport.getPullRequestChanges(
        pr.fromRef.repository.project.key,
        pr.fromRef.repository.slug,
        pr.id,
        { start }
      );
      isLastPage = response.isLastPage;
      start += response.values.length;
      files.push(
        ...response.values.reduce((acc, file) => [...acc, { path: file.path.toString, additionsInFile: 1 }], [])
      );
      filesWithAdditions += response.values.length;
    } while (!isLastPage);
    return {
      prId: pr.id,
      title: pr.title,
      state: pr.state,
      url: pr.links.self[0].href,
      createdAt: new Date(pr.createdDate).toISOString(),
      updatedAt: new Date(pr.updatedDate).toISOString(),
      description: '',
      prHeadSha: pr.fromRef.latestCommit,
      prBaseSha: pr.toRef.latestCommit,
      sourceBranchName: pr.fromRef.displayId,
      author: {
        username: pr.author.user.slug,
      },
      files,
      filesWithAdditions,
    };
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    const pr = await this.transport.getPullRequest(projectKey, repoName, prId);
    return this.mapPrData(pr);
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let start = 0;
    let isLastPage = false;
    const prs: PrData[] = [];
    do {
      const response = await this.transport.findPullRequests(projectKey, repoName, { start, state: prState });
      isLastPage = response.isLastPage;
      start += response.values.length;
      prs.push(...(await Promise.all(response.values.map((pr) => this.mapPrData(pr)))));
    } while (!isLastPage);
    return prs;
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let start = 0;
    let isLastPage = false;
    const prsData: ChangeRequestData[] = [];
    do {
      const response = await this.transport.findPullRequests(projectKey, repoName, {
        start,
        at: `refs/heads/${branchName}`,
        withProperties: false,
      });
      const result = response.values.map(async (pr) => {
        const prData: ChangeRequestData = {
          sourceBranchName: pr.fromRef.displayId,
          prId: pr.id,
          prTitle: pr.title,
          createdAt: new Date(pr.createdDate).toISOString(),
          updatedAt: new Date(pr.updatedDate).toISOString(),
          files: [],
        };
        const commitResponse = await this.transport.getPullRequestDiff(projectKey, repoName, pr.id, {});
        prData.files.push(
          ...commitResponse.diffs.map((diff) => {
            const additions = countChanges(diff.hunks, 'ADDED');
            return {
              path: diff.source?.toString ?? diff.destination?.toString,
              additions,
              additionsInFile: additions,
              deletions: countChanges(diff.hunks, 'REMOVED'),
              changeType: changeType2GitHubStatus(diff.source, diff.destination),
            };
          })
        );
        return prData;
      });
      isLastPage = response.isLastPage;
      start += response.values.length;
      prsData.push(...(await Promise.all(result)));
    } while (!isLastPage);
    return changeRequestsPendingDocsReducer(prsData);
  }

  private getFileContentFromRevisionCache = new Cache<
    GetFileContentFromRevisionCacheKey,
    ReturnType<typeof this.getFileContentFromRevision>
  >({
    ttl: CACHE_TTL_MS,
    ttlResolution: CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw = false,
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision?: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    const cacheKey: GetFileContentFromRevisionCacheKey = `RID:${repoId}-BNAME:${revision}-PATH:${filePath}-SAFE:${safe}-RAW:${raw}`;
    const cacheHit = this.getFileContentFromRevisionCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const getFileLogic = async () => {
      const { projectKey, repoName } = await this.getRepoInformation(repoId);
      const content = await this.transport.getRawFileContent(projectKey, repoName, filePath, { safe, at: revision });
      return raw ? content : removeCRLFCharacters(content);
    };
    const operation = getFileLogic();
    this.getFileContentFromRevisionCache.set(cacheKey, operation);
    return operation;
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean> {
    return !!(await this.getFileContentFromRevision({ repoId, filePath, revision, safe: true }));
  }

  private async pushSingleFileToBranch({
    filePath,
    fileContent,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    fileContent: string;
    commitMessage: string;
  }): Promise<void> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let sourceCommitId: string;
    let sourceBranch: string;
    try {
      if (await this.isFileExistsOnRevision({ repoId, filePath, revision: branch })) {
        const commitSha = await this.transport.getCommitById(projectKey, repoName, branch, { path: filePath });
        sourceCommitId = commitSha.id;
        sourceBranch = branch;
      }
    } catch (e) {
      // file does not exist on branch
    }
    await this.transport.pushFile(projectKey, repoName, filePath, {
      branch,
      content: fileContent,
      message: commitMessage,
      sourceBranch,
      sourceCommitId,
    });
    this.invalidateCaches({ repoId, revision: branch, filePath });
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    await this.transport.createBranch(projectKey, repoName, {
      name: branchName,
      startPoint: sourceSha,
    });
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
  }): Promise<CreatedPR> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    const result = await this.transport.createPullRequest(projectKey, repoName, {
      title,
      description: body,
      fromRef: {
        id: `refs/heads/${fromBranch}`,
      },
      toRef: {
        id: `refs/heads/${toBranch}`,
      },
    });
    return {
      url: result.links.self[0].href,
    };
  }

  deleteFileIfExists(_params: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    /**
     * NOTE: if we ever implement this, we need to remember to call {@link invalidateCaches}
     */
    throw new Error('Method not implemented the deleteFileIfExists.');
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    try {
      const result = await this.transport.getCommits(projectKey, repoName, {
        path: relativeFilePath,
        until: destCommit,
      });
      return result.values[0].id;
    } catch (e) {
      return '';
    }
  }

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    const content = await this.getFileContentFromRevision({ filePath: path, repoId, revision, safe: true });
    if (!content) {
      return { code: ERROR_RETURN_CODE, errorMessage: 'Not found' };
    }
    // The Request return context for a file, and if it the folder it will return the content of the folder like:
    // 100644 blob 0d73b50778293ff063efc8ab3b9ba2e74a01d9af	file.txt
    // 100644 blob aa9bca0cc98888d4ac010b45a4b06e9755b312a1	probe2.txt
    // 040000 tree 54ff94df0f273caaa5be73192e9ffe7308a6a9bc	probe_folde2
    //
    // we can check if the content is a file or a folder by checking if the content has a line that not match the regex
    if (content.split('\n').some((line) => !/^\d{6}\s(blob|tree)\s[0-9a-f]*\s.*$/.test(line) && line !== '')) {
      return { code: SUCCESS_RETURN_CODE, pathType: PathType.File };
    }
    return { code: SUCCESS_RETURN_CODE, pathType: PathType.Folder };
  }

  /**
   * Fetch repo metadata from provider
   * @param repoId the repoId, if availabe
   * @param repoName name of the repo (must if no repoId)
   * @param owner name of the organization / user who owns the repo (must if no repoId)
   * @param provider the git provider (must if no repoId)
   */
  async getRepoRemoteData({ repoId, repoName, owner }: RepoIdOrRepoData): Promise<RemoteRepository> {
    let projectName: string;
    let projectKey: string;
    if (owner && repoName) {
      projectName = repoName;
      projectKey = owner;
    } else {
      const repoData = await this.getRepoInformation(repoId);
      projectName = repoData.repoName;
      projectKey = repoData.projectKey;
    }
    const repoInfo = await this.transport.getRepoInfo(projectKey, projectName);
    const clone =
      repoInfo.links.clone.find((link) => link.name === 'http')?.href ??
      repoInfo.links.clone.find((link) => link.name === 'ssh')?.href ??
      '';
    const defaultBranch = await this.transport.getDefaultBranch(repoInfo.project.key, repoInfo.slug);
    return {
      name: repoInfo.name,
      owner: repoInfo.project.key,
      cloneUrl: clone,
      htmlUrl: repoInfo.links.self[0].href,
      defaultBranch: defaultBranch.displayId,
      isPrivate: repoInfo.public === false,
      writeAccess: repoInfo.state === 'AVAILABLE',
      fork: repoInfo.origin !== undefined,
    };
  }

  getRepoRemoteDataBatch(_params: {
    provider: GitProviderName;
    repos: RepoIdOwnerName[];
    driverOptions?: DriverOptions;
  }): Promise<Record<string, PartialRemoteRepository>> {
    throw new Error('Method not implemented the getRepoRemoteDataBatch.');
  }

  async pushMultipleFilesToBranch(_params: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    for (const swmFile of _params.files) {
      // Bitbucket DC doesn't support renames, so we'll only overwrite the content instead of using the new path.
      const filePath = swmFile.state === SwmResourceState.Renamed ? swmFile.oldPath : swmFile.path;
      await this.pushSingleFileToBranch({
        filePath,
        fileContent: swmFile['content'],
        branch: _params.branch,
        repoId: _params.repoId,
        commitMessage: _params.commitMessage,
      });
    }
    /**
     * No need to call {@link invalidateCaches} since we call it inside {@link pushSingleFileToBranch}.
     */
  }

  private replaceDiffSrcDstWithAB(diff: string): string {
    const diffCommandLineRegex = /diff --git src:\/\/(.*) dst:\/\/(.*)\n/g; // The diff command line
    const replaceWith = 'diff --git a/$1 b/$2\n';
    const fixedDiffCommand = diff.replaceAll(diffCommandLineRegex, replaceWith);
    const fixedFullDiff = fixedDiffCommand.replaceAll('--- src://', '--- a/').replaceAll('+++ dst://', '+++ b/');
    return fixedFullDiff;
  }

  // TODO: should be changed for a general `gitDiff` function
  async getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    const commitDiff = await this.transport.getCommitsDiff(projectKey, repoName, { since: base, head });
    const result = this.replaceDiffSrcDstWithAB(commitDiff);
    return result;
  }

  private async getFilesDirectoryContent({
    repoId,
    recursive,
    treeSha,
    path,
  }: {
    repoId: string;
    recursive: boolean;
    treeSha: string;
    path?: string;
  }): Promise<
    {
      path?: string;
      mode?: string;
      type?: string;
      sha?: string;
      size?: number;
      url?: string;
    }[]
  > {
    type CurrentReturnType = ReturnType<typeof this.getFilesDirectoryContent>;
    const { projectKey, repoName, url } = await this.getRepoInformation(repoId);
    const tree = await this.transport.getFilesDirectoryContent(projectKey, repoName, { at: treeSha, path });
    // will return null if path does not exists (404)
    if (!tree) {
      return [];
    }
    const q = new URLSearchParams({
      at: treeSha,
    });
    return await tree.children.values.reduce(async (acc: CurrentReturnType, file) => {
      const filePath = `${path ? `${path}/` : ''}${file.path.toString}`;
      const fileUrl = `${url}/${encodeURI(filePath)}?${q.toString()}`;
      if (file.type === 'DIRECTORY') {
        let children: Awaited<CurrentReturnType> = [];
        if (recursive) {
          children = await this.getFilesDirectoryContent({
            repoId,
            recursive,
            path: filePath,
            treeSha,
          });
        }
        return [
          ...(await acc),
          {
            path: filePath,
            type: 'tree',
            url: fileUrl,
          },
          ...children,
        ];
      } else if (file.type === 'FILE') {
        return [
          ...(await acc),
          {
            path: filePath,
            type: 'blob',
            sha: file.contentId,
            size: file.size,
            url: fileUrl,
          },
        ];
      }
      return acc;
    }, Promise.resolve([]));
  }

  private getRepoTreeCache = new Cache<GetRepoTreeCacheKey, ReturnType<typeof this.getRepoTree>>({
    ttl: 300_000,
    ttlResolution: 300_000 / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  async getRepoTree({
    repoId,
    recursive = false,
    treeSha = '',
    path,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    cacheBuster?: boolean;
    path?: string;
  }): Promise<
    {
      path?: string;
      mode?: string;
      type?: string;
      sha?: string;
      size?: number;
      url?: string;
    }[]
  > {
    const branch = await this.getBranch({ repoId, branchName: treeSha });
    const cacheKey: GetRepoTreeCacheKey = `RID:${repoId}-BSHA:${branch.sha}-PATH:${path}-RECURSIVE:${recursive}`;
    const cacheHit = this.getRepoTreeCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const result = this.getFilesDirectoryContent({ repoId, recursive, treeSha, path });
    this.getRepoTreeCache.set(cacheKey, result);
    return result;
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    /** *
     * Unusual situation.
     * If we ask for changes between branches, for example, `mode_changes` as `from` to the `master` as `to`, we'll see the same result as in the UI.
     * But we don't have the result if we ask for changes between commits, the `somecommit~1` as `from` to the `somecommit` as `to`.
     * The correct result will be only if we are changing the commits between themselves.
     */
    let from = compareFrom;
    let to = compareTo;
    if (compareFrom === `${compareTo}~1`) {
      to = compareFrom;
      from = compareTo;
    }
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let start = 0;
    let isLastPage = false;
    const diffFiles: DiffFileMetadata[] = [];
    do {
      const response = await this.transport.compareCommits(projectKey, repoName, {
        from,
        to,
        start,
      });
      start += response.size;
      isLastPage = response.isLastPage;
      diffFiles.push(
        ...response.values.map((file) => ({
          status: bitBucketStatus2GitHubStatus[file.type],
          newFilePath: file.path.toString,
          oldFilePath: file.type === 'DELETE' ? '' : file.srcPath?.toString ?? file.path.toString,
          sha: includeShas ? file.contentId : undefined,
        }))
      );
    } while (!isLastPage);
    return diffFiles;
  }

  async *getUserRemoteRepositories(
    _provider: GitProviderName,
    _driverOptions?: DriverOptions
  ): AsyncIterable<RemoteRepository> {
    let start = 0;
    let isLastPage = false;
    do {
      const response = await this.transport.getRepos({ start });
      start += response.size;
      isLastPage = response.isLastPage;
      for (const repo of response.values) {
        const defaultBranchBranch = this.transport.getDefaultBranch(repo.project.key, repo.name);
        yield {
          name: repo.name,
          isPrivate: repo.public === false,
          owner: repo.project.key,
          htmlUrl: repo.links.self[0].href,
          fork: repo['origin'] !== undefined,
          cloneUrl: repo.links.clone.find((link) => link.name === 'http')?.href ?? '',
          defaultBranch: (await defaultBranchBranch).displayId,
          writeAccess: repo.state === 'AVAILABLE',
        };
      }
    } while (!isLastPage);
  }

  getRepositoryLanguages(_repoId: string): Promise<{ [language: string]: number } | undefined> {
    // Bitbucket Datacenter doesn't support getting languages from a repository, so we'll send `undefined` instead.
    return undefined;
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path = SWM_FOLDER_IN_REPO,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    let start = 0;
    let isLastPage = false;
    const files: { path: string; content: string }[] = [];
    do {
      const response = await this.transport.getFilesDirectory(projectKey, repoName, {
        start,
        path,
        at: revision,
      });

      for (const file of response.values) {
        const content = await this.transport.getRawFileContent(projectKey, repoName, `${path}/${file}`, {
          safe: true,
          at: revision,
        });
        if (content) {
          files.push({ path: `${path}/${file}`, content });
        }
      }
      start += response.size;
      isLastPage = response.isLastPage;
    } while (!isLastPage);
    return files;
  }

  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    // Bitbucket DC allows getting updates only with the REPO_ADMIN permission
    // https://developer.atlassian.com/server/bitbucket/rest/v811/api-group-repository/#api-api-latest-projects-projectkey-repos-repositoryslug-ref-change-activities-get
    // Therfore, we instead fetch the last 30 branches changes made by anyone and then return the 3 branches that were changed
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    const MAX_ITEMS_TO_FETCH = 30;
    const response = await this.transport.getBranches(projectKey, repoName, {
      limit: MAX_ITEMS_TO_FETCH,
      orderBy: 'MODIFICATION',
    });
    const branches = new Set<string>();
    for (const branch of response.values) {
      branches.add(branch.displayId);
      if (branches.size === maxActiveBranchesToFetch) {
        break;
      }
    }
    return Array.from(branches);
  }

  async getUserOrganizations(_provider: GitProviderName, _driverOptions?: DriverOptions): Promise<string[]> {
    const users: string[] = [];
    let start = 0;
    let isLastPage = false;
    do {
      const response = await this.transport.getUsers({ start });
      users.push(...response.values.map((user) => user.name));
      start += response.limit;
      isLastPage = response.isLastPage;
    } while (!isLastPage);
    return users;
  }

  override async deleteBranch(repoId: string, branchName: string): Promise<void> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    return this.transport.deleteBranch(projectKey, repoName, { name: branchName });
  }

  override async closePullRequest(repoId: string, prId: number): Promise<void> {
    const { projectKey, repoName } = await this.getRepoInformation(repoId);
    const pr = await this.transport.getPullRequest(projectKey, repoName, prId);
    return this.transport.deletePullRequest(projectKey, repoName, prId, { version: pr.version });
  }
}

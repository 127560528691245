export interface SwimmEvent {
  code: string;
  description: string;
}

export const EVENTS_SOURCE_TYPES = {
  DOC: 'unit',
  PLAYLIST: 'playlist',
  CLOUD_DOC: 'cloud-doc',
};

export const SWIMM_EVENTS: { [eventKey: string]: SwimmEvent } = {
  // GENERAL ACTIONS
  UNKNOWN: { code: 'UNKNOWN', description: 'Unknown Event' },
  ERROR: { code: 'ERROR', description: 'Error' },

  // ACTIONS ON RESOURCES IN REPO
  EXTERNAL_LINK_CREATED: { code: 'EXTERNAL_LINK_CREATED', description: 'Created an external link' },
  EXTERNAL_LINK_DELETED: { code: 'EXTERNAL_LINK_DELETED', description: 'Deleted an external link' },
  EXTERNAL_LINK_UPDATED: { code: 'EXTERNAL_LINK_UPDATED', description: 'Updated an external link' },
  PLAYLIST_CREATED: { code: 'PLAYLIST_CREATED', description: 'Created a Playlist' },
  PLAYLIST_DELETED: { code: 'PLAYLIST_DELETED', description: 'Deleted a Playlist' },
  PLAYLIST_UPDATED: { code: 'PLAYLIST_UPDATED', description: 'Updated a Playlist' },
  PLAYLIST_VIEWED: { code: 'PLAYLIST_VIEWED', description: 'Viewed a Playlist' },
  CLOUD_DOC_CREATED: { code: 'CLOUD_DOC_CREATED', description: 'Created a Cloud Doc' },
  CLOUD_DOC_VIEWED: { code: 'CLOUD_DOC_VIEWED', description: 'Viewed a Cloud Doc' },
  COPILOT_DOC_CREATED: { code: 'COPILOT_DOC_CREATED', description: 'Created a doc from copilot' },
  COPILOT_PLUGIN_INSTALLED: { code: 'COPILOT_PLUGIN_INSTALLED', description: 'Copilot Plugin Installed' },
  COPILOT_PLUGIN_UNINSTALLED: { code: 'COPILOT_PLUGIN_UNINSTALLED', description: 'Copilot Plugin Uninstalled' },
  COPILOT_PLUGIN_PLAN_PURCHASED: {
    code: 'COPILOT_PLUGIN_PLAN_PURCHASED',
    description: 'Copilot Plugin Plan Purchased',
  },
  COPILOT_PLUGIN_PLAN_CANCELLED: {
    code: 'COPILOT_PLUGIN_PLAN_CANCELLED',
    description: 'Copilot Plugin Plan Cancelled',
  },
  DOC_CREATED: { code: 'DOC_CREATED', description: 'Created a Doc' },
  DOC_VIEWED: { code: 'DOC_VIEWED', description: 'Viewed a Doc' },
  SHARED_DOC_VIEWED: { code: 'SHARED_DOC_VIEWED', description: 'Viewed a shared Doc' },
  EMBED_DOC_VIEWED: { code: 'EMBED_DOC_VIEWED', description: 'Viewed an embedded (iframe) Doc' },
  DOC_CREATED_FROM_SUGGESTION: {
    code: 'DOC_CREATED_FROM_SUGGESTION',
    description: 'Created a Doc out of a suggestion',
  },
  DOC_DELETED: { code: 'DOC_DELETED', description: 'Deleted a Doc' },
  DOC_UPDATED: { code: 'DOC_UPDATED', description: 'Updated a Doc' },
  DOC_ASSIGNED: { code: 'DOC_ASSIGNED', description: 'User assigned a Doc to another user' },
  DOC_READER_THANKED_AUTHOR: { code: 'DOC_READER_THANKED_AUTHOR', description: 'User thanked the author of a Doc' },
  DOC_REQUEST_CREATED: { code: 'DOC_REQUEST_CREATED', description: 'User created a Doc request' },
  DOC_REQUEST_UPDATED: { code: 'DOC_REQUEST_UPDATED', description: 'User updated a Doc request' },
  FIRST_DRAFT_CREATED: { code: 'FIRST_DRAFT_CREATED', description: 'User created a first draft in the repo' },
  PROCESS_DOC_CREATED: { code: 'PROCESS_DOC_CREATED', description: 'Created a Process doc' },

  // IDE events
  IDE_DOC_VIEWED: { code: 'IDE_DOC_VIEWED', description: 'Viewed a Doc in IDE' },
  IDE_DOC_RENAMED: { code: 'IDE_DOC_RENAMED', description: 'Renamed a Doc in IDE' },
  IDE_DOC_CREATED: { code: 'IDE_DOC_CREATED', description: 'Created a Doc in IDE' },

  IDE_EXTENSION_INSTALLED: { code: 'IDE_EXTENSION_INSTALLED', description: 'IDE Extension Installed' },
  IDE_EXTENSION_UNINSTALLED: { code: 'IDE_EXTENSION_UNINSTALLED', description: 'IDE Extension Uninstalled' },
  IDE_DOC_VIEWED_FROM_RULE: { code: 'IDE_DOC_VIEWED_FROM_RULE', description: 'Viewed a Doc from Rule (IDE)' },
  IDE_RULE_CREATED: { code: 'IDE_RULE_CREATED', description: 'Rule Created (IDE)' },

  ASK_SWIMM_QA: { code: 'ASK_SWIMM_QA', description: 'Asked a question' },
  ASK_ANSWER_DOC_FOUND: { code: 'ASK_ANSWER_DOC_FOUND', description: 'Doc Found in Ask Answer' },
  ASK_USER_CREATED_DOC: { code: 'ASK_USER_CREATED_DOC', description: 'User Created a Doc Using Ask' },
  ASK_SWIMM_ANALYZE: { code: 'ASK_SWIMM_ANALYZE', description: 'Clicked Analyze' },
  ASK_SWIMM_REANALYZE: { code: 'ASK_SWIMM_REANALYZE', description: 'Clicked Reanalyze' },
  ASK_SWIMM_SHARE_QUESTION: { code: 'ASK_SWIMM_SHARE_QUESTION', description: 'Shared an ask swimm question' },

  // ACTIONS ON TEMPLATES
  TEMPLATE_USED: { code: 'TEMPLATE_USED', description: 'Used a template' },

  // ACTIONS ON REPO
  REPOSITORY_CREATED: { code: 'REPOSITORY_CREATED', description: 'Created Repository' },
  SWIMM_VERIFY_RUN_ON_REPO: { code: 'SWIMM_VERIFY_RUN_ON_REPO', description: 'Swimm Verify run on the repo' },
  COVERAGE_GOAL_SET_FOR_REPO: { code: 'COVERAGE_GOAL_SET_FOR_REPO', description: 'Coverage goal set for repo' },
  RULE_CREATED: { code: 'RULE_CREATED', description: 'Rule created' },
  RULE_EDITED: { code: 'RULE_EDITED', description: 'Rule edited' },
  RULE_DELETED: { code: 'RULE_DELETED', description: 'Rule deleted' },
  RULE_DISABLED: { code: 'RULE_DISABLED', description: 'Rule disabled' },
  RULE_ENABLED: { code: 'RULE_ENABLED', description: 'Rule enabled' },

  // SWIMMER_STATUS (PROGRESS) CHANGES
  DOC_SWIMMER_STATUS_UPDATED: { code: 'DOC_SWIMMER_STATUS_UPDATED', description: 'Doc swimmer`s status updated' },
  EXTERNAL_LINK_SWIMMER_STATUS_UPDATED: {
    code: 'EXTERNAL_LINK_SWIMMER_STATUS_UPDATED',
    description: 'External link swimmer`s status updated',
  },

  // USER ACTIONS
  USER_INVITED: { code: 'USER_INVITED', description: 'User invited a new person to the workspace' },
  USER_SIGN_UP: { code: 'USER_SIGN_UP', description: 'User signed up' },
  USER_SIGN_IN: { code: 'USER_SIGN_IN', description: 'User signed in' },
  USER_SIGN_OUT: { code: 'USER_SIGN_OUT', description: 'User signed out' },
  USER_RESET_PASSWORD: { code: 'USER_RESET_PASSWORD', description: 'User asked to reset password' },
  USER_REQUESTED_DEMO: { code: 'USER_REQUESTED_DEMO', description: 'User clicked request a demo button' },

  // GLOBAL ACTIONS
  USER_RATED_US: { code: 'USER_RATED_US', description: 'User gave us rating' },

  // WORKSPACES ACTIONS
  WORKSPACE_CREATED: { code: 'WORKSPACE_CREATED', description: 'Created a Workspace' },
  WORKSPACE_DELETED: { code: 'WORKSPACE_DELETED', description: 'Deleted a Workspace' },
  WORKSPACE_UPDATED: { code: 'WORKSPACE_UPDATED', description: 'Updated a Workspace' },
  WORKSPACE_USER_JOINED_WORKSPACE: {
    code: 'WORKSPACE_USER_JOINED_WORKSPACE',
    description: 'a user joined the workspace',
  },
  ADMIN_APPROVED_JOIN_WORKSPACE_REQUEST: {
    code: 'ADMIN_APPROVED_JOIN_WORKSPACE_REQUEST',
    description: 'an admin accepted users join request to the workspace',
  },
  WORKSPACE_USER_UNSUBSCRIBED_WORKSPACE: {
    code: 'WORKSPACE_USER_UNSUBSCRIBED_WORKSPACE',
    description: 'a workspace user has been unsubscribed from the workspace',
  },
  WORKSPACE_ADMIN_DEMOTED_TO_WORKSPACE_USER: {
    code: 'WORKSPACE_ADMIN_DEMOTED_TO_WORKSPACE_USER',
    description: 'A Workspace Admin was demoted to Workspace User role by another Workspace Admon',
  },
  WORKSPACE_USER_PROMOTED_TO_WORKSPACE_ADMIN: {
    code: 'WORKSPACE_USER_PROMOTED_TO_WORKSPACE_ADMIN',
    description: 'A Workspace User was promoted to Workspace Admin role by another Workspace Admin',
  },
  USER_REMOVED_FROM_WORKSPACE: {
    code: 'USER_REMOVED_FROM_WORKSPACE',
    description: 'A Workspace user was removed from Workspace by a workspace Admin',
  },
  REPO_REMOVED_FROM_WORKSPACE: {
    code: 'REPO_REMOVED_FROM_WORKSPACE',
    description: 'A repository was removed from the workspace',
  },
  WORKSPACE_STARTED_TRIAL: {
    code: 'WORKSPACE_STARTED_TRIAL',
    description: 'A workspace started a trial plan',
  },
  WORKSPACE_TRIAL_EXPIRED: {
    code: 'WORKSPACE_TRIAL_EXPIRED',
    description: 'Trial Ended',
  },
  WORKSPACE_GIT_PROVIDER_CHANGED: {
    code: 'WORKSPACE_GIT_PROVIDER_CHANGED',
    description: 'Git Provider was changed for the workspace',
  },

  // HUNKS UPDATE ACTIONS
  AUTOSYNC_HUNK_ACCEPTED: { code: 'AUTOSYNC_HUNK_ACCEPTED', description: 'Autosynced hunk was accepted by the user' },
  AUTOSYNCED_HUNK_RESELECTED: {
    code: 'AUTOSYNCED_HUNK_RESELECTED',
    description: 'Autosynced hunk was reselected and replaced with another hunk by the user',
  },
  OUTDATED_HUNK_DELETED: { code: 'OUTDATED_HUNK_DELETED', description: 'Outdated hunk was deleted by the user' },
  OUTDATED_HUNK_RESELECTED: {
    code: 'OUTDATED_HUNK_RESELECTED',
    description: 'Outdated hunk was reselected and replaced with another hunk by the user',
  },

  // CLI LOG COMMAND EVENTS
  CLI_VERIFY_RUN: { code: 'CLI_VERIFY_RUN', description: 'Log event sent from running the CLI for verify' },
  CLI_ONMERGE_RUN: { code: 'CLI_ONMERGE_RUN', description: 'Log event sent from running the CLI for on-merge' },

  SWMD_UPGRADE_CLICKED: { code: 'SWMD_UPGRADE_CLICKED', description: 'User clicked on the upgrade to SWMD button' },
  SWMD_UPGRADE_SHOWN: { code: 'SWMD_UPGRADE_SHOWN', description: 'View Update to SWMD Banner' },

  // GITHUB APP MODAL
  REPOSITORY_DOC_NOTIFICATION_RECIPIENT_ADDED: {
    code: 'REPOSITORY_DOC_NOTIFICATION_RECIPIENT_ADDED',
    description: 'Add an email to the Doc Notification Recipients list',
  },
  REPOSITORY_DOC_NOTIFICATION_RECIPIENT_REMOVED: {
    code: 'REPOSITORY_DOC_NOTIFICATION_RECIPIENT_REMOVED',
    description: 'Removed an email from the the Doc Notification Recipients list',
  },
  GITHUB_APP_AUTOSYNCED_FILES_AUTOMATICALLY: {
    code: 'GITHUB_APP_AUTOSYNCED_FILES_AUTOMATICALLY',
    description: 'File was autosynced automatically',
  },
  GITHUB_APP_AUTOSYNCED_FILES_MANUALLY: {
    code: 'GITHUB_APP_AUTOSYNCED_FILES_MANUALLY',
    description: 'File was autosynced manually',
  },
  WEB_APP_AUTOSYNCED_FILES: {
    code: 'WEB_APP_AUTOSYNCED_FILES',
    description: 'File was autosynced',
  },

  // UNUSED
  DOC_SUBMIT_A_COMMIT_SHA: {
    code: 'DOC_SUBMIT_A_COMMIT_SHA',
    description: 'User submmited a commit SHA to start writing a Doc',
  },
  REPOSITORY_UPDATED: { code: 'REPOSITORY_UPDATED', description: 'Updated Repository' },
  REPOSITORY_DELETED: { code: 'REPOSITORY_DELETED', description: 'Deleted Repository' },
  SWIMMER_UNSUBSCRIBED_FROM_REPOSITORY: {
    code: 'SWIMMER_UNSUBSCRIBED_FROM_REPOSITORY',
    description: 'a Swimmer unsubscribed from the Repository',
  },
  LIFEGUARD_UNSUBSCRIBED_FROM_REPOSITORY: {
    code: 'LIFEGUARD_UNSUBSCRIBED_FROM_REPOSITORY',
    description: 'a Lifeguard unsubscribed from the Repository',
  },
  LIFEGUARD_DEMOTED_TO_SWIMMER: {
    code: 'LIFEGUARD_DEMOTED_TO_SWIMMER',
    description: 'A Lifeguard was demoted to Swimmer role by another Lifeguard',
  },
  SWIMMER_PROMOTED_TO_LIFEGUARD: {
    code: 'SWIMMER_PROMOTED_TO_LIFEGUARD',
    description: 'A Swimmer was promoted to Lifeguard role by another Lifeguard',
  },
  REPOSITORY_MD_EXPORT_ENABLED: {
    code: 'REPOSITORY_MD_EXPORT_ENABLED',
    description: 'Enable MD export for repository',
  },
  REPOSITORY_MD_EXPORT_DISABLED: {
    code: 'REPOSITORY_MD_EXPORT_DISABLED',
    description: 'Disabled MD export for repository',
  },
  OPENED_REPOSITORY_INTEGRATIONS: { code: 'OPENED_REPOSITORY_INTEGRATIONS', description: 'Viewed integrations page' },
  PLAYLIST_SWIMMER_STATUS_CREATED: {
    code: 'PLAYLIST_SWIMMER_STATUS_CREATED',
    description: 'Playlist swimmer`s status created',
  },
  PLAYLIST_SWIMMER_STATUS_DELETED: {
    code: 'PLAYLIST_SWIMMER_STATUS_DELETED',
    description: 'Playlist swimmer`s status deleted',
  },
  PLAYLIST_SWIMMER_STATUS_UPDATED: {
    code: 'PLAYLIST_SWIMMER_STATUS_UPDATED',
    description: 'Playlist swimmer`s status updated',
  },
  EXERCISE_SWIMMER_STATUS_UPDATED: {
    code: 'EXERCISE_SWIMMER_STATUS_UPDATED',
    description: 'Exercise swimmer`s status updated',
  },
  EXTERNAL_LINK_SWIMMER_STATUS_CREATED: {
    code: 'EXTERNAL_LINK_SWIMMER_STATUS_CREATED',
    description: 'External link swimmer`s status created',
  },
  EXTERNAL_LINK_SWIMMER_STATUS_DELETED: {
    code: 'EXTERNAL_LINK_SWIMMER_STATUS_DELETED',
    description: 'External link swimmer`s status deleted',
  },
  WORKSPACE_ADMIN_JOINED_WORKSPACE: {
    code: 'WORKSPACE_ADMIN_JOINED_WORKSPACE',
    description: 'a workspace admin joined the workspace',
  },
  WORKSPACE_ADMIN_UNSUBSCRIBED_WORKSPACE: {
    code: 'WORKSPACE_ADMIN_UNSUBSCRIBED_WORKSPACE',
    description: 'a  workspace admin has been unsubscribed from the workspace',
  },
  AUTO_UPDATE_REQUESTED: { code: 'AUTO_UPDATE_REQUESTED', description: 'user requested to update the app' },
  AUTO_UPDATE_FAILED: { code: 'AUTO_UPDATE_FAILED', description: 'Autoupdate failed' },
  FOLDER_COMMENT_UPDATED: {
    code: 'FOLDER_COMMENT_UPDATED',
    description: 'A folder comment has been created / updated',
  },
  FOLDER_COMMENT_DELETED: { code: 'FOLDER_COMMENT_DELETED', description: 'A folder comment has been deleted' },
  CLICKED_LINK_IN_DOCUMENTATION_HUB: {
    code: 'CLICKED_LINK_IN_DOCUMENTATION_HUB',
    description: 'A link in documentation hub was clicked',
  },
  ADDED_GEN_AI_CONTENT: {
    code: 'ADDED_GEN_AI_CONTENT',
    description: 'Generative AI content was added to a doc',
  },
  PR_ADDED_TO_DOC: {
    code: 'PR_ADDED_TO_DOC',
    description: 'Doc from PR content was added to a doc',
  },
  AI_SNIPPETS_TO_DOC: {
    code: 'AI_SNIPPETS_TO_DOC',
    description: 'Doc from Snippets AI content was added to a doc',
  },
  TOGGLE_REPO_GENERATIVE_AI: {
    code: 'TOGGLE_REPO_GENERATIVE_AI',
    description: 'Generative AI features toggled on a repo',
  },
  TOGGLE_REPO_ASK_SWIMM: {
    code: 'TOGGLE_REPO_ASK_SWIMM',
    description: 'Ask Swimm features toggled on a repo',
  },
  ASK_SWIMM_CAP_REACHED: {
    code: 'ASK_SWIMM_CAP_REACHED',
    description: 'Ask Swimm cap was reached on workspace',
  },
  GENERATIVE_AI_CAP_REACHED: {
    code: 'GENERATIVE_AI_CAP_REACHED',
    description: 'Generative AI cap was reached on workspace',
  },
  TEXT_COMPLETION_CAP_REACHED: {
    code: 'TEXT_COMPLETION_CAP_REACHED',
    description: 'AI text completion cap was reached on workspace',
  },
  VERIFIED_ALL_DOCS: {
    code: 'VERIFIED_ALL_DOCS',
    description: 'All docs were verified',
  },
  COPILOT_CHAT_DOC_CREATE_REQUEST: {
    code: 'COPILOT_CHAT_DOC_CREATE_REQUEST',
    description: 'Copilot chat doc create request',
  },
  COPILOT_CHAT_DOC_SEARCH_REQUEST: {
    code: 'COPILOT_CHAT_DOC_SEARCH_REQUEST',
    description: 'Copilot chat doc search request',
  },
  COPILOT_CHAT_DOC_GENERATION_REQUEST: {
    code: 'COPILOT_CHAT_DOC_GENERATION_REQUEST',
    description: 'Copilot chat doc generation request',
  },
  COPILOT_CHAT_DOC_UPDATE_REQUEST: {
    code: 'COPILOT_CHAT_DOC_UPDATE_REQUEST',
    description: 'Copilot chat doc update request',
  },
  COPILOT_CHAT_DOC_RENAME_REQUEST: {
    code: 'COPILOT_CHAT_DOC_RENAME_REQUEST',
    description: 'Copilot chat doc rename request',
  },
  COPILOT_CHAT_DOC_USAGE_REQUEST: {
    code: 'COPILOT_CHAT_DOC_USAGE_REQUEST',
    description: 'Copilot chat doc usage request',
  },
  COPILOT_CHAT_DOC_UNKNOWN_REQUEST: {
    code: 'COPILOT_CHAT_DOC_UNKNOWN_REQUEST',
    description: 'Copilot chat doc unknown request',
  },
  ASK_SWIMM_ANALYZE_FINISHED_SUCCESSFULLY: {
    code: 'ASK_SWIMM_ANALYZE_FINISHED_SUCCESSFULLY',
    description: 'Analyze finished successfully',
  },
  DOCS_GENERATED_SUCCESSFULLY: {
    code: 'DOCS_GENERATED_SUCCESSFULLY',
    description: 'Docs generated successfully',
  },
};

export enum SwimmEnvKind {
  DEVELOPMENT = 'development',
  TEST = 'test',
  STAGING = 'staging',
  PRODUCTION = 'production',
}

export enum EditorEnvKind {
  VSCODE = 'VSCode',
  JETBRAINS = 'JetBrains',
  WEBAPP = 'WebApp',
}

import _ from 'lodash-es';
import gitwrapper from 'GitUtils/gitwrapper';
import { isFileSupportedFilter } from './repo-utils';
import { createDynamicStructureFromDiffString, dynamicPatchToSnippets } from '../diff-parser';
import nodePath from 'path-browserify';
import * as iter from '../utils/iter';

export interface PRData {
  base: string;
  head: string;
  metadata?: {
    title: string;
    body?: string;
  };
}

export const BIG_SNIPPET_SIZE_IN_LINES = 15;
const BIG_ENOUGH_INDENTED_SNIPPET = 3;
const BIG_ENOUGH_SAME_INDENTATION_SNIPPET = 7;
const BIG_ENOUGH_SNIPPET = 30;

// Paths that contain these parts are ignored by PR2doc since they can be big and not relevant to the logic of the PR.
const IGNORED_PATH_PARTS = ['dist'];

const isPathIrrelevantToPR2Doc = (filePath: string) => {
  if (!isFileSupportedFilter(filePath)) {
    return true;
  }
  const filePathParts = filePath.split(nodePath.sep);
  return IGNORED_PATH_PARTS.some((part) => filePathParts.includes(part));
};

export async function getPrChangesDetails({
  repoId,
  prHead,
  prBase,
}: {
  repoId: string;
  prHead: string;
  prBase: string;
}): Promise<{ diffSnippets: SnippetInfo[] }> {
  const gitDiff = await gitwrapper.getGitDiffRemote({
    repoId,
    base: prBase,
    head: prHead,
  });
  const diffSnippets = diffToSwimmDocumentSnippets({ gitDiff, filterIrreleventPaths: true });

  return { diffSnippets };
}

export interface SnippetInfo {
  filePath: string;
  content: string;
  startLine: number;
  endLine: number;
  repoId?: string;
}

export function diffToSwimmDocumentSnippets({
  gitDiff,
  filterIrreleventPaths,
}: {
  gitDiff: string;
  filterIrreleventPaths?: boolean;
}): SnippetInfo[] {
  const dynamic = createDynamicStructureFromDiffString({ rawDiffString: gitDiff }).swimmPatch;
  // create SnippetInfo objects from the dynamic structure and filter out snippets that had only deletions (no content)
  const snippets = dynamicPatchToSnippets(dynamic).filter((snippet) => snippet.content.length > 0);
  const snippetsSplit = [...splitLongSnippets(snippets)];
  if (filterIrreleventPaths) {
    return snippetsSplit.filter((snippet) => !isPathIrrelevantToPR2Doc(snippet.filePath));
  }
  return snippetsSplit;
}

const INDENTATION_REGEX = /^(\s+)/;

const CLOSING_BRACE_REGEX = /([)\]}>;,.:/\\\-=]|\s)+/g;

const isClosingBraceLine = (line: string) => line.replace(CLOSING_BRACE_REGEX, '') === '';

const stripCobolLineNumbers = (line: string | undefined) => line?.replace(/^[0-9]+/, '');

const trimTrailingEmptyLines = (snippet: SnippetInfo): SnippetInfo => {
  const contentLines = snippet.content.split('\n');
  const trailingEmptyLineCount = _.takeRightWhile(
    contentLines,
    (l) => stripCobolLineNumbers(l).trim().length === 0
  ).length;
  return {
    ...snippet,
    content: contentLines.slice(0, contentLines.length - trailingEmptyLineCount).join('\n'),
    endLine: snippet.endLine - trailingEmptyLineCount,
  };
};

export function* splitLongSnippet(snippet: SnippetInfo): Iterable<SnippetInfo> {
  const snippetLines = snippet.content.split('\n');
  let currentSection: SnippetInfo | undefined = undefined;
  let currentSectionIndentation: string | undefined = undefined;
  for (const [line, i] of iter.enumerate(snippetLines)) {
    // Some languages such as COBOL contain the line numbers inside the actual line text. We strip it so that it
    // doesn't affect our split algorithm (otherwise it would lump everything together since there's never an
    // 'empty' line.
    const strippedLine = stripCobolLineNumbers(line);
    const isEmptyLine = strippedLine.trim().length === 0;
    const indentation = (strippedLine.match(INDENTATION_REGEX)?.[0] || '').replace('\t', '    '); // get line indentation
    const shouldSplitHere = () => {
      const currentSectionLineCount = currentSection.endLine - currentSection.startLine + 1;
      if (
        (indentation.length < currentSectionIndentation.length &&
          currentSectionLineCount >= BIG_ENOUGH_INDENTED_SNIPPET) ||
        // Even if we haven't reached the end of the block, if there are too many lines - cut here.
        currentSectionLineCount >= BIG_ENOUGH_SNIPPET
      ) {
        if (!isEmptyLine) {
          // Don't split here if the line is just closing brackets/parens/braces/semicolons.
          const isClosingBrace = isClosingBraceLine(strippedLine);
          return !isClosingBrace;
        }
        const strippedNextLine = stripCobolLineNumbers(snippetLines[i + 1]);
        if (strippedNextLine === undefined) {
          // This is the last line, no use splitting here.
          return false;
        }
        const isNextLineEmpty = strippedNextLine.trim().length === 0;
        if (isNextLineEmpty) {
          return true;
        }
        const isNextLineClosingBrace = isClosingBraceLine(strippedNextLine);
        // If the next line is a closing brace, don't split here to not start the next snippet with a closing brace.
        return !isNextLineClosingBrace;
      }
      return currentSectionLineCount >= BIG_ENOUGH_SAME_INDENTATION_SNIPPET && isEmptyLine;
    };
    if (currentSection && !shouldSplitHere()) {
      // Append to the current section
      currentSection.content += '\n' + line;
      currentSection.endLine++;
      continue;
    }
    // We should split - yield the previous section and start a new one.
    if (currentSection) {
      yield trimTrailingEmptyLines(currentSection);
      currentSection = undefined;
    }
    if (isEmptyLine) {
      // Skip empty lines at the beginning of the snippet.
      continue;
    }
    currentSection = {
      content: line,
      startLine: snippet.startLine + i,
      endLine: snippet.startLine + i,
      repoId: snippet.repoId,
      filePath: snippet.filePath,
    };
    currentSectionIndentation = indentation;
  }
  if (currentSection) {
    yield trimTrailingEmptyLines(currentSection);
  }
}

export function* splitLongSnippets(snippets: SnippetInfo[]): Iterable<SnippetInfo> {
  for (const snippet of snippets) {
    const snippetLines = snippet.content.split('\n');
    if (snippetLines.length < BIG_SNIPPET_SIZE_IN_LINES) {
      yield snippet;
    } else {
      yield* splitLongSnippet(snippet);
    }
  }
}

<script setup>
import SettingsModalSection from '@/modules/core/components/settings-modal/SettingsModalSection.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { SWIMM_PRIVACY_POLICY_ADDRESS, SWIMM_TERMS_OF_SERVICE_ADDRESS } from '@/config';
import {
  LICENSES_SUPPORTING_SELF_SERVE_UPGRADE,
  billingPlanTypes,
  countryCodeToCountry,
  countryToStates,
  currencySymbols,
  getLoggerNew,
  productEvents,
} from '@swimm/shared';
import swal from 'sweetalert';
import { SWAL_CONTACT_US_CONTENT } from '@/common/utils/common-definitions';
import 'firebase/compat/auth';
import PlanCard from '@/common/components/Billing/PlanCard.vue';
import { Action, BaseLabel, Checkbox, Divider, Icon, SwText, TextField, TextSkeleton } from '@swimm/ui';
import { pluralize } from '@/common/utils/helpers';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { computed, defineAsyncComponent, onMounted, ref, watch } from 'vue';
import { useStigg } from '@/common/composables/useStigg';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useBillingStore } from '@/modules/billing/store/billing';
import { ilVatRateId, stripePublishableKey } from '@/config';
import { BillingPeriod } from '@stigg/js-client-sdk';

const logger = getLoggerNew(__modulename);

const store = useStore();
const route = useRoute();
const analytics = useAnalytics();
const { getActivePlans, stiggClient } = useStigg();
const billingStore = useBillingStore();
const { fetchBillingData } = billingStore;
const { currentSeats, isOnTrialPlan } = storeToRefs(billingStore);
const { user } = storeToRefs(useAuthStore());
const emit = defineEmits(['upgrade-success', 'back']);

const props = defineProps({
  billingPeriod: { type: String, required: true },
  productSKU: { type: String, required: true },
  hasPaymentData: { type: Boolean, default: false },
  billingQuantity: { type: Number, default: 0 },
});

const isAcceptAgreement = ref(false);
const selectedCountry = ref(null);
const selectedState = ref(null);
const selectedAddress = ref('');
const selectedCity = ref('');
const taxId = ref('');
const companyName = ref('');
const nameOnCard = ref('');
const publishableKey = ref(stripePublishableKey);
const countryList = Object.entries(countryCodeToCountry)
  .map(([countryCode, country]) => ({ code: countryCode, label: country }))
  .sort((countryA, countryB) => (countryA.label > countryB.label ? 1 : -1));
const countries = ref(countryList);
const defaultPrice = ref(16);
const paying = ref(false);
const isCreditCardValid = ref(false);
const cardField = ref(null);
const promotionCode = ref('');
const promo = ref(null);
const promoCodeError = ref('');
const prefilledQuantity = ref(null);
const verifyingPromotion = ref(false);
const priceLoading = ref(false);
const commitmentDate = new Date();
commitmentDate.setYear(commitmentDate.getUTCFullYear() + 1);
const commitmentDateString = commitmentDate.toDateString();

const workspaceId = ref(route.params.workspaceId);
const currentWorkspace = computed(() => store.getters['database/db_getWorkspace'](route.params.workspaceId));

const isStandardOrPro = computed(
  () =>
    currentWorkspace.value.license === billingPlanTypes.STANDARD ||
    currentWorkspace.value.license === billingPlanTypes.PRO
);

const quantity = computed(() => prefilledQuantity.value ?? props.billingQuantity);
const payingForQuantity = computed(() => {
  if (isStandardOrPro.value) {
    return quantity.value;
  }

  if (currentSeats.value && !isOnTrialPlan.value && quantity.value > currentSeats.value) {
    return quantity.value - currentSeats.value;
  }

  return quantity.value;
});
const isYearly = computed(() => props.billingPeriod === BillingPeriod.Annually);
const selectedCountryCode = ref('US');
const selectedProductSKU = computed(() => props.productSKU);
const currency = ref('USD');
const states = computed(() => countryToStates[selectedCountryCode.value] || null);
const currencySymbol = computed(() => currencySymbols[currency.value] || '$');
const defaultPriceString = computed(() => `${currencySymbol.value}${defaultPrice.value}`);

const callEstimateSubscription = async () => {
  const date = new Date();
  const backDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
  const countryCode = selectedCountryCode.value;
  const planId = `plan-swimm-${selectedProductSKU.value}`;

  try {
    return await stiggClient.estimateSubscription({
      customerId: workspaceId.value,
      unitQuantity: payingForQuantity.value,
      billingInformation: {
        taxRateIds: countryCode === 'IL' ? [ilVatRateId] : [],
      },
      billingPeriod: props.billingPeriod,
      planId,
      startDate: backDate,
      promotionCode: promotionCode.value,
      skipTrial: true,
      billingCountryCode: countryCode,
    });
  } catch (err) {
    logger.error({ err }, `Failed estimating subscription ${err.message}`);
    if (err.message.includes('Failed to estimate subscription. Error: Promotion')) {
      return await stiggClient.estimateSubscription({
        customerId: workspaceId.value,
        unitQuantity: payingForQuantity.value,
        billingInformation: {
          taxRateIds: countryCode === 'IL' ? [ilVatRateId] : [],
        },
        billingPeriod: props.billingPeriod,
        planId,
        startDate: backDate,
        skipTrial: true,
        billingCountryCode: countryCode,
      });
    } else {
      throw err;
    }
  }
};

const roundWithTwoDecimal = (num) => Math.round(num * 100.0) / 100;

const fullPrice = ref(
  roundWithTwoDecimal(defaultPrice.value * payingForQuantity.value * (isYearly.value ? 0.8 * 12 : 1))
);

async function calculatePrice() {
  priceLoading.value = true;
  try {
    const estimatedSubscription = await callEstimateSubscription();
    currency.value = estimatedSubscription.subscription.subTotal.currency;
    fullPrice.value = roundWithTwoDecimal(estimatedSubscription.subscription.subTotal.amount);
    totalPrice.value = roundWithTwoDecimal(estimatedSubscription.subscription.total.amount);

    if (estimatedSubscription.taxDetails) {
      taxRates.value = [
        {
          taxAmount: estimatedSubscription.tax?.amount,
          taxRate: estimatedSubscription.taxDetails.percentage,
          taxName: estimatedSubscription.taxDetails.displayName,
        },
      ];
    } else {
      taxRates.value = [];
    }

    if (estimatedSubscription?.discount) {
      promo.value = estimatedSubscription?.discount;
    } else if (promotionCode.value) {
      promo.value = null;
      promoCodeError.value = 'Invalid promo code';
    }
  } catch (err) {
    logger.error({ err }, `Failed fetching pricing data from Stigg. Details: ${err.message}`);
  } finally {
    priceLoading.value = false;
  }
}

watch(selectedCountry, async (newlySelectedCountry, _old) => {
  selectedCountryCode.value = newlySelectedCountry?.code || 'US';
  if (selectedCountryCode.value === 'IL') {
    defaultPrice.value = 61;
    taxIdName.value = 'VAT ID';
    shouldShowTaxId.value = true;
    taxIdType.value = 'il_vat';
    currency.value = 'ILS';
  } else {
    defaultPrice.value = 16;
    taxIdName.value = '';
    shouldShowTaxId.value = false;
    taxIdType.value = '';
    taxRates.value = null;
    currency.value = 'USD';
  }
  await calculatePrice();
});

const addPromo = async () => {
  verifyingPromotion.value = true;
  promoCodeError.value = '';

  try {
    await calculatePrice();
  } catch (err) {
    promoCodeError.value = 'Inavlid promo code';
  } finally {
    verifyingPromotion.value = false;
  }
};

const removePromo = async () => {
  promo.value = null;
  promotionCode.value = '';
  await calculatePrice();
};

const discountPercentage = computed(() => promo.value?.value);
const discountDuration = computed(() => promo.value?.durationInMonths);
const discountMessage = computed(
  () =>
    `${discountPercentage.value}% off${
      !isYearly.value && discountDuration ? ` for ${discountDuration.value} months` : ''
    }`
);

// stripe limits discounts per invoice and not actually per month so yearly discounts are also for the full term
const discountedAmount = computed(() =>
  roundWithTwoDecimal(
    promo.value && discountPercentage.value && promo.value?.type === 'PERCENTAGE'
      ? fullPrice.value * (discountPercentage.value / 100)
      : 0.0
  )
);

const pricePerUser = computed(() => fullPrice.value / payingForQuantity.value);

const taxIdType = ref('');
const taxIdName = ref('');
const shouldShowTaxId = ref(false);
const totalPrice = ref(fullPrice.value);
const taxRates = ref([]);
const taxes = computed(() =>
  !taxRates.value ? [] : taxRates.value.filter((tax) => !tax.taxState || tax.taxState === selectedState.value)
);

const isDisabled = computed(
  () =>
    !companyName.value ||
    !nameOnCard.value ||
    !selectedCountry.value ||
    !isCreditCardValid.value ||
    !isAcceptAgreement.value ||
    !(!shouldShowTaxId.value || taxId.value) ||
    !(!states.value || selectedState.value)
);

const emptyFields = computed(() => {
  const fields = [];
  if (!isCreditCardValid.value) {
    fields.push('Card number');
  }
  if (!companyName.value) {
    fields.push('Company name');
  }
  if (!nameOnCard.value) {
    fields.push('Name on card');
  }
  if (!selectedCountry.value) {
    fields.push('Country');
  }
  if (!selectedState.value && states.value) {
    fields.push('State');
  }
  if (!selectedAddress.value) {
    fields.push('Address');
  }
  if (!selectedCity.value) {
    fields.push('City');
  }
  if (!(!shouldShowTaxId.value || taxId.value)) {
    fields.push(taxIdName.value);
  }
  return fields;
});

const paymentPayload = computed(() => {
  if (!selectedCountry.value) {
    return {};
  }
  return {
    country: selectedCountryCode.value,
    companyName: `${companyName.value} - ${nameOnCard.value}`, // This goes on the description field for our use
    name: companyName.value, // This goes on the invoice
    currency: currency.value,
    workspaceId: route.params.workspaceId,
    email: user.value.email,
    taxId: shouldShowTaxId.value && taxId.value,
    taxType: taxIdType.value,
    address: {
      country: selectedCountryCode.value,
      state: selectedState.value,
      city: selectedCity.value,
      line1: selectedAddress.value,
    },
    countryCode: selectedCountryCode.value,
  };
});

function getPriceString(price) {
  return defaultPriceString.value.replace(defaultPrice.value, price.toFixed(2));
}

function getRoundedPriceString(price) {
  return defaultPriceString.value.replace(defaultPrice.value, Math.round(price));
}

async function getCurrentPlan() {
  let currentPlan = 'unknown';
  try {
    const activePlans = await getActivePlans();
    currentPlan = activePlans.length ? activePlans[0] : currentPlan;
  } catch (error) {
    logger.warn(`Failed fetching current customer data from Stigg. Details: ${error.errorMessage || error}`, {
      service: 'upgrade-page',
    });
  }
  return currentPlan;
}

async function paymentSuccess(currentPlan) {
  const workspaceId = route.params.workspaceId;
  await store.dispatch('database/refreshWorkspaceLicense', { workspaceId });
  await fetchBillingData();
  const traits = {
    Plan: selectedProductSKU.value,
    'Plan Modified Date': new Date().toISOString(),
  };
  await analytics.cloudWorkspaceGroup({
    workspaceId: workspaceId,
    traits,
    userId: user.value.uid,
  });
  analytics.track(productEvents.UPGRADED_WORKSPACE, {
    'User Id': user.value.uid,
    'User Email': user.value.email,
    'Workspace ID': workspaceId.value,
    'Workspace Name': currentWorkspace.value.name,
    'Previous Plan': currentPlan,
    'New Plan': selectedProductSKU.value,
    'Plan Modified Date': new Date().toISOString(),
    Yearly: props.billingPeriod === BillingPeriod.Annually,
  });
  upgradeSuccess();
  paying.value = false;
}

async function paymentFailure(error) {
  logger.error(`Failed creating subscription. Details: ${error.errorMessage || error}`);

  const currentPlan = await getCurrentPlan();
  analytics.cloudTrack({
    identity: user.value.uid,
    event: productEvents.FAILED_UPGRADE,
    payload: {
      'User Id': user.value.uid,
      'User Email': user.value.email,
      'Upgrade Attempt Date': new Date().toISOString(),
      'Workspace ID': workspaceId.value,
      'Workspace Name': currentWorkspace.value.name,
      'Current Plan': currentPlan,
      'Target Plan': selectedProductSKU.value,
      Context: 'Billing',
    },
  });
  await swal({ title: 'Something Happened - Upgrade Failed', content: { element: SWAL_CONTACT_US_CONTENT() } });
  paying.value = false;
}

async function paymentProcessed({ id: paymentMethod }) {
  try {
    const currentPlan = await getCurrentPlan();
    const payload = {
      ...paymentPayload.value,
      paymentMethod,
      productSKU: selectedProductSKU.value,
      promotionCode: (!promoCodeError.value && promotionCode.value) || '',
      billingPeriod: props.billingPeriod,
      quantity: quantity.value,
    };
    const subscribeResult = await CloudFunctions.subscribeToPlan(payload);
    if (subscribeResult?.data?.status !== 'success') {
      throw new Error('failed creating subscription');
    }
    paymentSuccess(currentPlan);
  } catch (error) {
    paymentFailure(error);
  }
}

function paymentCardUpdated({ completed }) {
  isCreditCardValid.value = completed;
}

function upgradeSuccess() {
  emit('upgrade-success', selectedProductSKU.value);
}

function onUpgradeConfirm() {
  paying.value = true;
  cardField.value.submitCard();
}

onMounted(async () => {
  if (!LICENSES_SUPPORTING_SELF_SERVE_UPGRADE.includes(props.productSKU)) {
    emit('back');
  } else {
    if (route.query?.quantity) {
      // Round up to the nearest 5, max 30 - this is what the current teams plan allows
      const roundedQuantity = Math.round(Math.ceil(route.query?.quantity / 5)) * 5;
      prefilledQuantity.value = roundedQuantity > 30 ? 30 : roundedQuantity;
    }

    if (route.query?.country) {
      selectedCountryCode.value = route.query?.country;
      selectedCountry.value = {
        label: countryCodeToCountry[selectedCountryCode.value],
        code: selectedCountryCode.value,
      };
    }

    if (route.query?.code) {
      promotionCode.value = route.query?.code;
    }

    await calculatePrice();
  }
});

const CreditCardTextFieldAsync = defineAsyncComponent(() =>
  import('@/common/components/Billing/CreditCardTextField.vue')
);
</script>

<template>
  <SettingsModalSection title="Upgrade plan" show-back :disable-back="paying" @back="emit('back')">
    <div class="upgrade-plan-container">
      <form class="upgrade-form" @submit.prevent="onUpgradeConfirm">
        <SwText variant="subtitle-XL" class="section-title">Billing details</SwText>
        <div class="section dual-section">
          <TextField v-model="selectedAddress" label="Address" placeholder="Address" />
          <TextField v-model="selectedCity" label="City" placeholder="City" class="shorter-section" />
        </div>
        <div class="section dual-section">
          <div class="expand-item">
            <BaseLabel required>Country</BaseLabel>
            <v-select
              v-model="selectedCountry"
              class="field-margin"
              :options="countries"
              :clearable="false"
              :disabled="route?.query?.country"
            >
            </v-select>
          </div>
          <div v-if="selectedCountry && states" class="shorter-section">
            <BaseLabel required>State</BaseLabel>
            <v-select v-model="selectedState" class="field-margin" :options="states" :clearable="false" />
          </div>
        </div>
        <div class="section dual-section">
          <TextField v-model="companyName" required label="Company name" placeholder="Company name" />
          <TextField
            v-if="selectedCountry && shouldShowTaxId"
            v-model="taxId"
            required
            class="shorter-section"
            :label="taxIdName"
            :placeholder="taxIdName"
          />
        </div>
        <Divider class="section-divider" />
        <SwText variant="headline3" class="section-title">Card details</SwText>
        <div class="section">
          <TextField v-model="nameOnCard" required label="Name on card" placeholder="Name on card" />
        </div>
        <div class="section">
          <BaseLabel required>Card number</BaseLabel>
          <CreditCardTextFieldAsync
            v-if="publishableKey"
            ref="cardField"
            :publishable-key="publishableKey"
            @token-create-failure="paymentFailure"
            @token-created="paymentProcessed"
            @payment-card-updated="paymentCardUpdated"
          />
          <TextField v-else disabled required />
        </div>
        <Divider class="section-divider" />
        <div :class="['section', 'promo-section', { ['promo-error']: !!promoCodeError }]">
          <TextField
            v-model="promotionCode"
            label="Promo code"
            placeholder=""
            :disabled="!!promo"
            :error="promoCodeError"
          >
            <template #right v-if="!!promo">
              <Icon name="check" />
            </template>
          </TextField>
          <Action
            v-if="!promo"
            data-testid="add-promo-code"
            :loading="verifyingPromotion"
            @click.prevent="addPromo"
            size="small"
            secondary
            >Apply
          </Action>
          <Action v-if="promo" data-testid="remove-promo-code" @click.prevent="removePromo" size="small" secondary>
            Remove
          </Action>
        </div>
        <Checkbox v-model="isAcceptAgreement" size="small">
          <span class="checkbox-agreement-text" data-testid="confirm-checkbox"
            >By clicking “Upgrade” you acknowledge that you have read Swimm's
            <a class="link" :href="SWIMM_PRIVACY_POLICY_ADDRESS" target="_blank">Privacy Policy</a>
            and agree to Swimm's
            <a class="link" :href="SWIMM_TERMS_OF_SERVICE_ADDRESS" target="_blank"
              >Master Subscription and Professional Services Agreement</a
            >.</span
          >
        </Checkbox>
        <VMenu popper-class="tooltip-on-modal" placement="right" open-on-click>
          <Action class="upgrade-button" data-testid="confirm-upgrade-button" :loading="paying" :disabled="isDisabled">
            Confirm upgrade
          </Action>
          <template #popper>
            <div class="empty-list">
              The following fields are empty/ invalid:
              <ul class="list">
                <li v-for="field in emptyFields" :key="field">{{ field }}</li>
              </ul>
            </div>
          </template>
        </VMenu>
      </form>
      <div>
        <PlanCard :name="productSKU">
          <div class="price-calc">
            <div class="line-item">
              <div>
                <span class="total-users">
                  {{ payingForQuantity }}
                  {{
                    ` ${quantity !== payingForQuantity ? 'additional' : ''} ${pluralize({
                      word: 'seat',
                      count: payingForQuantity,
                    })}`
                  }}
                </span>
                <span v-if="!priceLoading" class="price-details" data-testid="checkout-base-price">
                  {{ `x ${getRoundedPriceString(pricePerUser)} / seat / ${isYearly ? 'year' : 'month'} ` }}
                </span>
                <TextSkeleton v-else component="span" variant="body-S" class="price-details" />
              </div>
              <span v-if="!priceLoading" class="total-price" data-testid="checkout-subtotal-price">
                {{ getPriceString(roundWithTwoDecimal(fullPrice)) }}
              </span>
              <TextSkeleton v-else component="span" variant="body-S" class="total-price" />
            </div>
            <div v-if="promo" class="line-item">
              <div>
                <span class="total-users">Promo code</span>
                <span class="price-details discount" data-testid="checkout-discount-rate">({{ discountMessage }})</span>
              </div>
              <span v-if="!priceLoading" class="total-price" data-testid="checkout-discount-amount">
                {{ `-${getPriceString(roundWithTwoDecimal(discountedAmount))}` }}
              </span>
              <TextSkeleton v-else component="span" variant="body-S" class="total-price" />
            </div>
          </div>
          <div v-for="tax in taxes" :key="tax.taxName" class="price-calc">
            <div class="line-item">
              <div>
                <span class="total-users">Tax</span>
                <span v-if="!priceLoading" class="price-details" data-testid="checkout-tax-rate">
                  {{ `${roundWithTwoDecimal(tax.taxRate)}% ${tax.taxName}` }}
                </span>
                <TextSkeleton v-else component="span" variant="body-S" class="price-details" />
              </div>
              <span v-if="!priceLoading" class="total-price" data-testid="checkout-tax-amount">
                {{ getPriceString(roundWithTwoDecimal(tax.taxAmount)) }}
              </span>
              <TextSkeleton v-else component="span" variant="body-S" class="total-price" />
            </div>
          </div>
          <Divider class="card-divider" />
          <SwText variant="headline2" class="subtotal">
            <span class="subtotal-label">Total</span>
            <span v-if="!priceLoading" class="subtotal-price" data-testid="checkout-final-price">{{
              getPriceString(totalPrice)
            }}</span>
            <TextSkeleton v-else component="span" variant="body-S" class="total-price" />
          </SwText>
          <div class="plan-card-agreement-description">
            <span v-if="isStandardOrPro"
              >Payment for the remaining time on your old subscription will be deducted from the total price (not shown
              here).
              <br />
              <br />
            </span>
            <span v-if="billingPeriod === BillingPeriod.Monthly">
              You have selected an annual subscription with 12 required monthly payments. Your subscription will extend
              until {{ commitmentDateString }}.
            </span>
            <br v-if="billingPeriod === BillingPeriod.Monthly || currency !== 'USD'" />
            <br v-if="billingPeriod === BillingPeriod.Monthly || currency !== 'USD'" />
            By upgrading, you authorize Swimm to modify your subscription immediately. You will be automatically charged
            on a monthly basis.
          </div>
        </PlanCard>
        <div class="help">
          For any questions contact <a class="support-email" href="mailto:support@swimm.io">support@swimm.io</a>
        </div>
      </div>
    </div>
  </SettingsModalSection>
</template>

<style scoped lang="postcss">
.upgrade-plan-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: var(--space-md);
}

.container {
  margin: 0 auto;
  width: 70%;
  max-width: 900px;
}

.page-header {
  margin: 36px 0;
  padding: 0;
  font-size: var(--headline1);
  text-align: center;
}

.upgrade-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
}

.payment-info-section {
  align-self: center;

  .credit-card {
    margin-bottom: var(--space-md);
  }

  .actions {
    display: flex;
    align-items: center;
    gap: var(--space-md);

    .cancel {
      cursor: pointer;
    }
  }
}

.upgrade-form {
  flex: 1;
  margin-right: 48px;

  .section-divider {
    margin: var(--space-md) 0 var(--space-sm);
  }
}

.checkbox-agreement-text {
  font-size: var(--body-XS);
  color: var(--text-color-secondary);
}

.plan-card-agreement-description {
  font-size: var(--body-XS);
  color: var(--text-color-secondary);
  margin-bottom: var(--space-sm);
}

.upgrade-button {
  margin-top: 24px;
  width: 100%;
  height: 32px;
}

.price-calc {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 16px;
}

.price-details {
  display: inline-block;
  font-size: var(--body-S);
  color: var(--text-color-secondary);
  min-width: 100px;
}

.subtotal {
  display: flex;
  justify-content: space-between;
  margin-bottom: var(--space-base);
}

.card-divider {
  margin-top: var(--space-base);
  margin-bottom: var(--space-sm);
}

.total-users {
  font-weight: 800;
}

.total-users:after {
  content: ' ';
}

.total-price {
  font-weight: 800;
  color: var(--text-color-secondary);
  min-width: 40px;
}

.help {
  margin: 8px 0;
  font-size: var(--body-S);
  text-align: center;
  color: var(--text-color-secondary);
}

.support-email {
  font-weight: bold;
  color: var(--text-color-link);
}

.expand-item {
  width: 100%;
}

.section {
  margin-bottom: 16px;
}

.shorter-section {
  margin-left: 10px;
  width: 60%;
}

.dual-section {
  display: flex;
}

.label {
  display: inline-block;
  margin-bottom: 12px;
  color: var(--text-color-primary);
}

.link:hover {
  text-decoration: underline;
}

.section-title {
  margin-bottom: var(--space-sm);
}

.line-item {
  display: flex;
  justify-content: space-between;
  word-break: normal;
}

.promo-section {
  display: flex;
  align-items: flex-end;

  .button {
    height: 30px;
    margin-left: 10px;
    margin-bottom: 1px;
  }
}

.promo-error {
  align-items: center;

  .button {
    margin-top: 5px;
  }
}

.warnning {
  color: var(--text-color-warning);
}

.discount {
  color: var(--success-green);
}

.empty-list {
  padding: var(--space-xsmall);
}
</style>

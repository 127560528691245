import AuthPage from '@/common/pages/AuthPage.vue';
import AccountReadyPage from '@/common/pages/onboarding/AccountReadyPage.vue';
import OnboardingWelcomePage from '@/common/pages/onboarding/OnboardingWelcomePage.vue';
import Cookies from 'js-cookie';
import RepoPage from '@/common/pages/RepoPage.vue';
import SharedDocsPage from '@/common/components/organisms/SharedDocsPage.vue';
import SharedDocPage from '@/modules/editor3/pages/SharedDocPage.vue';
import PlaylistStepResolver from '@/common/components/Resolver/PlaylistStepResolver.vue';
import HomePage from '@/modules/core/workspace/home/pages/HomePage.vue';
import EditPlaylistPage from '@/common/pages/EditPlaylistPage.vue';
import NewPlaylistPage from '@/modules/playlists/pages/NewPlaylistPage.vue';
import EditorPage from '@/modules/editor3/pages/EditorPage.vue';
import NewDocPage from '@/modules/editor3/pages/NewDocPage.vue';
import NewWorkspacePage from '@/common/pages/NewWorkspacePage.vue';
import PaymentSuccessfulPage from '@/common/pages/PaymentSuccessfulPage.vue';
import ForgotPage from '@/common/pages/ForgotPage.vue';
import AuthHandlerPage from '@/common/pages/AuthHandlerPage.vue';
import JoinWorkspacePage from '@/common/pages/JoinWorkspacePage.vue';
import CreateWorkspacePage from '@/common/pages/JoinWorkspace/CreateWorkspacePage.vue';
import RequestInvitePage from '@/common/pages/JoinWorkspace/RequestInvitePage.vue';
import LoginPage from '@/common/pages/LoginPage.vue';
import LogoutPage from '@/common/pages/LogoutPage.vue';
import NotFoundPage from '@/common/pages/NotFoundPage.vue';
import NoDbAccess from '@/common/pages/NoDbAccess.vue';
import MobileNotAllowedPage from '@/common/pages/MobileNotAllowedPage.vue';
import PlaylistPage from '@/common/pages/PlaylistPage.vue';
import InteractiveOnboardingPage from '@/common/pages/onboarding/InteractiveOnboardingPage.vue';
import RegisterPage from '@/common/pages/RegisterPage.vue';
import RepoWorkspacePage from '@/common/pages/RepoWorkspacePage.vue';
import SetGitToken from '@/common/pages/SetGitToken.vue';
import LocalAuthentication from '@/common/pages/LocalAuthentication.vue';
import GitHostingNotSupported from '@/common/pages/GitHostingNotSupported.vue';
import PaywallPage from '@/modules/paywall/pages/PaywallPage.vue';
import SSOLoginPage from '@/common/pages/SSOLoginPage.vue';
import SSOLogoutPage from '@/common/pages/SSOLogoutPage.vue';
import SetOktaToken from '@/common/pages/SetOktaToken.vue';
import SetOIDCToken from '@/common/pages/SetOIDCToken.vue';
import { config, getLoggerNew, objectUtils, settingsTypes, workspaceSettingsTabs } from '@swimm/shared';
import { SOURCE_PROPERTIES_PARAMS } from '@/common/utils/common-definitions';
import { connectUserToSlack } from '@/modules/slack-app/services/slack-app-utils';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import {
  HomePageRouteNames,
  NoBranchRoutePrefix,
  NoWorkspaceRoutePrefix,
  PageRoutesNames,
  RepoPageRouteNames,
  localStorageKeys,
} from '@/common/consts';
import LocalStorage from '@/local-storage';
import IDELoginPage from '@/common/pages/IDELoginPage.vue';
import { useHelpCenterStore } from '@/modules/help-center/stores/helpCenter';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import mobile from 'is-mobile';
import SessionRevokedLogoutPage from '@/common/pages/SessionRevokedLogoutPage.vue';
import { isTokenRevoked } from '@/common/utils/authUtils';
import TemplateEditorPage from '@/modules/editor3/pages/TemplateEditorPage.vue';

const logger = getLoggerNew(__modulename);

const storeQueryParamsOnLocalStorage = (query) => {
  try {
    const utmQueryFromStorageString = Cookies.get('utmQuery');
    if (utmQueryFromStorageString) {
      return;
    }
    const utmQuery = {};
    SOURCE_PROPERTIES_PARAMS.forEach((queryKey) => {
      const queryValue = query[queryKey];
      if (queryValue) {
        utmQuery[queryKey] = queryValue;
      }
    });
    if (objectUtils.isEmpty(utmQuery)) {
      return;
    }
    Cookies.set('utmQuery', JSON.stringify({ ...utmQuery }));
  } catch (e) {
    logger.warn('Storing query params to LocalStorage failed', e);
  }
};

const playlistRoutes = (routePrefix, namePrefix) => [
  {
    path: `${routePrefix}playlists/new`,
    component: RepoWorkspacePage,
    meta: { requiresAuth: true },
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      repoId: route.params.repoId,
      branch: route.params.branch,
    }),
    children: [
      {
        path: '',
        component: NewPlaylistPage,
        name: namePrefix + PageRoutesNames.PLAYLIST_NEW,
        props: (route) => ({
          repoId: route.params.repoId,
          workspaceId: route.params.workspaceId,
          branch: route.params.branch,
          folderId: route.query.folderId,
        }),
      },
    ],
  },
  {
    path: `${routePrefix}playlists/:playlistId/edit`,
    component: RepoWorkspacePage,
    meta: { requiresAuth: true },
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      repoId: route.params.repoId,
      playlistId: route.params.playlistId,
      branch: route.params.branch,
    }),
    children: [
      {
        path: '',
        component: EditPlaylistPage,
        name: namePrefix + PageRoutesNames.PLAYLIST_EDIT,
        props: (route) => ({
          repoId: route.params.repoId,
          workspaceId: route.params.workspaceId,
          branch: route.params.branch,
        }),
      },
    ],
  },
  {
    path: `${routePrefix}playlists/:playlistId`,
    component: RepoWorkspacePage,
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      repoId: route.params.repoId,
      branch: route.params.branch,
    }),
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        component: PlaylistPage,
        name: namePrefix + PageRoutesNames.PLAYLIST_VIEW,
        props: (route) => ({
          workspaceId: route.params.workspaceId,
          repoId: route.params.repoId,
          branch: route.params.branch,
        }),
        children: [
          {
            path: 'steps/:stepIndex',
            component: PlaylistStepResolver,
            props: (route) => ({ stepIndex: route.params.stepIndex, key: route.params.stepIndex }),
          },
          {
            component: PlaylistStepResolver,
            path: 'summary',
          },
        ],
      },
    ],
  },
];

const docRoutes = (routePrefix, namePrefix) => {
  return [
    {
      path: `${routePrefix}docs`,
      component: RepoWorkspacePage,
      meta: { requiresAuth: true },
      props: (route) => ({
        workspaceId: route.params.workspaceId,
        repoId: route.params.repoId,
        branch: route.params.branch,
      }),
      children: [
        {
          path: ':unitId',
          // TODO This is a weird way to build route names
          name: namePrefix + PageRoutesNames.DOC_VIEW,
          component: EditorPage,
          props: (route) => ({
            editable: false,
            workspaceId: route.params.workspaceId,
            repoId: route.params.repoId,
            unitId: route.params.unitId,
            branch: route.params.branch,
            source: route.query?.source,
          }),
        },
        {
          path: ':unitId/edit',
          // TODO This is a weird way to build route names
          name: namePrefix + PageRoutesNames.DOC_EDIT,
          component: EditorPage,
          props: (route) => ({
            editable: true,
            workspaceId: route.params.workspaceId,
            repoId: route.params.repoId,
            unitId: route.params.unitId,
            branch: route.params.branch,
            source: route.query?.source,
          }),
        },
        {
          path: ':unitId/comitted',
          // TODO This is a weird way to build route names
          name: namePrefix + 'Comitted Document',
          component: EditorPage,
          props: (route) => ({
            editable: false,
            workspaceId: route.params.workspaceId,
            repoId: route.params.repoId,
            unitId: route.params.unitId,
            branch: route.params.branch,
            committed: true,
            source: route.query?.source,
          }),
        },
        {
          path: 'new',
          // TODO This is a weird way to build route names
          name: namePrefix + PageRoutesNames.DOC_NEW,
          component: NewDocPage,
          props: (route) => ({
            workspaceId: route.params.workspaceId,
            repoId: route.params.repoId,
            branch: route.params.branch,
            folderId: route.query.folderId,
            template: route.query.template,
            unitToDuplicateId: route.query.unitToDuplicateId,
          }),
        },
      ],
    },
  ];
};

const customTemplatesRoutes = (routePrefix, namePrefix) => [
  {
    path: `${routePrefix}templates`,
    component: RepoWorkspacePage, // TODO: replace?
    meta: { requiresAuth: true },
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      repoId: route.params.repoId,
      branch: route.params.branch,
      hash: route.hash,
    }),
    children: [
      {
        path: ':templateId',
        // TODO This is a weird way to build route names
        name: namePrefix + PageRoutesNames.CUSTOM_TEMPLATE,
        component: TemplateEditorPage,
        props: (route) => ({
          workspaceId: route.params.workspaceId,
          repoId: route.params.repoId,
          templateId: route.params.templateId,
          branch: route.params.branch,
        }),
      },
    ],
  },
];

const repoPageRoutes = (namePrefix) =>
  [
    {
      path: '',
      name: `${namePrefix}${RepoPageRouteNames.DOCUMENTATIONS}`,
      component: RepoPage,
      props: (route) => ({
        currentRoute: RepoPageRouteNames.DOCUMENTATIONS,
        modalToOpenOnMount: route.query.modal,
        modalTabOnMount: route.query.tab,
        repoId: route.params.repoId,
        workspaceId: route.params.workspaceId,
        branch: route.params.branch,
      }),
    },
    {
      path: RepoPageRouteNames.PULL_REQUEST,
      component: RepoPage,
      props: (route) => ({
        currentRoute: RepoPageRouteNames.PULL_REQUEST,
        repoId: route.params.repoId,
        workspaceId: route.params.workspaceId,
        branch: route.params.branch,
      }),
    },
    {
      path: RepoPageRouteNames.NEEDS_REVIEW,
      component: RepoPage,
      props: (route) => ({
        currentRoute: RepoPageRouteNames.NEEDS_REVIEW,
        repoId: route.params.repoId,
        workspaceId: route.params.workspaceId,
        branch: route.params.branch,
        source: route.query.source,
      }),
    },
    {
      path: RepoPageRouteNames.DOC_REQUESTS,
      component: RepoPage,
      props: (route) => ({
        currentRoute: RepoPageRouteNames.DOC_REQUESTS,
        repoId: route.params.repoId,
        workspaceId: route.params.workspaceId,
        branch: route.params.branch,
      }),
      meta: { hideForLaunch: true },
    },
  ].filter((route) => !route.meta?.hideForLaunch);

const homePageRoutes = [
  {
    path: '',
    component: HomePage,
    name: 'Home_Page',
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      currentRoute: HomePageRouteNames.SUMMARY,
      // open-invites is the old behavior for opening old invites modal
      modalToOpenOnMount: route.query.modal || (route.query['open-invites'] ? settingsTypes.WORKSPACE : ''),
      modalTabOnMount: route.query.tab || (route.query['open-invites'] ? workspaceSettingsTabs.MEMBERS : ''),
    }),
  },
  {
    path: 'statistics',
    component: HomePage,
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      currentRoute: HomePageRouteNames.STATISTICS,
    }),
    meta: { hideForLaunch: true },
  },
  {
    path: 'paywall',
    component: PaywallPage,
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      currentRoute: HomePageRouteNames.PAYWALL,
      src: route.query.src,
    }),
  },
].filter((route) => !route.meta?.hideForLaunch);

const repoRoutes = (routePrefix, namePrefix) =>
  [
    {
      path: `${routePrefix}repos/:repoId`,
      component: RepoWorkspacePage,
      meta: { requiresAuth: true },
      props: (route) => ({
        workspaceId: route.params.workspaceId,
        repoId: route.params.repoId,
      }),
      children: repoPageRoutes(namePrefix + NoBranchRoutePrefix),
    },
    {
      path: `${routePrefix}repos/:repoId/branch/:branch`,
      component: RepoWorkspacePage,
      name: namePrefix + PageRoutesNames.REPO_PAGE,
      meta: { requiresAuth: true },
      props: (route) => ({
        workspaceId: route.params.workspaceId,
        repoId: route.params.repoId,
        branch: route.params.branch,
      }),
      children: repoPageRoutes(namePrefix),
    },
    // TODO: support displaying a relative file from a link in a relative file
    // { path: `${routePrefix}repos/:repoId/file`, component: MarkdownFilePage, meta: { requiresAuth: false } },
  ]
    .concat(playlistRoutes(`${routePrefix}repos/:repoId/branch/:branch/`, namePrefix + ''))
    .concat(playlistRoutes(`${routePrefix}repos/:repoId/`, namePrefix + NoBranchRoutePrefix))
    .concat(docRoutes(`${routePrefix}repos/:repoId/branch/:branch/`, namePrefix + ''))
    .concat(docRoutes(`${routePrefix}repos/:repoId/`, namePrefix + NoBranchRoutePrefix))
    .concat(customTemplatesRoutes(`${routePrefix}repos/:repoId/branch/:branch/`, namePrefix + ''))
    .concat(customTemplatesRoutes(`${routePrefix}repos/:repoId/`, namePrefix + NoBranchRoutePrefix));

const workspaceRoutes = () =>
  [
    { path: '/workspaces', component: RepoWorkspacePage, meta: { requiresAuth: true } },
    { path: '/workspaces/new', component: NewWorkspacePage, meta: { requiresAuth: true } },
    {
      path: '/workspaces/create',
      component: CreateWorkspacePage,
      name: PageRoutesNames.CREATE_WORKSPACE,
      meta: { allowedOnMobile: true, requiresAuth: true },
    },
    {
      path: '/workspaces/initRepo/repos/:repoId',
      component: RepoWorkspacePage,
      meta: { requiresAuth: true },
      props: (route) => ({
        repoId: route.params.repoId,
      }),
      children: [
        {
          path: '',
          component: RepoPage,
          props: (route) => ({
            currentRoute: RepoPageRouteNames.DOCUMENTATIONS,
            repoId: route.params.repoId,
            workspaceId: route.params.workspaceId,
          }),
        },
      ],
    },
    {
      path: '/workspaces/:workspaceId/request',
      component: RequestInvitePage,
      name: PageRoutesNames.REQUEST_INVITE_PAGE,
      meta: { requiresAuth: true, allowedOnMobile: true },
      props: (route) => ({ workspaceId: route.params.workspaceId }),
    },
    {
      path: '/workspaces/:workspaceId',
      component: RepoWorkspacePage,
      name: PageRoutesNames.HOME_PAGE,
      meta: { requiresAuth: true },
      props: (route) => ({
        workspaceId: route.params.workspaceId,
        modalToOpenOnMount: route.query.modal,
        modalTabOnMount: route.query.tab,
        showSlackConnectErrorOnMount: route.query.error === config.SLACK_INDICATOR,
        redirectLinkOnMount: route.query.redirect,
      }),
      children: homePageRoutes,
    },
    {
      path: '/welcome',
      name: PageRoutesNames.ONBOARDING_WELCOME,
      component: OnboardingWelcomePage,
      meta: { allowedOnMobile: true, requiresAuth: true },
    },
    {
      path: '/onboarding',
      name: PageRoutesNames.ONBOARDING,
      component: InteractiveOnboardingPage,
      meta: { allowedOnMobile: true, requiresAuth: true },
    },
    {
      path: '/workspaces/:workspaceId/welcome',
      name: PageRoutesNames.ACCOUNT_READY,
      component: AccountReadyPage,
      meta: { allowedOnMobile: true, requiresAuth: true },
    },
    {
      path: '/workspaces/:workspaceId/shared-docs',
      component: RepoWorkspacePage,
      name: PageRoutesNames.SHARED_DOCS_PAGE,
      meta: { requiresAuth: true },
      props: (route) => ({ workspaceId: route.params.workspaceId }),
      children: [
        {
          path: '',
          name: 'Shared Docs',
          component: SharedDocsPage,
          props: (route) => ({
            workspaceId: route.params.workspaceId,
          }),
        },
      ],
    },
    {
      path: '/workspaces/:workspaceId/shared-docs/:sharedDocId',
      component: RepoWorkspacePage,
      name: PageRoutesNames.SHARED_DOC_PAGE,
      meta: { requiresAuth: true },
      props: (route) => ({ workspaceId: route.params.workspaceId }),
      children: [
        {
          path: '',
          name: 'Shared Doc',
          component: SharedDocPage,
          props: (route) => ({
            workspaceId: route.params.workspaceId,
            sharedDocId: route.params.sharedDocId,
          }),
        },
      ],
    },
    {
      path: '/workspaces/:workspaceId/billing/success',
      component: PaymentSuccessfulPage,
      name: 'PaymentSuccessfulPage',
      meta: { requiresAuth: true },
      props: (route) => ({ workspaceId: route.params.workspaceId, productSKU: route.query.productSKU }),
    },
  ].concat(repoRoutes('/workspaces/:workspaceId/', ''));

export const routes = [
  {
    path: '/',
    component: RepoWorkspacePage,
    meta: { requiresAuth: true },
    props: (route) => ({
      modalToOpenOnMount: route.query.modal,
      modalTabOnMount: route.query.tab,
    }),
  },
  {
    path: '/',
    component: AuthPage,
    children: [
      {
        path: '/register',
        component: RegisterPage,
        meta: { allowedOnMobile: true },
        props: (route) => ({
          isFromGHMarketplace: route.query.source === config.GH_MARKETPLACE_INDICATOR,
          ghName: route.query.name,
          ghEmail: route.query.email,
          ghLogin: route.query.login,
        }),
      },
      {
        path: '/login',
        component: LoginPage,
        meta: { allowedOnMobile: true },
        props: (route) => ({ isFromSlack: route.query.source === config.SLACK_INDICATOR }),
      },
      { path: '/logout', component: LogoutPage, meta: { allowedOnMobile: true } },
      { path: '/forgot', component: ForgotPage, meta: { allowedOnMobile: true } },
      { path: '/auth', component: AuthHandlerPage, meta: { allowedOnMobile: true } },
      { path: '/sso', component: SSOLoginPage, name: PageRoutesNames.SSO, meta: { allowedOnMobile: true } },
      {
        path: '/setOktaToken',
        component: SetOktaToken,
        name: PageRoutesNames.SetOktaToken,
        meta: { allowedOnMobile: true },
      },
      {
        path: '/setOIDCToken',
        component: SetOIDCToken,
        name: PageRoutesNames.SetOIDCToken,
        meta: { allowedOnMobile: true },
      },
      {
        path: '/ssologout',
        component: SSOLogoutPage,
        meta: { allowedOnMobile: true },
      },
      {
        path: '/session-ended',
        component: SessionRevokedLogoutPage,
        meta: { allowedOnMobile: true },
      },
    ],
  },
  {
    path: '/ide-login',
    component: IDELoginPage,
    meta: { requiresAuth: true },
    props: (route) => ({ ide: route.query.ide }),
  },
  { path: '/setGitToken', component: SetGitToken },
  { path: '/localGitProviderAuth', component: LocalAuthentication },
  { path: '/404', component: NotFoundPage, meta: { allowedOnMobile: true } },
  { path: '/mobile', component: MobileNotAllowedPage, meta: { allowedOnMobile: true } },
  {
    path: '/git-hosting-not-supported/:gitHosting?',
    component: GitHostingNotSupported,
    meta: { allowedOnMobile: true },
    props: (route) => ({ gitHosting: route.params.gitHosting }),
  },
  {
    path: '/joinWorkspace/:workspaceId?',
    component: JoinWorkspacePage,
    name: PageRoutesNames.JOIN_WORKSPACE,
    meta: { requiresAuth: true, allowedOnMobile: true },
    props: (route) => ({ workspaceId: route.params.workspaceId }),
  },
  {
    path: '/joinWorkspaceByRepo/:repoId',
    component: JoinWorkspacePage,
    meta: { requiresAuth: true, allowedOnMobile: true },
    props: (route) => ({ repoWorkspacesToShow: route.params.repoId }),
  },
  { path: '/noAccess', component: NoDbAccess, name: PageRoutesNames.NO_ACCESS },
  {
    path: '/embed/workspaces/:workspaceId/repos/:repoId/branch/:branch/docs/:unitId',
    component: RepoWorkspacePage,
    meta: { requiresAuth: true },
    props: (route) => ({
      workspaceId: route.params.workspaceId,
      repoId: route.params.repoId,
      branch: route.params.branch,
      embedded: true,
    }),
    children: [
      {
        path: '',
        component: EditorPage,
        props: (route) => ({
          editable: false,
          embedded: true,
          workspaceId: route.params.workspaceId,
          repoId: route.params.repoId,
          unitId: route.params.unitId,
          branch: route.params.branch,
        }),
      },
    ],
  },
  {
    path: '/embed/workspaces/:workspaceId/shared-docs/:sharedDocId',
    component: RepoWorkspacePage,
    meta: { requiresAuth: true },
    props: (route) => ({ workspaceId: route.params.workspaceId, embedded: true }),
    children: [
      {
        path: '',
        component: SharedDocPage,
        props: (route) => ({
          workspaceId: route.params.workspaceId,
          sharedDocId: route.params.sharedDocId,
          embedded: true,
        }),
      },
    ],
  },
  { path: '/:pathMatch(.*)*', component: NotFoundPage },
]
  .concat(repoRoutes('/', NoWorkspaceRoutePrefix))
  .concat(workspaceRoutes());

const router = createRouter({
  history: createWebHistory(),

  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },

  routes,
});

router.beforeEach(async (to, from, next) => {
  store.dispatch('storePreviousRoute', from.fullPath);
  storeQueryParamsOnLocalStorage(to.query);

  const { toggleHelpCenter } = useHelpCenterStore();
  const { observeAuthChanges } = useAuthStore();
  const { closeModals } = useAppModalsStore();

  toggleHelpCenter(false);
  closeModals({ fromRouter: true });
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const user = firebase.hasSubscribedAuth ? firebase.auth().currentUser : await observeAuthChanges();

  const isMobile = mobile();

  if (requiresAuth && !user) {
    if (isMobile) {
      return next({ path: '/mobile', query: copyQueryForRedirect(to.query) });
    }
    const path = shouldRedirectToLogin(to) ? '/login' : '/register';
    const redirect = to.fullPath === '/' ? undefined : to.fullPath; // do not add redirect to root if no real fullPath
    const query = { redirect, email: to.query.email, ...copyQueryForRedirect(to.query) };
    next({ path, query });
  } else if (shouldPassRedirect({ requiresAuth, from, to })) {
    next({ path: to.path, query: { ...copyQueryForRedirect(to.query), redirect: from.query.redirect } });
  } else if (user && isAuthPath(to.path)) {
    if (to.query.source === config.SLACK_INDICATOR) {
      const success = await connectUserToSlack({
        userUid: user.uid,
        slackTeamId: to.query.team,
        slackUserId: to.query.user,
      });
      if (!success) {
        to.query['error'] = config.SLACK_INDICATOR;
      } else {
        LocalStorage.set(localStorageKeys.SLACK_TOAST_UNSEEN, true);
      }
    }
    next({ path: '/', query: copyQueryForRedirect(to.query) });
  } else if (requiresAuth && user && (await isTokenRevoked())) {
    const path = '/session-ended';
    const redirect = to.fullPath === '/' ? undefined : to.fullPath; // do not add redirect to root if no real fullPath
    const query = { redirect, email: to.query.email, ...copyQueryForRedirect(to.query) };
    next({ path, query });
  } else {
    const allowedOnMobile = to.matched.some((record) => record.meta.allowedOnMobile);
    if (isMobile && !allowedOnMobile) {
      return next({ path: '/mobile', query: copyQueryForRedirect(to.query) });
    }
    // Prevent going back to workspace creation page from a route that has workspaceId
    if (Boolean(from.params.workspaceId) && !from.path.includes('/request') && to.path.includes('workspaces/create')) {
      return next(false);
    }
    next();
  }
});

// Pass redirect query param if moving to a page that don't require auth and redirect is not set yet
const shouldPassRedirect = ({ requiresAuth, from, to }) => {
  return !requiresAuth && from.query.redirect && !to.query.redirect;
};

/**
 *
 * @param query query object of route
 * @returns new query object with keys we want to pass when redirecting
 */
function copyQueryForRedirect(query) {
  const keys = [
    'utm_source',
    'utm_campaign',
    'utm_medium',
    'utm_content',
    'source',
    'feature',
    'error',
    'modal',
    'tab',
    'redirect',
    'workspaceName',
  ];
  return Object.fromEntries(Object.entries(query || {}).filter(([k]) => keys.includes(k)));
}

function isAuthPath(path) {
  return ['/register', '/login', '/forgot', '/auth'].includes(path);
}

function shouldRedirectToLogin({ params, path }) {
  const { workspaceId, repoId } = params;
  return ((workspaceId || repoId) && !path.includes('joinWorkspace')) || path.includes('ide-login');
}

export function getFullRouteName(name, { noWorkspace = false, noBranch = false }) {
  return [noWorkspace ? NoWorkspaceRoutePrefix : '', noBranch ? NoBranchRoutePrefix : '', name].join('');
}

export default router;

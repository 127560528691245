import {
  type GenerateSnippetCommentRequest,
  GenerativeAiRequestType,
  type LlmCallGenericRequest,
  type Prompt,
} from '@swimm/shared';
import { v4 as uuidv4 } from 'uuid';

export function getGenerateSnippetCommentPrompt(request: GenerateSnippetCommentRequest): Prompt {
  return {
    messages: [
      {
        role: 'system',
        content: `You are a developer who hates long texts, and thinks code should speak for itself, and documentation should only point out the 'why'.
You also despise fluffy sentences like "X is crucial for a seamless operation of the system" - instead you describe what things do exactly and not just say they are crucial.

You are explaining a code snippet as part of a document about the code. Generate text explaining what the snippet does at a high level, without describing it word for word.

Reply in JSON format, with ""s:
{"error"?: string; "generatedText"?: string}

Example
Context: "  if (result.status === 'error' || !apiResponse?.text) {\n    logger.error({ cloudRun, result }, \`Calling cloudrun {cloudRun} returned an error\`);\n    throw new Error('Calling cloudrun returned an error');..."

Bad response: "Here we check if there was an error in the \`result\` and log appropriately."
Why response is bad: The response did not follow the rules for generating a snippet comment. The response should be a JSON object with the keys "error" and "generatedText". The "generatedText" key should contain the generated text.

Good response: { "error": null, "generatedText": "Here we check if there was an error in the \`result\` and log appropriately." }
`,
      },
      {
        role: 'user',
        content: `${request.snippetContent}

Please provide a VERY short description of the code above, describing its main functionality. Never relate to error statuses or codes. Never list function arguments or describe the types of arguments. Start your response with the words "Here we". When relevant, include elements from the code in your response and wrap them with backticks.
`,
      },
    ],
    options: {
      max_tokens: 1000,
      temperature: 0.7,
      top_p: 1,
      n: 1,
    },
    workspaceId: request.workspaceId,
  };
}

export function getGenerateSnippetCommentRequestParams(request: GenerateSnippetCommentRequest): LlmCallGenericRequest {
  return {
    prompt: getGenerateSnippetCommentPrompt(request),
    type: GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT,
    workspaceId: request.workspaceId,
    requestId: `${GenerativeAiRequestType.GENERATE_SNIPPET_COMMENT}-${uuidv4()}`,
  };
}

// Parse curley braces
import type MarkdownIt from 'markdown-it';
import type StateInline from 'markdown-it/lib/rules_inline/state_inline';

// Matches placeholders in the form of {{ placeholder }} which we will want to replace with smart tokens
// Placeholder formats can be `{{ objectName.attributeName }}` or `{{ objectName.resourceType.resourceAttribute }}` or `{{ system-requirements }}`
export const PLACEHOLDER_TEXT_CONTENT_MATCHER =
  /^(?<optional>\??)\s?(?<placeholderAttributes>([\w-]+)((?:[.]\w+)*))\s?$/;

export const PLACEHOLDER_TEXT_SYNTAX_MATCHER =
  /{{(?<optional>\??)\s?(?<placeholderAttributes>([\w-]+)((?:[.]\w+)*))\s?}}/;

function transformSwmTemplateInline(state: StateInline, silent: boolean) {
  if (state.env?.swimm?.type !== 'template') {
    return false;
  }

  let pos = state.pos;
  const ch = state.src.charCodeAt(pos);

  if (ch !== 0x7b /* { */) {
    return false;
  }

  const start = pos;
  pos++;
  const max = state.posMax;

  // scan marker length
  while (pos < max && state.src.charCodeAt(pos) === 0x7b /* { */) {
    pos++;
  }

  const marker = state.src.slice(start, pos);
  const openerLength = marker.length;

  if (openerLength !== 2) {
    return false;
  }

  let matchEnd = pos;
  let matchStart;

  // Scan until the end of the line (or until marker is found)
  while ((matchStart = state.src.indexOf('}', matchEnd)) !== -1) {
    matchEnd = matchStart + 1;

    // scan marker length
    while (matchEnd < max && state.src.charCodeAt(matchEnd) === 0x7d /* } */) {
      matchEnd++;
    }

    const closerLength = matchEnd - matchStart;

    if (closerLength === openerLength) {
      // Found matching closer length.
      const content = state.src.substring(pos, matchStart);
      const all = state.src.substring(start, matchEnd);
      if (!all.match(PLACEHOLDER_TEXT_SYNTAX_MATCHER)) {
        return false;
      }

      if (!silent) {
        const token = state.push('swm_template_inline', '', 0);
        token.markup = marker;
        token.content = state.src.slice(pos, matchStart);

        if (content.startsWith('?')) {
          token.attrSet('optional', 'true');
        }
      }
      state.pos = matchEnd;
      return true;
    }
  }

  return false;
}

export default function swm_template_inline(md: MarkdownIt): void {
  md.inline.ruler.before('text', 'swm_template_inline', transformSwmTemplateInline);
}

import { OctokitOptions } from '@octokit/core/dist-types/types.d';
import { GraphqlResponseError } from '@octokit/graphql';
import { throttling } from '@octokit/plugin-throttling';
import { Octokit } from '@octokit/rest';
import type { GetResponseTypeFromEndpointMethod } from '@octokit/types';
import { Buffer } from 'buffer';
import { StatusCodes } from 'http-status-codes';
import Cache from 'lru-cache';
import * as config from '../../config';
import { DUMMY_REPO_ID } from '../../demoData';
import { getLoggerNew } from '#logger';
import { stringifyLogParams } from '../../logger/logger-utils';
import { isEmpty } from '../../objectUtils';
import type { ResultWithReturnCode, SwmResourceFile } from '../../types';
import { GitProviderName, PathType } from '../../types';
import { b64decodeAndRemoveCR, b64encodeString } from '../../utils/string-utils';
import { UrlUtils } from '../../utils/url-utils';
import { DUMMY_REPO_TOKEN } from '../consts';
import gitProviderUtils from '../git-provider-utils';
import { changeRequestsPendingDocsReducer, swmResourceFileToGitHubFileChanges } from '../git-utils-common';
import type {
  Branch,
  CreatedPR,
  DiffFileMetadata,
  DriverOptions,
  PartialRemoteRepository,
  PrData,
  PullRequestState,
  RemoteRepository,
  RepoIdOrRepoData,
  RepoIdOwnerName,
  githubChangeStatus,
} from './git-provider-base';
import { GitDriverBase, PendingPR, ProviderSpecificTerms } from './git-provider-base';
import { GitProviderRateLimitError } from '../../types/swimm-errors';

const MAX_ITEMS_TO_FETCH = 100;
const GITHUB_CLOUD_HOSTNAME = 'github_com';
const logger = getLoggerNew("packages/shared/src/git-utils/gitdrivers/github-driver.ts");

const OctokitWithPlugins = Octokit.plugin(throttling);

// This pattern is taken directly from `@octokit/types`'s documentation. Use only for typing purposes!
const typeOctokit = new Octokit();

const throttle = {
  onRateLimit: (retryAfter, options: OctokitOptions, octokit) => {
    octokit.log.error(`GitHub Rate Limit reached for request (${options.method} ${options.url})`);
    return false; // Tell the plugin to throw an exception, rather than retry the request
  },
  onSecondaryRateLimit: (retryAfter, options: OctokitOptions, octokit) => {
    octokit.log.error(`GitHub SecondaryRateLimit reached for request ${options.method} ${options.url}`);
    return false; // Tell the plugin to throw an exception, rather than retry the request
  },
};

type FetchBlobContentReturnType = GetResponseTypeFromEndpointMethod<typeof typeOctokit.rest.git.getBlob> | null;
type FetchFileContentReturnType = GetResponseTypeFromEndpointMethod<typeof typeOctokit.rest.repos.getContent> | null;

// Parameters for the fetch-cache.
const FETCH_FILE_CACHE_TTL_MS = 20000;

interface OctokitRateLimitError extends Error {
  data: {
    errors: Array<{
      message: string;
      type: string;
    }>;
  };
}
interface PageInfo {
  endCursor: string;
  hasNextPage: boolean;
}

interface RequestedBranchNode {
  node: {
    name: string;
    associatedPullRequests?: {
      totalCount: number;
      nodes: [
        {
          isDraft: boolean;
        }
      ];
    };
    refUpdateRule?: {
      pattern: string;
    };
    target?: {
      authoredDate: string;
      oid: string;
    };
  };
}

const pullRequestGraphql = `
  files(first: ${MAX_ITEMS_TO_FETCH}) {
    nodes {
      additions
      path
    }
  }
  number
  state
  additions
  createdAt
  updatedAt
  url
  title
  body
  headRefOid
  baseRefOid
  headRefName
  author{
    login
  }`;

interface PullRequestGraphql {
  files: {
    nodes: {
      path: string;
      additions: number;
    }[];
  };
  number: number;
  state: 'OPEN' | 'CLOSED' | 'MERGED';
  additions: number;
  createdAt: string;
  updatedAt: string;
  url: string;
  title: string;
  body: string;
  headRefOid: string;
  baseRefOid: string;
  headRefName: string;
  author: { login: string };
}

type RepoRemoteDataBatchResponse = Record<
  string,
  {
    name: string;
    owner: {
      login: string;
    };
    defaultBranchRef: {
      name: string;
    } | null;
  }
>;

export class GitHubDriver extends ProviderSpecificTerms implements GitDriverBase {
  private fetchBlobContentFromGitHubCache: Map<string, Awaited<ReturnType<typeof this.fetchBlobContentFromGithub>>> =
    new Map();
  private isBranchExistsCache: Map<string, Awaited<ReturnType<typeof this.isBranchExists>>> = new Map();

  private baseUrl;
  private enterprise;
  private authToken;
  private owner;
  private repoName;
  private publicAccessGitHubRepos = [config.SWIMM_TEMPLATES_REPO_DATA.repoId, DUMMY_REPO_ID];

  public constructor({ baseUrl = null, authToken = null }: DriverOptions = {}) {
    super();
    if (baseUrl) {
      this.baseUrl = baseUrl;
      this.enterprise = baseUrl !== gitProviderUtils.DEFAULT_GITHUB_API;
    } else {
      // Set the default url until we get it from the state
      this.baseUrl = gitProviderUtils.DEFAULT_GITHUB_API;
    }
    this.authToken = authToken;
    this.owner = null;
    this.repoName = null;
  }

  /**
   * @deprecated Do not use in web app. Set these values to avoid searching values in state.
   */
  public setRepoDetails(owner: string, repoName: string) {
    this.owner = owner;
    this.repoName = repoName;
  }

  private async getDetails(repoId: string): Promise<{ owner: string; repoName: string }> {
    if (this.owner && this.repoName) {
      return {
        owner: this.owner,
        repoName: this.repoName,
      };
    }

    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    return {
      owner: repoData.owner,
      repoName: repoData.repoName,
    };
  }

  protected async getAuthToken(repoId?: string): Promise<string | null> {
    const hostname = (
      this.baseUrl !== gitProviderUtils.DEFAULT_GITHUB_API
        ? UrlUtils.getUrlHostname(this.baseUrl)
        : GITHUB_CLOUD_HOSTNAME
    ) as string;
    if (!this.authToken) {
      this.authToken = await gitProviderUtils.getGitHostingToken(hostname);
    }

    const isPublicAccess = !!repoId && this.publicAccessGitHubRepos.includes(repoId);

    if (!this.authToken && !isPublicAccess) {
      const err = 'Initializing octokit failed for no token found.';
      logger.error({ err });
      throw new Error(err);
    }

    // Force token to be null to access public repos outside GitHub.com anonymously
    if (isPublicAccess && this.baseUrl !== gitProviderUtils.DEFAULT_GITHUB_API) {
      this.authToken = null;
    }

    return this.authToken;
  }

  /**
   * Returns an octokit-rest instance initialized with GH auth token
   */
  protected async getOctokitInstanceWithAuth({ repoId }: { repoId?: string }) {
    const token = await this.getAuthToken(repoId);

    return new OctokitWithPlugins({
      baseUrl: this.baseUrl,
      auth: token,
      log: {
        debug: function () {
          // Muted
        },
        info: function () {
          // Muted
        },
        warn: function (message) {
          logger.warn(message);
        },
        error: function (message) {
          logger.error(message);
        },
      },
      throttle,
    });
  }

  /**
   * Returns a Github GraphQL initialized with GH auth token
   */
  private async getGithubGQLInstanceWithAuth(repoId: string) {
    let token;

    if (repoId === DUMMY_REPO_ID) {
      token = DUMMY_REPO_TOKEN;
    } else if (this.authToken) {
      token = this.authToken;
    } else {
      const hostname =
        this.baseUrl !== gitProviderUtils.DEFAULT_GITHUB_API
          ? UrlUtils.getUrlHostname(this.baseUrl)
          : GITHUB_CLOUD_HOSTNAME;
      token = await gitProviderUtils.getGitHostingToken(hostname);
    }

    if (!token) {
      return null;
    }
    const defaults = {
      baseUrl: this.baseUrl,
    };

    const octokit = new OctokitWithPlugins({
      auth: token,
      baseUrl: this.baseUrl,
      throttle,
    });

    return octokit.graphql.defaults({ ...defaults, headers: { authorization: `token ${token}` } });
  }

  private async fetchCommitList({
    repoId,
    branch,
    maxCommitsNumber,
    relativeFilePath,
    maxPagesNumber = MAX_ITEMS_TO_FETCH,
  }: {
    repoId: string;
    branch: string;
    maxCommitsNumber?: number;
    relativeFilePath?: string;
    maxPagesNumber?: number;
  }) {
    try {
      const { owner, repoName } = await this.getDetails(repoId);
      const octokit = await this.getOctokitInstanceWithAuth({ repoId });

      let page = 1;
      const perPage = Math.min(maxCommitsNumber, MAX_ITEMS_TO_FETCH) || MAX_ITEMS_TO_FETCH;
      let result: Awaited<ReturnType<typeof octokit.rest.repos.listCommits>>;
      let commits: (typeof result)['data'] = [];
      do {
        result = await octokit.rest.repos.listCommits({
          owner: owner,
          repo: repoName,
          path: relativeFilePath, // When undefined, commits on all repo are fetched
          sha: branch,
          per_page: perPage,
          page: page,
          cacheBuster: Math.random(),
        });
        commits = [...commits, ...result.data];
        page++;
      } while (
        result.data.length === MAX_ITEMS_TO_FETCH &&
        (!maxCommitsNumber || commits.length < maxCommitsNumber) &&
        page < maxPagesNumber
      );
      return commits;
    } catch (error) {
      logger.warn(`Fail to fetchCommitList for repoId=${repoId} branch=${branch} ${error}`);
      return [];
    }
  }

  /**
   * Gets a blob content from github if exists and accessible
   * @param objectSha - the blob sha to fetch
   * @param repoId - the relevant repository ID to fetch from
   */
  private async fetchBlobContentFromGithub({
    objectSha,
    repoId,
  }: {
    objectSha: string;
    repoId: string;
  }): Promise<FetchBlobContentReturnType> {
    const cacheKey = `${objectSha}-${repoId}`;
    const cacheHit = this.fetchBlobContentFromGitHubCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    let returnValue: FetchBlobContentReturnType;
    try {
      const { owner, repoName } = await this.getDetails(repoId);

      const octokit = await this.getOctokitInstanceWithAuth({ repoId });
      const blob = await octokit.rest.git.getBlob({
        owner: owner,
        repo: repoName,
        file_sha: objectSha,
      });
      returnValue = blob.status === StatusCodes.OK ? blob : null;
    } catch (ex) {
      returnValue = null;
    }
    this.fetchBlobContentFromGitHubCache.set(cacheKey, returnValue);
    return returnValue;
  }

  deleteTokenFromMemory(): void {
    this.authToken = null;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getUserData(provider: GitProviderName, driverOptions?: DriverOptions): Promise<{ login: string; id: number }> {
    const octokit = await this.getOctokitInstanceWithAuth({});
    const user = await octokit.request('GET /user');
    return { id: user.data.id, login: user.data.login };
  }

  async getOrganizationData(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    provider: GitProviderName,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    driverOptions?: DriverOptions
  ): Promise<{ name: string; numOfDevelopers: number }> {
    const octokit = await this.getOctokitInstanceWithAuth({});
    const org = await octokit.request('GET /user/orgs');
    const login = org.data.length === 1 ? org.data[0].login : '';
    let numOfDevs = 0;
    let name = '';
    if (login) {
      const members = await octokit.request('GET /orgs/{org}/members', { org: login });
      numOfDevs = members.data?.length ?? 0;
      const orgData = await octokit.request('GET /orgs/{org}', { org: login });
      name = orgData.data?.name;
    }

    return { name, numOfDevelopers: numOfDevs };
  }

  /*
   * Returns the name of a request for changes, i.e a Pull Request
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getChangeRequestName({ repoId }: { repoId: string }): Promise<string> {
    return this.getTerminology(GitProviderName.GitHub).pullRequest;
  }

  // NOTE: This type of cache is optimized for LRU operations. When this become a performance issue, we should consider an alternative cache solution.
  private fetchFileContentCache = new Cache<string, FetchFileContentReturnType>({
    ttl: FETCH_FILE_CACHE_TTL_MS,
    ttlResolution: FETCH_FILE_CACHE_TTL_MS / 5, // We can manage with entries staying ~20% above their TTL time.
    ttlAutopurge: true,
  });

  /**
   * Gets a file's contents from github based on its relative path and a revision
   * @param filePath - the relative filepath of the file to fetch from the repo
   * @param repoId - the Id of the repository to fetch from
   * @param revision - a specific revision to fetch from. Use it if If provided, otherwise use branch from state or use HEAD.
   * @param safe - when true, do not throw an exception is the file does not exist
   * @param raw - when true, will fetch also the content in RAW (used in fetching images)
   */
  private async fetchFileWithMetadata({
    filePath,
    repoId,
    revision,
    safe = false,
    raw = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    safe?: boolean;
    raw?: boolean;
  }): Promise<FetchFileContentReturnType> {
    // If the requested file is inside the SWM folder, disable the cache.
    const disableCache = filePath.indexOf(config.SWM_FOLDER_IN_REPO + '/') >= 0;
    const cacheKey = `${repoId}-${revision}-${filePath}-${safe}`;
    if (!disableCache) {
      try {
        const cacheHit = this.fetchFileContentCache.get(cacheKey);
        if (cacheHit) {
          return cacheHit;
        }
      } catch (err) {
        logger.error({ err }, `FetchFileContentCache get threw an error`);
      }
    }
    let returnValue: FetchFileContentReturnType;

    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    if (filePath.startsWith('./')) {
      filePath = filePath.substring(2);
    }
    try {
      const getReposContentRequest: {
        owner: string;
        repo: string;
        path: string;
        ref: string;
        cacheBuster: number;
        baseUrl?: string;
      } = {
        owner: repoData.owner,
        repo: repoData.repoName,
        path: filePath,
        ref: revision,
        // GitHub API has 60sec cache-control for resources, sending a random on each request will fetch the resource without a cache (as we rely on a cache of our own anyway)
        cacheBuster: Math.random(),
      };
      // Get content from the specific GH server if exists (with the same token). Used in Templates repo
      if (repoData.api_url) {
        getReposContentRequest.baseUrl = repoData.api_url;
      }
      returnValue = await octokit.rest.repos.getContent(getReposContentRequest);
      if (raw && returnValue?.data?.['content'] === '') {
        returnValue.data['content'] = await this.getLargeFileContent(
          returnValue?.data?.['git_url'],
          getReposContentRequest,
          octokit,
          filePath
        );
      }
    } catch (error) {
      // Do not throw exception on HTTP 404 (file not found) if safe is true
      if (safe && error && error.status === 404) {
        returnValue = null;
      } else {
        throw error;
      }
    }

    if (!disableCache) {
      try {
        this.fetchFileContentCache.set(cacheKey, returnValue);
      } catch (err) {
        logger.error({ err }, `FetchFileContentCache set threw an error`);
      }
    }
    return returnValue;
  }

  /**
   * GitHub returns large files as empty content. This function attempts to fetch the file content as raw.
   * Mostly used for images.
   * @param blobGitURL - the blob git url on GitHub
   * @param getReposContentRequest - the request object to fetch the file content
   * @param octokit - the octokit instance with auth
   * @param filePath - the file path to fetch
   * @private
   * @returns the large file content as base64 string
   */
  private async getLargeFileContent(blobGitURL, getReposContentRequest, octokit, filePath): Promise<string> {
    try {
      const fileExtension = filePath.split('.').pop();
      logger.info(
        `File of type ${fileExtension} in blob-path ${blobGitURL} returned empty content. Attempting to fetch as raw...`
      );
      const rawData = await octokit.repos.getContent({
        ...getReposContentRequest,
        cacheBuster: Math.random(),
        headers: {
          accept: 'application/vnd.github.raw',
        },
      });
      return Buffer.from(rawData.data).toString('base64');
    } catch (err) {
      logger.error({ err }, `Failed to get the raw file contents`);
      return '';
    }
  }

  async createBranch({
    repoId,
    branchName,
    sourceSha,
  }: {
    repoId: string;
    branchName: string;
    sourceSha: string;
  }): Promise<void> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    await octokit.rest.git.createRef({
      owner: owner,
      repo: repoName,
      ref: `refs/heads/${branchName}`,
      sha: sourceSha,
    });
  }

  async createPullRequest({
    repoId,
    fromBranch,
    toBranch,
    title,
    body,
    draft = false,
  }: {
    repoId: string;
    fromBranch: string;
    toBranch: string;
    title?: string;
    body?: string;
    draft?: boolean;
  }): Promise<CreatedPR> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    const result = await octokit.rest.pulls.create({
      owner: owner,
      repo: repoName,
      head: fromBranch,
      base: toBranch,
      title,
      body,
      draft,
    });
    return { url: result.data.html_url };
  }

  async deleteFileIfExists({
    filePath,
    branch,
    commitMessage,
    repoId,
  }: {
    repoId: string;
    branch: string;
    filePath: string;
    commitMessage: string;
  }): Promise<void> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    const fileSha = await this.getFileBlobSha({ filePath, repoId, revision: branch });
    if (fileSha) {
      await octokit.rest.repos.deleteFile({
        owner: owner,
        repo: repoName,
        path: filePath,
        branch: branch,
        message: commitMessage,
        sha: fileSha,
      });
    }
  }

  async isBranchExists({ repoId, branchName }: { repoId: string; branchName: string }): Promise<boolean> {
    const cacheKey = `${branchName}-${repoId}`;
    const cacheHit = this.isBranchExistsCache.get(cacheKey);
    if (cacheHit) {
      return cacheHit;
    }
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    try {
      await octokit.rest.repos.getBranch({
        owner: owner,
        repo: repoName,
        branch: branchName,
      });
      this.isBranchExistsCache.set(cacheKey, true);
      return true;
    } catch (err) {
      if (err.status === 404) {
        // Don't cache the false state because branches often get created while working with the app. They don't get
        // Deleted that often.
        return false;
      }

      throw err;
    }
  }

  async getBranch({
    repoId,
    branchName,
    cacheBuster,
  }: {
    repoId: string;
    branchName: string;
    cacheBuster?: boolean;
  }): Promise<Branch> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    const result = await octokit.rest.repos.getBranch({
      owner: owner,
      repo: repoName,
      branch: branchName,
      cacheBuster: cacheBuster ? Math.random() : cacheBuster,
    });

    return {
      id: result.data.commit.sha,
      name: branchName,
      sha: result.data.commit.sha,
      protected: result.data.protected,
      lastUpdated: result.data.commit.commit.author.date,
    };
  }

  async *getBranches({
    repoId,
    prStates,
  }: {
    repoId: string;
    prStates?: PullRequestState[];
  }): AsyncIterable<{ hasNextPage: boolean; branches: Branch[] }> {
    const { owner, repoName } = await this.getDetails(repoId);
    const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);

    const statesString = prStates && prStates.length ? `states: [${prStates.join(', ')}]` : '';

    let endCursor = '';
    let hasNextPage = false;

    do {
      /**
       * In this query we:
       *  1. Get all of the branches ("refs/heads/").
       *  2. For each branch, we ask for:
       *    a. Potential public protection rules (`refUpdateRule`) to know if the branch is protected.
       *    b. The target commit of the PR (aka, HEAD) and the date it was created at.
       */
      const edgeList: Branch[] = [];
      try {
        const query = `query getRepoBranchesWithDates {
    repository(owner: "${owner}", name: "${repoName}") {
      refs(first: ${MAX_ITEMS_TO_FETCH}, refPrefix: "refs/heads/" after: "${endCursor}" orderBy: { field: ALPHABETICAL, direction: ASC }) {
        edges {
          node {
            name
            ${
              statesString
                ? `associatedPullRequests(first: ${MAX_ITEMS_TO_FETCH}, ${statesString}) {
              totalCount
              nodes {
                isDraft
              }
            }`
                : ''
            }
            refUpdateRule {
              pattern
            }
            target {
              ... on Commit {
                authoredDate
                oid
              }
            }
          }
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }`;
        const {
          repository: {
            refs: { edges: queryResults, pageInfo },
          },
        } = await graphqlWithAuth<{
          repository: { refs: { edges: RequestedBranchNode[]; pageInfo: PageInfo } };
        }>(query);

        ({ endCursor, hasNextPage } = pageInfo);

        if (statesString) {
          queryResults.forEach((edge) => {
            const { totalCount, nodes } = edge.node?.associatedPullRequests || {};
            if (totalCount && nodes?.some((pr) => !pr.isDraft)) {
              edgeList.push({
                id: edge.node.target.oid,
                name: edge.node.name,
                sha: edge.node.target.oid,
                protected: edge.node.refUpdateRule !== null,
                lastUpdated: edge.node.target.authoredDate,
              });
            }
          });
        } else {
          edgeList.push(
            ...queryResults.map((edge) => ({
              name: edge.node.name,
              id: edge.node.target.oid,
              sha: edge.node.target.oid,
              protected: edge.node.refUpdateRule !== null,
              lastUpdated: edge.node.target.authoredDate,
            }))
          );
        }
      } catch (err) {
        logger.error({ err }, `Error fetching branches.`);
        this.handleRateLimitError(err);
        hasNextPage = false;
      } finally {
        yield { hasNextPage, branches: edgeList };
      }
    } while (hasNextPage);
  }

  private async getFileBlobSha({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<string> {
    try {
      const result = await this.fetchFileWithMetadata({ filePath, repoId, revision });
      return result.data['sha'];
    } catch (ex) {
      return '';
    }
  }

  async getFileContentFromRevision({
    filePath,
    repoId,
    revision,
    raw = false,
    safe = false,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
    raw?: boolean;
    safe?: boolean;
  }): Promise<string> {
    const result = await this.fetchFileWithMetadata({ filePath, repoId, revision, safe, raw });
    if (safe && result === null) {
      return null;
    }

    // When `data` returns as an array, it means that the given `filePath` was a directory.
    if (Array.isArray(result.data)) {
      return '';
    }
    return raw ? result.data['content'] : b64decodeAndRemoveCR(result.data['content']);
  }

  async getPendingDocsForBranch({
    repoId,
    branchName,
  }: {
    repoId: string;
    branchName: string;
  }): Promise<Record<string, PendingPR[]>> {
    try {
      const repoData = await gitProviderUtils.getRepoStateData(repoId);
      const isGitHubEnterprise = !!repoData.api_url && repoData.api_url !== gitProviderUtils.DEFAULT_GITHUB_API;
      const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);

      const {
        repository: {
          pullRequests: { nodes },
        },
      } = await graphqlWithAuth<{
        repository: {
          pullRequests: {
            nodes: {
              createdAt: string;
              updatedAt: string;
              number: number;
              title: string;
              headRefName: string;
              files: { nodes: { path: string; changeType?: string; additions: number; deletions: number }[] };
            }[];
          };
        };
      }>(`
      {
        repository(owner:"${repoData.owner}", name:"${repoData.repoName}" ) {
          pullRequests(states: [OPEN], last: ${MAX_ITEMS_TO_FETCH}, baseRefName: "${branchName}") {
            nodes {
              createdAt
              updatedAt
              number
              title
              headRefName
              files(last: ${MAX_ITEMS_TO_FETCH}) {
                nodes {
                  path
                  ${isGitHubEnterprise ? '' : 'changeType'}
                  additions
                  deletions
                }
              }
            }
          }
        }
      }
    `);

      const prsData = nodes.reduce((acc, node) => {
        if (node.files && !!node.files.nodes) {
          return [
            ...acc,
            {
              files: node.files.nodes.map((fileNode) => ({
                ...fileNode,
                additionsInFile: fileNode.additions,
                changeType: fileNode.changeType ?? '',
              })),
              sourceBranchName: node.headRefName,
              prId: node.number,
              prTitle: node.title,
              createdAt: node.createdAt,
              updatedAt: node.updatedAt,
            },
          ];
        }
        return acc;
      }, []);

      return changeRequestsPendingDocsReducer(prsData);
    } catch (ex) {
      throw new Error(`Could not fetch open PRs from GitHub`);
    }
  }

  private mapPrData(pr: PullRequestGraphql): PrData {
    return {
      files: pr.files.nodes.map((file): PrData['files'][number] => ({
        additionsInFile: file.additions,
        path: file.path,
      })),
      state: pr.state,
      prId: pr.number,
      filesWithAdditions: pr.additions,
      createdAt: pr.createdAt,
      updatedAt: pr.updatedAt,
      url: pr.url,
      title: pr.title,
      description: pr.body,
      prHeadSha: pr.headRefOid,
      prBaseSha: pr.baseRefOid,
      sourceBranchName: pr.headRefName,
      author: { username: pr?.author?.login || 'ghost' },
    };
  }

  async getPr({ repoId, prId }: { repoId: string; prId: string }): Promise<PrData> {
    const { owner, repoName } = await this.getDetails(repoId);
    const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);
    const {
      repository: { pullRequest },
    } = await graphqlWithAuth<{ repository: { pullRequest: PullRequestGraphql } }>(`
      {
        repository(owner:"${owner}", name:"${repoName}" ) {
          pullRequest(number: ${prId}) {
            ${pullRequestGraphql}
          }
        }
      }
    `);

    return this.mapPrData(pullRequest);
  }

  async getPrs({ repoId, prState }: { repoId: string; prState?: string }): Promise<PrData[]> {
    const { owner, repoName } = await this.getDetails(repoId);
    const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);
    const {
      repository: {
        pullRequests: { nodes },
      },
    } = await graphqlWithAuth<{ repository: { pullRequests: { nodes: PullRequestGraphql[] } } }>(`
      {
        repository(owner:"${owner}", name:"${repoName}" ) {
          pullRequests(first: ${MAX_ITEMS_TO_FETCH}, ${
      prState ? `states: [${prState}],` : ''
    } orderBy:{field:UPDATED_AT, direction:DESC}) {
            nodes {
              ${pullRequestGraphql}
            }
          }
        }
      }
    `);

    return nodes.map((pr) => this.mapPrData(pr));
  }

  async isFileExistsOnRevision({
    repoId,
    filePath,
    revision,
  }: {
    repoId: string;
    filePath: string;
    revision?: string;
  }): Promise<boolean> {
    try {
      const result = await this.fetchFileWithMetadata({ filePath, repoId, revision });
      return result.status === StatusCodes.OK;
    } catch (ex) {
      return false;
    }
  }

  async getLastCommitShaWhereFileExisted(
    relativeFilePath: string,
    repoId: string,
    destCommit: string
  ): Promise<string> {
    try {
      const commits = await this.fetchCommitList({ repoId, relativeFilePath, branch: destCommit, maxCommitsNumber: 1 });
      return commits[0].sha;
    } catch (ex) {
      return '';
    }
  }

  async getDiffFiles({
    repoId,
    compareFrom,
    compareTo,
    includeShas,
  }: {
    repoId: string;
    compareFrom: string;
    compareTo: string;
    includeShas?: boolean;
  }): Promise<DiffFileMetadata[]> {
    try {
      const { owner, repoName } = await this.getDetails(repoId);
      const octokit = await this.getOctokitInstanceWithAuth({ repoId });

      const result = await octokit.rest.repos.compareCommits({
        owner: owner,
        repo: repoName,
        base: compareFrom,
        head: compareTo,
      });

      const diffMetadata: DiffFileMetadata[] = result.data.files.map((file) => {
        return {
          newFilePath: file.filename,
          oldFilePath: file.previous_filename || file.filename,
          status: file.status as githubChangeStatus,
          sha: includeShas ? file.sha : undefined,
        };
      });

      return diffMetadata;
    } catch (ex) {
      return [];
    }
  }

  async getPathType({
    path,
    repoId,
    revision,
  }: {
    path: string;
    repoId: string;
    revision: string;
  }): Promise<ResultWithReturnCode<{ pathType: PathType }, { errorMessage: string }>> {
    let pathType: PathType = PathType.File;
    try {
      const fetchResult = await this.fetchFileWithMetadata({ filePath: path, repoId: repoId, revision: revision });
      if (Array.isArray(fetchResult.data)) {
        pathType = PathType.Folder;
      }
      return { code: config.SUCCESS_RETURN_CODE, pathType: pathType };
    } catch (error) {
      return { code: config.ERROR_RETURN_CODE, errorMessage: error.toString() };
    }
  }

  async getRepoRemoteData({ repoId, repoName, owner }: RepoIdOrRepoData): Promise<RemoteRepository> {
    const repoData = owner && repoName ? { owner, repoName } : await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });
    const result = await octokit.rest.repos.get({
      owner: repoData.owner,
      repo: repoData.repoName,
    });
    const returnValue: RemoteRepository = {
      cloneUrl: result.data.clone_url,
      defaultBranch: result.data.default_branch,
      fork: result.data.fork,
      htmlUrl: result.data.html_url,
      isPrivate: result.data.private,
      name: result.data.name,
      owner: result.data.owner.login,
      writeAccess: !!result.data.permissions?.push,
    };
    return returnValue;
  }

  async getRepoRemoteDataBatch({
    repos,
  }: {
    repos: RepoIdOwnerName[];
  }): Promise<Record<string, PartialRemoteRepository>> {
    const reposList = repos
      .map(
        (repo, index) =>
          `
      repo${index}: repository(owner: "${repo.owner}", name: "${repo.repoName}") {
        ...repositoryFields
      }
    `
      )
      .join('\n');
    const query = `
    query {
      ${reposList}
    }

    fragment repositoryFields on Repository {
      name
      owner {
        login
      }
      defaultBranchRef {
        name
      }
    }
    `;
    const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth('');
    let data: RepoRemoteDataBatchResponse;
    const result: Record<string, PartialRemoteRepository> = {};
    try {
      data = await graphqlWithAuth<RepoRemoteDataBatchResponse>(query);
    } catch (err) {
      // if some repos are 404 (no access)
      // then we get an error, but the err.data will hold data for no fail repos
      // https://github.com/octokit/graphql.js/#partial-responses
      logger.error({ err }, `Got an error ${err}`);
      if (err instanceof GraphqlResponseError) {
        data = err.data as RepoRemoteDataBatchResponse;
        const notFoundErrors = err.errors.filter((e) => e.type === 'NOT_FOUND');
        for (const notFound of notFoundErrors) {
          try {
            const repoIndex = parseInt(notFound['path'][0].replace('repo', ''), 10);
            const repoId = repos[repoIndex].repoId;
            result[repoId] = 'not-found';
          } catch (err) {
            logger.error({ err }, `Failed in parse error ${JSON.stringify(notFound)}`);
          }
        }
      }
    }
    if (data) {
      for (const [queryName, repoFields] of Object.entries(data)) {
        if (!repoFields) {
          // repoFields is null will be null in case there was an error for this repo
          continue;
        }
        const repoIndex = parseInt(queryName.replace('repo', ''), 10);
        const repoId = repos[repoIndex].repoId;
        result[repoId] = {
          owner: repoFields.owner.login,
          name: repoFields.name,
          defaultBranch: repoFields.defaultBranchRef?.name ?? null, // defaultBranchRef is null if case of empty repo
        };
      }
    }
    return result;
  }

  async getAllSwmFilesContent({
    repoId,
    revision,
    path = config.SWM_FOLDER_IN_REPO,
  }: {
    repoId: string;
    revision: string;
    path?: string;
  }): Promise<{ path: string; content: string }[]> {
    try {
      const { owner, repoName } = await this.getDetails(repoId);
      const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);

      const query = `query allSwmFileData {
      repository(owner: "${owner}", name: "${repoName}") {
        ref(qualifiedName: "${revision}") {
          target {
            ... on Commit {
              file(path: "${path}") {
                object {
                  id
                  ... on Tree {
                    entries {
                      path
                      object {
                        id
                        ... on Blob {
                          text
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }`;
      const {
        repository: {
          ref: {
            target: {
              file: {
                object: { entries },
              },
            },
          },
        },
      } = await graphqlWithAuth<{
        repository: {
          ref: { target: { file: { object: { entries: { path: string; object: { text: string } }[] } } } };
        };
      }>(query);
      return entries.map((entry) => {
        return { path: entry.path, content: entry.object.text };
      });
    } catch (err) {
      logger.error({ err }, `getting swm folder of revision "${revision}"`);
      return [];
    }
  }

  private async createTree({ octokit, repoData, treeItems, branchSha }) {
    logger.debug(`Creating tree with ${treeItems.length} new and/or deleted files`);
    try {
      return await octokit.request('POST /repos/{owner}/{repo}/git/trees', {
        owner: repoData.owner,
        repo: repoData.repoName,
        tree: treeItems,
        base_tree: branchSha,
      });
    } catch (err) {
      logger.error({ err }, `Failed to create tree based on branch SHA ${branchSha}`);
      throw err;
    }
  }

  private async createCommit({ octokit, repoData, treeSha, parentCommitSha, commitMessage }) {
    logger.debug(`Creating commit for tree ${treeSha}`);
    try {
      return await octokit.request('POST /repos/{owner}/{repo}/git/commits', {
        owner: repoData.owner,
        repo: repoData.repoName,
        message: commitMessage,
        tree: treeSha,
        parents: [parentCommitSha],
      });
    } catch (err) {
      logger.error({ err }, `Failed to create commit for tree ${treeSha} based on commit ${parentCommitSha}`);
      throw err;
    }
  }

  private async updateBranchRef({ octokit, repoData, branch, currentSha, targetSha }) {
    logger.info(`Updating branch ${branch}: ${currentSha}..${targetSha}`);
    try {
      await octokit.request('PATCH /repos/{owner}/{repo}/git/refs/{ref}', {
        owner: repoData.owner,
        repo: repoData.repoName,
        ref: `heads/${branch}`,
        sha: targetSha,
      });
    } catch (err) {
      logger.error({ err }, `Failed to update branch ${branch} reference from ${currentSha} to ${targetSha}`);
      throw err;
    }
  }

  private async pushTreeItems({ treeItems, repoId, repoData, branch, commitMessage }) {
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });
    const branchSha = (await this.getBranch({ repoId, branchName: branch, cacheBuster: true })).sha;
    const treeResponse = await this.createTree({ octokit, repoData, treeItems, branchSha });
    const commitResponse = await this.createCommit({
      octokit,
      repoData,
      treeSha: treeResponse.data.sha,
      parentCommitSha: branchSha,
      commitMessage,
    });
    await this.updateBranchRef({
      octokit,
      repoData,
      branch,
      currentSha: branchSha,
      targetSha: commitResponse.data.sha,
    });
  }

  private static async createBlob({ octokit, repoData, file }) {
    try {
      return await octokit.request('POST /repos/{owner}/{repo}/git/blobs', {
        owner: repoData.owner,
        repo: repoData.repoName,
        content: file.isBase64Encoded ? file.content : b64encodeString(file.content),
        encoding: 'base64',
      });
    } catch (err) {
      logger.error({ err }, `Failed to create blob`);
      throw err;
    }
  }

  async pushMultipleFilesToBranch({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    logger.debug(
      `About to push files to repo, ${stringifyLogParams({
        repoId,
        branch,
        commitMessage,
        files: files.map((file) => `${file.path} (${file.state})`).join(', '),
      })}`
    );
    await this.pushMultipleFilesToBranchGraphQlWithRetries({ files, repoId, branch, commitMessage });
  }

  private async pushMultipleFilesToBranchGraphQlWithRetries({
    files,
    repoId,
    branch,
    commitMessage,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
  }) {
    let attempsRemaining = 3;
    const repoData = await gitProviderUtils.getRepoStateData(repoId);
    let repoName = repoData.repoName;
    let repoOwner = repoData.owner;
    let fetchFromRemote = false;
    while (attempsRemaining-- > 0) {
      try {
        if (fetchFromRemote) {
          // try to fetch the repo name and owner from the github rest api to see if there was rename
          const repoMetadata = await this.getRepoRemoteData({ repoId });
          if (repoMetadata.name !== repoName || repoMetadata.owner !== repoOwner) {
            const old = `${repoOwner}/${repoName}`; // for logging
            repoName = repoMetadata.name;
            repoOwner = repoMetadata.owner;
            logger.warn(`Incosistency between state and remote data. Changed ${old} => ${repoOwner}/${repoName}`);
          }
        }
        await this.pushMultipleFilesToBranchGraphQl({ files, repoId, branch, commitMessage, repoOwner, repoName });
        return;
      } catch (err) {
        fetchFromRemote = true; // next time, we will try to fetch repo name and owner from remote
        logger.warn(
          `Failed to create commit in repoId: ${repoId} ${repoOwner}/${repoName}. ${err}, retrying... (${attempsRemaining} attempts remaining)`
        );
        if (attempsRemaining === 0) {
          throw err;
        }
      }
    }
  }

  /* pushes the files to branch using graph-ql
    usage of graph-ql gives us free "verified"
    https://docs.github.com/en/graphql/reference/mutations#commit-signing
  */
  private async pushMultipleFilesToBranchGraphQl({
    files,
    repoId,
    branch,
    commitMessage,
    repoOwner,
    repoName,
  }: {
    files: SwmResourceFile[];
    repoId: string;
    branch: string;
    commitMessage: string;
    repoOwner: string;
    repoName: string;
  }) {
    const graphqlWithAuth = await this.getGithubGQLInstanceWithAuth(repoId);
    const lastCommits = await this.fetchCommitList({ repoId, branch, maxCommitsNumber: 1 });
    const lastCommit = lastCommits?.[0]?.sha;
    logger.info(`lastCommit for branch ${branch} is ${lastCommit}`);
    if (!lastCommit) {
      throw new Error(`Failed to find last commit on branch ${branch} for ${repoId}`);
    }
    const mutation = `
    mutation CommitMutation($input: CreateCommitOnBranchInput!) {
      createCommitOnBranch(input: $input) {
        commit {
          url
        }
      }
    }
    `;
    const fileChanges = swmResourceFileToGitHubFileChanges(files);
    const input = {
      branch: {
        repositoryNameWithOwner: `${repoOwner}/${repoName}`,
        branchName: branch,
      },
      fileChanges,
      message: {
        headline: commitMessage,
      },
      expectedHeadOid: lastCommit,
    };
    await graphqlWithAuth<{ commit: { url: string } }>(mutation, { input });
  }

  async getGitDiffRemote({ repoId, base, head }: { repoId: string; base: string; head: string }): Promise<string> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });
    const patch = await octokit.request('GET /repos/{owner}/{repo}/compare/{base}...{head}', {
      mediaType: { format: 'diff' },
      owner: owner,
      repo: repoName,
      base,
      head,
    });
    // For some reason the types for `octokit.request` can't recognize that `data` will be a string when using the `diff` mediaType
    return patch.data as unknown as string;
  }

  async getRepoTree({
    repoId,
    recursive = false,
    treeSha = 'HEAD',
    cacheBuster = false,
  }: {
    repoId: string;
    recursive?: boolean;
    treeSha?: string;
    cacheBuster?: boolean;
  }): Promise<{ path?: string; mode?: string; type?: string; sha?: string; size?: number; url?: string }[]> {
    const { owner, repoName } = await this.getDetails(repoId);
    const octokit = await this.getOctokitInstanceWithAuth({ repoId });

    const getTreeOptions = {
      owner: owner,
      repo: repoName,
      tree_sha: treeSha,
      recursive: String(recursive),
    };
    if (cacheBuster) {
      getTreeOptions['cacheBuster'] = Math.random();
    }
    try {
      const result = await octokit.rest.git.getTree(getTreeOptions);
      return result.data.tree;
    } catch (err) {
      /*
      from GH docs:
      The REST API will return a 409 Conflict if the Git repository is empty or unavailable.
      An unavailable repository typically means GitHub is in the process of creating the repository.
      For an empty repository, you can use the "Repositories" endpoint to create content and initialize the repository
      so you can use the API to manage the Git database. Contact GitHub Support if this response status persists.
      */
      if (err?.status === StatusCodes.CONFLICT) {
        return [];
      }
      throw err;
    }
  }

  async *getUserRemoteRepositories(
    provider: GitProviderName, // eslint-disable-line @typescript-eslint/no-unused-vars
    driverOptions?: DriverOptions // eslint-disable-line @typescript-eslint/no-unused-vars
  ): AsyncIterable<RemoteRepository> {
    try {
      const octokit = await this.getOctokitInstanceWithAuth({});
      let result: Awaited<ReturnType<typeof octokit.rest.repos.listForAuthenticatedUser>>;
      let page = 1;
      do {
        result = await octokit.rest.repos.listForAuthenticatedUser({
          per_page: MAX_ITEMS_TO_FETCH,
          page,
          sort: 'full_name',
          baseUrl: driverOptions?.baseUrl,
          cacheBuster: Math.random(),
        });
        for (const repo of result.data) {
          yield {
            owner: repo.owner.login,
            name: repo.name,
            isPrivate: repo.private,
            fork: repo.fork,
            htmlUrl: repo.html_url,
            cloneUrl: repo.clone_url,
            defaultBranch: repo.default_branch,
            writeAccess: !!repo.permissions?.push,
          };
        }
        page++;
      } while (result.data.length === MAX_ITEMS_TO_FETCH);
    } catch (ex) {
      throw new Error(`Could not fetch user's repositories from GitHub`);
    }
  }

  async getRepositoryLanguages(repoId: string): Promise<{ [p: string]: number } | undefined> {
    try {
      const [octokit, repoData] = await Promise.all([
        this.getOctokitInstanceWithAuth({ repoId }),
        gitProviderUtils.getRepoStateData(repoId),
      ]);
      const result = await octokit.request('GET /repos/{owner}/{repo}/languages', {
        owner: repoData.owner,
        repo: repoData.repoName,
      });
      return result.data as { [p: string]: number };
    } catch (err) {
      logger.error({ err }, `Failed to get languages for repo ${repoId}`);
      return undefined;
    }
  }
  // NOTE: this function shouldn't be called directly! It it implemented in the main "gitwrapper.ts"!
  async getSwimmJsonContentsWrapper() {
    throw new Error(`getSwimmJsonContentsWrapper called not from the main wrapper!`);
  }
  // NOTE: this function shouldn't be called directly! It it implemented in the main "gitwrapper.ts"!
  getFullFileContentFromFSWrapper({
    filePath,
    repoId,
    revision,
  }: {
    filePath: string;
    repoId: string;
    revision: string;
  }): Promise<string> {
    throw new Error(
      `getFullFileContentFromFSWrapper called not from the main wrapper! with args: ${{
        filePath,
        repoId,
        revision,
      }}`
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async getUserOrganizations(provider: GitProviderName, driverOptions?: DriverOptions): Promise<string[]> {
    try {
      const octokit = await this.getOctokitInstanceWithAuth({});
      // prefix of GH App user token
      if (this?.authToken?.startsWith('ghu_')) {
        const installations = await octokit.rest.apps.listInstallationsForAuthenticatedUser();
        // @ts-ignore
        return installations.data.installations.map((installation) => installation.account.login || '');
      } else {
        const organizations = await octokit.rest.orgs.listForAuthenticatedUser();
        return organizations.data.map((organization) => organization.login);
      }
    } catch (err) {
      logger.error({ err }, 'Cannot get user organizations');
      throw err;
    }
  }

  async getUserActiveBranches(repoId: string, maxActiveBranchesToFetch: number): Promise<string[]> {
    const { owner, repoName } = await this.getDetails(repoId);
    // we are not passing baseUrl since it is initialized based on the repo here.
    const userData = await this.getUserData(GitProviderName.GitHub);
    const octokit = await this.getOctokitInstanceWithAuth({});
    const events = await octokit.rest.activity.listEventsForAuthenticatedUser({
      username: userData.login,
      per_page: MAX_ITEMS_TO_FETCH,
    });
    const branches = new Set<string>();
    const repoEvents = events.data.filter((event) => event.repo && event.repo.name === `${owner}/${repoName}`);
    for (const event of repoEvents) {
      if (!event.type) {
        continue;
      }
      // Using ts-ignore on event.payload because the types are not corrent on the octokit/types library:
      // https://github.com/octokit/types.ts/issues/476
      switch (event.type) {
        case 'CreateEvent': {
          // find branch creation events
          // @ts-ignore
          if (event.payload?.ref_type === 'branch') {
            // @ts-ignore
            branches.add(event.payload.ref);
          }
          break;
        }
        case 'PullRequestEvent': {
          // find PR creation events and add the head branch
          // @ts-ignore
          if (event.payload?.action === 'opened') {
            // @ts-ignore
            if (event.payload.pull_request?.head?.ref) {
              // @ts-ignore
              branches.add(event.payload.pull_request?.head?.ref);
            }
          }
          break;
        }
        case 'PushEvent': {
          // find push events to branch
          // @ts-ignore
          if (event.payload?.ref && event.payload?.ref.startsWith('refs/heads/')) {
            // @ts-ignore
            branches.add(event.payload?.ref.replace('refs/heads/', ''));
          }
          break;
        }
      }
    }
    const unfilteredBrnaches = Array.from(branches);
    const availableActiveBranches = [];
    await Promise.all(
      unfilteredBrnaches.map(async (branch) => {
        if (availableActiveBranches.length < maxActiveBranchesToFetch) {
          const remoteBranch = await this.getBranch({ repoId, branchName: branch }).catch(() => {
            /* Ignore branches not found */
          });
          if (remoteBranch && !isEmpty(remoteBranch)) {
            availableActiveBranches.push(branch);
          }
        }
      })
    );
    return availableActiveBranches.slice(0, maxActiveBranchesToFetch);
  }

  // Handle GitHub rate limit errors uniformly by throwing a custom error
  handleRateLimitError(error: OctokitRateLimitError): void {
    if (error?.data?.errors[0].type === 'RATE_LIMITED') {
      throw new GitProviderRateLimitError(this.getTerminology(GitProviderName.GitHub).displayName);
    }
  }
}

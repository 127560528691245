<script setup lang="ts">
import { NodeViewContent } from '@tiptap/vue-3';
import { iter, productEvents } from '@swimm/shared';
import { computed, nextTick, ref, watch } from 'vue';
import { SwToggle } from '@swimm/ui';
import { BaseButton, BaseIcon, BaseProse } from '@swimm/reefui';
import type { AnalyticsTrackProperties } from '@/types';

const props = defineProps<{
  isEditMode: boolean;
  isEmpty: boolean;
  error: string | null;
  svg: string | null;
  shouldShowMermaidEmptyState: boolean;
  editorOverlapped: boolean;
  placeholder: string | null;
  isSideBySideView: boolean;
  showEditor: boolean;
}>();

const emit = defineEmits<{
  (e: 'track-event', eventName: string, props: AnalyticsTrackProperties): void;
}>();

const mainDirection = computed(() => (props.isSideBySideView && props.showEditor ? 'row-reverse' : 'column'));

// To simulate the effect of padding, we zoom out a bit after fitting the diagram to its bounds.
const PADDING_ZOOM_OUT = 0.85;

// When zooming the diagram, ensure the smallest text never exceeds this height in pixels.
// Sometimes diagrams can be zoomed pretty far to fill the available space, but doing so would make the text
// too large, so we set this limit to make sure the diagram's text remains comfortably sized.
const MAX_TEXT_HEIGHT_PX = 24;

const MIN_MERMAID_CONTAINER_HEIGHT_VH = 16;

const MIN_MERMAID_CONTAINER_HEIGHT_STR = `${MIN_MERMAID_CONTAINER_HEIGHT_VH}vh`;

const MAX_MERMAID_CONTAINER_HEIGHT_VH = 55;

const MAX_MERMAID_CONTAINER_HEIGHT_STR = `${MAX_MERMAID_CONTAINER_HEIGHT_VH}vh`;

const diagramAspectRatio = ref<number | null>(null);

const textHeightOverDiagramHeight = ref<number | null>(null);

const baseZoomLevel = ref<number>(1);

const panZoomEnabled = ref(false);

const resetPanZoom = () => {
  currentSvgPanZoom.value?.center();
  currentSvgPanZoom.value?.zoom(baseZoomLevel.value * PADDING_ZOOM_OUT);
};

watch(panZoomEnabled, (enabled) => {
  if (!enabled) {
    resetPanZoom();
    currentSvgPanZoom.value?.disablePan();
    currentSvgPanZoom.value?.disableZoom();
  } else {
    currentSvgPanZoom.value?.enablePan();
    currentSvgPanZoom.value?.enableZoom();
    emit('track-event', productEvents.ENABLED_MERMAID_PAN_ZOOM, {
      'Diagram Aspect Ratio': diagramAspectRatio.value,
      'Diagram Text Height Ratio': textHeightOverDiagramHeight.value,
      'Is Edit Mode': props.isEditMode,
      'Editor Shown': props.isEditMode ? props.showEditor : null,
      'Is Side By Side View': props.showEditor ? props.isSideBySideView : null,
    });
  }
});

let svgPanZoom: typeof import('svg-pan-zoom') | null = null;
const currentSvgPanZoom = ref<ReturnType<typeof import('svg-pan-zoom')> | null>(null);

// When in editor mode, we want the diagram to be as high as the editor even if it's a relatively short diagram,
// so that it will be centered vertically. When pan is enabled, we want to give the user space to zoom, otherwise they
// could have a relatively small window to zoom in through.
const showFullMermaidHeight = computed(() => props.showEditor || panZoomEnabled.value);

const svgContainer = ref<HTMLElement | null>();

const onZoomInClick = () => {
  currentSvgPanZoom.value?.zoomIn();
};

const onZoomOutClick = () => {
  currentSvgPanZoom.value?.zoomOut();
};

const onResetClick = () => {
  resetPanZoom();
};

const calculateTargetDiagramHeight = (containerWidth: number) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const targetDiagramHeight = containerWidth / diagramAspectRatio.value!;
  if (!textHeightOverDiagramHeight.value) {
    return targetDiagramHeight;
  }
  const maxDiagramHeightBasedOnText = MAX_TEXT_HEIGHT_PX / textHeightOverDiagramHeight.value;
  return Math.min(targetDiagramHeight, maxDiagramHeightBasedOnText);
};

const vhToPixels = (vh: number) => (vh / 100) * window.innerHeight;

const recalculateDiagramHeight = () => {
  if (!svgContainer.value) {
    return;
  }
  const containerWidth = svgContainer.value.clientWidth;
  const targetDiagramHeight = calculateTargetDiagramHeight(containerWidth);
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const svgElement = svgContainer.value.querySelector('svg')! as SVGSVGElement;
  const svgElementHeight = showFullMermaidHeight.value
    ? Math.max(targetDiagramHeight, vhToPixels(MAX_MERMAID_CONTAINER_HEIGHT_VH))
    : Math.max(targetDiagramHeight, vhToPixels(MIN_MERMAID_CONTAINER_HEIGHT_VH));
  // Now we know what height the SVG element is going to be. Calculate what would be the actual height of the
  // diagram itself
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const actualDiagramHeight = Math.min(containerWidth / diagramAspectRatio.value!, svgElementHeight);
  // If the diagram turned out larger than our target height - zoom out (this can happen in edit mode because we
  // set the height to a constant which might be bigger than our target size.
  baseZoomLevel.value = actualDiagramHeight > targetDiagramHeight ? targetDiagramHeight / actualDiagramHeight : 1;
  svgElement.style.height = `${svgElementHeight}px`;
  nextTick(() => {
    currentSvgPanZoom.value?.updateBBox();
    currentSvgPanZoom.value?.resize();
    resetPanZoom();
  });
};

watch(
  () => [props.svg, svgContainer.value] as const,
  async ([svg, svgContainer], _, onCleanup) => {
    if (!svg || !svgContainer) {
      return;
    }
    // We need to do a dynamic import as this is a browser-only library.
    svgPanZoom ??= (await import('svg-pan-zoom')).default;
    // The SVG element will only be available after the next tick after the svg property was updated.
    nextTick(() => {
      const svgElement = svgContainer.childNodes[0] as SVGSVGElement | null;
      if (!svgElement) {
        return;
      }
      // Something about 'gantt' type mermaid charts causes svg-pan-zoom to go crazy and make the diagram super small.
      // Since we don't expect many gantt charts in Swimm, we simply disregard them here.
      if (svgElement.ariaRoleDescription === 'gantt') {
        return;
      }
      // We also remove the maxWidth (which 'hugs' the diagram) so that the entire mermaid viewport is available for the
      // user to zoom in.
      svgElement.style.maxWidth = '';
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      currentSvgPanZoom.value = svgPanZoom!(svgElement, {
        zoomEnabled: panZoomEnabled.value,
        // We'd rather have the user be able to scroll up/down since it's more comfortable in longer, vertical diagrams
        // such as flow diagrams, which we expect to be the most common.
        mouseWheelZoomEnabled: false,
        panEnabled: panZoomEnabled.value,
        // We have our own controls.
        controlIconsEnabled: false,
        zoomScaleSensitivity: 0.6,
        center: true,
        minZoom: 0.001,
        maxZoom: 10,
      });
      // Give the UI time to respond to the style changes above.
      nextTick(() => {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        const actualDiagramElement = svgContainer.querySelector('svg>g') as SVGGElement;
        const rect = actualDiagramElement.getBoundingClientRect();
        diagramAspectRatio.value = rect.width / rect.height;

        const getImmediateTextContent = (node: Node) =>
          iter.reduce(node.childNodes, '', (a, b) => a + (b.nodeType === 3 ? b.textContent : ''));
        const allTextElements = iter.filter(
          svgElement.querySelectorAll('*'),
          (element) => getImmediateTextContent(element) !== ''
        );
        const allTextElementsWithHeight = iter.filter(
          allTextElements,
          (element) => element.getBoundingClientRect().height > 0
        );
        const smallestTextElement = iter.minBy(allTextElementsWithHeight, (t) => t.getBoundingClientRect().height);
        textHeightOverDiagramHeight.value = smallestTextElement
          ? smallestTextElement.getBoundingClientRect().height / rect.height
          : null;
        recalculateDiagramHeight();
      });
    });
    // We need to let svg-pan-zoom know when its container changes size.
    const observer = new ResizeObserver(() => {
      currentSvgPanZoom.value?.updateBBox();
      currentSvgPanZoom.value?.resize();
      nextTick(recalculateDiagramHeight);
    });
    observer.observe(svgContainer);
    onCleanup(() => {
      currentSvgPanZoom.value?.destroy();
      currentSvgPanZoom.value = null;
      observer.disconnect();
    });
  },
  { immediate: true }
);

// We need this as well as the ResizeObserver above, to avoid a 1 frame jump whenever the user switches to edit mode.
// This makes sure we recalculate on the exact frame the change happens and not a frame afterwards.
watch(
  () => [props.showEditor, props.isSideBySideView, panZoomEnabled.value],
  () => {
    if (!svgContainer.value) {
      return;
    }
    // Wait for the layout change to happen before updating the SVG Pan Zoom.
    nextTick(() => {
      currentSvgPanZoom.value?.updateBBox();
      currentSvgPanZoom.value?.resize();
      nextTick(recalculateDiagramHeight);
    });
  }
);
</script>
<template>
  <div class="mermaid-panels">
    <div
      :contenteditable="false"
      :class="[
        'container',
        {
          clickable: isEditMode,
          'pan-zoom-enabled': panZoomEnabled,
          'side-container': isSideBySideView && showEditor,
          'top-container': !isSideBySideView && showEditor,
        },
      ]"
    >
      <div
        v-if="svg"
        :contenteditable="false"
        class="diagram"
        :class="{ 'invalid-diagram': !!error, 'empty-state': shouldShowMermaidEmptyState }"
        data-testid="mermaid-diagram"
      >
        <!-- eslint-disable-next-line vue/no-v-html -->
        <pre class="mermaid" v-html="svg" ref="svgContainer"></pre>
        <!-- we prevent default on mousedows here so that no matter where the user clicks in the control box, it isn't
             caught by the editor and changes focus. -->
        <div v-if="currentSvgPanZoom" class="pan-zoom-controls" @mousedown.prevent="() => {}">
          <div class="pan-zoom-control-box toggle-box" @mousedown.prevent="() => (panZoomEnabled = !panZoomEnabled)">
            <SwToggle class="pan-zoom-control-toggle" size="xsmall" :value="panZoomEnabled" />
            <BaseProse size="small">Pan & zoom</BaseProse>
          </div>
          <div v-if="panZoomEnabled" class="pan-zoom-control-box zoom-box">
            <BaseButton variant="tertiary" size="small" @mousedown.prevent="onZoomInClick">
              <template #leftIcon><BaseIcon name="zoom-in" /></template>
            </BaseButton>
            <BaseButton variant="tertiary" size="small" @mousedown.prevent="onResetClick"> Reset </BaseButton>
            <BaseButton variant="tertiary" size="small" @mousedown.prevent="onZoomOutClick">
              <template #leftIcon><BaseIcon name="zoom-out" /></template>
            </BaseButton>
          </div>
        </div>
      </div>
      <div v-if="shouldShowMermaidEmptyState" class="mermaid-edit-empty-state"></div>
    </div>
    <div
      class="editor"
      :class="{
        'editor-overlapped': editorOverlapped,
        'hide-editor': !showEditor,
        'side-editor': isSideBySideView && showEditor,
      }"
    >
      <NodeViewContent
        id="mermaid-content"
        as="pre"
        data-testid="mermaid-content"
        :data-placeholder="placeholder"
        :contenteditable="isEditMode"
        class="content"
        :class="{ 'is-empty': isEmpty && showEditor }"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.mermaid-panels {
  > * {
    box-sizing: border-box;
  }
  display: flex;
  flex-direction: v-bind(mainDirection);

  --mermaid-min-height: v-bind(MIN_MERMAID_CONTAINER_HEIGHT_STR);
  --mermaid-max-height: v-bind(MAX_MERMAID_CONTAINER_HEIGHT_STR);

  .container {
    &.clickable {
      cursor: pointer;
    }

    &.pan-zoom-enabled {
      cursor: grab;
    }

    .diagram {
      position: relative;
      overflow: hidden;
      padding: 0;
      text-align: center;
    }

    .invalid-diagram {
      opacity: 0.2;
      pointer-events: none;
    }

    .mermaid-edit-empty-state {
      height: 140px;
      padding: var(--space-sm) var(--space-base);
    }

    &.side-container {
      border-left: 1px solid var(--color-border-default);
      flex-shrink: 0;
      flex-basis: 60%;
      border-radius: 0 4px 4px 0;

      .diagram {
        max-height: var(--mermaid-max-height);
        min-height: 25vh; /* Edit mode needs to be fixed in hight */
        overflow: auto;
      }
    }

    &.top-container {
      border-bottom: 1px solid var(--color-border-default);
    }
  }

  .mermaid {
    display: flex;
    margin: 0;
    min-height: var(--mermaid-min-height);
    max-height: var(--mermaid-max-height);
    overflow-y: auto;
    scrollbar-gutter: stable;
  }

  .mermaid > :deep(svg) {
    width: 100%;
    transition: 200ms ease height;
  }

  .container:not(.pan-zoom-enabled) :deep(svg) {
    pointer-events: none;
  }

  .pan-zoom-controls {
    position: absolute;
    top: var(--space-xsmall);
    right: var(--space-xsmall);
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: var(--space-xsmall);

    .pan-zoom-control-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: var(--space-xxsmall) var(--space-xsmall);
      background-color: var(--color-bg-default);
      box-shadow: var(--box-shadow-small);
      border: 1px solid var(--color-border-default);
      border-radius: var(--border-radius);
      height: var(--space-medium);

      & > button {
        margin-left: var(--space-xxxsmall) !important;
      }

      &.toggle-box {
        gap: var(--space-xsmall);
        cursor: pointer;

        & > .pan-zoom-control-toggle {
          pointer-events: none;
        }
      }
    }
  }

  .editor {
    top: 100%;
    width: 100%;
    z-index: 2;
    overflow: hidden;

    // Visually hidden CSS trick, https://css-tricks.com/inclusively-hidden/, so that the content can still be navigated to with the keyboard
    &.hide-editor {
      position: absolute;
      width: 1px;
      height: 1px;
      clip-path: inset(50%);
      overflow: hidden;
      white-space: nowrap;
    }

    &.editor-overlapped {
      pointer-events: none;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--text-color-secondary);
    }

    .content {
      padding: var(--space-sm) var(--space-base);
      font-family: var(--fontfamily-secondary);
      font-size: var(--body-S);
      line-height: var(--space-md);
      height: 140px;
      overflow: auto;
      background-color: var(--color-surface);

      &:focus-visible {
        outline: none;
      }

      &.is-empty::before {
        // TODO Proper color
        /* stylelint-disable-next-line scale-unlimited/declaration-strict-value */
        color: #adb5bd;
        content: attr(data-placeholder);
        /* stylelint-disable-next-line property-disallowed-list */
        float: left;
        height: 0;
        pointer-events: none;
      }
    }

    &.side-editor {
      display: flex;
      flex-direction: column;
      transform: scaleY(1);
      pointer-events: auto;
      flex-shrink: 0;
      flex-basis: 40%;

      &.editor-overlapped {
        pointer-events: none;
      }

      .content {
        flex: 1;
        max-height: var(--mermaid-max-height);
        min-height: 25vh;
        box-sizing: border-box;
        font-size: var(--body-XS);
        overflow: auto;
        margin: 0;
      }
    }
  }
}
</style>

import type { JSONContent } from '@tiptap/core';
import { Fragment as ProseMirrorFragment, Node as ProseMirrorNode } from '@tiptap/pm/model';
import { forAllSwimmNodes, parseSwmdContent, schema } from '..';
import {
  SNIPPETS2DOC_SNIPPET_DELIMITER,
  type SnippetInfo,
  concat,
  exhaustiveTypeException,
  formatSnippetsForSnippets2DocPrompt,
  lazyOpt,
  lazyRepeat,
  lazyRepeatAtLeastOnce,
  namedGroup,
  opt,
  or,
  repeat,
  splitLongSnippets,
} from '@swimm/shared';
import { Transform } from '@tiptap/pm/transform';

const INNER_SNIPPET_DELIMITER = ':::';

export const swmdSnippetsToText = (swimmDocumentContent: JSONContent) => {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDocumentContent);
  const snippets: { index: number; path: string; content: string }[] = [];
  forAllSwimmNodes(rootNode, (node: ProseMirrorNode, pos: number) => {
    if (node.type.name === 'swmSnippet') {
      snippets.push({ index: pos, path: node.attrs.path.substr(1), content: node.attrs.snippet });
    }
    return true;
  });
  return formatSnippetsForSnippets2DocPrompt(snippets);
};

export function splitSnippets(swimmDocumentContent: JSONContent, repoId: string): JSONContent {
  const rootNode = ProseMirrorNode.fromJSON(schema, swimmDocumentContent);
  const snippets: SnippetInfo[] = [];
  forAllSwimmNodes(rootNode, (node: ProseMirrorNode) => {
    if (node.type.name === 'swmSnippet') {
      snippets.push({
        filePath: node.attrs.path,
        content: node.attrs.snippet,
        startLine: node.attrs.line,
        endLine: node.attrs.line + node.attrs.snippet.split('\n').length,
        repoId: node.attrs.repoId || repoId,
      });
    }
    return true;
  });
  const splitSnippets = [...splitLongSnippets(snippets)];
  return buildSwimmDocumentFromSnippets(splitSnippets, repoId);
}

export function buildSwimmDocumentFromSnippets(snippets: SnippetInfo[], repoId: string): JSONContent {
  const document = schema.topNodeType.create();
  const tr = new Transform(document);
  for (const snippet of snippets) {
    const node = schema.node(
      'swmSnippet',
      {
        snippet: snippet.content,
        path: snippet.filePath,
        line: snippet.startLine,
        repoId: snippet.repoId || repoId,
      },
      [schema.node('paragraph')]
    );
    tr.insert(tr.doc.content.size, node);
  }
  return tr.doc.toJSON();
}

// ~~~<number>~~~
const snippetPlaceholder = concat(
  SNIPPETS2DOC_SNIPPET_DELIMITER,
  namedGroup('snippetNumber', /[0-9]+/),
  SNIPPETS2DOC_SNIPPET_DELIMITER
);

// ~~~<name>:::<file-path>:::<number>~~~
const snippetPlaceholderWithInnerDelimiter = concat(
  SNIPPETS2DOC_SNIPPET_DELIMITER,
  namedGroup('snippetFilePath', /.+?/),
  INNER_SNIPPET_DELIMITER,
  namedGroup('snippetName', /.+/),
  INNER_SNIPPET_DELIMITER,
  namedGroup('snippetNumber', /[0-9]+/),
  SNIPPETS2DOC_SNIPPET_DELIMITER
);

const SNIPPET_REGEXES = {
  snippetPlaceholder,
  snippetPlaceholderWithInnerDelimiter,
};

type SnippetRegexKind = keyof typeof SNIPPET_REGEXES;

// Any line that isn't empty and doesn't start with a #.
const nonHeadingParagraphLine = /^[^#\n].*\n/;

const textUpToOneParagraph = or(
  // If we have multiple placeholders in the same line:
  // ~~~1~~~ [text here] ~~~2~~~
  // Then we try to consume these non-newline characters here.
  lazyRepeatAtLeastOnce(/[^\n]/),
  // Otherwise, we handle the case where there are multiple lines between the last snippet placeholder and the next
  // one.
  // For example:
  // ~~~1~~~ text A
  // text B
  // text B
  // text C ~~~2~~~
  concat(
    // This captures text A in the example above (up until the newline character)
    opt(/[^#\n]*?\n/),
    // This captures text B in the example above (complete lines that are not headings or empty).
    lazyRepeat(nonHeadingParagraphLine),
    // This captures text C in the example above (text before the snippet placeholder on the same line).
    opt(/^[^#\n].*?/)
  )
);

const anyLineOfText = /.*\n/;

const textUpToSnippetPlaceholder = (snippetRegexType: SnippetRegexKind) =>
  concat(
    // This is lazy since we first want to try to match the line as a snippet comment/snippet placeholder, and only if it
    // fails consider it as a previous line.
    lazyOpt(
      namedGroup(
        'previousLines',
        // Lazy repeat since otherwise it would 'eat up' the snippet comment lines as well.
        lazyRepeat(anyLineOfText)
      )
    ),
    // Discard empty lines between the previous lines and the snippet comment lines.
    repeat(/\n/),
    // This is lazy since we first want to try to match the line as a snippet placeholder, and only if it fails then
    // consider it as a snippet comment.
    lazyOpt(namedGroup('snippetCommentLines', textUpToOneParagraph)),
    // Completion often contains some text, followed by 2 empty lines before the actual snippet placeholder.
    // e.g. ([] marks this regex's match)
    // Here we handle the authentication[
    //
    // ]~~~111~~~
    opt(/\n\n/),
    SNIPPET_REGEXES[snippetRegexType],
    // Discard trailing punctuation after the snippet number - happens when the placeholders are used in a sentence
    // e.g. "~~~1~~~, ~~~2~~~."
    opt(/[,.:]/),
    // Discard empty lines after the snippet placeholder.
    repeat(/\n/)
  );

const anyText = /(?:.|\n)+/;

const snippets2DocAIResponseRegex = (snippetRegexType: SnippetRegexKind) =>
  new RegExp(
    or(
      // We try to parse snippet placeholders in a loop, grouping each placeholder with the paragraph that precedes it
      // and the previous lines before that (since the last match of the regex, which ended at the last snippet
      // placeholder).
      textUpToSnippetPlaceholder(snippetRegexType),
      // If we get here, it means that the previous option failed, meaning there's no ~~~#~~~ in the rest of the text, so
      // we just consume the rest of the lines so that we can output them as-is.
      namedGroup('textAfterAllPlaceholders', anyText)
    ),
    'gm'
  );

type ParsedSnippets2DocMarkdown = { type: 'markdown'; markdown: string };
type ParsedSnippets2DocSnippet = { type: 'snippet'; number: number; snippetCommentMarkdown?: string };
type ResolvedSnippets2DocSnippet = {
  type: 'snippet';
  snippetInfo: SnippetInfo;
  snippetCommentMarkdown?: string;
};

export type ParsedSnippets2DocElementWithMetadata = ParsedSnippets2DocSnippet & {
  snippetName: string;
  snippetFilePath: string;
};

export type ParsedSnippets2DocElement = ParsedSnippets2DocMarkdown | ParsedSnippets2DocSnippet;

export function parseSnippets2DocAIResponse(
  response: string,
  snippetRegexType?: 'snippetPlaceholder'
): Iterable<ParsedSnippets2DocElement>;
export function parseSnippets2DocAIResponse(
  response: string,
  snippetRegexType: SnippetRegexKind
): Iterable<ParsedSnippets2DocElement | ParsedSnippets2DocElementWithMetadata>;
export function* parseSnippets2DocAIResponse(
  response: string,
  snippetRegexType: SnippetRegexKind = 'snippetPlaceholder'
): Iterable<ParsedSnippets2DocElement | ParsedSnippets2DocElementWithMetadata> {
  for (const match of response.matchAll(snippets2DocAIResponseRegex(snippetRegexType))) {
    if (!match.groups) {
      continue;
    }
    const {
      previousLines,
      snippetCommentLines,
      snippetNumber,
      textAfterAllPlaceholders,
      snippetName,
      snippetFilePath,
    } = match.groups;
    if (textAfterAllPlaceholders) {
      yield { type: 'markdown', markdown: textAfterAllPlaceholders };
      continue;
    }
    if (previousLines) {
      yield { type: 'markdown', markdown: previousLines };
    }
    if (!snippetNumber) {
      if (snippetCommentLines) {
        yield { type: 'markdown', markdown: snippetCommentLines };
      }
      continue;
    }
    const parsedPos = parseInt(snippetNumber);
    if (Number.isNaN(parsedPos)) {
      if (snippetCommentLines) {
        yield { type: 'markdown', markdown: snippetCommentLines };
      }
      continue;
    }
    yield {
      type: 'snippet',
      number: parsedPos,
      ...(snippetName ? { snippetName } : {}),
      ...(snippetFilePath ? { snippetFilePath } : {}),
      ...(snippetCommentLines ? { snippetCommentMarkdown: snippetCommentLines } : {}),
    };
  }
}

type ResolvedSnippets2DocElement = ParsedSnippets2DocMarkdown | ResolvedSnippets2DocSnippet;

// Given the parsed AI response - replaces the snippet numbers with the actual snippet node elements from the original
// documents.
export function* resolveSnippets(
  parsedResponse: Iterable<ParsedSnippets2DocElement>,
  resolver: (number: number) => SnippetInfo | null
): Iterable<ResolvedSnippets2DocElement> {
  for (const element of parsedResponse) {
    const type = element.type;
    switch (type) {
      case 'markdown':
        yield element;
        break;
      case 'snippet': {
        const { number, snippetCommentMarkdown } = element;
        const resolvedSnippet = resolver(number);
        if (!resolvedSnippet) {
          if (snippetCommentMarkdown) {
            yield { type: 'markdown', markdown: snippetCommentMarkdown };
          }
          continue;
        }
        yield {
          type: 'snippet',
          snippetInfo: resolvedSnippet,
          ...(snippetCommentMarkdown ? { snippetCommentMarkdown } : {}),
        };
        break;
      }
      default:
        exhaustiveTypeException(type);
    }
  }
}

// Given the resolved snippets, merges consecutive snippets that have the same path, are adjacent to each other and have
// no comment between them.
export async function* mergeAdjacentSnippets(
  resolvedSnippets: Iterable<ResolvedSnippets2DocElement>,
  readLines: (filePath: string, startLine: number, endLine: number) => Promise<string[]>
): AsyncIterable<ResolvedSnippets2DocElement> {
  let previousSnippet: ResolvedSnippets2DocSnippet | null = null;
  for (const element of resolvedSnippets) {
    if (element.type === 'markdown') {
      if (previousSnippet) {
        yield previousSnippet;
        previousSnippet = null;
      }
      yield element;
      continue;
    }
    if (previousSnippet) {
      if (
        // If the snippet has no comment, check if it's immediately adjacent to the previous snippet.
        !element.snippetCommentMarkdown &&
        // Make sure it's the same file.
        previousSnippet.snippetInfo.filePath === element.snippetInfo.filePath &&
        // Make sure the lines are adjacent.
        (previousSnippet.snippetInfo.endLine + 1 === element.snippetInfo.startLine ||
          // If there's just one empty line between the snippets, we can still merge them (this can happen often because
          // we split long snippets at empty lines, and if a long function has an empty line in it, we'd split it into 2
          // snippets, and the LLM might want to refer to the whole function).
          previousSnippet.snippetInfo.endLine + 2 === element.snippetInfo.startLine)
      ) {
        let mergedSnippetContent: string;
        if (previousSnippet.snippetInfo.endLine + 1 < element.snippetInfo.startLine) {
          // If there's an empty line between the snippets, we need to read the content of the file between the snippets
          // to merge them.
          const lines = await readLines(
            previousSnippet.snippetInfo.filePath,
            previousSnippet.snippetInfo.startLine,
            element.snippetInfo.endLine
          );
          mergedSnippetContent = lines.join('\n');
        } else {
          mergedSnippetContent = `${previousSnippet.snippetInfo.content}\n${element.snippetInfo.content}`;
        }
        const mergedSnippetInfo: SnippetInfo = {
          ...previousSnippet.snippetInfo,
          content: mergedSnippetContent,
          endLine: element.snippetInfo.endLine,
        };
        previousSnippet = {
          type: 'snippet',
          snippetInfo: mergedSnippetInfo,
          snippetCommentMarkdown: previousSnippet.snippetCommentMarkdown,
        };
        continue;
      }
      yield previousSnippet;
    }
    previousSnippet = element;
  }
  if (previousSnippet) {
    yield previousSnippet;
  }
}

export function processSnippets2DocResponse(
  response: string,
  resolver: (number: number) => SnippetInfo | null,
  readLines: (filePath: string, startLine: number, endLine: number) => Promise<string[]>
): AsyncIterable<ResolvedSnippets2DocElement> {
  // Parse the response that contains snippet placeholders into elements of markdown and snippets.
  const parsedResponse = parseSnippets2DocAIResponse(response);
  // Given the snippet numbers, resolve them into actual snippets from the original document.
  const resolvedSnippets = resolveSnippets(parsedResponse, resolver);
  // Merge adjacent snippets that continue each other into one so that there's no long streak of consecutive snippets
  // without any comments.
  return mergeAdjacentSnippets(resolvedSnippets, readLines);
}

export async function snippets2DocResponseToSwmd(
  response: string,
  originalDoc: JSONContent,
  readLines: (filePath: string, startLine: number, endLine: number) => Promise<string[]>
): Promise<ProseMirrorFragment> {
  const originalRootNode = ProseMirrorNode.fromJSON(schema, originalDoc);
  const elements = processSnippets2DocResponse(
    response,
    (number: number): SnippetInfo | null => {
      if (number >= originalRootNode.nodeSize || originalRootNode.nodeAt(number)?.type?.name !== 'swmSnippet') {
        return null;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const currentSnippetNode = originalRootNode.nodeAt(number)!;
      return {
        filePath: currentSnippetNode.attrs.path,
        content: currentSnippetNode.attrs.snippet,
        startLine: currentSnippetNode.attrs.line,
        endLine: currentSnippetNode.attrs.line + currentSnippetNode.attrs.snippet.split('\n').length - 1,
        repoId: currentSnippetNode.attrs.repoId,
      };
    },
    readLines
  );

  let orderedContent = ProseMirrorFragment.empty;
  for await (const element of elements) {
    const type = element.type;
    switch (type) {
      case 'markdown':
        orderedContent = orderedContent.append(
          ProseMirrorFragment.from(ProseMirrorNode.fromJSON(schema, parseSwmdContent(element.markdown, {})).content)
        );
        break;
      case 'snippet': {
        const {
          snippetInfo: { content, startLine, filePath, repoId },
          snippetCommentMarkdown,
        } = element;
        const parsedContent = snippetCommentMarkdown
          ? ProseMirrorNode.fromJSON(schema, parseSwmdContent(snippetCommentMarkdown, {})).content
          : null;
        const snippetNode: ProseMirrorNode = schema.node(
          'swmSnippet',
          {
            snippet: content,
            line: startLine,
            path: filePath,
            repoId,
          },
          parsedContent ?? schema.node('paragraph')
        );
        orderedContent = orderedContent.addToEnd(snippetNode);
        break;
      }
      default:
        exhaustiveTypeException(type);
    }
  }
  // at the end we return the content of the newly constructed ProseMirror document to be sent back to the editor
  return orderedContent;
}

import { type SwimmDocument, getLoggerNew } from '@swimm/shared';
import type { User } from '@swimm/reefui';
import { filter, parse, toFileMetadata } from './input';
import { toSwmd } from './output';
import InvalidInputError from '../invalidInputError';

const logger = getLoggerNew('ppg/cdi/main.ts');

export default async function main(
  title: string,
  template: string,
  filePaths: string[],
  config: { repoId: string; repoName: string; branch: string; user: User }
): Promise<SwimmDocument> {
  logger.info('Starting CDI process');
  const validFilePaths: string[] = filter(filePaths);
  if (!validFilePaths.length) {
    logger.error('Invalid input >> no files match required input for composing a CDI document');
    throw new InvalidInputError('no files match required input for composing a CDI document');
  }

  const filesToProcess = toFileMetadata(filePaths);

  if (!filesToProcess.length) {
    logger.error('No relevant process files found');
    throw new InvalidInputError('No relevant process files found');
  }

  const taskFlows = await parse(filesToProcess, config.repoId, config.branch);

  if (!taskFlows.length) {
    logger.error('No TASKFLOW | WORKFLOW files selected');
    throw new InvalidInputError('No TASKFLOW files selected');
  }

  return toSwmd(
    {
      title,
      repoId: config.repoId,
      repoName: config.repoName,
      branch: config.branch,
      user: config.user,
    },
    taskFlows,
    template
  );
}

<template>
  <SwModal :show-modal="show" heading="Upgrade and unlock" :padded="false" @close="onClose">
    <div class="content">
      <img
        v-if="isEligibleForTrialPlan && allowUpgrade"
        class="header-image"
        src="@/modules/billing/assets/paywall-header.png"
        role="presentation"
      />
      <div class="info">
        <p class="description">{{ details }}</p>
        <UpgradeBenefitsList v-if="isEligibleForTrialPlan && allowUpgrade" :entity="entity" />
        <div class="actions" v-if="workspaceId && isWorkspaceAdmin">
          <Action
            v-if="allowUpgrade"
            class="narrow"
            data-testid="upgrade-button"
            :loading="startingTrial"
            @click="onUpgradePlan"
            >{{ callToActionText }}
          </Action>
          <Action v-if="isEligibleForTrialPlan" class="narrow" secondary @click="openPlansPage">See plans</Action>
          <Action class="narrow" :secondary="allowUpgrade" @click="openSalesCalendly">Contact sales</Action>
        </div>
      </div>
    </div>
  </SwModal>
</template>

<script lang="ts">
import { useStigg } from '@/common/composables/useStigg';
import {
  FREE_TRIAL_LENGTH,
  StiggFeatures,
  billingPlanTypes,
  config,
  getUserEntitlementByLicense,
  productEvents,
} from '@swimm/shared';
import { mapGetters } from 'vuex';
import { capitalize } from 'lodash-es';
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { SwModal } from '@swimm/ui';
import { useBillingStore } from '@/modules/billing/store/billing';
import { CALENDLY_SALES_LINK } from '@/config';
import { useAnalytics } from '@/common/composables/useAnalytics';
import { CloudFunctions } from '@/common/utils/cloud-functions-utils';
import { ref } from 'vue';
import { useNotificationsStore } from '@swimm/editor';
import UpgradeBenefitsList from '@/modules/billing/components/UpgradeBenefitsList.vue';

export const PaywallEntity = {
  USER: 'user',
  REPOSITORY: 'repository',
  WORKSPACE: 'workspace',
  TAG: 'tag',
  SHARING: 'sharing',
  GENERATIVE_AI: 'generative-ai',
  GENERATIVE_AI_CAP: 'generative-ai-cap',
  ASK_SWIMM_CAP: 'ask-swimm-cap',
  ASK_SWIMM: 'ask-swimm',
  SLACK: 'slack',
};

export const TEAM_PLAN = 'Team';

export const AnalyticsEntity = {
  [PaywallEntity.USER]: 'User',
  [PaywallEntity.REPOSITORY]: 'Private Repos',
  [PaywallEntity.WORKSPACE]: 'Workspaces',
  [PaywallEntity.TAG]: 'Tags',
  [PaywallEntity.SHARING]: 'Shared Docs',
  [PaywallEntity.GENERATIVE_AI]: 'Generative AI',
  [PaywallEntity.GENERATIVE_AI_CAP]: 'Generative AI Cap',
  [PaywallEntity.ASK_SWIMM_CAP]: 'Ask Swimm Cap',
  [PaywallEntity.ASK_SWIMM]: 'Ask Swimm',
  [PaywallEntity.SLACK]: 'Slack App',
};

export default {
  components: { SwModal, UpgradeBenefitsList },
  props: {
    entity: { type: String, required: true },
    show: { type: Boolean, default: false },
  },
  // emits only single event with all data
  emits: ['close'],
  setup(props) {
    const { user } = storeToRefs(useAuthStore());
    const { stiggClient } = useStigg();
    const billing = useBillingStore();
    const setShowBillingPlans = billing.setShowBillingPlans;
    const { isEligibleForTrialPlan } = storeToRefs(billing);
    const { trialSuccess, trialFailure } = billing;
    const analytics = useAnalytics();
    const { addNotification } = useNotificationsStore();

    const startingTrial = ref(false);
    const allowUpgrade = props.entity !== PaywallEntity.ASK_SWIMM;

    return {
      user,
      stiggClient,
      setShowBillingPlans,
      isEligibleForTrialPlan,
      analytics,
      trialSuccess,
      trialFailure,
      addNotification,
      startingTrial,
      allowUpgrade,
    };
  },
  computed: {
    ...mapGetters('database', ['db_getWorkspace', 'db_isWorkspaceAdmin']),
    isWorkspaceAdmin() {
      return this.db_isWorkspaceAdmin(this.workspaceId, this.user.uid);
    },
    workspaceId() {
      return this.$route.params.workspaceId;
    },
    details() {
      return this.entityToPaywallDetails[this.entity];
    },
    workspace() {
      return this.db_getWorkspace(this.workspaceId);
    },
    workspaceLicense() {
      return this.workspace.license || billingPlanTypes.FREE;
    },
    callToActionText() {
      return this.isEligibleForTrialPlan ? 'Start free trial' : 'Upgrade plan';
    },
    entityToPaywallDetails() {
      const reposEntitlement = this.stiggClient.getMeteredEntitlement({
        featureId: StiggFeatures.REPOS,
        options: {
          fallback: {
            hasAccess: true, // this is not used for feature gating
            usageLimit: config.FREE_TIER_LIMITS.privateRepos,
          },
        },
      });

      const usersEntitlement = this.stiggClient.getMeteredEntitlement({
        featureId: getUserEntitlementByLicense(this.workspaceLicense),
        options: {
          fallback: {
            hasAccess: true, // this is not used for feature gating
            usageLimit: config.FREE_TIER_LIMITS.users,
          },
        },
      });

      let resetDate = null;
      let resetDateToPrint = null;
      let cap = null;
      if (this.entity === PaywallEntity.GENERATIVE_AI_CAP) {
        const generativeAICapEntitlement = this.stiggClient.getMeteredEntitlement({
          featureId: StiggFeatures.GENERATIVE_AI_CAP,
        });
        cap = generativeAICapEntitlement?.usageLimit ?? null;
        resetDate = generativeAICapEntitlement?.resetSettings?.nextResetDate ?? null;
      } else if (this.entity === PaywallEntity.ASK_SWIMM_CAP) {
        const askSwimmCapEntitlement = this.stiggClient.getMeteredEntitlement({
          featureId: StiggFeatures.ASK_SWIMM_CAP,
        });
        cap = askSwimmCapEntitlement?.usageLimit ?? null;
        resetDate = askSwimmCapEntitlement?.resetSettings?.nextResetDate ?? null;
      }
      resetDateToPrint = resetDate
        ? new Date(resetDate).toLocaleDateString(undefined, { year: 'numeric', month: 'short', day: 'numeric' })
        : null;

      const isOnFreeTierUsersLimit =
        !usersEntitlement.usageLimit || usersEntitlement.usageLimit === config.FREE_TIER_LIMITS.users;

      return {
        [PaywallEntity.USER]: `Your ${capitalize(
          this.workspaceLicense.replace('-', ' ')
        )} Swimm account is limited to ${usersEntitlement.usageLimit || config.FREE_TIER_LIMITS.users} users.${
          this.workspaceLicense === billingPlanTypes.FREE
            ? '\n\nRemoved users also count towards this limit in the Free plan.\n'
            : ''
        }
        ${
          this.isEligibleForTrialPlan
            ? `You can start a free ${FREE_TRIAL_LENGTH}-day trial of Swimm's ${TEAM_PLAN} plan and get access to more users (no credit card required).`
            : `Get access to more users by upgrading your workspace to Swimm's ${
                isOnFreeTierUsersLimit ? `${TEAM_PLAN} or ` : ''
              }Enterprise plan.`
        }`,
        [PaywallEntity.REPOSITORY]: `Swimm's ${capitalize(
          this.workspaceLicense.replace('-', ' ')
        )} account comes with an option to sync ${
          reposEntitlement.usageLimit || config.FREE_TIER_LIMITS.privateRepos
        } private repository.
        ${
          this.isEligibleForTrialPlan
            ? `You can start a free ${FREE_TRIAL_LENGTH}-day trial of Swimm's ${TEAM_PLAN} plan and add unlimited private repositories (no credit card required).`
            : `To upgrade your workspace and add unlimited repositories, learn more about Swimm's ${TEAM_PLAN} or Enterprise plan.`
        }`,
        [PaywallEntity.WORKSPACE]: `Your ${capitalize(
          this.workspaceLicense.replace('-', ' ')
        )} Swimm account is limited to ${config.FREE_TIER_LIMITS.workspaces} workspace.
        ${
          this.isEligibleForTrialPlan
            ? `You can start a free ${FREE_TRIAL_LENGTH}-day trial of Swimm's ${TEAM_PLAN} plan and get access to more workspaces (no credit card required).`
            : this.isWorkspaceAdmin
            ? `You will have access to create more workspaces when you upgrade your workspace to Swimm's ${TEAM_PLAN} or Enterprise plan.`
            : 'Please contact your workspace admin.'
        }`,
        [PaywallEntity.SHARING]: `Your ${capitalize(
          this.workspaceLicense.replace('-', ' ')
        )} Swimm account does not include this feature.
        ${
          this.isEligibleForTrialPlan
            ? `You can start a free ${FREE_TRIAL_LENGTH}-day trial of Swimm's ${TEAM_PLAN} plan and get access to the document sharing feature (no credit card required).`
            : ''
        }`,
        [PaywallEntity.GENERATIVE_AI]: this.isEligibleForTrialPlan
          ? `Access Generative AI and other ${TEAM_PLAN} plan features today! Start your ${FREE_TRIAL_LENGTH}-day trial (no credit card required).`
          : `Your ${capitalize(this.workspaceLicense.replace('-', ' '))} Swimm account does not include this feature.`,
        [PaywallEntity.GENERATIVE_AI_CAP]: `Your workspace has reached the Generative AI query quota allowed for this month${
          cap ? ` (${cap})` : ''
        }.
        You can contact sales to increase your quota, or wait until the quota resets ${
          resetDateToPrint ? `on ${resetDateToPrint}` : 'at the end of the month'
        }.`,
        [PaywallEntity.ASK_SWIMM_CAP]: `Your workspace has reached the Ask Swimm query quota allowed for this month${
          cap ? ` (${cap})` : ''
        }.
        You can contact sales to increase your quota, or wait until the quota resets ${
          resetDateToPrint ? `on ${resetDateToPrint}` : 'at the end of the month'
        }.`,
        [PaywallEntity.ASK_SWIMM]: `Ask Swimm is only included in our Enterprise tiers.
Contact our sales team to set up a trial.`,
        [PaywallEntity.SLACK]: `The Swimm Slack app is only included in our Enterprise tiers.
Contact our sales team to set up a trial.`,
      };
    },
  },
  watch: {
    show(newValue) {
      if (newValue) {
        this.report(productEvents.OPENED_PAYWALL_MODAL, { 'Can Start Trial': this.isEligibleForTrialPlan });
      } else {
        this.report(productEvents.CLOSED_PAYWALL_MODAL);
      }
    },
  },
  methods: {
    async startTrial() {
      this.startingTrial = true;
      let notification = 'Your 14-day Team plan trial has started!';
      try {
        const subscribeResult = await CloudFunctions.subscribeToTrial({
          workspaceId: this.workspaceId as string,
          productSKU: billingPlanTypes.SMALL_TEAM,
          billingPeriod: 'ANNUALLY',
          quantity: 30,
        });
        if (subscribeResult?.data?.status !== 'success') {
          throw new Error(
            `Failed starting trial subscription to workspace ${this.workspaceId} (productSKU: ${billingPlanTypes.TEAM})`
          );
        } else {
          this.onClose({ isTrialStarted: true });
          await this.trialSuccess(billingPlanTypes.TEAM, this.workspace.name);
        }
      } catch (err) {
        this.trialFailure(err);
        notification = 'Could not start a trial - please contact support.';
      }
      this.addNotification(notification);
      this.startingTrial = false;
    },
    openPlansPage() {
      // Close global modal before redirecting to Plans Page
      this.setShowBillingPlans(true);
      this.onClose({ isRedirecting: true, isUpgrade: true });
    },
    async onUpgradePlan() {
      this.report(productEvents.CLICKED_START_TRIAL_PAYWALL_MODAL);

      if (this.isEligibleForTrialPlan) {
        await this.startTrial();
        return;
      }

      this.openPlansPage();
    },
    onNotifyAdmins() {
      this.onClose();
    },
    onClose(
      { isRedirecting, isTrialStarted, isUpgrade } = { isRedirecting: false, isTrialStarted: false, isUpgrade: false }
    ) {
      this.$emit('close', { isRedirecting, isTrialStarted, isUpgrade });
    },
    report(event, payload = {}) {
      this.analytics.track(event, {
        'Workspace ID': this.workspaceId,
        Context: AnalyticsEntity[this.entity],
        ...payload,
      });
    },
    openSalesCalendly() {
      this.analytics.track(productEvents.CLICKED_CONTACT_SALES, {
        Context: 'Paywall',
        'Workspace ID': this.workspaceId,
      });
      window.open(CALENDLY_SALES_LINK, '_blank');
      this.onClose();
    },
  },
};
</script>

<style scoped lang="postcss">
.content {
  max-width: 480px;
}

.header-image {
  height: 200px;
}

.info {
  padding-right: 24px;
  padding-bottom: 24px;
  padding-left: 24px;

  .description {
    white-space: pre-line;
  }
}

.actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: var(--space-base);

  .narrow {
    width: 180px;
    padding: 8px 16px;
  }
}
</style>

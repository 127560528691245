<script setup lang="ts">
import { computedAsync } from '@vueuse/core';
import { useAppModalsStore } from '@/modules/core/stores/modals-store';
import {
  BaseAnchor,
  BaseButton,
  BaseDivider,
  BaseDropdown,
  BaseHeading,
  BaseIcon,
  BaseInput,
  BaseLayoutGap,
  BaseLoading,
  BaseProse,
  NodeTree,
} from '@swimm/reefui';
import { FolderTree, WorkspaceDocumentTemplate, eventLogger, getLoggerNew, productEvents } from '@swimm/shared';
import { SwModal } from '@swimm/ui';
import { storeToRefs } from 'pinia';
import { computed, ref, watch } from 'vue';

import { useFsWrapper } from '@/modules/editor/tiptapEditor/compositions/fsWrapper';
import { useWorkspaceStore } from '@/modules/core/stores/workspace';
import { useAuthStore } from '@/modules/core/stores/auth-store';
import { useAnalytics } from '@/common/composables/useAnalytics';
import main from '@/modules/customizations/custom-process-to-doc/ppg/main';
import { useStore } from 'vuex';
import { useDrafts3Store } from '@/modules/drafts3/stores/drafts3';
import { useResourceActions } from '@/modules/resources/composables/resource-actions';
import InvalidInputError from '@/modules/customizations/custom-process-to-doc/ppg/invalidInputError';
import INSTRUCTIONS from '@/modules/customizations/custom-process-to-doc/ppg/instructions';

const props = withDefaults(
  defineProps<{
    repoId: string;
    branch: string;
  }>(),
  {
    repoId: '',
    branch: null,
  }
);

const logger = getLoggerNew(__modulename);

const store = useStore();
const { newDoc } = useDrafts3Store();
const { getResourceLink } = useResourceActions();
const { user } = storeToRefs(useAuthStore());
const { fsWrapper } = useFsWrapper();
const modalStore = useAppModalsStore();
const { id: workspaceId, documentTemplates, currentUser } = storeToRefs(useWorkspaceStore());
const { showProcessToDocModal } = storeToRefs(modalStore);
const { closeProcessToDocModal } = modalStore;
const analytics = useAnalytics();

const db_getRepoMetadata = computed(() => store.getters['database/db_getRepoMetadata']);
const isWorkspaceAdmin = computed<boolean>(() =>
  store.getters['database/db_isWorkspaceAdmin'](workspaceId.value, user.value.uid)
);

const selectedProcess = ref<WorkspaceDocumentTemplate>();
const selectedNodes = ref([]);
const docTitle = ref<string>('');
const query = ref<string>('');
const processLoading = ref<boolean>(false);
const newDocUrl = ref<string>('');
const error = ref<string>();
const nodeTreeKey = ref(Date.now());
const dropDownTreeKey = ref(Date.now());

const templates = computed(() => {
  if (documentTemplates.value instanceof Map) {
    return Array.from(documentTemplates.value.values()).map((template) => ({
      id: template.id,
      label: template.name,
      name: template.name,
      link: isWorkspaceAdmin.value
        ? getResourceLink({
            resource: { id: template.id },
            repoId: props.repoId,
            branch: props.branch,
            type: 'template',
          })
        : '',
    }));
  }
  return [];
});

const folderTree = computedAsync<FolderTree | null>(async () => {
  if (props.repoId == null || props.branch == null || !showProcessToDocModal.value) {
    return null;
  }
  return await getRepoFolderTree(props.repoId, props.branch, false);
});

const loadingTree = ref<boolean>(true);

async function getRepoFolderTree(repoId: string, branch: string, isCrossRepo: boolean): Promise<FolderTree> {
  await fsWrapper.populateRepoFolderTree({ repoId: repoId, branch, isCrossRepo });
  return await fsWrapper.getRepoFolderTree(repoId);
}

function addSelectedNode(node: FolderTree) {
  selectedNodes.value.push(node);
}
function removeSelectedNode(node: FolderTree) {
  selectedNodes.value = selectedNodes.value.filter((n) => n.path !== node.path);
}
function resetSelectedNodes() {
  selectedNodes.value = [];
  nodeTreeKey.value = Date.now();
}

function selectDocumentTemplate(templateName: string) {
  selectedProcess.value = documentTemplates.value.get(templateName);
}

watch(
  () => [folderTree.value, documentTemplates.value],
  (value) => {
    if (value[0]) {
      loadingTree.value = false;
    }
  },
  { immediate: true }
);

watch(
  () => [selectedNodes.value, selectedProcess.value],
  () => {
    if (error.value) {
      error.value = '';
    }
  }
);

const isValid = computed(() => {
  return docTitle.value && selectedProcess.value && selectedNodes.value.length > 0;
});

function resetModal() {
  resetSelectedNodes();
  docTitle.value = '';
  selectedProcess.value = {
    id: '',
    name: '',
    text: '',
    format: '',
  };
  dropDownTreeKey.value = Date.now();
  error.value = '';
}

function onCloseModal() {
  closeProcessToDocModal();
  resetModal();
}

async function onSubmit() {
  try {
    if (isValid.value) {
      processLoading.value = true;
      const title = docTitle.value;
      const template = selectedProcess.value.text;
      // selectedNodes also includes folders. We want to exclude those before triggering the generation process
      const nodesToProcess = selectedNodes.value.filter((node) => node.type === 'file');
      const filePaths = nodesToProcess.map((node) => node.path);
      const repoName: string = db_getRepoMetadata.value(props.repoId)
        ? db_getRepoMetadata.value(props.repoId).name
        : '';

      const doc = await main(title, template, selectedProcess.value.name.toLowerCase() as 'cdi' | 'cai', filePaths, {
        repoId: props.repoId,
        repoName,
        branch: props.branch,
        user: {
          id: currentUser.value,
          name: user.value.displayName,
          email: user.value.email,
        },
      });

      const newDocId = await newDoc(doc);

      processLoading.value = false;

      newDocUrl.value = getResourceLink({
        resource: { id: newDocId, name: title },
        repoId: props.repoId,
        branch: props.branch,
        type: 'document',
        editMode: true,
      });

      analytics.track(
        productEvents.PROCESS_DOC_CREATED,
        {
          template: selectedProcess.value.name,
          branch: props.branch,
          backofficeCode: eventLogger.SWIMM_EVENTS.PROCESS_DOC_CREATED.code,
        },
        { addRouteParams: true }
      );

      resetModal();
      return;
    }
  } catch (err) {
    processLoading.value = false;

    if (err instanceof InvalidInputError) {
      error.value = err.message;
    } else {
      error.value = 'An error occurred while generating the document, please contact support.';
    }

    logger.error({ err }, 'Error processing template to doc');
  }
}
</script>

<template>
  <div>
    <SwModal :padded="false" :show-modal="showProcessToDocModal" @close="onCloseModal">
      <template #header>
        <BaseLayoutGap alignment="left">
          <BaseIcon name="magic" />
          <BaseProse weight="bolder"> Process to doc </BaseProse>
        </BaseLayoutGap>
      </template>
      <div class="prs-modal">
        <BaseLayoutGap direction="column" size="small" alignment="stretch">
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Template </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <BaseDropdown
            :key="dropDownTreeKey"
            @selected="(e) => selectDocumentTemplate(e.name)"
            placeholder="Select a template"
            :options="templates"
            :footer="true"
          />
          <BaseProse v-if="selectedProcess?.name" variant="secondary" size="small">
            <pre class="prs-modal__pre-wrap">{{ INSTRUCTIONS[selectedProcess.name] }}</pre>
          </BaseProse>
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Input Files </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <div class="tree-container">
            <template v-if="loadingTree"> <BaseLoading /> </template>
            <template v-else>
              <NodeTree
                :key="nodeTreeKey"
                :query="query"
                :node="folderTree"
                :level-offset="1"
                :selected-nodes="selectedNodes"
                selectable
                selectable-folders
                @selected-nodes:add="addSelectedNode"
                @selected-nodes:remove="removeSelectedNode"
              />
            </template>
          </div>
          <BaseLayoutGap alignment="left" size="xxsmall">
            <BaseHeading :level="4"> Document Title </BaseHeading>
            <BaseHeading :level="4" style="color: var(--color-text-danger)"> *</BaseHeading>
          </BaseLayoutGap>
          <BaseInput v-model="docTitle" type="text" placeholder="Title" required />
        </BaseLayoutGap>
        <BaseLayoutGap direction="row" alignment="right" class="buttons-container">
          <BaseButton @click="onSubmit" :disabled="!isValid" :loading="processLoading">Submit</BaseButton>
        </BaseLayoutGap>

        <template v-if="newDocUrl">
          <BaseDivider size="xxsmall" />
          <BaseLayoutGap>
            <BaseProse variant="success"> Succesfully generated document : </BaseProse>
            <BaseAnchor :href="newDocUrl"> Go to doc. </BaseAnchor>
          </BaseLayoutGap>
        </template>
        <template v-if="error">
          <BaseDivider size="xxsmall" />
          <BaseProse variant="danger">
            {{ error }}
          </BaseProse>
        </template>
      </div>
    </SwModal>
  </div>
</template>

<style scoped>
.prs-modal {
  padding: var(--space-medium);
  width: 540px;
  display: flex;
  flex-direction: column;
  gap: var(--space-small);
  overflow-y: auto;

  &__pre-wrap {
    white-space: pre-wrap;
  }
}

.tree-container {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
</style>
